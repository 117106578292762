import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Role from './components/Role';

const role = ({ t }) => (
    <Container className="Custom_container">
        <Role />
    </Container>
);

role.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(role);
