

import React from 'react';
import {
  Button, Card, CardBody, Modal, ModalHeader, ModalBody,
  TabContent, TabPane, Nav, NavItem, NavLink, Row, Table
} from 'reactstrap';
import classnames from 'classnames';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { settings } from '../../../redux/actions/settingsAction'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '../../../shared/components/form/Select';
import { Redirect } from 'react-router-dom';
import ElasticProperties from '../../Cruds/Elastic/index';
import Scoring1 from '../../Cruds/BatchStatus/index'
import Comparision from '../../Cruds/Comparision/index'
import Scoring2 from '../../Cruds/Scoring2'
import PropertyTrace from "../../Cruds/PropertyTrace"


import Buckets from '../Buckets/index';
// Toaster message
// import validate from '../Validations/validate';
// Loader
// import Loader from '../App/Loader';
// // Calendar
// //session expiry modal
// import store from '../App/store';
import configMessages from '../../../config/configMessages'
// import DealSizingThreshold from '../DealSizingThreshold/index';
const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);

class NewScoring extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: '',
      options: [
        { label: 'OPRA Validated Property Lookup', value: 'OPRA Validated Property Lookup' },
        { label: 'OPRA Business Name Issue Lookup', value: 'OPRA Business Name Issue Lookup' }
        // { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
      ],
      isLoading: false,
      isUserUpdated: false,
      activeTab: '1'
    };
  }

  componentDidMount = async () => {
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    // let apiUrl=''
    this.setState({
      isLoading: true
    });

    // })
  }
  //send data to server

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  handleScreenChange = async (e) => {
    await this.setState({
      selectedScreen: e
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }

  }

  saveDataToServer() { }
  render() {
    const { handleSubmit } = this.props;
    const { options } = this.state
    let isSubmitting = false

    //
    return (
      <div style={{ width: "100%" }}>
        <Nav tabs >
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Properties In Elastic
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Scoring1
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              Scoring
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); }}
            >
              Map Scoring1 Properties
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '5' })}
              onClick={() => { this.toggle('5'); }}
            >
              Scored Properties
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '6' })}
              onClick={() => { this.toggle('6'); }}
            >
              Property Trace
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          {this.state.activeTab === "1" ?
            < TabPane tabId="1">
              {<ElasticProperties />}
            </TabPane>
            : null}

          {/* {this.state.activeTab === "2" ?
            < TabPane tabId="2">
              {<Scoring1
                tabType={"Scoring1"}
              />}
            </TabPane>
            : null} */}

          {this.state.activeTab === "3" ?
            < TabPane tabId="3">
              {<Scoring1
                tabType={"Scoring1"}
              />}
            </TabPane>
            : null}
          {/* {this.state.activeTab === "4" ?
            < TabPane tabId="4">
              {<Comparision
                tabType={"MapScoring1"}
              />}
            </TabPane>
            : null} */}
          {this.state.activeTab === "5" ?
            < TabPane tabId="5">
              {<Scoring2 />}
            </TabPane>
            : null}
          {this.state.activeTab === "6" ?
            < TabPane tabId="6">
              {<PropertyTrace />}
            </TabPane>
            : null}
        </TabContent>
      </div >
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
NewScoring = reduxForm({
  form: "Common Form", // a unique identifier for this form
  // validate,
  enableReinitialize: true,
})(NewScoring);

// You have to connect() to any reducers that you wish to connect to yourself
NewScoring = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  {
    load: loadAccount,// bind account loading action creator
    settingsLoad: settings,
  }
)(NewScoring);

export default NewScoring;






