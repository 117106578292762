import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OPRAValidatedPropertyLookup from './components/OPRAValidatedPropertyLookup';

const opraValidatedPropertyLookup = () => (
  <div>
    <OPRAValidatedPropertyLookup />
  </div>
);

opraValidatedPropertyLookup.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(opraValidatedPropertyLookup);
