import React from 'react';
import DataTables from '../../CommonDataTable/DataTableEstateMongo';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import ViewModal from '../../CommonModals/viewModal';
import NewUserModal from '../../CommonModals/NewUserModal';

import FormModal from '../../../Form/FormModal';
import store from '../../../App/store';
import filePath from "../../../../config/configSampleFiles";
// config file
export default class SearchName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: ''
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = []
    return data;
  };

  getTableFields1 = () => {
    let data = [
      {
        "name": "CompanyID",
        "type": "text",
        "placeholder": "CompanyID",
        "label": "CompanyID",
        "id": "CompanyID",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "CompanyID",
        "header": "CompanyID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 30,
        sortable: false,
        // "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" },
        "options": [
          { label: 'Green', value: 'Green', color: 'success' },
          { label: 'Blue', value: 'Blue', color: 'primary' },
          { label: 'Yellow', value: 'Yellow', color: 'warning' },
          { label: 'Brown', value: 'Brown', color: 'secondary' },
          { label: 'Black', value: 'Black', color: 'dark' }
        ]
      },
      {
        "name": "SicCode",
        "type": "text",
        "placeholder": "SicCode",
        "label": "SicCode",
        "id": "SicCode",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "SicCode",
        "header": "SicCode",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 30,
        sortable: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "SicDescription",
        "type": "text",
        "placeholder": "SicDescription",
        "label": "SicDescription",
        "id": "SicDescription",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "SicDescription",
        "header": "SicDescription",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 60,
        sortable: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "CompanyName",
        "type": "text",
        "placeholder": "CompanyName",
        "label": "CompanyName",
        "id": "CompanyName",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "CompanyName",
        "header": "CompanyName",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 80,
        sortable: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "Address1",
        "type": "text",
        "placeholder": "Address1",
        "label": "Address1",
        "id": "Address1",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "Address1",
        "header": "Address1",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 40,
        sortable: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "City",
        "type": "text",
        "placeholder": "City",
        "label": "City",
        "id": "City",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "City",
        "header": "City",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 30,
        sortable: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "StateProvince",
        "type": "text",
        "placeholder": "StateProvince",
        "label": "StateProvince",
        "id": "StateProvince",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "StateProvince",
        "header": "StateProvince",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Number",
        "width": 30,
        sortable: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      }
    ]
    return data;
  };

  getFormFields = () => {
    return ([]);
  }

  getFormFields1 = () => {
    return ([]);
  }

  submit = async (item) => {

    this.setState({ isOpenFormModal: true })
    await this.formModalRef.getRowData(item, 'edit');

    console.log("Submit Button in sode          ")
  }
  getMobileTableFields = () => {
    let data = [{ "textAlign": "center", "width": 47, "field": "", "fieldType": "multiple", "header": "", "selectionMode": "multiple", "show": true, "mobile": true, "displayInSettings": true }, { "textAlign": "center", "width": 47, "field": "Sno", "header": "SNo", "filter": false, "sortable": false, "placeholder": "Search", "show": true, "mobile": true, "displayInSettings": true }, { "name": "propertyName", "type": "text", "placeholder": "propertyName", "label": "Matching Property Name", "id": "propertyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyName", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyString", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "NameMatchScore", "type": "text", "placeholder": "NameMatchScore", "label": "Name Score", "id": "NameMatchScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "NameMatchScore", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }]

    return data;
  };
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
    })
  }

  cancelReset = async (type) => {
    this.setState({
      openNewUserModal: false
    });
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };
  render() {
    return (
      <span>
        <DataTables
          onRef={ref => (this.dataTableRef = ref)}
          // MobileTableFields={this.getMobileTableFields}
          // getTableFields={this.getTableFields}
          // formFields={this.getFormFields}
          // 
          addRequired={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={false}
          exportRequired={false}
          sample={false}
          getTableFields={this.getTableFields}
          getTableFields1={this.getTableFields1}
          formFields={this.getFormFields}
          // exportRequried={true}

          printRequried={false}
          actionsTypes={[{
            'name': 'Delete',
            "options": [
              { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
            ]
          },
          // {
          //   'name': 'Block',
          //   "options": [
          //     { 'label': 'Block', 'value': 'Block', 'show': true, "multiple": false, }
          //   ]
          // },
          {
            'name': 'ResetPassword',
            "options": [
              { 'label': 'ResetPassword', 'value': 'ResetPassword', 'show': true, "multiple": false, }
            ]
          },
          {
            'name': 'Submit',
            'action': this.submit,
            "options": [
              { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
            ]
          },
            // {
            //   name: "newModel",
            //   action: this.submit1,
            //   options: [
            //     { label: "newModel", value: "newModel", show: true, multiple: false }
            //   ]
            // }

          ]}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={true}
          gridRequried={true}
          exportToCsv={true}

          sampleFilePath={filePath.scorings}

          // globalSearch={'Name String'}
          type='SearchName'
          routeTo={apiCalls.SearchName}
          displayViewOfForm='screen'
          apiResponseKey={"SearchName"}
          apiUrl={apiCalls.SearchName}

        />
      </span>
    );
  }
}