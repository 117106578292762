module.exports = {
    array: [
        {
            label: "Commercial Index",
            value: "com"
        },
        {
            label: "Residential Index",
            value: "res"
        },
        {
            label: "Both Indexes",
            value: "both"
        }, {
            label: "Ultimate Indexes",
            value: "ultimate"
        }
    ]
}