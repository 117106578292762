import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Scorings from './components/Scorings';

const scorings = ({ t }) => (
  <div>
    <Scorings />
  </div>
);

scorings.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(scorings);
