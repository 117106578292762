import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Comparision from './components/Comparision';

const comparision = (props) => (
  <div>
    <Comparision
      {...props}
    />
  </div>
);

comparision.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(comparision);
