import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Scoring2 from './components/Scoring2';

const scoring2 = (props) => (
  <div>
    <Scoring2
      {...props}
    />
  </div>
);

scoring2.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(scoring2);
