import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ErrorsAndExceptions from './components/ErrorsAndExceptions';

const errorsAndExceptions = () => (
  <div>
    <ErrorsAndExceptions />
  </div>
);

errorsAndExceptions.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(errorsAndExceptions);
