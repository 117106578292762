import config from './config';
import showToasterMessage from '../containers/UI/ToasterMessage/toasterMessage';
import SessionExpiryModal from '../containers/Cruds/CommonModals/SessionexpiryModal'
let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

// To get logged in user token
const getLoggedInuserToken = () => {
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    if (userData) {
        headers.Authorization = `Bearer ${userData.accessToken}`;
        headers.logggedInUserData = {
            email: userData.email,
            password: userData.password,
        }
        return headers;
    } else {
        return headers;
    }
}

const fetchMethodRequest = (method, url, body, type, value, email, ScoringThresholds, companyName, propertyIds, ElasticIndex, ElasticQueryComplexity) => {
    if (url === 'auth/login') {
        return sendRequestToServer(method, url, body, headers);
    } else {
        let headers = getLoggedInuserToken();
        // upload method conditions, headers
        if (type && type == 'upload') {
            let formData = new FormData();
            if (body) {
                formData.append('file', body);
            }
            if (headers.logggedInUserData) {
                delete headers.logggedInUserData;
            }
            body = {
                isUploadingImage: true,
                imageInfo: formData,
            }
        }
        let flag = ''
        if (type && type == 'elastic') {
            flag = 'elastic'
        } else if (type && type == 'opp') {
            flag = 'opp'
        }
        return sendRequestToServer(method, url, body, headers, flag, value, email, ScoringThresholds, companyName, propertyIds, ElasticIndex, ElasticQueryComplexity)
            .then(response => {
                if (response) {
                    if (response.errorCode && response.errorCode === 9001) { // token expiry
                        return response;
                    } else {
                        return response;
                    }
                }
            })
            .catch((err) => {
                // console.log('error ' + err)
            });
    }
}

// generate guid and update headers from Azure Access Token
export const sendLoginRequestForToken = (accessToken) => {
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    return sendRequestToServer('POST', 'api/auth/login', {
        'accessType': config.entityType,
        offsetTime: new Date().getTimezoneOffset() / 60,
    }, headers, false)
        .then(
            (response) => {
                if (response && response.respCode && response.respCode === 200) {
                    let userDetails = response.details;
                    if (userDetails && userDetails._id) {
                        if (response.accessToken) {
                            let tokenInfo = {
                                accessToken: response.accessToken,
                                refreshToken: response.refreshToken,
                                tokenExpires: response.tokenExpires,
                            };
                            userDetails = { ...userDetails, ...tokenInfo };
                            // save user credentials in storage

                            let expiryTime = response.expireTime * 60 * 1000
                            localStorage.setItem('loggedTime', expiryTime);
                            // save user credentials in storage
                            localStorage.setItem('loginCredentials', JSON.stringify(userDetails));

                        }
                        if (userDetails && userDetails.rolePermissions) {
                            let permissions = userDetails.rolePermissions;
                            localStorage.setItem('rolePermissions', JSON.stringify(permissions));
                        }
                    }
                    return userDetails;
                } else if (response.errorCode) {
                    throw Error(`Login Failed: ${response.errorMessage}`)
                }
            });
}

const sendRequestToServer = (method, url, body, headers, flag, value, email, ScoringThresholds, companyName, propertyIds, ElasticIndex, ElasticQueryComplexity) => {
    console.log("method, url, body, headers, flag, value", method, url, body, headers, flag, value)
    let reqHeaders = { ...headers };

    if (reqHeaders && reqHeaders.logggedInUserData) {
        delete reqHeaders.logggedInUserData;
    }
    let isImageReqSent = false;
    let request;

    if (body && body.isUploadingImage) { // only for image upload
        isImageReqSent = true;
        request = fetch(`${config.apiUrl}${url}`, {
            method: method,
            headers: {
                'Authorization': `${reqHeaders.Authorization}`
            },
            body: body.imageInfo
        })

    }
    // console.log(method, body, `${config.apiUrl}${url}`);
    // console.log(reqHeaders);

    value = encodeURIComponent(value);
    companyName = encodeURIComponent(companyName);

    if (!isImageReqSent) { // send request for call except image upload
        if (url.includes('submitJob')) {
            if (flag == 'elastic') {
                request = fetch(`${config.elasticApiUrl}/api/estate/?query=${value}&job=true&email=${email}&ScoringThresholds=${ScoringThresholds}&companyName=${companyName}&propertyIds=${propertyIds}&typeOfIndex=${ElasticIndex}&companySourceType=OPRA&ScoringType=RegularScoring&ElasticQueryComplexity=${ElasticQueryComplexity}`, { method: method })
            } else if (flag == 'opp') {
                request = fetch(`${config.elasticApiUrl}/api/estate/opp?query=${value}&job=true&email=${email}&ScoringThresholds=${ScoringThresholds}&companyName=${companyName}&propertyIds=${propertyIds}&typeOfIndex=${ElasticIndex}&companySourceType=OPRA&ScoringType=RegularScoring&ElasticQueryComplexity=${ElasticQueryComplexity}`, { method: method })
            }
        }
        else if (flag == 'elastic') {
            request = fetch(`${config.elasticApiUrl}/api/estate/?query=${value}&job=false&email=${email}&ScoringThresholds=${ScoringThresholds}&companyName=${companyName}&propertyIds=${propertyIds}&typeOfIndex=${ElasticIndex}&companySourceType=OPRA&ScoringType=RegularScoring&ElasticQueryComplexity=${ElasticQueryComplexity}`, { method: method })
        } else if (flag == 'opp') {
            request = fetch(`${config.elasticApiUrl}/api/estate/opp?query=${value}&job=false&email=${email}&ScoringThresholds=${ScoringThresholds}&companyName=${companyName}&propertyIds=${propertyIds}&typeOfIndex=${ElasticIndex}&companySourceType=OPRA&ScoringType=RegularScoring&ElasticQueryComplexity=${ElasticQueryComplexity}`, { method: method })
        }
        else if (url.includes('scorings')) {
            request = fetch(`${config.apiUrl}`, { method: method, headers: reqHeaders })

        }
        else if (method === 'GET' || method === 'DELETE') {
            request = fetch(`${config.apiUrl}${url}`, { method: method, headers: reqHeaders })
        } else if (method === 'POST' || method === 'PUT') {
            request = fetch(`${config.apiUrl}${url}`, { method: method, headers: reqHeaders, body: JSON.stringify(body) })
        }
        console.log("request", request)
    }

    return request
        .then(res => {
            console.log("res", res)
            let result = res.json()
            return result
        })
        .then(responseJson => {
            console.log("responseJson", responseJson)
            if (responseJson && responseJson['errorMessage'] == "Session expired please login again.") {
                localStorage.setItem('sessionexpired', true)
            }
            // console.log(responseJson);
            return responseJson;
        }).catch(err => {
            showToasterMessage(config.serverErrMessage, 'error');
            return 'serverError';
        });
}
export default fetchMethodRequest;
