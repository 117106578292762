import React from 'react';
import DataTables from '../../CommonDataTable/DatatableCommon';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import Loader from '../../../App/Loader';
import fetch from '../../../../config/service';
import fetchMethodRequest from '../../../../config/service';
import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class Employees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Employees',
            loading: false,
            sessionWarning: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        let storeData = store.getState()
        let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
        return { languageData: languageData };
    }



    componentDidMount = () => {
        //Screen permisions value can be edit,view, no view
        return fetch('GET', apiCalls.Roles)
            .then(async (response) => {
                if (response && response.roles && response.roles.length > 0) {
                    let data = response.roles, tempData = [];
                    for (let i = 0; i < data.length; i++) {
                        tempData.push({ 'label': data[i]['role'], "value": data[i]['role'] })
                    }
                    await this.setState({
                        roleOptions: tempData
                    })
                } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
                    await this.setState({
                        sessionWarning: true,

                    })
                } else {
                    await this.setState({
                        roleOptions: []
                    })
                }
            }).catch((err) => {
                return err;
            });
    }
    getTableFields = () => {
        let data = [
            // {
            //     "textAlign": "center",
            //     "width": 47,
            //     "field": "",
            //     "fieldType": 'multiple',
            //     "header": "",
            //     'selectionMode': 'multiple',
            //     "show": true,
            //     "mobile": true,
            //     "displayInSettings": false,
            // },
            {
                "show": true,
                textAlign: 'center',
                width: 150,
                field: 'display_name',
                "mobile": true,
                fieldType: 'Badge',
                tableField: 'name',
                header: 'Name',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
                options: [{ "label": "Active", "value": "Active", "color": "success" }, { "label": "Inactive", "value": "Inactive", "color": "warning" }, { "label": "Pending", "value": "Pending", "color": "danger", }],
                filterElement: [{ "label": "All", "value": null }, { "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "email",
                "mobile": true,
                "header": "Email",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "secondaryEmail",
                "mobile": true,
                "header": "email",
                "displayInSettings": true,
                "filter": true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "mobile": true,
                "field": "phone",
                "header": "Phone",
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": false,
                //"options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "role",
                "mobile": true,
                "header": "Role",
                "filter": true,
                "sortable": true,
                "displayInSettings": true,
                "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
                // "fieldType": 'Role',
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "mobile": true,
                "field": "status",
                "header": "status",
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": false,
                //"options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 180,
                "fieldType": 'Actions',
                "field": "Actions",
                "header": "Actions",
                "filter": false,
                "displayInSettings": true,
                "sortable": false
            }
        ]
        let screenPermissions = RolePermissions.screenPermissions('Users');
        if (screenPermissions != 'Edit') {
            data.pop();
        }
        return data;
    };


    getFormFields = () => {
        return ([
            {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "fname",
                "label": "First Name",
                "id": "name",
                "placeholder": "First Name",
                "required": true
            },
            {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "lname",
                "label": "Last Name",
                "id": "lname",
                "placeholder": "Last Name",
                "required": true
            },
            {
                "value": "",
                "type": "email",
                "name": "email",
                "label": "Email",
                "id": "email",
                "placeholder": "UserName",
                "required": true
            },
            {
                "value": "",
                "type": "text",
                "name": "secondaryEmail",
                "label": "Secondary Email",
                "id": "secondaryEmail",
                "placeholder": "Email",
                "required": true
            },
            {
                "value": "",
                "type": "text",
                "name": "phone",
                "label": "Phone",
                "id": "phone",
                "formatType": 'US',
                "placeholder": "Phone",
                "required": true
            },
            {
                "value": "",
                "type": "dropDown",
                "name": "status",
                "label": "Status",
                "id": "status",
                //"formatType": 'US',
                "placeholder": "Status",
                "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
                "required": true
            },
            {
                "value": "",
                "type": "dropDown",
                // "isMultiSelect": true,
                "name": "role",
                "label": "Role",
                "id": "Role",
                //"formatType": 'US',
                "placeholder": "Role",
                "options": this.state.roleOptions,
                "required": true
            }

        ]);
    }



    submit = async (item) => {

    }

    render() {
        return (
            <span>
                <Loader loader={this.state.isLoading} />
                <DataTables
                    onRef={(ref) => (this.datatableref = ref)}
                    getTableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    statusFilter={false}
                    isClearFilters={false}
                    className={false}
                    exportRequried={false}
                    printRequried={false}
                    addRequried={true}
                    editRequired={true}
                    deleteRequired={true}
                    viewRequired={true}
                    settingsRequired={false}
                    filterRequired={false}
                    gridRequried={false}
                    sample={false}
                    // globalSearchFieldName='user'
                    // globalSearch={'Search'}
                    type='Users'
                    displayright={false}
                    icon='users'
                    routeTo='users'
                    displayViewOfForm='modal'
                    apiResponseKey='users'
                    apiUrl={apiCalls.Users}
                    entityType='employee'
                    sessionWarning={this.state.sessionWarning}
                />

            </span>
        );
    }
}