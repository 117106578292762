import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CompanyNamesSQL from './components/CompanyNamesSQL';

const companyNamesSQL = () => (
  <div>
    <CompanyNamesSQL />
  </div>
);

companyNamesSQL.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(companyNamesSQL);
