import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import fetch from '../../../config/service'
import configMessages from '../../../config/configMessages';
import { Redirect } from 'react-router-dom';
// config file
export default class confirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    deleteSelectedRow = () => {
        // this.props.deleteSelectedRow();
    }
    signedIn = () => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
            this.setState({ sessionExpiryModal: true })
        }
        return fetch('POST', `Auth/refresh-AuthenticationToken`)
            .then((response) => {
                if (response) {
                    if (response.respCode && response.respCode === 200) {
                        // display message
                        // showToasterMessage(response.respMessage, 'success');
                    } else if (response.errorMessage && response.errorMessage === configMessages.tokenMessage) {
                        this.setState({ sessionExpiryModal: true })
                    }
                    this.setState({ isLoading: false });
                    this.props.cancelSessionWarningModal()
                    this.props.getData()
                }
            }).catch((err) => {
                return err;
            });
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.openConfirmationModal} fade={false} className='deleteModalHeight'>
                    <ModalBody className='deleteModalBody'>
                        <div style={{ marginBottom: 10 }}>Session is about to expire in 5 minutes</div>
                        <Button color="primary" outline onClick={this.signedIn}
                            className='deleteModalBtn'>Stay Signed in</Button>
                    </ModalBody>
                </Modal>
                {this.state.sessionExpiryModal ?
                    <Redirect to="/log_in" /> : null}
            </div>

        );
    }
}