import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls';
import fetch from '../../../../config/service'
import DataTables from '../../CommonDataTable/DatatableCommon';
import config from '../../../../config/config';

// config file
export default class Roles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languageData: '',
            menuList: []
        };
    }
    componentDidMount = async () => {
        this.getMenuListFromServer();
    }

    getMenuListFromServer = async () => {
        let filterCriteria = {}, url;
        filterCriteria.sortfield = 'sequenceNo';
        filterCriteria.direction = 'asc';
        url = `${apiCalls.MenuList}?filter=${JSON.stringify(filterCriteria)}`;
        let neWmenuList = [];
        return fetch('GET', url)
            .then(async (res) => {
                if (res && res.menulists && res.menulists.length > 0) {
                    if (res.menulists) {
                        res.menulists.forEach((item) => {
                            if (item.submenus && item.submenus.length > 0) {
                                item.submenus.forEach(sitem => {
                                    neWmenuList.push(sitem);
                                })
                            } else {
                                neWmenuList.push(item);
                            }
                        });
                    }
                    this.setState({
                        menuList: neWmenuList
                    })
                } else {
                    this.setState({
                        menuList: []
                    })
                }
                console.log("menuList", this.state.menuList)
            })

    }

    static getDerivedStateFromProps(props, state) {
        let storeData = store.getState()
        let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
        return { languageData: languageData };
    }

    getTableFields = () => {
        let data = [

            {
                "show": true,
                "textAlign": "left",
                "mobile": true,
                "width": 50,
                "field": "role",
                "tableField": "role",
                "fieldType": 'Badge',
                options: [{ "value": "Active", "color": "success" }, { "value": "Inactive", "color": "danger" }, { "value": "Pending", "color": "danger", }],
                filterElement: [{ "label": "All", "value": null }, { "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
                "header": "Role",
                "filter": true,
                "sortable": true
            },
            // { "textAlign": "left", "width": 150, "field": "permission", "header": "Permissions", "filter": true, "sortable": true },
            {
                "show": true,
                "textAlign": "left",
                "width": 30,
                "field": "Actions",
                "fieldType": "Actions",
                "header": "Actions",
                "filter": true, "sortable": false
            }
        ]
        return data;
    };

    getFormFields = () => {
        return ([{ "value": "", "type": "text", "name": "role", "label": "Role", "id": "role", "placeholder": "Role", "required": true },
        // {
        //     value: "",
        //     type: "radio",
        //     name: "isHold",
        //     label: "Hold",
        //     id: "isHold",
        //     options: config.biOptions,
        //     required: true,
        // },
        { "value": "", "type": "permission", "name": "permission", "label": "Permission", "id": "permission", "placeholder": "permission", "required": true },
        ]);
    }

    render() {
        return (
            <DataTables
                onRef={(ref) => (this.datatableref = ref)}
                getTableFields={this.getTableFields}
                formFields={this.getFormFields}
                exportRequried={false}
                printRequried={false}
                addRequried={true}
                editRequired={true}
                deleteRequired={true}
                viewRequired={true}
                settingsRequired={false}
                filterRequired={false}
                gridRequried={false}
                isClearFilters={false}
                sample={false}
                // globalSearchFieldName='user'
                // globalSearch={'Search'}
                type='Roles'
                icon='user'
                routeTo='roles'
                displayViewOfForm='modal'
                apiResponseKey='roles'
                apiUrl={apiCalls.Roles}
                entityType='employee'
                menuList={this.state.menuList}
            />
        );
    }
}