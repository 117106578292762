import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';
import ViewModal from '../../CommonModals/viewModal';

// config file
export default class Activities extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'Activities',
			rolePermission: ""
		};
	}

	static getDerivedStateFromProps(props, state) {
		let storeData = store.getState()
		let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
		return { languageData: languageData };
	}

	componentDidMount = () => {
		//Screen permisions value can be edit,view, no view
		let screenPermissions = RolePermissions.screenPermissions('Activities');
		if (screenPermissions) {
			this.setState({
				rolePermission: screenPermissions
			})
		}
	}

	getTableFields = () => {
		let { languageData } = this.state
		let data =
			[
				{
					textAlign: "left",
					width: 80,
					field: "batchNo",
					mobile: true,
					header: "Batch No.",
					filter: true,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
				},
				{
					textAlign: "left",
					width: 80,
					field: "type",
					mobile: true,
					header: "Type",
					filter: true,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
				},
				{
					textAlign: "left",
					width: 80,
					field: "status",
					mobile: true,
					header: "Status",
					filter: false,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
				},
				{
					textAlign: "left",
					width: 80,
					field: "processedCount",
					mobile: true,
					header: "Processed Count",
					filter: true,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
					fieldType: "Number",
				},
				{
					textAlign: "center",
					show: true,
					mobile: true,
					width: 80,
					field: "insertDate",
					fieldType: "Date",
					dateFormat: config.basicDateFromat,
					header: "Created Date",
					filter: false,
					sortable: true,
					displayInSettings: true,
				},
				{
					textAlign: "center",
					show: true,
					mobile: true,
					width: 80,
					field: "updatedDate",
					fieldType: "Date",
					dateFormat: config.basicDateFromat,
					header: "Updated Date",
					filter: false,
					sortable: true,
					displayInSettings: true,
				},
				{
					textAlign: "right",
					width: 80,
					field: "error",
					mobile: true,
					header: "error",
					filter: false,
					sortable: true,
					show: true,
					fieldType: "text",
					displayInSettings: true,
				}
			]
		return data;
	};


	getFormFields = () => {
		let { languageData } = this.state
		return ([
			{
				'show': false,
				"value": "",
				"type": "text",
				"name": "firstName",
				"label": "First Name",
				"id": "firstName",
				"placeholder": "First Name",
				"required": true
			},

		]);
	}

	render() {
		return (
			<span>
				<DataTables
					getTableFields={this.getTableFields}
					formFields={this.getFormFields}
					actionsTypes={[{
						'name': 'Delete',
						"options": [
							{ 'label': 'Delete', 'value': 'Delete', 'show': this.state.rolePermission && this.state.rolePermission == "Edit" ? true : false, "multiple": true, },
						]
					},
					]}
					exportRequried={false}
					printRequried={false}
					addRequried={false}
					editRequired={false}
					deleteRequired={false}
					viewRequired={false}
					settingsRequired={true}
					filterRequired={false}
					gridRequried={false}
					sample={false}
					// globalSearchFieldName='activity'
					// globalSearch={"Context/Email/Description"}
					type="BusinessNameIssueLookup"
					apiResponseKey={"BusinessNameIssueLookup"}
					apiUrl={"api/BusinessNameIssueLookup/"}
					routeTo='BusinessNameIssueLookup'
					displayViewOfForm='screen'
				/>
			</span>
		);
	}
}