import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

import fetch from "../../../config/service";
import apiCalls from '../../../config/apiCalls';

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: [],
      loginCredentials: {}
    }
  }
  componentDidMount = async () => {
    let loginCredentials = localStorage.getItem("loginCredentials");
    if (loginCredentials) {
      loginCredentials = JSON.parse(loginCredentials);
      this.setState({
        loginCredentials: loginCredentials
      })
    }
    await this.getMenuListFromServer();
  }

  getMenuListFromServer1 = async () => {
    //   let loginCredentials = this.state.loginCredentials;
    //   if (loginCredentials) {
    //     let filterCriteria = {}, url;
    //     filterCriteria.sortfield = 'sequenceNo';
    //     filterCriteria.direction = 'asc';
    //     // url = `menus?filter=${JSON.stringify(filterCriteria)}`;
    //     // return fetch('GET', url)
    //     //   .then(async (res) => {
    //     //     if (res && res.menus && res.menus.length > 0) {
    let menuList = [
      // {
      //   "icon": "employee",
      //   "displayTitle": "Employees",
      //   "title": "Employee",
      //   "route": "/employee"
      // }, {
      //   "icon": "activities",
      //   "displayTitle": "Activitiess",
      //   "title": "Activities",
      //   "route": "/activities"
      // }, {
      //   "icon": "email templates",
      //   "displayTitle": "Email Templatess",
      //   "title": "Email Templates",
      //   "route": "/email templates"
      // }, {
      //   "icon": "roles",
      //   "displayTitle": "Roless",
      //   "title": "Roles",
      //   "route": "/roles"
      // }, {
      //   "icon": "upload history",
      //   "displayTitle": "Upload Historys",
      //   "title": "Upload History",
      //   "route": "/upload history"
      // },
      // {
      //   "icon": "users",
      //   "displayTitle": "Users",
      //   "title": "Users",
      //   "route": "/users"
      // },
      // {
      //   "icon": "scoring",
      //   "displayTitle": "Main",
      //   "title": "Main",
      //   "route": "/scorings"
      // },
      {
        "icon": "Scoring",
        "displayTitle": "Scoring",
        "title": "Scoring",
        "route": "/NewScoring"
      },
      {
        "icon": "Regular Scoring Bucket",
        "displayTitle": "Regular Scoring Bucket",
        "title": "Regular Scoring Bucket",
        "route": "/buckets"
      },
      {
        "icon": "Deal Sizing Bucket",
        "displayTitle": "Deal Sizing Bucket",
        "title": "Deal Sizing Bucket",
        "route": "/dealbuckets"
      },
      {
        "icon": "ElasticQuery",
        "displayTitle": "ElasticQuery",
        "title": "ElasticQuery",
        "route": "/elasticQuery"
      },
      {
        "icon": "Bucket Score",
        "displayTitle": "Bucket Check",
        "title": "BucketScore",
        "route": "/bucketScore"
      },
      {
        "icon": "Compare Strings",
        "displayTitle": "Compare Strings",
        "title": "CompareStrings",
        "route": "/compareStrings"
      },
      // {
      //   "icon": "EstateNameResults",
      //   "displayTitle": "Estate Name Results",
      //   "title": "Estate Name Resluts",
      //   "route": "/estateNameResult"
      // },
      // {
      //   "icon": "Audi Logs",
      //   "displayTitle": "Audit Logs",
      //   "title": "Audit Logs",
      //   "route": "/activities"
      // },
      // {
      //   "icon": "Estate Mongo",
      //   "displayTitle": "Estate Mongo",
      //   "title": "Estate Mongo",
      //   "route": "/estateMongo"
      // },
      // {
      //   "icon": "Scoring Batch",
      //   "displayTitle": "Scoring Batch",
      //   "title": "Scoring Batch",
      //   "route": "/batchStatus"
      // },
      {
        "icon": "Configutaion Screen",
        "displayTitle": "Configutaion Screen",
        "title": "Configutaion Screen",
        "route": "/configuration"
      },
      // {
      //   "icon": "Elastic Properties",
      //   "displayTitle": "Elastic Properties",
      //   "title": "Elastic Properties",
      //   "route": "/elastic"
      // },
      // {
      //   "icon": "Comparision",
      //   "displayTitle": "Comparision",
      //   "title": "Comparision",
      //   "route": "/comparision"
      // },

      // {
      //   "icon": "UP Opportunity",
      //   "displayTitle": "UP Opportunity",
      //   "title": "UP Opportunity",
      //   "route": "/upOpportunity"
      // },
      // {
      //   "icon": "Search Names",
      //   "displayTitle": "Search Names",
      //   "title": "SearchNames",
      //   "route": "/SearchNames"
      // },
    ];
    //     // menuList = res.menus;
    //     await this.setState({
    //       menuList: menuList
    //     })
    //     //     }
    //     //   })
    //   }
    await this.setState({ menuList: menuList });
  }

  getMenuListFromServer = async () => {
    let loginCredentials = this.state.loginCredentials;
    if (loginCredentials) {
      let filterCriteria = {}, url;
      filterCriteria.sortfield = 'sequenceNo';
      filterCriteria.direction = 'asc';
      url = `${apiCalls.MenuList}?filter=${JSON.stringify(filterCriteria)}`;
      return fetch('GET', url)
        .then(async (res) => {
          console.log(res)
          if (res && res.menulists && res.menulists.length > 0) {
            let menuList = res.menulists;
            let rolePermissions = localStorage.getItem("rolePermissions");
            rolePermissions = JSON.parse(rolePermissions);
            if (rolePermissions) {
              let neWmenuList = [];
              let keys = Object.keys(rolePermissions);
              if (menuList) {
                menuList.forEach((item) => {
                  if (item.submenus && item.submenus.length > 0) {
                    let newSubmenus = [];
                    item.submenus.map(sitem => {
                      keys.forEach(element => {
                        if (sitem.title === element) {
                          if (rolePermissions[element] !== "NoView") {
                            newSubmenus.push(sitem);
                          }
                        }
                      });
                    })
                    if (newSubmenus && newSubmenus.length > 0) {
                      item.submenus = newSubmenus;
                      neWmenuList.push(item);
                    }
                  } else {
                    keys.forEach(element => {
                      if (item.title === element) {
                        if (rolePermissions[element] !== "NoView") {
                          neWmenuList.push(item);
                        }
                      }
                    });
                  }
                });
              }
              await this.setState({ menuList: neWmenuList });
            }
          }
        })

    }
  }

  render() {
    const { changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar } = this.props;
    const sidebarClass = classNames({
      sidebar: true,
      'sidebar--show': sidebar.show,
      'sidebar--collapse': sidebar.collapse,
    });

    return (
      <div className={sidebarClass}>
        <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
        <Scrollbar className="sidebar__scroll scroll">
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
              onClick={() => { }}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={this.state.menuList}
            />
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <SidebarContent
              onClick={changeMobileSidebarVisibility}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={this.state.menuList}
            />
          </div>
        </Scrollbar>
      </div>
    );
  };
}

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};