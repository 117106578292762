import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Row, Card, CardBody, ButtonGroup, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';
import '../../../scss/dashboardStyles.css';
import '../../../scss/app.css'
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { SelectButton } from 'primereact/selectbutton';
import { FormControl } from 'react-bootstrap';
import { Accordion, AccordionTab } from 'primereact/accordion';
// import config from '../../../config/config';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DropDown } from 'primereact/dropdown';
import { Dropdown } from 'primereact/dropdown';
// import Select from './dropDownfield';
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import ConfirmaionModels from '../../Cruds/CommonModals/isApplyFilter';
import ConfirmaionModelorSave from '../../Cruds/CommonModals/ConfirmationModal'
import { CSVLink } from "react-csv";
import { Redirect } from 'react-router-dom';
// import { withStyles } from '@material-ui/core/styles';
// import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';

//Modals
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import FormModal from '../../Form/FormModal';
import ShowHideColumnsModal from '../CommonModals/ShowHideColumnsModal';
import DeleteRowModal from '../CommonModals/DeleteRowModal';
import SessionExpiryModal from '../CommonModals/SessionexpiryModal';
import RolePermissions from '../CommonModals/Permissions'
import SessionWarningModal from '../CommonModals/SessionWarningModal';
// config
import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';
import apiCalls from '../../../config/apiCalls';

// Toaster message
// import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
// import TextAutocomplete from '../../../shared/components/form/TextAutocomplete'

// Date Formate
import dateFormats from '../../UI/FormatDate/formatDate';
//Loader
import Loader from '../../App/Loader';
//store
// import validate from '../../Validations/validate'
// import { reduxForm, } from 'redux-form'
// import { connect } from 'react-redux'

import PaginatorComponent from './PaginatorComponent';
import { element } from 'prop-types';
import { faArrowLeft, faCheckCircle, faArrowCircleLeft, faSave, faArrowCircleRight, faBuilding, faExternalLinkAlt, faMinus, faChevronDown, faCircle, faTimes, faToggleOff, faCity, faFolder, faUsers, faExpand } from '@fortawesome/free-solid-svg-icons';


import { faCopy } from '@fortawesome/free-regular-svg-icons'
class DataTables extends React.Component {
    constructor(props) {
        super(props);
        this.closeMultiSelectDropdown = React.createRef();
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        this.applyCallback = this.applyCallback.bind(this);
        this.closeMultiSelectDropdown = React.createRef();

        this.state = {
            totalSelectedRows: 0,
            allUsersData: this.props.data ? this.props.data : [],
            deleteRowDataId: {},
            claimNotesEdit: false,
            isFrozenColumns: false,
            tableFields: this.props.getTableFields(),
            loginData: {},
            originalTableFields: this.props.getTableFields(),
            tablefieldsToShow: this.props.getTableFields(),
            isOpenShowHideColumnsModal: false,
            start: start,
            selectedvalueforStatus: this.props.selectedStatusFilter ? this.props.selectedStatusFilter : "",
            end: end,
            first: 0,
            selectedCountrie: "US",
            isPreviousWeek: false,
            criteriaData: { criteria: [] },
            copiedA: false,
            lastScoredDate: "",
            // city: "Private",
            columnOrderError: false,
            addFilters: ["isExcludeRoyalties"],
            // clickCount: 0,
            validatedArray: [],
            rows: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
            isShowTable: false,
            sortified: false,
            showError: false,
            Properties: true,
            confirmation: true,
            showErrorMessage: false,
            exportHeaders: [],
            sellerEditType: "",
            isDisabledTab: false,
            direction: 'asc',
            redirectSellerId: null,
            negativePeriod: null,
            downloadData: [],
            columns: [

            ],
            // dynamicColumns: [],
            selectedStatus: ["Not Started", "In Progress", "Paused", "Completed"],
            filterCriteria: {
                limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
                page: 1,
                criteria: this.props.criteria ? this.props.criteria : [],
                sortfield: (this.props.type === "Property Status" || this.props.type === "Property Reason" ? "Status" :
                    this.props.type === "Claims" ? "EstateName,SourceState,BusinessName" :
                        this.props.sortField ? this.props.sortField : "created"),
                direction: (this.props.type === "Labels" || this.props.type === "Departments" || this.props.type === "Purposes" || this.props.type === "Investor" || this.props.type === "OPRA2 Property Editor" ?
                    'asc'
                    : this.props.sortDirection ? this.props.sortDirection : "desc")
            },
            collapsefilter: false,
            selectednote: "",
            stats: [],
            selectedRows: '',
            activeTab: this.props.activeTab,
            selectedRowsId: '',
            selectedViewType: "Default",
            dataChangeOptionswithFilter: [
                { "label": "Unsort", "value": 'unsort' },
                { "label": "Sort by ASC", "value": 'asc' },
                { "label": "Sort by DSC", "value": 'desc' },
                { "label": "Filter", "value": 'filter' },
                { "label": "Clear Filter", "value": 'clear_filter' },
                { "label": "Hide Column", "value": 'hide' },
                { "label": "Show Column", "value": 'show' },
            ],
            dataChangeOptionswithoutSortwithFilter: [
                { "label": "Unsort", "value": 'unsort' },
                { "label": "Sort by ASC", "value": 'asc' },
                { "label": "Sort by DSC", "value": 'desc' },
                { "label": "Filter", "value": 'filter' },
                { "label": "Clear Filter", "value": 'clear_filter' },
                { "label": "Hide Column", "value": 'hide' },
                { "label": "Show Column", "value": 'show' },
                { "label": "Freeze first 3", "value": 'freeze' },
                { "label": "UnFreeze first 3", "value": 'unfreeze' },
            ],
            dataChangeOptionswithoutSortwithoutFilter: [
                { "label": "Hide Column", "value": 'hide' },
                { "label": "Show Column", "value": 'show' },
                { "label": "Freeze first 3", "value": 'freeze' },
                { "label": "UnFreeze first 3", "value": 'unfreeze' },
            ],
            dataChangeOptionswithoutFilter: [
                { "label": "Unsort", "value": 'unsort' },
                { "label": "Sort by ASC", "value": 'asc' },
                { "label": "Sort by DSC", "value": 'desc' },
                { "label": "Hide Column", "value": 'hide' },
                { "label": "Show Column", "value": 'show' },
            ],
            dataChangeOptionswithoutFilterPE: [
                { "label": "Unsort", "value": 'unsort' },
                { "label": "Sort by ASC", "value": 'asc' },
                { "label": "Sort by DSC", "value": 'desc' },
                { "label": "Hide Column", "value": 'hide' },
                { "label": "Show Column", "value": 'show' },
                { "label": "Freeze first 3", "value": 'freeze' },
                { "label": "UnFreeze first 3", "value": 'unfreeze' },
            ],
            dataChangeOptionswithFilterPE: [
                { "label": "Unsort", "value": 'unsort' },
                { "label": "Sort by ASC", "value": 'asc' },
                { "label": "Sort by DSC", "value": 'desc' },
                { "label": "Filter", "value": 'filter' },
                { "label": "Clear Filter", "value": 'clear_filter' },
                { "label": "Hide Column", "value": 'hide' },
                { "label": "Show Column", "value": 'show' },
                { "label": "Freeze first 3", "value": 'freeze' },
                { "label": "UnFreeze first 3", "value": 'unfreeze' },
            ],
            Apa: [{ "label": "10-Full Remnant,subject to Exclusions", "value": '10' },
            { "label": "14-Full Remnant,includes RCS,subject to Exclusions", "value": '14' },
            { "label": "20-Limited scope,see Inclusions,subject to Exclusions", "value": '20' },
            { "label": "24-Limited scope,includes RCS,see Inclusions", "value": '24' },
            { "label": "30-UP only", "value": '30' },
            { "label": "40-RCS only", "value": '40' },
            { "label": "50-Judgment only", "value": '50' },
            { "label": "60-Other,limited to named inclusion(s)", "value": '60' },
            { "label": "64-Other,limited to named inclusion (s) with RCS", "value": '64' },
            { "label": "70-Full Remnant,Unable to pursue recoveries", "value": '70' },
            { "label": "75-Full Remnant,PWC portfolio restriction", "value": '75' },
            { "label": "80-Corporate Remnants", "value": '80' },
            { "label": "82-Corporate Remnants,limited scope", "value": '82' },
            { "label": "85-Corporate Remnants,NDA", "value": '85' },
            { "label": "87-Corporate Remnants,Sold", "value": '87' },
            ],
            FilterSortOptions: [
                // { label: 'Type of UP Recovery', value: "dealType" },
                { label: 'Business Name', value: "company" },
                { label: 'Transition Target', value: "transitionTarget" },
                { label: 'Site Discovery Target', value: "searchTarget" },
                { label: 'Validation Target', value: "validationTarget" },
                { label: 'File Target', value: "fileTarget" }
                // { label: 'Property City', value: "propertyCity" },
                // { label: 'Property State', value: "propertyState" },
            ],
            totalRecords: [],
            exportData: [],
            city: "Private",
            analysttype: null,
            sellertype: null,
            selectFilterValue: "",
            isOpenFormModal: false,
            openViewModal: false,
            isLoading: false,
            isOpenRolesModal: false,
            isFilterShown: true,
            searchedCompany: "",
            isOpenConfirmationModal: false,
            isApplyFilter: false,
            selectedScreenPermission: '',
            loginRole: 'Admin',
            startDate: '',
            endDate: '',
            progress: 0,
            unassignedOnly: false,
            isShowunAssign: false,
            // isTopFilter: true,
            apiUrl: this.props.apiUrl,
            totalRecordsLength: 0,
            tabType: this.props.tabType,
            sessionExpiryModal: false,
            mobileListFields: [],
            dateFormat: '',
            filteredAnalysts: [],
            investorCompany: [],
            openTemplateModal: false,
            isOpenProductsModal: false,
            selectedFiledBusinessName: "",
            selectschool: '',
            roleType: '',
            openId: 1,
            openActionDropDown: false,
            redirecToLogin: false,
            openDeleteModal: false,
            blockFamily: false,
            selectActions: '',
            viewType: 'list',
            claimID: "",
            issellerModalView: false,
            actions: '',
            displayViewOfForm: this.props.displayViewOfForm,
            displayBreadCrumbField: '',
            displayBreadCrumbValue: '',
            width: window.innerWidth,
            isChangeHit: false,
            height: window.innerHeight,
            globalSearch: '',
            showMultiSelectDropDown: false,
            multiSelectTypes: '',
            actionsTypes: '',
            userStatus: '',
            selectedAutoCompleteValue: '',
            suggestions: [],
            confirmModalText: '',
            confirmType: '',
            openUserPasswordResetModal: false,
            iskanbanModal: false,
            errorResponse: false,
            successResponse: false,
            errorMessage: '',
            successMessage: '',
            editedrowData: {},
            sessionWarning: false,
            forceLogout: false,
            iscontactNoticeModal: false,
            dateQuery: {},
            addRow: false,
            companyFilterType: "sw",
            propertyEditorTabs: [
                { name: "Green", color: "green", tooltip: "Green" },
                { name: "Blue", color: "blue", tooltip: "Blue" },
                { name: "Yellow", color: "#ff9700", tooltip: "Yellow" },
                { name: "Brown", color: "brown", tooltip: "Green" },
                { name: "Black", color: "black", tooltip: "Green" },
                // { name: "Teal", color: "#008080" },
                { name: "SP", color: "blue", tooltip: "Similarly Validated Properties For This Deal" },
                { name: "All", color: "red", tooltip: "All validated properties for this Deal" },
            ],
            IssueCodes: {},
            csvOrderforPE: ["estateName", "companyID", "claimID", "propertyID", "propertyDataSource", "sourceState", "statePropertyId", "businessName", "propertyName", "businessAddressLine1", "businessCity", "businessState", "businessZipCode", "propertyAddressLine1", "PropertyAddressLine2", "propertyCity", "propertyState", "propertyZipCode", "propertyDescription", "holderName", "propertyMinValueAmount", "propertyMaxValueAmount", "escheatmentDate", "dateOfLastContact", "matchMatchStatus", "matchStatusReason", "matchStatusNotes", "fileFor", "propertyAlternateId", "alternateID", "prospectiveStatus", "nameIssueCode", "nameMatchScore", "addressLine1", "MatchScore", "cityStateZipScore", "batchName", "bucket", "scoreDate", "scoreUpdateDate", "isValidated", "validatedDate", "validatedBy", "hasPropertyCoOwner", "userEmailID", "opraValidatedFlag", "createdDate", "createdByUser", "createdBy", "updatedDate", "dateIndexedInElastic", "opraAddedDate", "activeproperty", "ResidentialFlag", "nameId", "addressId", "oprA_AddressDetailID", "_id", "note", "businessNameID", "addressLine1MatchScore", "propertyResidentialFlag", "active"]
        };
    }

    componentDidUpdate() {
        if (this.state.errorResponse) {
            setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
        }

        if (this.state.copiedA) {
            setTimeout(() => this.setState({ copiedA: false }), 10000);
        }
        if (!this.state.errorResponse && localStorage.getItem('serverError') === "true") {
            localStorage.removeItem('serverError')
        }
        if (this.state.successMessage) {
            setTimeout(() => this.setState({ successMessage: false, successResponse: '' }), 20000);
        }
        if (this.state.showSelectederror) {
            setTimeout(() => this.setState({ showSelectederror: false, errormsg: '', errorColor: false }), 20000);
        }
        // if (localStorage.getItem('loggedTime')) {
        //     let loggedTime = localStorage.getItem('loggedTime')
        //     setTimeout(() => this.setState({ forceLogout: true }), loggedTime);
        // }
        if (this.state.progress === 100) {
            setTimeout(() => this.setState({ progress: 0 }), 200);
        }
    }

    getFiltercriteria = () => {
        return this.state.filterCriteria
    }

    getScreenPermissions = async () => {
        let screenPermissions
        if (this.props.type === "Legal Entity Suffix" || this.props.type === "Legal Entity Abbreviation" || this.props.type === "State-UP Filing Category" || this.props.type === "State-UP Filing Sub Category" || this.props.type === "Available To File - Statuses") {
            screenPermissions = RolePermissions.screenPermissions("Configurations");
        } else if (this.props.type === "Property Reason" || this.props.type === "Property Status" || this.props.type === "Claim Status" || this.props.type === "Claim Reason" || this.props.type === "Business Name Issue Code") {
            screenPermissions = RolePermissions.screenPermissions("Status Reason Screen");
        } else if (this.props.type === "ClaimsManagement") {
            screenPermissions = RolePermissions.screenPermissions("Claims Management");
        } else if (this.props.type === "crMatrix") {
            screenPermissions = RolePermissions.screenPermissions("CR Matrix");
        } else if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt")) {
            screenPermissions = RolePermissions.screenPermissions("Deal Mgmt");
        } else if (this.props.type === "Regular Scoring Queue") {
            screenPermissions = RolePermissions.screenPermissions("RS Queue");
        } else if (this.props.type === "Deal Sizing Queue") {
            screenPermissions = RolePermissions.screenPermissions("DS Queue");
        } else { screenPermissions = RolePermissions.screenPermissions(this.props.type); }
        await this.setState({
            screenPermissions: screenPermissions
        })
    }
    trimmedValue = (value) => {
        if (value === "true" || value === "false") {
            return JSON.parse(value)
            // return true
        } else {
            let val = value.trim()
            return parseInt(val)
        }
    }
    // trimmedconfirmedValue = (value) => {
    //   this.setState({ filtertypeserver: "" })
    //   return value
    // }
    componentDidMount = async () => {
        if (!document.getElementsByClassName("layout layout--collapse")[0]) {
            document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
        }
        if (await localStorage.getItem("succ")) {
            this.getSuccessMessage(await localStorage.getItem("succ"))
        }


        var arrTabs = this.state.propertyEditorTabs
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        await this.getTimeFormat()
        await this.getLoginRole();
        await this.getActions();
        await this.getScreenPermissions()

        let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
        localStorage.removeItem("ISEdited")
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
            await this.setState({ sessionExpiryModal: true })
        }
        window.addEventListener('resize', this.updateDimensions);
        // document.addEventListener('mousedown', this.handleClickOutside)
        //showing the data only other than default role
        if (!loginCredentials) {
            await this.setState({ redirecToLogin: true })
        } else {
            if (loginCredentials.roleName === "Analyst") {
                await this.setState({
                    iscontactNoticeModal: true
                });
            }
            if (loginCredentials.roleName === "Admin" || loginCredentials.roleName === "Recovery Manager") {
                arrTabs.splice(5, 0,
                    { name: "Teal", color: "#008080", tooltip: "Teal" },
                );
            } else {
                arrTabs = this.state.propertyEditorTabs
            }
            let userName = loginCredentials.display_name
            if (userName.includes("FINAC".toLowerCase()) || userName.includes("Integrity".toLowerCase())) {
                this.setState({
                    isShowunAssign: true
                })
            }
            if (this.props.type === "List View") {
                await this.handleListViewCanrdsbasedonRole(loginCredentials)
            }
            if (this.props.type === "Weekly Recovery Plan" || this.props.type === "Recovery Planner V2" || this.props.type === "Utilization - US Team" || this.props.type === "ClaimsManagement") {
                await this.getAnalysts()
            }
            if (this.props.type === "ClaimsManagement") {
                await this.getClaimStatus()
            }
            let filterCriteria = this.state.filterCriteria
            filterCriteria["limit"] = loginCredentials.pageLimit ? loginCredentials.pageLimit :
                localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption"))
                    : 10
            await this.setState({
                rows: loginCredentials.pageLimit ? loginCredentials.pageLimit :
                    localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption"))
                        : 10,
                filterCriteria: filterCriteria,
                redirecToLogin: false,
                iscontactNoticeModal: false,
                // userRole: loginCredentials.roleName,
                loginCredentials: loginCredentials,
                displayViewOfForm: this.props.displayViewOfForm,
            })
        }
        if (this.props.type === "Company") {
            if (!localStorage.getItem('CompanyType')) {
                localStorage.setItem('CompanyType', this.state.city);
            } else {
                await this.setState({
                    city: localStorage.getItem('CompanyType')
                })
            }
        } else {
            localStorage.removeItem('CompanyType');
            localStorage.removeItem('SearchedCompany');
        }
        if (this.props.type === "Weekly Recovery Plan" || this.props.type === "Recovery Planner V2" || this.props.type === "Weekly Recovery Report" || this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity") {
            await this.handleDatesFormating()
        }
        // hari check where this should go
        if (this.props.getTableFields) {
            //let tablefields = await this.props.getTableFields();
            this.setState({
                tablefields: this.props.getTableFields(),
                originalTableFields: this.props.getTableFields(),
                // isLoading: false,
                mobileListFields: this.props.getTableFields(),
                searchedCompany: localStorage.getItem('SearchedCompany') ? localStorage.getItem('SearchedCompany') : "",
            }, async () => {
                let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
                let columns = await JSON.parse(colOrder);
                if (columns) {
                    await this.getTableFieldsOrder();
                } else {
                    await this.updateColumnsOrder(null);
                }
            });
        }
        // await this.sendColumnDataToServer("", "GET");
        if (this.props.type !== "Properties" && this.props.type !== "Property Editor" && this.props.type !== "OPRA2 Property Editor" && this.props.type !== "Deal Sizing-Properties" && this.props.type !== "Claims" && this.props.type !== "ClaimsManagement" && this.props.type !== "Deal Sizing Report For Companies" && this.props.type !== "Deal Sizing Report For Private Equity Portfolio") {
            // if (this.props.type !== "OPRA2 Property Editor" && this.props.type !== "Property Editor") {
            // if (this.props.type && document.getElementsByClassName("layout layout--collapse").length === 1) {
            //     document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();

            // }
            if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt") && localStorage.getItem("sellerFilter")) {
                let filter = JSON.parse(localStorage.getItem("sellerFilter"))
                if (filter && filter.limit) {
                    filter.limit = loginCredentials.pageLimit ? loginCredentials.pageLimit :
                        localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption"))
                            : 10
                }
                this.setState({ filterCriteria: filter })
            }
            if (this.props.type !== "Deal Statistics" && loginCredentials.roleName !== "Analyst" && this.props.type !== "Manually Loaded Properties" && this.props.type !== "ClaimSummary") {
                if (this.props.type !== "UP Opportunity") {
                    //          this.getAdditionalFilter()
                    let body = {
                        // "states": [],
                        "dealType": ["BK", "CR"],
                        // "sellerId": 0,
                    }
                    this.getDataFromServer(this.state.filterCriteria, "", "", body);
                } else {
                    let addFilters = ["isExcludeRoyalties"]
                    this.getUsersSummaryData("", addFilters)
                }
            }
            if (this.props.type) {
                await this.setState({
                    isShowTable: true
                })
            }
        } else {
            var url;
            if (localStorage.getItem("nspropertiesparamsUrl")) {
                url = new URL(localStorage.getItem("nspropertiesparamsUrl"));
            } else {
                url = new URL(window.location.href);
            }
            var c = url.searchParams.get("sellerName");
            if (c || url.searchParams.get("propertyId")) {
                this.getFilteredValues()
            }
            //user preference column order
            //for properties hide the sidebar by default
            // if (document.getElementsByClassName("layout layout--collapse") && document.getElementsByClassName("layout layout--collapse")[0] === undefined) {
            //     document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
            // }
        }
        if (this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
            this.isShowTable(true)
        }
        // await this.setDynamicColumns()

        this.setState({
            formFields: this.props.formFields,
            limit: loginCredentials.pageLimit,
            propertyEditorTabs: arrTabs

        });
    }
    setDynamicColumns = async () => {
        let columns = []
        let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
        colOrder = JSON.parse(colOrder)
        let details = colOrder.details
        let Defaults
        // let Defaults = details["Default"]
        if (details) {
            Defaults = details[this.state.selectedViewType]
            if (Defaults === undefined) {
                Defaults = this.props.getTableFields()
            }
        } else {
            Defaults = colOrder
        }

        for (let Default of Defaults) {
            if (Default.field !== "expander" && (Default.field === "AssetType" || Default.field === "Payments" || Default.field === "PaymentBtn"
                || Default.field === "CompanyID" || Default.field === "feinProof" || Default.field === "nameProof" || Default.field === "addressProof" || Default.field === "holderProof" || Default.field === "sellerProof"
                || Default.field === "EstateName" || Default.field === "PropertyID" || Default.field === "SourceState" || Default.field === "BusinessName" || Default.field === "StatePropertyID" || Default.field === "OppClaimID" || Default.field === "StateClaimID" || Default.field === "PropertyStatus" || Default.field === "PropertyReason" || Default.field === "PropertyNote" || Default.field === "ClaimAmount" || Default.field === "FileFor")) {
                columns.push({ field: Default.field, header: Default.header, bodyStyle: Default.width, show: Default.show })
            } else if (Default.field !== "expander") {
                columns.push({ field: "", header: "", bodyStyle: Default.width, show: Default.show })
            }
        }
        await this.setState({ columns: columns })
        if (this.props.type === "ClaimsManagement" && colOrder) {
            let dynamicColumns = this.state.columns.map((col, i) => {
                if (col.show) {
                    return <Column key={col.field} field={col.field} textAlign={col.textAlign} selectionMode={col.selectionMode} body={this.changeFieldValues}
                        bodyStyle={{ width: col.bodyStyle }} />;
                }
            });
            await this.setState({ dynamicColumns: dynamicColumns })
        }

    }

    handleListViewCanrdsbasedonRole = async (loginCredentials) => {
        let result = loginCredentials.roleName.toLowerCase();
        let excludeStr = "Search"
        if (result.includes("analyst") && result !== "recovery analyst") {
            if (result.includes(excludeStr.toLowerCase())) {
                await this.setState({ userRole: false, showSiteDiscoveryCards: true, isShowunAssign: true })
            } else {
                await this.setState({ userRole: false })
            }
        } else if (result.includes("manager")) {
            if (result.includes(excludeStr.toLowerCase())) {
                await this.setState({ userRole: true, showSiteDiscoveryCards: true, isShowunAssign: true })
            } else {
                await this.setState({ userRole: true })
            }
        } else if (result === "admin" || result === "leadership" || result === "filer" || result === "reviewer") {
            await this.setState({ userRole: true })
        } else {
            if (result === "recovery analyst") {
                await this.setState({ userRole: true })
            } else {
                await this.setState({ userRole: false })
            }
        }
    }

    handleDatesFormating() {
        let curr = new Date(); // get current date
        let first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
        let firstday = new Date(curr.setDate(first)).toUTCString();
        let last = new Date(firstday).getDate() - (new Date(firstday).getDay() - 1) + 4;// last day is the first day + 6
        let startDateReports = dateFormats.formatDate(firstday, "MM/DD/YY");
        let lastday = new Date(new Date(firstday).setDate(last));
        let lastDateReports = dateFormats.formatDate(lastday, "MM/DD/YY");
        this.setState({
            startDateReports: startDateReports,
            lastDateReports: lastDateReports,
            disableDate: lastDateReports
        })
        this.searchItems();
    }

    endOfWeek(date) {
        var lastday = date.getDate() - (date.getDay() - 1) + 4;
        return new Date(date.setDate(lastday));
    }

    //user preference col order
    sendColumnDataToServer = async (value, type, view) => {
        let method = type;
        let url = "";
        let keyname = `${this.props.type}_column_order`;
        if (type === "PUT") {
            await this.setState({
                isLoading: true
            })
            this.handlePercentage("1")
            url = `${apiCalls.Users}/preferences/${this.state.loginData._id}?key=${keyname}`;
            let listPreferences = {};
            listPreferences[keyname] = value;
            let body = { listPreferences: listPreferences };
            if (view) {
                body.view = view.views
            }
            await fetchMethodRequest(method, url, body).then(async (resp) => {
                if (resp) {
                    if (resp.respCode) {
                        this.setState({ columnOrderError: true, columnOrderMsg: resp.respMessage })
                        return;
                    } else if (resp.errorMessage) {
                        // showToasterMessage(resp.errorMessage, "error");
                    }
                }
            });
        } else {
            console.log("this.state.loginData", this.state.loginData)
            url = `${apiCalls.Users}/preferences/${this.state.loginData._id}?key=${keyname}`;
            await fetchMethodRequest(method, url).then(async (resp) => {
                if (resp) {
                    if (resp) {
                        if (resp.details) {
                            localStorage.setItem(
                                `${this.props.type}_column_order`,
                                JSON.stringify(resp)
                            );
                            this.setState({ columnOrderError: true, columnOrderMsg: resp.respMessage })
                            this.scrollWin();
                            // hari check where this should go
                            if (this.props.getTableFields) {
                                this.setState(
                                    {
                                        tablefields: this.props.getTableFields(),
                                        originalTableFields: this.props.getTableFields(),
                                        isLoading: false,
                                        mobileListFields: this.props.getTableFields(),
                                        displayViewOfForm: this.props.displayViewOfForm,
                                    },
                                    async () => {
                                        // let colOrder = localStorage.getItem(
                                        //   `${ this.props.type }_column_order`
                                        // );
                                        let colOrder = resp.details[this.state.selectedViewType];
                                        // let columns = await JSON.parse(colOrder);
                                        if (colOrder && colOrder.length > 0) {
                                            await this.getTableFieldsOrder();
                                        } else {
                                            await this.updateColumnsOrder(null);
                                        }
                                    }
                                );
                            }
                        } else {
                            if (this.props.getTableFields) {
                                this.setState(
                                    {
                                        tablefields: this.props.getTableFields(),
                                        originalTableFields: this.props.getTableFields(),
                                        isLoading: false,
                                        mobileListFields: this.props.getTableFields(),
                                        displayViewOfForm: this.props.displayViewOfForm,
                                    },
                                    async () => {
                                        await this.updateColumnsOrder(null);
                                    }
                                );
                            }
                        }
                    } else if (resp.errorMessage) {
                        this.getErrorMessage(resp.errorMessage, "", "error")
                        // showToasterMessage(resp.errorMessage, "error");
                    }
                }
            });
        }
    };

    // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal
    closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
        if (type && type === 'confirm' && columnFields) {
            let updatedOrder = [];
            let fields = [...changedTableFields];

            this.setState({
                isOpenShowHideColumnsModal: false,
            })

            fields.map(async item => {
                Object.entries(columnFields).forEach(async ([key, value]) => {
                    if (item.field === key) {
                        return item.show = value;
                    }
                });
            })

            fields.map(async col => {
                updatedOrder.push(
                    {
                        field: col.field,
                        show: col.show,
                        header: col.header,
                        width: col.width
                    }
                )
            });
            // await this.sendColumnDataToServer(updatedOrder, "PUT", columnFields);
            // await this.sendColumnDataToServer("", "GET");
            // localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
            await this.getTableFieldsOrder(type, this.state.originalTableFields);
        } else if (type && type === 'confirm') {
            let fields = [...changedTableFields];
            this.setState({
                isOpenShowHideColumnsModal: false,
            })
            // await this.sendColumnDataToServer(fields, "PUT", columnFields);
            // await this.sendColumnDataToServer("", "GET");
            // localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(fields));
            await this.getTableFieldsOrder(type, this.state.originalTableFields, "isLoading");
        } else {
            this.setState({
                isOpenShowHideColumnsModal: false,
                // isOpenConfirmColumnModel: true
            })
        }
        this.setDynamicColumns()
    }


    getActions = async () => {
        let selectedRows = this.state.selectedRows ? [...this.state.selectedRows] : [];
        let actionsTypes = this.props.actionsTypes ? [...this.props.actionsTypes] : [];
        let result = [];
        if (actionsTypes && actionsTypes.length) {
            for (let i = 0; i < actionsTypes.length; i++) {
                if (actionsTypes[i]['options'] && actionsTypes[i]['options'].length > 0) {
                    let options = actionsTypes[i]['options']
                    for (let j = 0; j < options.length; j++) {
                        if (options[j]['show'] === true) {
                            if (selectedRows && selectedRows.length >= 2 && options[j]['multiple'] === true) {
                                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
                            } else if (selectedRows && selectedRows.length === 1) {
                                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
                            } else if (selectedRows && selectedRows.length === 0) {
                                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
                            }
                        }
                    }
                }
            }
        }
        await this.setState({
            actionsTypes: result
        })
    }

    handleClickOutside = (event) => {
        if (
            this.closeMultiSelectDropdown.current &&
            !this.closeMultiSelectDropdown.current.contains(event.target)
        ) {
            this.setState({
                showMultiSelectDropDown: false
            });
        }
    };

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
        window.removeEventListener('resize', this.updateDimensions);
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    updateDimensions = async () => {
        await this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    getTimeFormat = () => {
        // let store = store.getState()
        // let dateFormat=store && store.commonData && store.commonData.data && store.commonData.data.timeFormat?store.commonData.data.timeFormat:'DD-MM-YYYY'
    }

    // Handle Table fields to show in datatable
    getTableFieldsOrder = async (type, test, loading) => {
        this.setState({
            tablefieldsToShow: [],
            isLoading: true,
        })
        this.handlePercentage('1')
        let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
        let columns = JSON.parse(colOrder);
        if (columns && columns.details) {
            columns = columns.details[this.state.selectedViewType];
        }
        if (this.state.screenPermissions !== "Edit") {
            const findIndex = columns.findIndex(a => a.field === 'Actions')
            findIndex !== -1 && columns.splice(findIndex, 1)
        }
        let tempTableFields = [];
        let frozenWidth = 0;
        let newTableFields = [];
        let staticTableFields = [...this.state.originalTableFields];
        //adding newly added fields directly to list prefereneces of user
        if (columns) {
            let newArrayFields = []
            for (let k = 0; k < staticTableFields.length; k++) {
                let y = columns.findIndex(u => u.field === staticTableFields[k].field)
                if (y === -1) {
                    newArrayFields.push({
                        field: staticTableFields[k].field,
                        header: staticTableFields[k].header,
                        width: staticTableFields[k].width,
                        show: true
                    })
                }
            }
            columns = [...columns, ...newArrayFields]
            let columns1 = JSON.parse(colOrder);
            if (columns1 && columns1.details) {
                columns1.details[this.state.selectedViewType] = columns;
            }
            localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columns1))

            let showFrozen = [];
            for (let m = 0; m < columns.length; m++) {
                if (columns[m].show) {
                    showFrozen.push(m)
                }

            }

            for (let i = 0; i < columns.length; i++) {
                for (let j = 0; j < staticTableFields.length; j++) {
                    if (columns[i].field === staticTableFields[j].field && this.state.viewType === 'list') {
                        let pushItem = staticTableFields[j];
                        pushItem.show = typeof columns[i].show === "string" && columns[i].show === "true" ? true
                            : typeof columns[i].show === "string" && columns[i].show === "false" ? false :
                                columns[i].show;
                        pushItem.width = columns[i].width ? columns[i].width : staticTableFields[j].width;
                        pushItem.displayInSettings = columns[i].displayInSettings;
                        if (this.props.type === "Property Editor" && this.state.isFrozenColumns && (i == 0 || i === showFrozen[1] || i === showFrozen[2] || i === showFrozen[3])) {
                            pushItem.frozen = true
                            frozenWidth = frozenWidth + columns[i].width
                        } else {
                            pushItem.frozen = false
                            // frozenWidth = 0
                        }
                        tempTableFields.push(pushItem);
                        break;
                    }
                    if (columns[i].field === staticTableFields[j].field && this.state.viewType === 'grid') {
                        let pushItem = staticTableFields[j];
                        pushItem.width = columns[i].width ? columns[i].width : staticTableFields[j].width;
                        pushItem.displayInSettings = columns[i].displayInSettings;
                        pushItem.mobile = columns[i].mobile;
                        if (this.props.type === "Property Editor" && this.state.isFrozenColumns && (i == 0 || i === showFrozen[1] || i === showFrozen[2] || i === showFrozen[3])) {
                            pushItem.frozen = true
                            frozenWidth = frozenWidth + columns[i].width
                        } else {
                            pushItem.frozen = false
                            frozenWidth = frozenWidth
                        }
                        tempTableFields.push(pushItem);
                        break;
                    }
                }
            }
        } else {
            tempTableFields = this.state.originalTableFields
        }
        newTableFields = tempTableFields;

        let TableFieldsToShow = [];
        if (newTableFields && newTableFields.length > 0) {
            // newTableFields.map(item => {
            newTableFields.forEach(item => {

                // TableFieldsToShow.push(item)
                if (item.show && item.show === true && this.state.viewType === 'list') {
                    TableFieldsToShow.push(item)
                }
                if (item.mobile && this.state.viewType === 'grid') {
                    TableFieldsToShow.push(item)
                }
            })
        }

        //for remove editing for previous weeks
        if (this.props.type === "Weekly Recovery Plan" || this.props.type === "ClaimsManagement") {
            TableFieldsToShow = await this.getCustumFieldOrderforRecoveryPlan(TableFieldsToShow)
        }
        frozenWidth = frozenWidth
        this.setState({
            frozenWidth: frozenWidth.toString() + "px",
            tablefieldsToShow: this.props.type === "Company" && this.state.city === "Public" ? this.props.getPublicCompanies() : TableFieldsToShow,
        })
        if (this.props.type === "Properties" || this.props.type === "Deal Statistics" || this.props.type === "Deal Sizing Report For Private Equity Portfolio" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" || loading || this.props.loading) {
            this.setState({
                isLoading: false,
            })
        }
        if (this.state.isLoading) {
            this.setState({
                isLoading: false,
            })
        }
        this.scrollWin();
        if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt") && localStorage.getItem("sellerFilter")) {
            this.custumApplyFilter()
        }
    }
    custumApplyFilter = () => {
        let criteria = JSON.parse(localStorage.getItem("sellerFilter"))
        if (criteria && criteria.criteria.length > 0) {
            for (let crt of criteria.criteria) {
                var shownField = document.getElementsByClassName(`p-column-filter-Deal Mgmt${crt.key}`)[0]
                shownField.style.display = "block"
                shownField.children[0].value = crt.type//for type
                shownField.children[1].value = crt.value//for value
                if (crt.type && crt.type.includes("date")) {
                    document.getElementsByClassName(`p-column-filterMode-${crt.key}`)[0].selectedIndex = crt.type === "dateeq" ? 0 : crt.type === "datelt" ? 1 : crt.type === "dategt" ? 2 : crt.type === "datelte" ? 3 : crt.type === "dategte" ? 4 : crt.type === "datenin" ? 5 : crt.type === "datein" ? 6 : null
                    document.getElementsByClassName(`p-column-filter p-column-${crt.key}`).value = new Date(decodeURIComponent(crt.value))
                }
            }


        }
        if (criteria && criteria.globalSearch) {
            document.getElementById("globalSearch").value = criteria.globalSearch.value
        }
    }
    getConfirmaionModel() {
        return (
            <ConfirmaionModels
                openConfirmationModal={this.state.isApplyFilter}
                closeConfirmationModal={this.isConfirmFilter}
                onFilterChange={this.onFilterChange}
                filterInput={this.state.filteredCol}
                handleFilterInputField={this.handleFilterInputField}

            />
        )
    }

    getCustumFieldOrderforRecoveryPlan(TableFieldsToShow) {
        let index = TableFieldsToShow.findIndex(a => a.fieldType === "rowEditor")
        let deleteindex = TableFieldsToShow.findIndex(a => a.fieldType === "Actions")

        if (this.state.isPreviousWeek || window.location.pathname === "/reportPlan") {
            if (index !== -1) {
                TableFieldsToShow.splice(index, 1)
            }
            if (deleteindex !== -1) {
                TableFieldsToShow.splice(deleteindex, 1)
            }
        } else {
            if (this.state.screenPermissions === "Edit") {
                var field, delfield;
                if (this.props.editRequired) {
                    field = {
                        "show": true,
                        "textAlign": "left",
                        "width": 70,
                        "fieldType": "rowEditor",
                        "header": "Edit",
                        "filter": false,
                        "sortable": false
                    }
                    if (index === -1) {
                        TableFieldsToShow.push(field);
                    }
                }
                if (this.props.deleteRequired) {
                    var delfield = {
                        "show": true,
                        "textAlign": "left",
                        "width": 80,
                        "header": "Delete",
                        "filter": false,
                        "sortable": false
                    }
                    if (deleteindex === -1) {
                        TableFieldsToShow.push(delfield);
                    }
                }
            }
        }
        return TableFieldsToShow
    }

    changeCheckIcon = async (index, subIndex, key, value) => {
        let tablefieldsToShow = this.state.tablefieldsToShow;
        if (tablefieldsToShow[index]['options'][subIndex]['checkIcon'] === true) {
            let filterCriteria = Object.assign({}, this.state.filterCriteria);
            if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
                let obj = filterCriteria.criteria.find(x => x.key === key);
                let objIndex = filterCriteria.criteria.indexOf(obj)
                let objValue = obj.value.find(y => y === value)
                let i = obj.value.indexOf(objValue);
                filterCriteria.criteria[objIndex]['value'].splice(i, 1);
                let length = filterCriteria.criteria[objIndex]['value'] ? filterCriteria.criteria[objIndex]['value'].length : 0;
                if (length === 0) {
                    filterCriteria.criteria.splice(objIndex, 1)
                }
            }
            tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
            await this.setState({
                tablefieldsToShow: tablefieldsToShow,
                filterCriteria: filterCriteria
            })
            await this.onMultiSelectFilterChange(key, value, 'pop', index)
        } else {
            let filterCriteria = Object.assign({}, this.state.filterCriteria);
            tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
            await this.setState({
                tablefieldsToShow: tablefieldsToShow
            })
            if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
                let obj = filterCriteria.criteria.find(x => x.key === key);
                let objIndex = filterCriteria.criteria.indexOf(obj);
                await this.onMultiSelectFilterChange(key, value, 'push', objIndex)
            } else {
                await this.onMultiSelectFilterChange(key, value, 'push', index)
            }
        }
    }
    showDatatable = async (type) => {
        if (type) {
            await this.isShowTable(true)
        } else {
            await this.isShowTable(false)
        }
    }
    clearData = async () => {
        await this.setState({
            uppropConfirmationMessage: false, uppropPropertyIds: [],
        })
    }
    showDatatableClaimSummary = async (type) => {

        if (type) {
            await this.isShowTable(true)
        } else {
            await this.setState({ totalRecordsLength: 0 })
            await this.isShowTable(false)
        }
    }
    //getting Error Message from formModa;
    getErrorMessage = async (errorMessage, type, color) => {
        if (type === "removingSeller") {
            let counts = {
                Green: 0, Blue: 0, Teal: 0, Brown: 0, Yellow: 0, Black: 0, All: 0, PV: 0, SP: 0
            }
            await this.setState({
                allUsersData: [],
                Counts: counts,
                fileForOptions: [],
                lastScoredDate: "",
                IssueCodes: {},
                redirectSellerId: null,
                negativePeriod: null
            })
            if (this.props.type !== "Up Prop Confirmation" && this.editorComponentRef) {
                this.editorComponentRef.handlePropertiesEditChange("", "Cancel", "removingSeller")
            }
            if (this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
                await this.setState({
                    hidePropValues: true
                })
            }
            this.isShowTable(false)
        }
        if (errorMessage && errorMessage.length > 0) {
            await this.setState({
                errorMessage: errorMessage,
                errorResponse: true,
                errorcolor: color === "error" ? "red" : "green"
            })
        } else {
            await this.setState({
                errorMessage: errorMessage,
                errorResponse: false,
                errorcolor: color === "error" ? "red" : "green"
            })
        }
        if (type === "selectedSeller" || type === "claimName") {
            await this.setState({
                errorMessage: "",
                errorResponse: false,

            })
        }
    }

    getSuccessMessage = async (successMessage, color) => {
        await this.setState({
            successMessage: successMessage,
            successResponse: true,
            color: "green"
        })
        if (localStorage.getItem("succ")) {
            localStorage.removeItem("succ")
        }
    }

    changeViewType = async (viewType) => {
        await this.setState({ viewType: viewType })
        await this.getTableFieldsOrder()
    }

    // Handle Table fields order of display in DataTable
    updateColumnsOrder(currentOrder) {
        this.setState({
            isLoading: true,
        })
        this.handlePercentage('1')
        let originalTableFields = [...this.state.originalTableFields];
        //  [...this.state.originalTableFields];
        let order = currentOrder ? currentOrder : null;
        let updatedOrder = [];
        let unmatchedTableFields = [...this.state.originalTableFields];

        if (order && order.length > 0) {
            order.map(async item => {
                if (item && item.props && item.props.field) {
                    // originalTableFields.map(col => {
                    originalTableFields.forEach(col => {
                        if (item.props.field === col.field) {
                            updatedOrder.push(
                                {
                                    field: col.field,
                                    show: col.show,
                                    width: col.width,
                                    header: col.header,
                                    displayInSettings: col.displayInSettings,
                                    mobile: col.mobile
                                }
                            )
                        }
                    })
                }
            })

            order.map(async item => {
                if (item && item.props && item.props.field) {
                    for (let i = 0; i < unmatchedTableFields.length; i++) {
                        if (item.props.field === unmatchedTableFields[i].field) {
                            unmatchedTableFields.splice(i, 1)
                        }
                    }
                }
            })

            if (unmatchedTableFields && unmatchedTableFields.length > 0) {
                // unmatchedTableFields.map(col => {
                unmatchedTableFields.forEach(col => {
                    updatedOrder.push(
                        {
                            field: col.field,
                            show: false,
                            width: col.width,
                            header: col.header,
                            displayInSettings: col.displayInSettings,
                            mobile: false
                        }
                    )
                })
            }
        } else {
            originalTableFields.map(async col => {
                updatedOrder.push(
                    {
                        field: col.field,
                        show: col.show,
                        width: col.width,
                        header: col.header,
                        displayInSettings: col.displayInSettings,
                        mobile: col.mobile
                    }
                )
            });
        }
        localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
        this.getTableFieldsOrder(this.state.originalTableFields);
    }

    // static getDerivedStateFromProps(props, state) {
    //   let storeData = store.getState()
    //   let dateFormat = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.dateFormat ? storeData.settingsData.settings.dateFormat : "MM-DD-YYYY"
    //   if (state.dateFormat !== dateFormat) {
    //     return { dateFormat: dateFormat };

    //   }
    //   return { dateFormat: dateFormat };

    // }

    getLoginRole() {
        let loginData = localStorage.getItem("loginCredentials");
        console.log("getLoginRole>loginData", loginData)
        if (loginData) {
            loginData = JSON.parse(loginData);
            if (loginData && loginData.role) {
                this.setState({
                    loginRole: loginData.role,
                    loginData: loginData,
                });
            }
        }
    }
    updateReasonfromserver(itemdata) {
        let modifiedData = [];
        itemdata.forEach((item, index) => {
            if (item.ClaimStatus) {
                let ReasonsArray = [];
                if (item.claimReasonsRow.length > 0) {
                    for (let claimReasonRo of item.claimReasonsRow) {
                        ReasonsArray.push({ label: claimReasonRo, value: claimReasonRo })
                    }
                }
                item.claimReasonsRow = ReasonsArray
                modifiedData.push(item);
            } else {
                item.claimReasonsRow = []
                modifiedData.push(item);
            }
        })
        return modifiedData
    }
    updateReason(itemdata) {
        let modifiedData = [];
        itemdata.forEach((item, index) => {
            if (item.ClaimStatus) {
                let ReasonsArray = [];
                let filterCriteria = {};
                filterCriteria['criteria'] = [{ key: 'Status', value: item.ClaimStatus, type: 'eq', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
                return fetchMethodRequest('GET', `${apiCalls.ClaimReasons}?filter=${JSON.stringify(filterCriteria)}`)
                    .then(async (response) => {
                        if (response && response["claimReasons"].length > 0) {
                            let claimReasons = response["claimReasons"]
                            for (let reason of claimReasons) {
                                ReasonsArray.push({ label: reason.reason, value: reason.reason })
                            }
                            item.claimReasonsRow = ReasonsArray
                            modifiedData.push(item);
                        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
                            await this.setState({
                                sessionWarning: true,

                            })
                        } else {
                            item.claimReasonsRow = []
                            modifiedData.push(item);
                        }
                    }).catch((err) => {
                        return err;
                    })
            } else {
                item.claimReasonsRow = []
                modifiedData.push(item);
            }
        })
        return modifiedData
    }
    updateSlNoToData(itemdata) {
        let modifiedData = [];
        itemdata.forEach((item, index) => {
            if (this.props.flags) {
                let flags = this.props.flags
                if (item[flags['label']] === flags['value']) {
                    modifiedData.push(item);
                }
            } else {
                if (this.state.first) {
                    item.Sno = (index + 1) + this.state.first;
                } else {
                    item.Sno = index + 1;
                }
                modifiedData.push(item);
            }
        });
        return modifiedData;
    }

    //properties Summary Call
    getUsersSummaryData = async (formValues, addFilters, type) => {
        let data, filterCriteria = this.state.filterCriteria;
        if (formValues) {
            data = formValues
            this.setState({
                summarySelectedranges: formValues.ranges,
                summaryObject: formValues,
                addFilters: addFilters,
                isChangeHit: true,
                isLoading: true
            })
        } else {
            this.setState({
                isChangeHit: true,
                summarySelectedranges: this.props.getDataObj.ranges
            })
            data = this.props.getDataObj
        }
        let allUsersData = await this.props.getFormatedUsersData(data, filterCriteria, addFilters, type)
        this.setState({
            allUsersData: allUsersData,
            isLoading: false
        })
    }


    updateDateFormat(itemdata, dateFormat) {
        let modifiedData = [];
        for (let i = 0; i < itemdata.length; i++) {
            for (let k = 0; k < this.state.tablefieldsToShow.length; k++) {
                if ("Date" === this.state.tablefieldsToShow[k]['fieldType']) {
                    if (this.props.type !== "Property Editor") {
                        if (itemdata[i][this.state.tablefieldsToShow[k]['field']] !== null) {
                            let time = moment(itemdata[i][this.state.tablefieldsToShow[k]['field']]).utcOffset(dateFormats.isDstObserved(itemdata[i][this.state.tablefieldsToShow[k]['field']]) ? '-05:00' : '-06:00');

                            itemdata[i][this.state.tablefieldsToShow[k]['field']] =
                                this.props.type === "Company" ?
                                    dateFormats.formatDate(
                                        itemdata[i][this.state.tablefieldsToShow[k]['field']],
                                        this.state.tablefieldsToShow[k]['dateFormat'] ? this.state.tablefieldsToShow[k]['dateFormat'] : dateFormat, "nhg")
                                    :
                                    dateFormats.formatDate(
                                        time,
                                        this.state.tablefieldsToShow[k]['dateFormat'] ? this.state.tablefieldsToShow[k]['dateFormat'] : dateFormat);
                        }
                    } else {
                        if (itemdata[i][this.state.tablefieldsToShow[k]['field']] !== null) {
                            itemdata[i][this.state.tablefieldsToShow[k]['field']] =
                                dateFormats.formatDate(
                                    itemdata[i][this.state.tablefieldsToShow[k]['field']],
                                    this.state.tablefieldsToShow[k]['dateFormat'], 'NS');
                        }
                    }
                } else if ("Time" === this.state.tablefieldsToShow[k]["fieldType"]) {
                    let val = itemdata[i][this.state.tablefieldsToShow[k]["field"]];
                    if (val) {
                        let time = moment(val).utcOffset(dateFormats.isDstObserved(val) ? '-05:00' : '-06:00');
                        let dd = dateFormats.formatDate(time, "MM/DD/YY hh:mm a");
                        itemdata[i][this.state.tablefieldsToShow[k]["field"]] = dd;
                    }

                }
            }
            modifiedData.push(itemdata[i])
        }
        return modifiedData;
    }

    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }

    getDataAfterCriteria = async (filterCriteria, type, setGoButton, url) => {
        let apiUrl;
        if (type === 'refresh') {
            let tablefieldsToShow = this.state.tablefieldsToShow;
            if (tablefieldsToShow && tablefieldsToShow.length > 0) {
                for (let i = 0; i < tablefieldsToShow.length; i++) {
                    let options = tablefieldsToShow[i].options;
                    if (options && options.length) {
                        for (let j = 0; j < options.length; j++) {
                            options[j]['checkIcon'] = false
                        }
                    }
                }
            }
            this.setState({
                globalSearch: '',
                allUsersData: [],
                status: '',
                roleType: '',
                // selectFilterValue: '',
                tablefieldsToShow: tablefieldsToShow
            });
        }
        if (!filterCriteria || !filterCriteria['criteria']) {
            // let filterCriteria = {};
            let limit = localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10
            filterCriteria = {
                limit: limit, page: 1, criteria: [], sortfield: this.props.sortField ? this.props.sortField : "created", direction: this.props.type === "Weekly Recovery Plan" ? 'asc' : 'desc'
            };
        }
        if (type === 'refresh' && filterCriteria) {
            filterCriteria['criteria'] = this.props.type === "List View" ? this.state.filterCriteria.criteria : [];
            // delete filterCriteria.globalSearch;
            if (this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
                filterCriteria = await this.getAdditionalCriteriaafterRefresh(filterCriteria);
            }
            filterCriteria['direction'] = this.state.filterCriteria.direction;
            filterCriteria['sortfield'] = this.props.sortField ? this.props.sortField : "created";
            if (this.props.type === "Company" && localStorage.getItem('SearchedCompany')) {
                localStorage.removeItem('SearchedCompany');
            }
        }
        if (type === "globalSearch") {
            if (this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
                filterCriteria = await this.getAdditionalCriteriaafterRefresh(filterCriteria);
            }
            delete filterCriteria.globalSearch;
            this.setState({
                selectFilterValue: '',
            })
        }
        if (this.props.params) {
            let params = this.props.params
            for (let i in params) {
                if (i) {
                    let obj = {}
                    obj['key'] = i
                    obj['value'] = params[i]
                    obj['type'] = 'regexOr'
                    filterCriteria['criteria'].push(obj)
                }
            }
        }
        //applyng filter when a row item is deleted 
        if (filterCriteria === undefined) {
            filterCriteria = { limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10, page: 1, criteria: [], direction: 'desc' };
            filterCriteria['sortfield'] = this.props.sortField ? this.props.sortField : "created";
            apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
        }
        if (this.props.filterExtension) {
            apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${this.props.filterExtension}`
        }
        if (this.props.hasQueryInCall) {
            apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
        }
        else {
            if (this.props.type === "List View" || this.props.type === "Recovery Planner V2") {
                filterCriteria = await this.getCriteriaListView(filterCriteria)
                apiUrl = await this.getUrlwithFlags(url, type, filterCriteria)

            } else {
                if (this.props.type === "ClaimsManagement") {
                    filterCriteria = await this.getCriteriaUrlforClaims(apiUrl, filterCriteria)
                }
                var dataurl, obj;
                if (localStorage.getItem("nspropertiesparamsUrl")) {
                    dataurl = new URL(localStorage.getItem("nspropertiesparamsUrl"));
                } else {
                    dataurl = new URL(window.location.href);
                }
                if (this.state.PropertyData && this.state.PropertyData.groupMessage && this.state.PropertyData.groupFlag) {
                    let flag = this.state.PropertyData.groupFlag.slice(0, 6);
                    filterCriteria.sortfield = `PropertyReviewedFlag, ${flag}ParentPropertyID, ${this.state.PropertyData.groupFlag}, PropertyName, PropertyAddressLine1`
                }
                if (this.state.PropertyData && this.state.PropertyData.groupMessage && !this.state.PropertyData.groupFlag) {
                    filterCriteria.sortfield = "PropertyReviewedFlag, Group1ParentPropertyID, Group1Flag, Group2ParentPropertyID, Group2Flag, Group3ParentPropertyID, Group3Flag, Group4ParentPropertyID, Group4Flag, Group5ParentPropertyID, Group5Flag, Group6ParentPropertyID, Group6Flag, Group7ParentPropertyID, Group7Flag, Group8ParentPropertyID, Group8Flag, Group9ParentPropertyID, Group9Flag, Group10ParentPropertyID, Group10Flag, PropertyName, PropertyAddressLine1"
                }
                apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
                apiUrl = await this.getUrlbasedonrequirements(apiUrl, filterCriteria, url)
            }
        }
        if (this.props.type === "Validated Prop. By State") {
            apiUrl = `${apiUrl}&sellerID=${this.props.sellerID}`
        }

        if (this.props.type === "Ready To File") {
            if (this.state.activeTab && this.state.activeTab === "Ready To File") {
                let StatusArray = [];
                let filterCriteria1 = {}
                filterCriteria1['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: 'screen', value: "Ready To File", type: "eq" }];
                await fetchMethodRequest('GET', `${apiCalls.AvailableToFile}?filter=${JSON.stringify(filterCriteria1)}`).then(async (response) => {
                    if (response && response["statusConfigurations"] && response["statusConfigurations"].length > 0) {
                        let propertyStatuses = response["statusConfigurations"]
                        for (let status of propertyStatuses) {

                            StatusArray.push(status.status)
                        }
                        this.setState({ ReadyToFileStatus1: StatusArray })
                    }
                })

                // apiUrl = `${apiUrl}&matchStatus=Validated-Ready to File`
                let obj = {}
                obj['key'] = "MatchMatchStatus"
                obj['value'] = this.props.ReadyToFileStatus && this.props.ReadyToFileStatus.length > 0 ? this.props.ReadyToFileStatus : this.state.ReadyToFileStatus1 ? this.state.ReadyToFileStatus1 : []
                obj['type'] = 'in'
                obj["add_Filter"] = true
                let index = filterCriteria["criteria"].findIndex(obj => obj.key === "MatchMatchStatus")
                if (index !== -1) {
                    filterCriteria["criteria"].splice(index, 1)

                }
                filterCriteria['criteria'].push(obj)
                apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
            }
        } else if (this.props.type === "On Hold") {
            if (this.state.activeTab && this.state.activeTab === "On Hold") {
                let obj = {}
                obj['key'] = "MatchMatchStatus"
                obj['value'] = this.props.OnHoldStatus ? this.props.OnHoldStatus : []
                obj['type'] = 'in'
                obj["add_Filter"] = true
                let index = filterCriteria["criteria"].findIndex(obj => obj.key === "MatchMatchStatus")
                if (index !== -1) {
                    filterCriteria["criteria"].splice(index, 1)

                }
                filterCriteria['criteria'].push(obj)
                // filterCriteria['criteria'].push(obj)
                apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
            }
            // apiUrl = `${apiUrl}&matchStatus=Validated-Ready to File`
        }

        if (this.props.type === "ClaimSummary") {
            filterCriteria.sortfield = this.state.clearFiltersGroupBy
        }
        if (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") {
            localStorage.setItem("sellerFilter", JSON.stringify(filterCriteria))
        } else {
            localStorage.setItem("sellerFilter", null)

        }
        return apiUrl;
    }
    //get table list data from server with filters if any
    getDataFromServer = async (filterCriteria, type, setGoButton, bodyData) => {
        let url;
        if (this.props.type === "Company" && this.state.city === "Public") {
            url = apiCalls.publicCompanies
        } else {
            url = this.getAPIUrl();
        }
        if (type === "dateQuery") {
            await this.setState({
                dateQuery: filterCriteria
            })
        }

        // if (filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length !== 0 && this.props.type === "Property Editor") {
        //   let index = filterCriteria["criteria"].findIndex(obj => obj.key === "nameIssueCode")
        // if (index === -1 && (this.state.PropertyData && this.state.PropertyData.nameIssueCode !== "delete"))
        // if (this.props.type === "Property Editor") {
        //   filterCriteria["criteria"].push({
        //     key: "nameIssueCode",
        //     value: ["5", "7", "2", "6"],
        //     type: "nin",
        //     add_Filter: true
        //   })
        // }
        // }
        let SearchCompanyID = localStorage.getItem("SearchedCompany")
        if (SearchCompanyID && SearchCompanyID.length > 0) {
            document.getElementById("globalSearch").value = SearchCompanyID
        }
        localStorage.removeItem("ISEdited")
        if (this.props.type === "Company" && SearchCompanyID && SearchCompanyID.length > 0 && SearchCompanyID !== undefined) {
            filterCriteria.globalSearch = {
                value: encodeURIComponent(SearchCompanyID),
                type: this.props.globalSearchFieldName
            };
            await this.setState({
                hidePropValues: false
            })
        }
        if (url) {
            let apiUrl, Counts = [];
            this.setState({
                progress: 0,
                isLoading: true,
                selectedRows: '',
            });
            await this.handlePercentage('1')

            apiUrl = await this.getDataAfterCriteria(filterCriteria, type, setGoButton, url)
            if (this.props.type === "Property Editor") {
                let index = filterCriteria && filterCriteria["criteria"] ? filterCriteria["criteria"].findIndex(obj => obj.type === "fuzzyregexOr") : null
                // if (index !== -1) filterCriteria["criteria"].splice(index, 1)
                if (index !== -1) {
                    apiUrl = `${apiUrl}&wildCard=${true}`
                }
            }
            let method, body = {};
            if (this.props.type === "UP Opportunity – V2") {
                body.dealType = ["BK", "CR"]
                body = typeof bodyData !== "string" && bodyData !== undefined ? bodyData : this.state.postBody && this.state.postBody !== undefined ? this.state.postBody : body
                method = "POST"
                body = body
            } else {
                method = "GET"
            }
            return fetchMethodRequest(method, apiUrl, body)
                .then(async (response) => {
                    if (this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor") {
                        if (setGoButton) {
                            this.propertyFilterRef.setGoButton()
                        }
                    }
                    let apiResponseKey = this.state.city === "Public" ? "publicCompanies" : this.props.apiResponseKey;
                    let sessionexpired = localStorage.getItem('sessionexpired')
                    if (sessionexpired === "true") { await this.setState({ sessionExpiryModal: true }) }
                    let responseData = [], totalRecordsLength = this.state.totalRecordsLength;
                    if (response && response.details && response.details.length > 0) {
                        responseData = response.details;
                        totalRecordsLength = response.details.length;
                    } else if (response && response['errorMessage']) {
                        await this.getErrorResponseFromServer(response)
                    }
                    let IssueCodes = {}, defaultIssueNames = []
                    if (this.props.type === "Property Editor") {
                        let nameIssueCodes = [], newNameIssueCodes = []
                        if (response.nameIssueCodes.length > 0) {
                            for (var item of response.nameIssueCodes) {
                                nameIssueCodes.push({ label: item === null ? "N/A" : item, value: item })
                                // if (item !== "5" && item !== "7" && item !== "2" && item !== "6") {
                                //   defaultIssueNames.push(item)
                                // }
                            }
                        }
                        if (response.newNameIssueCodes.length > 0) {
                            for (var obj of response.newNameIssueCodes) {
                                newNameIssueCodes.push({ label: obj, value: obj })
                            }
                        }
                        IssueCodes.nameIssueCodes = nameIssueCodes
                        IssueCodes.newNameIssueCodes = newNameIssueCodes
                        IssueCodes.defaultIssueNames = []
                    }
                    if (apiResponseKey) {
                        if ((type === null || type !== "column_filter") && this.state.activeTab !== "SP") {
                            this.clickCount(response, this.props.type)
                        }
                        if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
                            if (response.pagination && response.pagination.totalCount) {
                                totalRecordsLength = response.pagination.totalCount;
                            }
                            if (this.props.type === "Properties" || this.props.type === "Deal Sizing Report For Private Equity Portfolio" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" || this.props.type === "ClaimSummary") {
                                this.isShowTable(true)
                            }
                            responseData = this.updateSlNoToData(response[apiResponseKey]);
                            responseData = this.updateDateFormat(responseData, this.state.dateFormat);
                            if (this.props.type === "ClaimsManagement") {
                                responseData = this.updateReasonfromserver(responseData);
                            }

                        }
                        if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount === 0)) {
                            totalRecordsLength = response.pagination.totalCount;
                            if (this.props.type === "OPRA2 Property Editor" || this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Properties") {
                                // if (setGoButton)
                                this.propertyFilterRef.setGoButton()
                            }
                        }
                        if ((this.props.type === "Properties" && totalRecordsLength > 1000) || ((this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties") && totalRecordsLength > 10000)) {
                            this.isShowTable(false)
                        }

                        // await this.setState({ isLoading: false })
                        if ((this.props.type === "Claims" || this.props.type === "ClaimsManagement") && filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length === 0) {
                            this.isShowTable(false)
                        }

                        if (this.props.type === "Deal Sizing Report For Companies" && response.deal_sizing_reports.length === 0) {
                            this.isShowTable(true)
                        }
                        if (this.props.type === "Manually Loaded Properties") {
                            this.isShowTable(true)
                        }
                    }
                    let colunHeight
                    if (this.props.frozen && this.state.isFrozenColumns) {
                        colunHeight = document.getElementsByClassName("p-datatable-thead")[1].clientHeight
                    }
                    await this.applyCustomChanges(responseData, response)
                    if (this.props.type === "ClaimsManagement" && responseData.length < 5) {
                        this.expandAll(responseData)
                    } else if (this.props.type === "ClaimsManagement" && responseData.length > 5) {
                        await this.closeAll(responseData)
                    }
                    this.setState({
                        progress: 100,
                        responsesaveData: JSON.parse(JSON.stringify(responseData)),
                        IssueCodes: IssueCodes,
                        colunHeight: colunHeight,
                        allUsersData: responseData,
                        response: response,
                        isLoading: false,
                        disableIcon: false,
                        isDisabledTab: false,
                        lastScoredDate: response.lastScoredDate ? dateFormats.formatDate(moment(response.lastScoredDate).utcOffset(dateFormats.isDstObserved(response.lastScoredDate) ? '-05:00' : '-06:00'), "MM/DD/YY hh:mm A CT") : response.lastUpdatedDate ? dateFormats.formatDate(moment(response.lastUpdatedDate).utcOffset(dateFormats.isDstObserved(response.lastUpdatedDate) ? '-05:00' : '-06:00'), "MM/DD/YY hh:mm A CT") : "",
                        totalRecordsLength: totalRecordsLength,
                    })
                    this.setDownloadData();

                    return responseData;
                }).catch((err) => {
                    // this.getErrorMessage(config.serverErrMessage)
                    return err;
                });
        }
    }


    setDownloadData = async (val) => {
        if (this.state.downloadData && this.state.downloadData.length > 0 && this.props.xlsxExport) {
            return fetchMethodRequest('GET', `${this.state.apiUrlforExport}&type=exportToCsv`)
                .then(async (response) => {
                    if (response && response["ns_scored_propertys"] && response["ns_scored_propertys"].length && response["ns_scored_propertys"].length > 0) {
                        let downloadData = this.state.downloadData
                        let item = {
                            name: this.state.activeTab,
                            headers: this.setHeadersforxlsx("Data"),
                            data: await this.formatChange(response["ns_scored_propertys"])
                        }
                        let itemId = downloadData.findIndex(obj => obj.name === this.state.activeTab)
                        if (itemId === -1) {
                            downloadData.push(item)
                        }
                        await this.setState({
                            downloadData: downloadData
                        })
                        this.dealStatsFilterRef.getDownloadData(downloadData)
                    }
                }).catch((err) => {
                    return err;
                })
        }
    }


    expandAll(t) {
        let rowDataValues = this.expandDataAll(t)
        this.setState({
            expandedRows: rowDataValues
        });
    }
    closeAll = async (t) => {
        let rowDataValues = await this.closeDataAll(t)
        this.setState({
            expandedRows: rowDataValues
        });
    }
    closeDataAll = async (t) => {
        let expandedRows = {};
        t.forEach(element => {
            if (element.ChildCount > 0) {

                // expandedRows[`${element.DealID}`] = true;
                expandedRows = null;
            }
        });
        return expandedRows;
    }

    expandDataAll(t) {
        let expandedRows = {};
        t.forEach(element => {
            if (element.ChildCount > 0) {
                // expandedRows[`${element.DealID}`] = true;
                expandedRows[`${element.OppClaimID}`] = true;
            }
        });
        return expandedRows;
    }
    showMatchedResponses = (url) => {
        if (url && url.properties && url.pagination) {
            this.updateDateFormat(url.properties, "MM/DD/YYYY")
            this.setState({
                isLoading: false,
                allUsersData: url.properties,
                totalRecordsLength: url.pagination.totalCount
            })
        } else {
            this.setState({
                isLoading: false,
                allUsersData: []
            })
        }
    }


    applyCustomChanges = async (responseData, response) => {
        if ((this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report") && responseData && responseData.length > 0) {
            this.setWeeklyPlanChanges(responseData)
        }
        if (this.state.softWarning) {
            setTimeout(() => this.setState({ softMessage: "", softWarning: false }), 30000);
        }
        if ((this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties") && response.counts) {
            await this.setState({
                Counts: response.counts
            })
        }
        if (this.props.type === "Deal Sizing Report For Companies") {
            if (response) {
                this.props.getReportSummaryDetail(response)
            }
            if (response.lastUpdatedDate) {
                this.propertyFilterRef.lastUpdatedDate(response.lastUpdatedDate)
            }
        }

        localStorage.setItem('responsesaveData', JSON.stringify(responseData))

    }

    getErrorResponseFromServer = async (response) => {
        if (response && response['errorMessage'] === configMessages.warningMessage) {
            this.setState({
                sessionWarning: true
            })
        } else if (response && response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
            this.setState({
                sessionExpiryModal: true
            })
        } else if (response && response['errorMessage']) {
            this.getErrorMessage(response.errorMessage, "", "error")
            if (this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
                await this.setState({
                    totalRecordsLength: 0,
                    allUsersData: []
                })
                await this.isShowTable(false)
            }
            // else if (response && response['errorMessage'] && (this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties")) {
            //   await this.setState({
            //     totalRecordsLength: 0,
            //   })
            //   await this.isShowTable(false)
            // }
        }
    }

    getAdditionalCriteriaafterRefresh = async (filterCriteria) => {
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "bucket")
        if (index !== -1) filterCriteria["criteria"].splice(index, 1)
        filterCriteria['criteria'].push({
            key: "bucket",
            value: this.state.activeTab,
            type: "eq",
            add_Filter: true
        })
        if (this.state.PropertyData && this.state.PropertyData.selectedState) {
            let index = filterCriteria["criteria"].findIndex(obj => obj.key === "sourceState")
            if (index !== -1) filterCriteria["criteria"].splice(index, 1)
            filterCriteria['criteria'].push({
                key: this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? "sourceState" : this.props.type === "Deal Sizing-Properties" ? "sourceState" : "property_StateId",
                value: this.state.PropertyData.selectedState,
                type: "in"
            })
        }

        return filterCriteria;
    }

    getCriteriaUrlforClaims = async (apiUrl, filterCriteria) => {

        if (this.state.PropertyData && this.props.type === "ClaimsManagement") {
            let obj = this.state.PropertyData
            if (obj.selectedClaims.EstateID) {
                localStorage.setItem("claimSummaryID", obj.selectedClaims.EstateID)
                localStorage.setItem("claimSummaryName", obj.selectedClaims.EstateName)
                localStorage.setItem("hitback", "no")
                let index = filterCriteria.criteria.findIndex(obj => obj.key === "CompanyID")
                if (index !== -1)
                    filterCriteria["criteria"].splice(index, 1)

                filterCriteria.criteria.push({
                    key: "CompanyID",
                    value: obj.selectedClaims.EstateID,
                    type: "eq",
                    add_Filter: true
                })

            }
            if (obj.selectedClaims.BusinessNameID) {
                let index = filterCriteria.criteria.findIndex(obj => obj.key === "BusinessNameID")
                if (index !== -1)
                    filterCriteria["criteria"].splice(index, 1)

                filterCriteria.criteria.push({
                    key: "BusinessNameID",
                    value: obj.selectedClaims.BusinessNameID,
                    type: "eq",
                    add_Filter: true
                })
            }
            if (obj.selectedClaims.selectedOpraStClaimId) {
                let index = filterCriteria.criteria.findIndex(obj => obj.key === "StateClaimID")
                if (index !== -1)
                    filterCriteria["criteria"].splice(index, 1)

                const results = obj.selectedClaims.selectedOpraStClaimId.map(element => {
                    return element.trim();
                });
                filterCriteria.criteria.push({
                    key: "StateClaimID",
                    value: results,
                    type: "in"
                })
            }
            if (obj.selectedClaims.selectedOpraClaimIds) {
                let index = filterCriteria.criteria.findIndex(obj => obj.key === "OppClaimID")
                if (index !== -1)
                    filterCriteria["criteria"].splice(index, 1)

                const results = obj.selectedClaims.selectedOpraClaimIds.map(element => {
                    return element.trim();
                });
                filterCriteria.criteria.push({
                    key: "OppClaimID",
                    value: results,
                    type: "in"
                })
            }
            if (obj.selectedClaims.selectedOpraStId) {
                let index = filterCriteria.criteria.findIndex(obj => obj.key === "PropertyID")
                if (index !== -1)
                    filterCriteria["criteria"].splice(index, 1)
                const results = obj.selectedClaims.selectedOpraStId.map(element => {
                    return element.trim();
                });
                filterCriteria.criteria.push({
                    key: "PropertyID",
                    value: results,
                    type: "in"
                })
            }
            if (obj.selectedClaims.setectedStOpraId) {
                let index = filterCriteria.criteria.findIndex(obj => obj.key === "StatePropertyID")
                if (index !== -1)
                    filterCriteria["criteria"].splice(index, 1)
                const resultsData = obj.selectedClaims.setectedStOpraId.map(element => {
                    return element.trim();
                });
                filterCriteria.criteria.push({
                    key: "StatePropertyID",
                    value: resultsData,
                    type: "in"
                })
            }
            await this.setState({
                filterCriteria: filterCriteria
            })
        }

        return filterCriteria;
    }

    getUrlbasedonrequirements = async (apiUrl, filterCriteria, url) => {
        if (this.props.type === "Properties" || this.props.type === "Deal Sizing Report For Private Equity Portfolio" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" || this.props.type === "ClaimSummary") {
            apiUrl = await this.setFlagsforProperties(apiUrl)
            await this.setState({
                apiUrlforExport: apiUrl
            })
        }
        if (this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report" || this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity") {
            apiUrl = await this.getCustomisedRecoveryUrl(url, filterCriteria)
        }
        // if (this.props.type === "Company" && this.state.isTopFilter) {
        //   apiUrl = `${apiUrl}&isTopFilter=true`
        // }
        if (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") {
            apiUrl = `${apiUrl}&ScoringStatus=In Progress`
        } if (this.props.type === "Manually Loaded Properties") {
            apiUrl = await this.ManuallyLoadedRef.ManuallyLoadedRefValues(apiUrl)
            await this.setState({
                apiUrlforExport: apiUrl
            })
        } if (this.props.type === "Utilization - Integrity" || this.props.type === "Utilization - US Team") {
            apiUrl = await this.getReportsUrl(apiUrl)
            if (this.state.seletedSellerType) {

                apiUrl = `${apiUrl}&company=${this.state.seletedSellerType}`
            }
        }
        if (this.props.type === "Deal Statistics" && this.state.activeTab === "Data") {
            apiUrl = `${apiUrl}&sellerID=${this.state.sellerId}`
            await this.setState({
                apiUrlforExport: apiUrl
            })
        }
        return apiUrl;
    }

    getReportsUrl = async (apiUrl) => {
        if (window.location.href.includes("integrity")) {
            apiUrl = `${apiUrl}&team=Integrity`
        } else if (window.location.href.includes("executive")) {
            apiUrl = `${apiUrl}&team=US`
        }

        return apiUrl;
    }

    isShowTable = (e) => {
        this.setState({
            isShowTable: e
        })
    }

    getCriteriaListView = async (filterCriteria) => {
        if (this.props.type === "List View") {
            if (!this.state.userRole && this.state.loginCredentials) {
                filterCriteria['criteria'].push({
                    "key": "assignToId",
                    "value": this.state.loginCredentials._id,
                    "type": "eq"
                }
                )
            }
            if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].findIndex(obj => obj.key === "status") === -1 && this.state.selectedStatus.length > 0) {
                filterCriteria['criteria'].push({
                    "key": "status",
                    "value": this.state.selectedStatus,
                    "type": "in"
                }
                )
            }
            if (this.state.showSiteDiscoveryCards && filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].findIndex(obj => obj.key === "title") === -1) {
                filterCriteria['criteria'].push({
                    "key": "title",
                    "value": "Site Discovery",
                    "type": "eq"
                })
            }
        }
        return filterCriteria
    }

    setFlagsforProperties = async (apiUrl) => {
        if ((this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") && this.state.PropertyData && this.state.PropertyData.selectedSeller && this.state.PropertyData.selectedName && this.state.PropertyData.selectedName.length > 0) {
            apiUrl = `${apiUrl}&sellerID=${this.state.PropertyData.selectedSeller.EstateID ? this.state.PropertyData.selectedSeller.EstateID : this.state.PropertyData.selectedSeller}&nameID=${encodeURIComponent(this.state.PropertyData.selectedName)}`
        } if ((this.props.type === "Properties" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") && this.state.PropertyData && this.state.PropertyData.selectedSeller && (!this.state.PropertyData.selectedName || this.state.PropertyData.selectedName.length === 0)) {
            apiUrl = `${apiUrl}&sellerID=${this.state.PropertyData.selectedSeller.EstateID}`
        } if ((this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") && this.state.PropertyData && this.state.PropertyData.selectedProperty_Id) {
            apiUrl = `${apiUrl}&propertyId=${this.state.PropertyData.selectedProperty_Id}&&missingOPRAPropertyIds`
        } if ((this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") && this.state.PropertyData && this.state.PropertyData.selectedOpraStId) {
            apiUrl = `${apiUrl}&statePropertyId=${this.state.PropertyData.selectedOpraStId}&missingStatePropertyIds`
        }
        if (this.state.PropertyData && this.state.PropertyData.groupFlag) {
            let key = this.state.PropertyData.groupFlag
            apiUrl = `${apiUrl}&redirecting=${key}`

        }
        if ((this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") && this.state.PropertyData && this.state.PropertyData.selectedBucket) {
            apiUrl = `${apiUrl}&matchbucket=${this.state.PropertyData.selectedBucket}`
        }
        if (this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
            apiUrl = `${apiUrl}&CompanyID=${this.state.PropertyData && this.state.PropertyData.selectedSeller && this.state.PropertyData.selectedSeller.EstateID ? this.state.PropertyData.selectedSeller.EstateID : this.state.PropertyData.selectedSeller}`
        }
        if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
            if (this.state.PropertyData) {
                if (this.state.PropertyData.isValidate) {
                    apiUrl = `${apiUrl}&validated=true`
                }
                if (this.state.PropertyData.isexcludeValidated) {
                    apiUrl = `${apiUrl}&excludeValidated=true`
                }
                if (this.state.PropertyData.isexcludePossiblyPaid) {
                    apiUrl = `${apiUrl}&isexcludePossiblyPaid=true`
                }
                if (this.state.PropertyData.isexcludeProperties) {
                    apiUrl = `${apiUrl}&excludeProperties=true`
                }
                if (this.state.PropertyData.excludeCoOwners) {
                    apiUrl = `${apiUrl}&excludeCoOwners=true`
                }
                if (this.state.PropertyData.excludeNotConfirmedOnSite) {
                    apiUrl = `${apiUrl}&excludeNotConfirmedOnSite=true`
                }
                if (this.state.PropertyData.isexcludeRoyalties) {
                    apiUrl = `${apiUrl}&excludeRoyalties=true`
                }
                if (this.state.PropertyData.selectedRanges) {
                    let index = this.state.PropertyData.selectedRanges.indexOf("All");
                    if (index === -1) {
                        apiUrl = `${apiUrl}&ranges=${this.state.PropertyData.selectedRanges}`
                    } else {
                        apiUrl = `${apiUrl}&ranges=All`
                    }
                }
            }
        }
        if (this.props.type === "ClaimSummary") {
            if (this.state.PropertyData && this.state.PropertyData.claimType) {
                apiUrl = `${apiUrl}&claimType=${this.state.PropertyData.claimType}`
            }
            if (this.state.PropertyData && this.state.PropertyData.DealId) {
                apiUrl = `${apiUrl}&DealId=${this.state.PropertyData.DealId}`
            }
        }
        return apiUrl;
    }

    //listView ApiwithFlags
    getUrlwithFlags = async (url, type, filterCriteria) => {
        let startDate = this.timeConversionString(new Date(this.state.startDateReports));
        let endDate = this.timeConversionString(new Date(this.state.lastDateReports));
        let selectedDates = {
            'startDate': startDate, 'endDate': endDate
        }
        let apiUrl;
        if (type === "dateQuery") {
            apiUrl = `${url}?${this.props.type === "List View" ? `view=list&` : ''}filter=${JSON.stringify(this.state.filterCriteria)}&dateQuery=${JSON.stringify(this.props.type === "List View" ? this.state.dateQuery : selectedDates)}`
        } else {
            if (typeof this.state.dateQuery === "object") {
                apiUrl = `${url}?${this.props.type === "List View" ? `view=list&` : ''}filter=${JSON.stringify(filterCriteria)}&dateQuery=${JSON.stringify(this.props.type === "List View" ? this.state.dateQuery : selectedDates)}`
            } else {
                apiUrl = `${url}?${this.props.type === "List View" ? `view=list&` : ''}filter=${JSON.stringify(filterCriteria)}`;
            }
        }

        if (this.state.unassignedOnly) {
            apiUrl = `${apiUrl}&isUnassigned=true`
        }
        if (this.state.selectedDepartment && this.state.selectedDepartment.length > 0) {
            apiUrl = `${apiUrl}&selectedDepartment=${encodeURIComponent(this.state.selectedDepartment.join(", "))}`
        }
        await this.setState({
            apiUrlforExport: apiUrl
        })
        return apiUrl
    }

    getCustomisedRecoveryUrl = async (url, filterCriteria) => {
        let startDate = this.timeConversionString(new Date(this.state.startDateReports));
        let endDate = this.timeConversionString(new Date(this.state.lastDateReports));
        let selectedDates = {
            'startDate': startDate, 'endDate': endDate
        }
        let plan, apiUrl;
        if (window.location.pathname === "/nonSiteDiscoveryPlan") {
            plan = "Non-Search"
        } else if (window.location.pathname === "/siteDiscoveryPlan") {
            plan = "Search"
        }

        if (plan) {
            apiUrl = `${url}?dateQuery=${JSON.stringify(selectedDates)}&filter=${JSON.stringify(filterCriteria)}&plan=${plan}`
        } else {
            apiUrl = `${url}?dateQuery=${JSON.stringify(selectedDates)}&filter=${JSON.stringify(filterCriteria)}`
        }
        if (apiUrl) {
            return apiUrl;
        } else {
            return ""
        }

    }

    //Counts for footer in weekly Recovery PLan
    setWeeklyPlanChanges = async (responseData) => {
        var totalForcastTransitionHours = Object.values(responseData).reduce((t, { forecastTransitionHours }) => t + forecastTransitionHours, 0)
        var totalActualTransitionHours = Object.values(responseData).reduce((t, { transitionHours }) => t + transitionHours, 0)
        var totalForcastSiteDiscovery = Object.values(responseData).reduce((t, { forecastSearchHours }) => t + forecastSearchHours, 0)
        var totalActualSiteDiscovery = Object.values(responseData).reduce((t, { searchHours }) => t + searchHours, 0)
        var totalForcastValidation = Object.values(responseData).reduce((t, { forecastValidationHours }) => t + forecastValidationHours, 0)
        var totalActualValidation = Object.values(responseData).reduce((t, { validationHours }) => t + validationHours, 0)
        var totalForcastFile = Object.values(responseData).reduce((t, { forecastFileHours }) => t + forecastFileHours, 0)
        var totalActualFile = Object.values(responseData).reduce((t, { fileHours }) => t + fileHours, 0)
        var totalSideProjectActual = Object.values(responseData).reduce((t, { recoverySideProjectHours }) => t + recoverySideProjectHours, 0)
        await this.setState({
            totalForcastTransitionHours: totalForcastTransitionHours,
            totalActualTransitionHours: totalActualTransitionHours,
            totalForcastSiteDiscovery: totalForcastSiteDiscovery,
            totalActualSiteDiscovery: totalActualSiteDiscovery,
            totalForcastValidation: totalForcastValidation,
            totalActualValidation: totalActualValidation,
            totalForcastFile: totalForcastFile,
            totalActualFile: totalActualFile,
            totalSideProjectActual: totalSideProjectActual,
            totalActualHours: totalActualTransitionHours + totalActualValidation + totalActualFile,
            totalForcastHours: totalForcastTransitionHours + totalForcastValidation + totalForcastFile
        })
    }
    lowestValueAndKey = (obj) => {
        if (obj) {
            var [lowestItems] = Object.entries(obj).filter(a => a[1] > 0).sort(([, v1], [, v2]) => v1 - v2);
            if (lowestItems && lowestItems[0]) {
                return lowestItems[0]
            }
            else {
                return "Green"
            }
        }
    }
    // clickCount = async (response, type) => {
    //   // if (response && response.counts && type === "Property Editor" && this.state.isChangeHit) {
    //   if (response && response.counts && type === "Property Editor" && this.state.isChangeHit) {
    //     if (response.counts.Green === 0) {
    //       if (response.counts.Blue > 0) {
    //         this.toggletab("Blue")
    //       } else if (response.counts.Yellow > 0) {
    //         this.toggletab("Yellow")
    //       } else if (response.counts.Brown > 0) {
    //         this.toggletab("Brown")
    //       } else if (response.counts.Black > 0) {
    //         this.toggletab("Black")
    //       } else if (response.counts.Teal > 0 && (this.state.loginRole === "Admin" || this.state.loginRole === "Recovery Manager")) {
    //         this.toggletab("Teal")
    //       } else if (response.counts.All > 0) {
    //         if (response.counts.All > 10000) {
    //           this.toggletab(lowestBucket)
    //         } else {
    //           this.toggletab("All")
    //         }
    //       } else {
    //         this.toggletab("Green")
    //       }
    //     }
    //     await this.setState({ isChangeHit: false })
    //   }
    // }

    clickCount = async (response, type) => {
        // if (response && response.counts && type === "Property Editor" && this.state.isChangeHit) {
        var lowestBucket;
        if (response.counts) {
            lowestBucket = this.lowestValueAndKey(response.counts)
        }
        if (response && response.counts && type === "Property Editor" && this.state.isChangeHit) {
            if (response.counts.Green === 0) {
                if (response.counts.Blue > 0) {
                    if (response.counts.Blue > 10000) {
                        this.toggletab(lowestBucket)
                    } else {
                        this.toggletab("Blue")
                    }
                } else if (response.counts.Yellow > 0) {
                    if (response.counts.Yellow > 10000) {
                        this.toggletab(lowestBucket)
                    } else {
                        this.toggletab("Yellow")
                    }
                } else if (response.counts.Brown > 0) {
                    if (response.counts.Brown > 10000) {
                        this.toggletab(lowestBucket)
                    } else {
                        this.toggletab("Brown")
                    }
                } else if (response.counts.Black > 0) {
                    if (response.counts.Black > 10000) {
                        this.toggletab(lowestBucket)
                    } else {
                        this.toggletab("Black")
                    }
                } else if (response.counts.Teal > 0 && (this.state.loginRole === "Admin" || this.state.loginRole === "Recovery Manager")) {
                    if (response.counts.Teal > 10000) {
                        this.toggletab(lowestBucket)
                    } else {
                        this.toggletab("Teal")
                    }
                } else if (response.counts.All > 0) {
                    if (response.counts.All > 10000) {
                        this.toggletab(lowestBucket)
                    } else {
                        this.toggletab("All")
                    }
                } else {
                    this.toggletab("Green")
                }
            }
            await this.setState({ isChangeHit: false })
        }
    }

    getAPIUrl() {
        return this.props.apiUrl;
    }

    getDataForMatch = () => {
        if (this.props.getFileOptionsforMatching) {
            this.props.getSellerName(this.state.PropertyData.selectedSeller)
            this.props.getFileOptionsforMatching(this.state.fileForOptions)
        }
    }
    sendUpPropConfirmation = async (obj) => {
        let body = {
            // PropertyIds: obj.coutntValue ? obj.coutntValue.split(',').map(Number) : [],
            StatePropertyIds: obj.coutntValue ? obj.coutntValue : [],
            CompanyID: obj.selectedSeller.EstateID,
            SourceState: obj.selectedState
        }
        await this.setState({ isLoading: true })
        fetchMethodRequest('POST', 'NsScoredProperties/checkProperties', body)
            .then(async (response) => {
                if (response) {
                    this.setState({
                        uppropConfirmationMessage: true, uppropPropertyIds: response.notUpdatedList, upconfirmProperties: response
                    })
                    let notUpdateArray = []
                    for (let id of response.notUpdatedList) {
                        notUpdateArray.push({ id: id, clicked: false })
                    }
                    await this.setState({ notUpdateArray: notUpdateArray })
                } else if (response && response.errorMessage) {
                    this.getErrorMessage(response.errorMessage, "", "error")
                }
                await this.setState({ isLoading: false })


            }).catch((err) => {
                return err;
            });
        // `Out of ${response.totalPropertyCount} properties, ${response.updated} were update as Confirmed on site.${response.notUpdated} Properties were NOT found in the OPRA2, below are those properties: Please lot these properties in OPRA manually`
    }
    clearOnsitemessage = async () => {
        await this.setState({ uppropConfirmationMessage: false, uppropPropertyIds: [] })
    }
    //Custom Properties  Filters
    getFilteredValues = async (obj, criteria, changeHit, setGoButton, fileForOptions) => {
        if (this.props.type === "Deal Sizing Report For Companies") {
            // let criteria1 = criteria
            if (criteria && criteria.sortfield && criteria.sortfield === "EntityName") {
                criteria.sortfield = "BusinessName"
                criteria.direction = "asc"
                // this.setState({ filterCriteria: criteria })
            }
        } else if (this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
            if (criteria && criteria.sortfield && criteria.sortfield === "CompanyName") {
                criteria.sortfield = "bussinessName"
                criteria.direction = "asc"
                criteria.globalSearch = {}
                let index = criteria["criteria"].findIndex(obj => obj.key === "CompanyName" && obj.add_Filter === true)
                if (index !== -1) criteria["criteria"].splice(index, 1)

                // this.setState({ filterCriteria: criteria })
            }
        } else if (this.props.type === "ClaimSummary") {
            criteria = this.state.filterCriteria
            if (obj.groupBy) {
                this.setState({ clearFiltersGroupBy: obj.groupBy })

            }
            if (criteria) {
                criteria.sortfield = obj && obj.groupBy ? obj.groupBy : null
                // criteria.direction = "asc"
                // this.setState({ filterCriteria: criteria })
            }
        }

        let modifiedLabels = []
        if (changeHit) {
            await this.setState({ isChangeHit: true })
        }
        if (fileForOptions) {
            for (let name of fileForOptions) {
                modifiedLabels.push({
                    label: name.companyName,
                    value: {
                        labelName: name.companyName,
                        id: name.nameID,
                    },
                })
            }
        } else {
            fileForOptions = []
        }
        if (obj && criteria) {
            await this.setState({
                PropertyData: obj,
                fileForOptions: this.props.type === "ClaimsManagement" ? modifiedLabels : fileForOptions,
                first: 0,
                redirectSellerId: null,
                negativePeriod: null

            })
            await this.getDataForMatch()
            criteria.page = "1"
            if (this.state.PropertyData && (this.state.PropertyData.selectedSeller && !this.state.PropertyData.selectedSeller.EstateID || this.state.PropertyData.selectedSeller && this.state.PropertyData.selectedSeller.EstateID === "")) {
                delete criteria.globalSearch
                await this.setState({
                    selectFilterValue: "",
                    isShowTable: false,
                    totalRecordsLength: 0
                })
            }
            if (obj.selectedClaims) {
                this.editorComponentRef.getFileForEditor(obj.selectedClaims.EstateID)
                // let PropertyStatusindex = criteria.criteria.findIndex(obj => obj.key === "PropertyStatus")
                // if (PropertyStatusindex !== -1) criteria.criteria.splice(PropertyStatusindex, 1)

            } else {
                if (this.editorComponentRef)
                    this.editorComponentRef.getFileForEditor(this.state.PropertyData.selectedSeller.EstateID)
            }
            if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" && this.state.PropertyData.sell) {
                this.toggletab("Green", setGoButton)
                this.getNegativeNoticeSellers()
            } else {
                if (this.state.PropertyData && this.state.PropertyData.isValidate) {
                    this.getTableFieldsOrder();

                }
                this.getDataFromServer(criteria)
                this.getNegativeNoticeSellers()

            }
        } else {
            var url, obj;
            if (localStorage.getItem("nspropertiesparamsUrl")) {
                url = new URL(localStorage.getItem("nspropertiesparamsUrl"));
            } else {
                url = new URL(window.location.href);
            }
            obj = await this.getObjectFromCopiedUrl(url);
            let groupMessage = this.props && this.props.tabDescriptionArray ? this.props.tabDescriptionArray.filter(object => {
                return object.value.flagName === obj.groupFlag
            }) : []

            obj.groupMessage = groupMessage[0] ? groupMessage[0].value.description : ""
            obj.groupedflag = groupMessage[0] ? groupMessage[0].value.name : ""
            obj.redirectedGroup = groupMessage[0]
            await this.setState({
                PropertyData: obj,
                isShowTable: true,
                activeTab: obj.bucket,
                isChangeHit: true
            })
            this.getNegativeNoticeSellers()
            this.editorComponentRef.getFileForEditor(this.state.PropertyData.selectedSeller.EstateID)
            await this.getDataForMatch()
            let filtercriteria = this.state.filterCriteria
            let index = filtercriteria["criteria"].findIndex(obj => obj.key === "bucket")
            if (index !== -1) filtercriteria["criteria"].splice(index, 1)
            filtercriteria["criteria"].push({
                key: "bucket",
                value: this.state.activeTab,
                type: "eq",

                // type: "eq",
                add_Filter: true
            })
            let index2 = filtercriteria["criteria"].findIndex(obj => obj.key === "businessState" && obj.type === "in")
            if (index2 !== -1) filtercriteria["criteria"].splice(index, 1)
            if (obj.states && obj.states !== "null") {
                filtercriteria["criteria"].push({
                    key: this.props.type === "Deal Sizing-Properties" ? "state" : "sourceState",
                    value: obj.states.split(","),
                    type: "in"
                })
            }
            if (obj.fiftyk && obj.fiftyk !== "null") {
                filtercriteria["criteria"].push({
                    key: "propertyMaxValueAmount",
                    value: 50000,
                    type: "gt"
                })
            } if (obj.unknown && obj.unknown !== "null") {
                filtercriteria["criteria"].push({
                    key: "propertyMaxValueAmount",
                    value: 0,
                    type: "nexists"
                })
            } if (obj.Count10kTo50k && obj.Count10kTo50k !== "null") {
                filtercriteria["criteria"].push(
                    { key: "propertyMaxValueAmount", value: 10000, type: "gte" }, { key: "propertyMaxValueAmount", value: 50000, type: "lte" }
                )
            }

            let matchStatusIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "MatchMatchStatus" && obj.type === "in")
            if (matchStatusIndex !== -1) filtercriteria["criteria"].splice(matchStatusIndex, 1)
            if (obj.selectedPropertyStatus && obj.selectedPropertyStatus !== "null") {
                filtercriteria["criteria"].push({
                    key: "MatchMatchStatus",
                    value: obj.selectedPropertyStatus.split(","),
                    type: "in"
                })
            }
            if (obj.selectedPropertyReasons && obj.selectedPropertyReasons !== "null") {
                filtercriteria["criteria"].push({
                    key: "MatchStatusReason",
                    value: obj.selectedPropertyReasons.split(","),
                    type: "in"
                })
            }
            this.propertyFilterRef.getData(obj)

            this.getDataFromServer(this.state.filterCriteria)

            localStorage.removeItem("nspropertiesparamsUrl")
            window.history.pushState(null, null, `${this.props.routeTo}`);
        }
        if (this.props.type === "ClaimsManagement") {
            if (this.state.resized) {
                // await this.sendColumnDataToServer("", "GET")
                await this.setState({ resized: false })
            }
            await this.setDynamicColumns()
        }
    }
    getFileOptions = async (fileOptions) => {

        if (this.props.type === "ClaimsManagement") {

            let modifiedLabels = []
            for (let name of fileOptions) {
                modifiedLabels.push({
                    label: name.companyName,
                    value: {
                        labelName: name.companyName,
                        id: name.nameID,
                    },
                })
            }

            await this.setState({
                fileForOptions: modifiedLabels
            })

        } else {
            await this.setState({
                fileForOptions: fileOptions
            })
        }
    }
    getObjectFromCopiedUrl = async (url) => {
        var sellerName = url.searchParams.get("sellerName");
        var sellerId = url.searchParams.get("sellerId");
        var nameId = url.searchParams.get("nameId");
        var isValidate = url.searchParams.get("isValidated")
        var isBucket = url.searchParams.get("bucket")
        var selectedStates = url.searchParams.get("state")
        var Count10kTo50k = url.searchParams.get("10kto50k")
        var fiftyk = url.searchParams.get("50k")
        var unknown = url.searchParams.get("unknown")
        var Property_Id = url.searchParams.get("propertyId")
        var state_property_Id = url.searchParams.get("statePropertyId")
        var is_excludeValidated = url.searchParams.get("isexcludeValidated")
        var is_excludeProperties = url.searchParams.get("isexcludeProperties")
        var exclude_CoOwners = url.searchParams.get("excludeCoOwners")
        var isexcludePossiblyPaid = url.searchParams.get("isexcludePossiblyPaid")
        var selectedPropertyStatus = url.searchParams.get("selectedPropertyStatus")
        var selectedPropertyReasons = url.searchParams.get("selectedPropertyReasons")
        var isexcludeRoyalties = url.searchParams.get("excludeRoyalties")
        var selectedRanges = url.searchParams.get("ranges")
        var excludeNotConfirmedOnSite = url.searchParams.get("excludeNotConfirmedOnSite")
        var groupFlag = url.searchParams.get("GroupFlag")
        let obj = {}
        let SellerfromURL = {
            EstateName: sellerName,
            EstateID: sellerId
        }
        obj.selectedSeller = SellerfromURL
        if (nameId !== "null") {
            obj.selectedName = nameId
            obj.nameId = nameId
        }
        obj.selectedPropertyStatus = selectedPropertyStatus
        obj.selectedPropertyReasons = selectedPropertyReasons
        obj.selectedProperty_Id = Property_Id
        obj.bucket = isBucket
        obj.Count10kTo50k = Count10kTo50k
        obj.fiftyk = fiftyk
        obj.unknown = unknown
        obj.isexcludeValidated = is_excludeValidated
        obj.selectedOpraStId = state_property_Id
        obj.isexcludeProperties = is_excludeProperties
        obj.isexcludeRoyalties = isexcludeRoyalties
        obj.isexcludePossiblyPaid = isexcludePossiblyPaid
        obj.excludeCoOwners = exclude_CoOwners
        obj.selectedRanges = selectedRanges
        obj.excludeNotConfirmedOnSite = excludeNotConfirmedOnSite
        obj.groupFlag = groupFlag
        if (selectedStates) {
            obj.states = selectedStates
        }

        if (isValidate === "true") {
            obj.isValidate = isValidate
        }
        return obj;
    }
    goBack = async () => {
        localStorage.setItem("hitback", "yes")
        await this.setState({ goBackToClaims: true })
    }

    getFilterUrl(filterCriteria, type) {
        return this.props.apiUrl;
    }
    getBackButton() {
        let claimSummaryName = localStorage.getItem('claimSummaryName');

        return (
            <div className='d-flex'>

                <Button
                    color='primary'
                    className="mb-0"
                    outline
                    style={{ height: "34px" }}

                    // style={{ color: "white", backgroundColor: '#354f6e', width: 115 }}
                    onClick={() => this.goBack()}
                >

                    <FontAwesomeIcon
                        color='#354f6e'
                        style={{ cursor: "pointer", height: "1em" }}
                        icon={faArrowCircleLeft}
                        data-toggle="tool-tip"
                        onClick={() => this.goBack()}
                    />
                </Button>
                <p style={{ "font-weight": "600", marginLeft: "10px", marginTop: "3px", fontSize: "18px" }}>{`Deal : ${claimSummaryName}`}</p>

            </div >
        )
    }

    formatChange = (data) => {
        data.forEach((item) => {
            for (let key in item) {
                if (item[key] && item[key].name) {
                    item[key] = item[key].name;
                }
                if (typeof item[key] == "object") {
                    item[key] = this.flattenArray(item[key]);
                }
            }
        });
        return data
    }
    //Get all data of current screen with filters applied from server to export to CSV
    getDataToExport = async () => {
        if (this.props.type === "UP Opportunity") {
            await this.getCustumisedDatatoExport()
        } else {
            await this.setState({ isLoading: true, isexported: true })
            let filterCriteriaData = this.state.filterCriteria;
            // delete filterCriteriaData['limit'];
            // delete filterCriteriaData['page'];
            let url;
            url = this.props.apiResponseKey
            let apiUrl;
            if (this.props.type === "Property Editor" || this.props.type === "Recovery Planner V2" || this.props.type === "Manually Loaded Properties" || this.props.type === "ClaimSummary") {
                if (this.state.apiUrlforExport) {
                    this.getErrorMessage("The file will be downloaded in few minutes", "", "Success")
                    apiUrl = `${this.state.apiUrlforExport}&type=exportToCsv`;
                } else {
                    await this.setState({ isLoading: false })

                    this.getErrorMessage("Please select seller", "", "error")
                }
            } else {
                apiUrl = `${this.state.apiUrl}?filter=${JSON.stringify(filterCriteriaData)}&type=exportToCsv`;
            }
            if (apiUrl) {
                return fetchMethodRequest('GET', apiUrl)
                    .then(async (response) => {
                        let sessionexpired = localStorage.getItem('sessionexpired')
                        if (sessionexpired === "true") {
                            await this.setState({ sessionExpiryModal: true })
                        }
                        let headers = [], item2 = {}, data = []
                        let arrayFormatcolumns = []

                        if (this.props.type !== "ClaimSummary") {
                            let columns = JSON.parse(localStorage.getItem(`${this.props.type}_column_order`));
                            if (columns && columns.details) {
                                columns = columns.details[this.state.selectedViewType];
                                if (columns === undefined) {
                                    columns = this.props.getTableFields()
                                }
                            } else {
                                columns = columns
                            }
                            for (var obj of columns) {
                                if (obj.field !== "Actions" && obj.field !== "" && obj.field !== "edit") {
                                    arrayFormatcolumns.push(obj.field)
                                }
                            }

                        } else {
                            arrayFormatcolumns = ["entityEntitlementStateEntityIdNO", "EntityEntitlementExhibitNo", "EntityEntitlementExhibitNo", "FEIN", "FEINEvidenceExhibitNo", "AddressExhibitNo", "ClaimID", "PropertyID", "ClaimStatus", "ReportedName", "ReportedAddress1", "ReportedAddress2", "ReportedCity", "ReportedState", "ReportedZipCode"]
                        }
                        if (response && response[url] && response[url].length && response[url].length > 0) {
                            let exportData = this.updateDateFormat(response[url], "MM/DD/YYYY");
                            let item = exportData[0]
                            for (var key of arrayFormatcolumns) {
                                if (key === "matchStatusNotes") {
                                    if (item[key] && item[key].length > 0) {
                                        for (var obj of item[key]) {
                                            if (obj.note) {
                                                data.push(obj.note)
                                            }
                                        }
                                        if (data.length > 0) {
                                            item2[key] = this.getArrayVal(JSON.stringify(data))
                                        }
                                    } else {
                                        item2[key] = null
                                    }
                                } else {
                                    item2[key] = item[key]
                                }
                            }
                            for (let key in item2) {
                                let index = headers.findIndex(obj => obj.key === key)
                                if (index === -1) {
                                    headers.push(
                                        { label: key.charAt(0).toUpperCase() + key.slice(1), key: key }
                                    )
                                }
                            }
                            exportData[0] = item2
                            exportData.forEach((item) => {


                                for (let key in item) {
                                    if (item[key] && item[key].name) {
                                        item[key] = item[key].name;
                                    }
                                    if (key === "matchStatusNotes") {
                                        if (item[key] && item[key].length > 0) {
                                            for (var obj of item[key]) {
                                                if (obj.note) {
                                                    data.push(obj.note)
                                                }
                                            }
                                            item[key] = this.getArrayVal(JSON.stringify(data))
                                        } else {
                                            item[key] = null
                                        }
                                    }
                                }
                                for (let key in item) {
                                    if (item[key] && item[key].name) {
                                        item[key] = item[key].name;
                                    }

                                    if (key === "matchStatusNotes" || key === "notes") {
                                        if (item[key] && item[key].length > 0) {
                                            for (var obj of item[key]) {
                                                if (obj.note) {
                                                    data.push(obj.note)
                                                }
                                            }
                                            item[key] = this.getArrayVal(JSON.stringify(data))
                                            data = []
                                        } else {
                                            item[key] = null
                                        }
                                    } else if (typeof key === "array") {
                                        item[key] = this.getArrayVal(JSON.stringify(item[key]))

                                    }
                                }
                                item = exportData[0]
                            });
                            this.setState({
                                exportData: exportData,
                                exportHeaders: headers,
                                isLoading: false,
                            }, () => {
                                // click the CSVLink component to trigger the CSV download
                                this.csvLinkRef.link.click();
                                this.setState({
                                    exportData: [],
                                    isexported: false
                                })
                            });
                        } else {
                            this.setState({
                                isLoading: false
                            });
                        }
                    }).catch((err) => {
                        this.setState({
                            isLoading: false
                        });
                        return err;
                    });
            }
        }
    }

    getCustumisedDatatoExport = async () => {
        var customisedExportData = []
        let Data = ["EstateName", "GreenCOUNT", "GreenPropertyMinValueAmount", "GreenPropertyMaxValueAmount", "BlueCOUNT", "BluePropertyMinValueAmount", "BluePropertyMaxValueAmount"]
        if (this.state.summaryObject === undefined) {
            await this.getUsersSummaryData("", this.state.addFilters, "export")
        } else {
            await this.getUsersSummaryData(this.state.summaryObject, this.state.addFilters, "export")

        }
        let allUsersData = this.state.allUsersData
        if (allUsersData) {
            for (var obj of allUsersData) {
                let dataitem = {}
                dataitem.EstateName = obj.EstateName
                for (var item of obj.buckets) {
                    if (item.bucket === "Green") {
                        dataitem.GreenCOUNT = item["COUNT(ns᎐CompanyID)"]
                        dataitem.GreenPropertyMinValueAmount = item["PropertyMinValueAmountSum"]
                        dataitem.GreenPropertyMaxValueAmount = item["PropertyMaxValueAmountSum"]
                    }
                    if (item.bucket === "Blue") {
                        dataitem.BlueCOUNT = item["COUNT(ns᎐CompanyID)"]
                        dataitem.BluePropertyMinValueAmount = item["PropertyMinValueAmountSum"]
                        dataitem.BluePropertyMaxValueAmount = item["PropertyMaxValueAmountSum"]
                    }
                }
                customisedExportData.push(dataitem)
            }
        }
        this.setState({
            exportData: customisedExportData,
            exportHeaders: Data,
        }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLinkRef.link.click();

        });
    }

    //getAll Call
    attributesgetCall = async () => {
        fetchMethodRequest('GET', apiCalls.getAll)
            .then(async (response) => {
                if (response) {
                    await this.setState({
                        getAllresponse: response
                    })
                }
            }).catch((err) => {
                return err;
            });
    }

    showNotesData = async (item) => {
        fetchMethodRequest('GET', `NsScoredProperties/propertyNotes?propertyId=${item.propertyID}&companyId=${this.state.PropertyData.selectedSeller ? this.state.PropertyData.selectedSeller.EstateID : item.companyID}`)
            .then(async (response) => {
                if (response) {
                    await this.setState({
                        validatedNotesId: item.propertyID,
                        getNotesresponse: response.notes,
                        isValidData: true
                    })
                }
            }).catch((err) => {
                return err;
            });
    }
    openSellerInNewTab = async (item) => {
        let url = `${window.location.protocol}//${window.location.host}/sellersEdit/${item.DealID}`
        return <a href={url} target="_blank"> </a>
    }
    //open Form modal
    openFormModal = async (rowData, type, claimType, paymentItem, index) => {
        if (claimType === "Payments") {
            this.setState({
                formFields: this.props.formFields,
            })
        } else if (claimType === "ClaimStatus") {
            this.setState({
                formFields: this.props.claimStatusFormFields,
            })
        }
        if ((this.props.type === "Attributes" && type === 'edit') || this.props.type === "Rules") {
            this.attributesgetCall()
        }
        if (rowData === 'add') {
            if (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") {
                await this.setState({
                    issellerModal: true,
                    formType: 'add',
                });
            } else if (this.props.type === "crMatrix") {
                await this.setState({
                    iscrMatrixModal: true,
                    formType: 'add',
                });
            }
        }
        if (this.props.type !== "List View" && this.props.type !== "Recovery Planner V2" && this.props.type !== "Sellers" && this.props.type !== "crMatrix") {
            await this.setState({
                isOpenFormModal: true,
                formType: type ? type : 'add',
                selectedRows: ''
            });
        }
        if (type === 'view') {
            if (this.state.isOpenFormModal && this.formModalRef) {
                await this.formModalRef.getViewData(rowData);
            }
        }

        if (type === 'edit') {
            if (this.state.isOpenFormModal && this.formModalRef) {
                await this.setState({
                    selectedInfo: rowData

                })
                await this.formModalRef.getRowData(rowData, 'edit', paymentItem ? paymentItem : "", index);
            }
            if (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") {
                await this.setState({
                    issellerModalEdit: true,
                    sellerID: rowData.DealID,
                });
            } else if (this.props.type === "crMatrix") {
                await this.setState({
                    iscrMatrixModalEdit: true,
                    crMatrixId: rowData.Version,
                });
            }
            if (this.props.type === "List View" || this.props.type === "Recovery Planner V2") {
                await this.setState({
                    taskrowData: rowData,
                    iskanbanModal: true,
                    formType: type ? type : 'add',
                });
            }
        }
        if (this.props.type === "List View" || this.props.type === "Recovery Planner V2") {
            await this.setState({
                iskanbanModal: true,
                formType: type ? type : 'add',
            });
        }
    }

    //close form modal
    closeFormModal = async () => {
        this.setState({
            isOpenFormModal: false,
            iskanbanModal: false,
            issellerModal: false,
            iscrMatrixModal: false,
            sellerEditType: ""
        })
    }

    applyCallback(startDate, endDate) {
        let dateStartRange = startDate.toISOString()
        let dateEndRange = endDate.toISOString()
        var d1 = new Date(dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY'))
        var d2 = new Date(dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY'))
        var startDate1 = moment(d1, "MM-DD-YYYY");
        var endDate2 = moment(d2, "MM-DD-YYYY");
        var result = endDate2.diff(startDate1, 'days');
        let dateQuery = {
            'startDate': dateStartRange, 'endDate': dateEndRange, type: this.state.range
        }
        this.setState({
            start: startDate,
            end: endDate,
            filterRange: result === 0 ? `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')}` : `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')} to ${dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY')} `,
            dateQuery: dateQuery,
            first: 0
        }
        )
        this.state.filterCriteria.page = 1;
        this.getDataFromServer(dateQuery, 'dateQuery');
    }

    rangeCallback = (i, v) => {
        this.setState({
            range: v
        })
    }

    ontaskFilterChange = async (e, key, selectedtype) => {
        let criteriaData = this.state.filterCriteria
        criteriaData.page = 1
        let val
        if (e && e.persist) {
            e.persist();
        }
        if (e && e.target) {
            val = e.target.value
            if (key === "company") {
                await this.setState({
                    businessName: val,
                })
            } else if (key === "assignTo") {
                await this.setState({
                    selectedAnalyst: val,
                })
            } else if (key === "status") {
                val = e.value
                await this.setState({
                    selectedStatus: val,
                })
            }
            let filtercriteria = {
                "key": key,
                "value": key === "status" || key === "department" ? val : encodeURIComponent(val),
                "type": selectedtype,
                "add_Filter": true
            }

            await this.setState({
                selectedKeydata: e.key === "Enter" ? true : false,
            })
            if (criteriaData.criteria &&
                criteriaData.criteria.length > 0) {
                for (let i = 0; i < criteriaData.criteria.length; i++) {
                    let crit = criteriaData.criteria
                    if ((crit[i].key === filtercriteria.key)) {
                        crit.splice(i, 1)
                    }
                }
            }
            if (val && val.length > 1) {
                criteriaData["criteria"].push(filtercriteria)
            }
        }

        this.setState({
            filterCriteria: criteriaData,
            first: 0
        })
        if (this.state.selectedKeydata || key === 'status' || key === "department") {
            this.getDataFromServer(this.state.filterCriteria);
        }

    }

    resettaskFilters = async () => {
        await this.setState({
            businessName: "",
            selectedAnalyst: "",
            selectedDepartment: "",
            selectedStatus: ["Not Started", "In Progress", "Paused", "Completed"],
            filterRange: "",
            dateQuery: "",
        })
        this.state.filterCriteria["criteria"] = []

        this.getDataFromServer(this.state.filterCriteria)
    }

    handleunassigned = async (e) => {
        await this.setState({
            unassignedOnly: this.state.unassignedOnly === true ? false : true
        })
        if (this.state.unassignedOnly) {
            await this.setState({
                first: 0
            })
            this.state.filterCriteria.page = 1
        }
        this.getDataFromServer(this.state.filterCriteria)
    }
    onChangeDepartment = async (e) => {
        await this.setState({
            selectedDepartment: e.value,
        })
        await this.getDataFromServer(this.state.filterCriteria)
    }
    gettaskFilters(handleSubmit) {
        let now = new Date();
        let month = now.getMonth()
        let year = now.getFullYear()
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        //number of days in last month
        let y = new Date(year, month - 1, 0).getDate();
        let t = now.getDate() - 1 + y + 2;
        let ranges = {
            "Today Only": [moment(start), moment(end)],
            "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
            "This Week(sun-Today)": [moment(start).subtract(now.getDay(), "days"), moment(end)],
            "Last 7 days": [moment(start).subtract(7, "days"), moment(end).subtract(1, "days")],
            "Last Week(Sun-Sat)": [moment(start).subtract(now.getDay() + 7, "days"), moment(start).subtract(now.getDay() + 1, "days")],
            "Last 14 days": [moment(start).subtract(14, "days"), moment(end).subtract(1, "days")],
            "This month": [moment(start).subtract((now.getDate() - 1), "days"), moment(end)],
            "Last 30 days": [moment(start).subtract(30, "days"), moment(end).subtract(1, "days")],
            "Last month": [moment(start).subtract(t, "days"), moment(start).subtract(now.getDate(), "days")],
            "last 3 Days": [moment(start).subtract(3, "days"), moment(end).subtract(1, "days")],
            "30 Days upto today": [moment(start).subtract(29, "days"), moment(end)],
            "All Time": [moment(start), moment(end)]
        }
        let local = {
            "format": "MM-DD-YYYY HH:mm",
        }
        let maxDate = moment(end);
        return (

            <div className="form kanbanfilterDiv ml-0 mr-0">
                <div className="row filterKanbandiv" style={{ width: "100%" }}>
                    <div className=" align-items-center pl-0" style={{ display: "inline-flex", width: "100%" }}>
                        <div className="kanban_filter_field col-sm-3" >
                            <label className="mr-1 kanban_filter_label" style={{ marginTop: 4 }}>
                                Business:
                            </label>
                            <div className="" >
                                <InputText
                                    type="search"
                                    value={this.state.businessName}
                                    name='company'
                                    id='company'
                                    style={{ height: 33 }}
                                    onKeyPress={(e) => this.ontaskFilterChange(e, 'company', 'regexOr')}
                                    onChange={(e) => this.ontaskFilterChange(e, 'company', 'regexOr')}
                                    size={"20"} />

                            </div>
                        </div>
                        <div className="kanban_filter_field col-sm-2">
                            <div className="d-flex">
                                <label className="mr-auto kanban_filter_label" style={{ marginTop: 4 }}>
                                    Analyst:
                                </label>
                                <div style={!this.state.isShowunAssign ? { display: "block" } : { display: "none" }}>
                                    <div className="">
                                        <Checkbox
                                            label='Unassigned Only'
                                            type='checkbox'
                                            // component={renderCheckBoxField}
                                            name={'unassigned'}
                                            className="unassignCheckbox mb-0"
                                            checked={this.state.unassignedOnly}
                                            onChange={(e) => this.handleunassigned(e)}
                                        />
                                        <label htmlFor="cb3" className="p-checkbox-label">Unassigned Only</label>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {/* <InputText
                  name="analyst"
                  value={this.state.selectedAnalyst}
                  component={DefaultInput}
                  onKeyPress={(e) => this.ontaskFilterChange(e, 'assignTo', 'regexOr')}
                  onChange={(e) => this.ontaskFilterChange(e, 'assignTo', 'regexOr')}
                  type="text"
                  placeholder=""
                /> */}
                                <InputText
                                    type="search"
                                    value={this.state.selectedAnalyst}
                                    name='assignTo'
                                    id='assignTo'
                                    style={{ height: 33 }}
                                    onKeyPress={(e) => this.ontaskFilterChange(e, 'assignTo', 'regexOr')}
                                    onChange={(e) => this.ontaskFilterChange(e, 'assignTo', 'regexOr')}
                                    size={"20"} />
                            </div>
                        </div>
                        <div className="kanban_filter_field col-sm-3" >
                            <label className="mr-1 kanban_filter_label" style={{ marginTop: 4 }}>
                                Department:
                            </label>
                            <div className="" style={{ width: "100%" }} >
                                <MultiSelect
                                    name="department"
                                    id='department'
                                    style={{ height: 33, width: "100%" }}
                                    options={this.props.taskDepartmentItems}
                                    value={this.state.selectedDepartment}
                                    onChange={(e) => this.onChangeDepartment(e, 'department', 'eq')}
                                    size={"20"} />
                            </div>
                        </div>
                        <div className="kanban_filter_field col-sm-2" >
                            <label className="mr-1 kanban_filter_label" style={{ marginTop: 1 }}>
                                Task Status:
                            </label>
                            <div className="form__form-group-input-wrap">
                                <MultiSelect
                                    className={"form__form-group-field "}
                                    style={{ width: "100%", height: 32 }}
                                    value={this.state.selectedStatus}
                                    options={config.taskFilterItems}
                                    onChange={(e) => this.ontaskFilterChange(e, 'status', 'in')}
                                    placeholder=''
                                />
                            </div>
                        </div>
                        <div className="kanban_filter_field col-sm-2 mt-2">
                            <label className="mr-1 kanban_filter_label">
                                Task Created Date:
                            </label>
                            <DateTimeRangeContainer
                                ranges={ranges}
                                start={this.state.start}
                                end={this.state.end}
                                local={local}
                                maxDate={maxDate}
                                // enableButtons={true}
                                onClick={this.onClick}
                                onChange={this.applyCallback}
                                applyCallback={this.applyCallback}
                                rangeCallback={this.rangeCallback}
                                leftMode
                                noMobileMode
                                style={{
                                    customRangeButtons: { backgroundColor: "#357ABD" },
                                    customRangeSelected: { backgroundColor: "#357ABD" },

                                }}
                            // 
                            >
                                <FormControl
                                    id="formControlsTextB"
                                    type="text"
                                    label="Text"
                                    placeholder="Task Created Date"
                                    style={{ cursor: "pointer" }}
                                    disabled={true}
                                    className={"dateFilterForm mb-0"}
                                    value={this.state.filterRange}
                                />
                            </DateTimeRangeContainer>
                            <div style={{ height: 10 }}></div>
                        </div>
                        <div className="" >
                            <span className=" " style={{ cursor: "pointer" }}>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{
                                        width: 16,
                                        height: 16,
                                        margin: 5,
                                        marginTop: 15,
                                    }}
                                    color="red"
                                    onClick={(e) => this.resettaskFilters()}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    getStateFIlingValues = async (filter, subCategory, category, stateSubCategories, stateDealType, UpState, type) => {
        if (!filter.page) {
            filter.page = 1
        }
        if (type === "clear") {
            this.getremoveFilters()
        }
        if (!filter.limit) {
            filter.limit = this.state.rows
        }
        this.setState({
            isLoading: true, reasonByRow: false
        })
        if (filter && filter.criteria.length > 0) {
            return fetchMethodRequest('GET', `StateUpFiling?filter=${JSON.stringify(filter)}`)
                .then(async (response) => {
                    if (response && response.stateUpFilings && response.stateUpFilings.length > 0) {
                        await this.setState({
                            allUsersData: JSON.parse(JSON.stringify(response.stateUpFilings)),
                        })
                        // await this.showDatatable("true")
                    } else if (response && response.stateUpFilings && response.stateUpFilings.length === 0) {
                        await this.setState({
                            editedindex: null,
                            attributeFields: [],
                            allUsersData: []
                        })
                        await this.showDatatable("true")
                    } else if (response && response.errorMessage) {
                        await this.setState({
                            editedindex: null,
                            attributeFields: [],
                        })
                    }
                    await this.setState({
                        progress: 100,
                        responsesaveData: JSON.parse(JSON.stringify(response.stateUpFilings)),
                        isLoading: false,
                        disableIcon: false,
                        isDisabledTab: false,
                        filterCriteria: filter,
                        stateSubCategory: subCategory,
                        stateCategory: category,
                        stateDealType: stateDealType,
                        UpState: UpState,
                        stateSubCategories: stateSubCategories,
                        totalRecordsLength: response.pagination.totalCount,
                    })
                }).catch((err) => {
                    return err;
                });
        }



    }
    OpenConfirmaionModelorSave = async () => {
        await this.setState({
            isopenConfirmaionModelforSave: true
        })
    }

    closeConfirmationModalforSave = async (type) => {
        await this.setState({
            isopenConfirmaionModelforSave: false
        })
        if (type === "close") {
            await this.getDataFromServer()
            this.CancelMultiLine()
        } else {
            this.handleCheckValidations()
        }
    }

    getSelectedFilterValues = async (criteria, data) => {

        let getlocaldata = JSON.parse(localStorage.getItem("tableFields_download"))

        await this.setState({
            StatCriteria: criteria,
            criteriaStatis: JSON.stringify(criteria),
            downloadData: [],
            // tablefieldsToShow: data,
            tablefieldsToShow: data.length > 0 ? data : data && getlocaldata["Summary"] ? getlocaldata["Summary"] : [],
            allUsersData: [],
            activeTab: "Summary"
        })
        this.isShowTable(true)

        // this.getDataFromServer(this.state.criteria)
        this.getSelectedTabTypeInfo("Summary", "", this.state.tablefieldsToShow)
    }

    getConfirmaionModelforSave() {
        return (
            <ConfirmaionModelorSave
                openConfirmationModal={this.state.isopenConfirmaionModelforSave}
                closeConfirmationModal={this.closeConfirmationModalforSave}
                shownText={"Are you sure you would like to undo the updated changes for claims?"}
            // confirm={this.handleCheckValidations}
            />
        )
    }

    setDataCriteriaforOpportunity = async (criteria, obj) => {
        await this.setState({
            postBody: obj,
        })
        this.getDataFromServer(criteria, "", "", this.state.postBody)
    }

    submitActionsData = async (method, url, body) => {
        return fetchMethodRequest(method, url, body)
            .then(async (response) => {
                let sessionexpired = localStorage.getItem('sessionexpired')
                if (sessionexpired === "true") {
                    this.setState({ sessionExpiryModal: true })
                }
                await this.setState({
                    openDeleteModal: false,
                    actions: '',
                    selectedRows: '',
                    selectedRowsId: ''
                });
                if (response && response.respCode) {
                    // showToasterMessage(response.respMessage, 'success');
                    this.getDataFromServer();
                } else if (response && response.errorMessage) {
                    //showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    handleActions = async (apiType) => {
        let apiUrl = this.state.apiUrl;
        let method, url, body = {}
        if (this.state.confirmType === 'Delete' || this.state.confirmType === 'Block' || this.state.confirmType === 'UnBlock') {
            url = `${apiUrl}/${apiType}`;
            method = 'POST';
            body = {
                selectedIds: this.state.selectedRowsId
            }
        }
        this.submitActionsData(method, url, body)
    }

    // delete selected row
    deleteSelectedRow = async () => {
        let apiUrl = this.state.apiUrl;
        let method, url, body = {}
        let id = this.state.deleteRowDataId._id ? this.state.deleteRowDataId._id : this.state.deleteRowDataId
        url = `${apiUrl}/${id}`;
        // hari why this crud
        if (this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue") {
            method = "PUT"
            this.state.deleteRowDataId.status = "Cancel"
            body = this.state.deleteRowDataId
        } else {
            method = 'DELETE';
            body = ''
        }
        return fetchMethodRequest(method, url, body)
            .then(async (response) => {
                let sessionexpired = localStorage.getItem('sessionexpired')
                if (sessionexpired === "true") {
                    this.setState({ sessionExpiryModal: true })
                }
                await this.setState({
                    openDeleteModal: false
                });
                if (response && response.respCode) {
                    //showToasterMessage(response.respMessage, 'success');
                    this.getDataFromServer(this.state.filterCriteria);
                } else if (response && response.errorMessage) {
                    // showToasterMessage(response.errorMessage, 'error');
                    this.getErrorMessage(response.errorMessage, "", "error")
                }
            }).catch((err) => {
                return err;
            });
    }

    //close delete modal
    closeDeleteModal = async () => {
        this.setState({
            openDeleteModal: false,
            actions: ''
        })
    }

    // conformation for delete item
    deleteConfirmAction = async (rowData, selectActions) => {
        let selectedRowsId = [];
        let selectedRows = [...this.state.selectedRows]
        if (selectedRows && selectedRows.length && selectedRows.length > 0) {
            selectedRows.forEach((item, index) => {
                selectedRowsId.push(item._id)
            })
            await this.setState({
                selectedRowsId: selectedRowsId,
                openDeleteModal: true,
                selectActions: selectActions,
            })
        }
        if (rowData) {
            this.setState({
                deleteRowDataId: rowData,
                openDeleteModal: true,
                selectActions: selectActions,
            });
        }
    }

    //change dropdown elememt
    changeDropDownElement = (event) => {
        this.dt.filter(event.target.value, event.target.name, 'equals');
        this.setState({
            [event.target.name]: event.value
        });
    }

    getTableFieldItem(field) {
        for (let i = 0; i < this.state.tablefieldsToShow.length; i++) {
            if (this.state.tablefieldsToShow[i].field === field) {
                return this.state.tablefieldsToShow[i];
            }
            if (field === "Payments") {
                return { fieldType: "PaymentArray" }
            }
            // if (field === "PaymentBtn") {
            //   return { fieldType: "PaymentBtn" }
            // }
            //condition to match fieldType
            if (this.state.tablefieldsToShow[i].fieldType === field) {
                return this.state.tablefieldsToShow[i]['field'];
            }
        }
        return null;
    }

    getColorFromOptions(options, name) {
        if (options) {
            for (let i = 0; i < options.length; i++) {
                if (options[i].value === name) {
                    return options[i].color;
                }
            }
        }
        return 'success';
    }

    getUserData(_id, type) {
        let data = this.state.allUsersData;
        for (let i = 0; i < data.length; i++) {
            if (data[i]['_id'] === _id) {
                return i
            }
        }
    }

    getIconValue(rowData, labelKey) {
        if (labelKey && labelKey.options && labelKey.options.length > 0) {
            for (let i = 0; i < labelKey.options.length; i++) {
                if (labelKey.options[i].value === rowData[labelKey.field]) {
                    return labelKey.options[i].displayText;
                }
            }
        }
        return '';
    }

    async getAUserData(_id, type) {
        let filterCriteria = { "limit": 20, "page": "1", "criteria": [], "sortfield": "CompanyName", "direction": "asc", "globalSearch": { "value": _id, "type": "user" } }
        let apiUrl = `${apiCalls.Companies}?filter=${JSON.stringify(filterCriteria)}`
        return fetchMethodRequest('GET', apiUrl)
            .then(async (response) => {
                await this.setState({ filterData: response.companies[0] })
            })
    }

    openViewModal = async (rowData, type, screen) => {
        if (screen && screen === "dashboard") {
            await this.getAUserData(rowData['_id'], '_id')
            rowData = this.state.filterData
        }
        if (this.props.type === "Company") {
            window.history.pushState(null, null, `${this.props.routeTo}`);
        }
        // if (rowData) {
        //   // window.history.replaceState(null, '', "http://localhost:3001/tree")
        //   // screen && screen == "dashboard" ? rowData = this.getAUserData(rowData['_id'], '_id') : null;

        //   let rowDataIndex = this.getUserData(rowData['_id'], '_id');
        //   // let rowDataIndex = this.getUserData(rowData['_id'], '_id');
        //   let _id = rowData['_id'] ? rowData['_id'] : "";
        //   let status = "Active"
        //   let keys = Object.keys(rowData);
        //   let formFields = [];
        //   if (formFields) {
        //     if (this.props.type) {
        //       if (rowData) {
        //         let values, self = this, icon = false;
        //         // hari get all the labels from 
        //         keys.forEach(async function (key) {
        //           let labelKey;
        //           labelKey = await self.getTableFieldItem(key);
        //           if (labelKey === null) {
        //             labelKey = key;
        //           } else {
        //             let val = rowData[key];
        //             if (labelKey.fieldType === 'icon') {
        //               val = self.getIconValue(rowData, labelKey);
        //               icon = true;
        //             }
        //             labelKey = labelKey.header
        //             if (val) {
        //               values = {
        //                 label: labelKey,
        //                 value: icon ? val : rowData[key]
        //               }
        //               formFields.push(values);
        //               icon = false;
        //             }

        //           }
        //           // if (key==='fromTime' || key==='toTime') {
        //           //   let date = dateFormats.addDaysToDate(rowData[key], 1);
        //           //   date = dateFormats.formatDate(date, config.timeFormat);
        //           //   values = {
        //           //     label: key,
        //           //     value: date
        //           //   }
        //           //   formFields.push(values);
        //           // }
        //         });

        //       }
        //     }

        //     if (this.state.displayViewOfForm === 'modal') {
        //       await this.setState({
        //         // openViewModal: true
        //         isOpenFormModal: true,
        //         userStatus: status,
        //         formType: type,
        //       });

        //       // await this.viewModalRef.getRowData(formFields);
        //       await this.formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id, 'investors');
        //     } else if (this.state.displayViewOfForm === 'screen') {
        //       let displayBreadCrumbField = this.getTableFieldItem('Link');

        //       await this.setState({
        //         isOpenFormModal: true,
        //         formType: type,
        //         displayBreadCrumbValue: rowData[displayBreadCrumbField],
        //         displayBreadCrumbField: displayBreadCrumbField,
        //         userStatus: status
        //       });

        //       await this.formModalRef.handleViewDisplay(rowData);
        //     }
        //   }
        // } else {
        //   if (this.state.displayViewOfForm === 'screen') {
        //     let displayBreadCrumbField = this.getTableFieldItem('Link');
        //     let rowData = [];
        //     await this.setState({
        //       isOpenFormModal: true,
        //       formType: type,
        //       // displayBreadCrumbValue: rowData[displayBreadCrumbField],
        //       // displayBreadCrumbField: displayBreadCrumbField,
        //       // userStatus: status
        //     });

        //     await this.formModalRef.handleViewDisplay(rowData);
        //   }
        // }
        // await this.getDataFromServer();
        if (rowData) {
            await this.setState({
                redirectCompanyId: rowData["CompanyID"] ? rowData["CompanyID"] : rowData["CIK"],
                CompanyDetailView: true
            })
        }
        else {
            await this.setState({
                // redirectCompanyId: id,
                CompanyDetailView: false
            })
        }
    }

    closeViewModal = async () => {
        this.setState({
            openViewModal: false
        });
    }

    //on changing pagination
    onPageChange = async (event) => {
        let newArray = this.checkDataisEdited()
        if (newArray && newArray.length > 0) {
            this.OpenConfirmaionModelorSave()
        } else {
            let filterCriteria = this.state.filterCriteria;
            if (event && event.rows) {
                let currentPage = event.page + 1;
                filterCriteria['limit'] = event.rows;
                filterCriteria['page'] = currentPage;
                this.setState({
                    rows: event.rows,
                    page: event.page,
                    first: event.first,
                    filterCriteria: filterCriteria,
                    isLoading: true
                })
            }
            this.handlePercentage("1")
            if (this.props.isMatchproperties) {
                this.props.getResponseforSelectedPropertyData(filterCriteria)

            } else if (this.props.type === "State Attributes") {
                await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)

            } else {
                this.getDataFromServer(filterCriteria)
            }
        }
    }

    scrollWin() {
        if (this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Properties" || this.props.type === "Deal-Sizing Properties") {
            let filterCriteria = this.state.filterCriteria;
            if (filterCriteria.limit > 30)
                window.scrollTo(0, 600);
        }
    }

    onPageChangeAccordion = async (event) => {
        let filterCriteria = this.state.filterCriteria;
        let id = this.state.openId;
        if (event && event.rows) {
            let currentPage = event.page + 1;
            filterCriteria['limit'] = event.rows;
            filterCriteria['page'] = currentPage;
            this.setState({
                [`rows${id}`]: event.rows,
                page: event.page,
                [`first${id}`]: event.first
            })
        }
        // this.getDataFromServer(filterCriteria)
    }

    //sorting fields
    sortChange = (event) => {
        let data = this.props.type === "Labels" || this.props.type === "Departments" || this.props.type === "Purposes" || this.props.type === "Investor" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ?
            'asc'
            : this.props.sortDirection ? this.props.sortDirection : "desc";
        this.setState({ selectedRows: '' })
        let sortCount = this.state.sortCount;
        if (event && event['sortField']) {
            sortCount = sortCount === 0 ? sortCount + 1 : 0;
            let sortField
            if (this.state.activeTab === "SP") {
                sortField = `${event['sortField']}, ${this.state.filterCriteria.sortfield}`
            } else {
                sortField = `${event['sortField']}`;
            }
            let filterCriteria = {
                direction: event.selectedOption === "unsort" ? data : event.selectedOption,
                sortfield: event.selectedOption === "unsort" ? this.props.sortField ? this.props.sortField : "created" : sortField,
                limit: this.state.filterCriteria.limit,
                page: this.state.filterCriteria.page,
                criteria: this.state.filterCriteria['criteria']
            }
            if (this.state.filterCriteria && this.state.filterCriteria.globalSearch) {
                filterCriteria.globalSearch = this.state.filterCriteria.globalSearch
            }
            this.setState({
                sortCount: sortCount,
                isSortField: sortField,
                isLoading: true,
                filterCriteria: filterCriteria
            });
            this.handlePercentage('1')
            if (this.props.type === "State Attributes") {
                this.getStateFIlingValues(filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
            } else {
                this.getDataFromServer(filterCriteria)
            }
        }
    }

    selectedOptionData = async (e, key) => {
        if (e && key) {
            let type, value;
            await this.setState({
                selectedvalueforStatus: e
            })
            let criteria = this.state.filterCriteria
            if (e.length === 3) {
                type = "nin"
            } else if (e.length === 2) {
                type = "in"
            } else {
                type = "eq"
            }

            value = e
            let index = criteria["criteria"].findIndex(obj => obj.key === key)
            if (index !== -1) {
                criteria["criteria"][index].value = value
                criteria["criteria"][index].type = type
            } else {
                criteria["criteria"].push({ key: key, value: value, type: type, add_Filter: true })
            }
            let filterCriteria = {
                sortfield:
                    e && e.length === 3 ? "endTime" : e && e.length === 1 ? "Priority" : e && e.length === 2 ? "status" : this.props.sortField,
                criteria: criteria["criteria"],
                limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : this.state.limit
            }
            await this.setState({
                filterCriteria: filterCriteria
            })
            // this.props.getTableFields()
            this.changeTableFieldsbasedonOption(e)
            this.getremoveFilters()
            // this.getDataFromServer(this.state.filterCriteria)
        }
        return e;
    }

    changeTableFieldsbasedonOption = async () => {
        let selectedOption = this.state.selectedvalueforStatus
        let tablefieldsToShow = this.state.tablefieldsToShow
        let index = tablefieldsToShow.findIndex(obj => obj.field === "reOrder")
        if (index !== -1) {
            tablefieldsToShow.splice(index, 1)
        }
        let timeField = tablefieldsToShow.findIndex(obj => obj.field === "indexType")
        if (timeField !== -1) {
            tablefieldsToShow.splice(timeField, 3)
        }
        let delindex = tablefieldsToShow.findIndex(obj => obj.field === "Actions")
        if (delindex !== -1) {
            tablefieldsToShow.splice(delindex, 1)
        }

        if (selectedOption.length === 2 || selectedOption.length === 3) {
            tablefieldsToShow.push(


                {
                    "show": true,
                    "textAlign": "left",
                    "width": 70,
                    "field": "indexType",
                    "header": "Index Type",
                    "filter": true,
                    "sortable": true
                },
                {
                    "show": true,
                    "textAlign": "left",
                    "width": 80,
                    "mobile": true,
                    "field": "startTime",
                    "fieldType": 'number',
                    "header": "Start Time",
                    "filterType": "num",
                    "dateFormat": "MM/DD/YY hh:mm a",
                    "fieldType": 'Date',
                    "filter": true,
                    "displayInSettings": true,
                    "displayInFilter": false,
                    "sortable": true,
                }, {
                "show": true,
                "textAlign": "left",
                "width": 80,
                "mobile": true,
                "field": "endTime",
                "fieldType": 'number',
                "header": "End Time",
                "filterType": "num",
                "dateFormat": "MM/DD/YY hh:mm a",
                "fieldType": 'Date',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": false,
                "sortable": true,
            })

        } else {
            tablefieldsToShow.unshift(
                {
                    "show": true,
                    textAlign: 'left',
                    width: 16,
                    field: 'reOrder',
                },
            )
            if (this.state.screenPermissions === "Edit") {
                tablefieldsToShow.push(

                    {
                        "show": true,
                        "textAlign": "left",
                        "width": 60,
                        "fieldType": 'Actions',
                        "field": "Actions",
                        "header": "Actions",
                        filter: true,
                        "displayInSettings": true,
                        "sortable": false
                    },
                )
            }
        }
        await this.setState({
            tablefieldsToShow: tablefieldsToShow
        })
    }

    confirmActionType = async (type) => {
        if (type === 'Delete') {
            await this.setState({
                confirmType: type,
                confirmModalText: 'Are you sure want to Delete',
            })
            this.deleteConfirmAction()
        } else if (type === 'Block') {
            await this.setState({
                confirmType: type,
                confirmModalText: 'Are you sure want to Block',
            })
            this.deleteConfirmAction()
        } else if (type === 'ResetPassword') {
            await this.setState({
                confirmType: type,
                confirmModalText: 'Are you sure want to Reset Password',
                openUserPasswordResetModal: true,
                openDeleteModal: false,
                selectedRows: this.state.selectedRows
            })
            // this.deleteConfirmAction()
        }
    }

    //onActionsChange
    onActionsChange = async (event, type) => {
        if (type === 'dropdownFilter') {
            await this.setState({
                [event.target.name]: event.target.value,
            })
            for (let i in this.state.actionsTypes) {
                if (this.state.actionsTypes[i].value === event.target.value) {
                    if (this.state.actionsTypes[i].action) {
                        this.state.actionsTypes[i].action(this.state.selectedRows)
                    }
                }
            }
            this.confirmActionType(event.target.value)
        }
    }

    cancelSessionWarningModal = async () => {
        await this.setState({
            sessionWarning: false
        })
    }

    getSessionWraningModal() {
        return (
            <SessionWarningModal
                openConfirmationModal={this.state.sessionWarning}
                cancelSessionWarningModal={this.cancelSessionWarningModal}
                getData={this.getDataFromServer}

            />
        )
    }

    onMultiSelectFilterChange = async (key, value, type, index) => {
        let filterCriteria = this.state.filterCriteria;
        if (filterCriteria && type === 'push') {
            let v = [], length = '';
            if (filterCriteria['criteria'] && filterCriteria['criteria'][0] && filterCriteria['criteria'][0]["value"]) {
                v = [...filterCriteria['criteria'][0]["value"]];
                length = v.length;
                v[length] = value;
                filterCriteria['criteria'][0]["value"] = v;
            } else {
                v[0] = value
                if (filterCriteria && filterCriteria['criteria']) {
                    filterCriteria['criteria'].push({
                        key: key,
                        value: v,
                        type: 'in'
                    });
                } else {
                    filterCriteria['criteria'] = []
                    filterCriteria['criteria'].push({
                        key: key,
                        value: v,
                        type: 'in'
                    });
                }
            }
            await this.setState({
                filterCriteria: filterCriteria
            })

            await this.getDataFromServer(this.state.filterCriteria)
        } else if (type === 'pop') {
            filterCriteria['criteria'][0]["value"].splice(filterCriteria['criteria'][0]["value"].indexOf(value), 1);
            if (filterCriteria['criteria'][0]["value"].length === 0) {
                await this.setState({
                    filterCriteria: { criteria: [], sortfield: filterCriteria.sortfield ? filterCriteria.sortfield : 'created', direction: filterCriteria.direction ? filterCriteria.direction : 'desc' },
                })
            } else {
                await this.setState({
                    filterCriteria: filterCriteria
                })
            }
            this.getDataFromServer(this.state.filterCriteria)
        }
    }

    toggleActions(id, name) {
        this.setState(prevState => (
            {
                dropdownOpen: !prevState.dropdownOpen
                , rowName: name
            }));
    }

    isConfirmFilter = async (val, type) => {
        if (val && val.filters) {
            let fieldName = Object.keys(val.filters)[0];
            if (val.filters[fieldName].value.length === 0 || val.filters[fieldName].value.length === undefined) {
                await this.setState({ isApplyFilter: true, filteredCol: val })
                this.getConfirmaionModel()
            } else {
                await this.onFilterChange(val)
            }
        } else {
            this.setState({ isLoading: false, isApplyFilter: false, })
            if (type) {
                this.onSeletFilterType(val, type)
                //  this.handleFilterInputField(val, "clear")
            }
        }
    }

    // on search get data from server
    onFilterChange = async (event, type) => {
        let isDateFilterEnabled = false;
        this.setState({ selectedRows: '' });
        let pageEvent = { first: 0, page: 0, rows: this.state.rows, pageCount: this.state.pageCount };
        if (type == 'dropdownFilter') {
            await this.setState({ [event.target.name]: event.target.value });
        }
        let filterCriteria = this.state.filterCriteria;
        if (filterCriteria.criteria === undefined) {
            filterCriteria.criteria = [];
        }
        if (type == 'dropdownFilter' && event.value == null) {
            // in  All case if the value is null then find & remove that obj from filter criteria
            if (filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length > 0) {
                let obj = filterCriteria.criteria.find(x => x.key == event.target.name);
                let indx = filterCriteria.criteria.indexOf(obj);
                filterCriteria.criteria.splice(indx, 1);
                this.onPageChange(pageEvent, filterCriteria);
                return;
            }
        } else {
            let fieldName = '', selectedFilterValue, selectedFilterType, formattedDate, invalidDate = false, invalidLength = false;
            if (event) {
                if (event && event.filters) {
                    this.setState({ filters: event.filters });
                    if (Object.keys(event.filters) && Object.keys(event.filters).length > 0) {
                        let filterObj = event.filters;
                        // let otherFilterCase = false;
                        Object.keys(filterObj).forEach(async (key) => {
                            fieldName = key;
                            if (filterCriteria && filterCriteria.criteria) {
                                if (fieldName == 'createdBy') {
                                    fieldName = 'createdBy.name';
                                }
                                //  date fields
                                let dateFields = ['dateOfBirth', 'fromDate', 'toDate', 'date', 'created', 'updated', 'purchaseDate', 'dateOfBirth', 'startDate', 'endDate', 'joinDate'];
                                if (dateFields.includes(fieldName)) {
                                    // to search
                                    if ((filterObj[key].value && filterObj[key].value.length < 10)) {
                                        invalidLength = true;
                                    } else {
                                        if (!dateFormats.dateIsValid(filterObj[key].value)) {
                                            invalidDate = true;
                                        }
                                        // to search
                                        selectedFilterValue = filterObj[key] && filterObj[key].value && filterObj[key].value.length >= 10 ? dateFormats.formatDate(filterObj[key].value, config.dateDashFormat, config.dateDmyFormat) : null;

                                        selectedFilterType = 'gte';
                                        // formatted date is given date plus one to give value for lte type
                                        let date = dateFormats.addDaysToDate(selectedFilterValue, 1);
                                        formattedDate = dateFormats.formatDate(date, config.dateDashFormat);
                                        // removing  lte prev  obj of same field in filter criteria (remove duplicates)
                                        let obj = function getIndex(fieldName) {
                                            for (var i = 0; i < filterCriteria.criteria.length; i++) {
                                                if (filterCriteria.criteria[i].key == fieldName && filterCriteria.criteria[i].type == 'lt') return i;
                                            }

                                        }
                                        let index = filterCriteria.criteria.indexOf(obj);
                                        filterCriteria['criteria'].splice(index, 1);
                                    }
                                }
                                //To check whether filter value has any '&' and replace with 'ampd' to escape '&' char in server call 
                                else {
                                    selectedFilterValue = filterObj[key] && filterObj[key].value ? filterObj[key].value : null;
                                    selectedFilterValue = selectedFilterValue.replace('&', 'ampd');
                                    selectedFilterValue = selectedFilterValue.trim()
                                    selectedFilterType = 'regexOr';
                                }
                                // to check whether  key already exits in filter criteria or not 
                                let obj = filterCriteria.criteria.find(x => x.key == fieldName);
                                let index = filterCriteria.criteria.indexOf(obj);
                                if (index === -1) {
                                    await filterCriteria['criteria'].push({
                                        key: fieldName,
                                        value: selectedFilterValue,
                                        type: selectedFilterType
                                    });

                                } else {
                                    filterCriteria.criteria[index].value = selectedFilterValue;
                                    filterCriteria.criteria[index].type = selectedFilterType;

                                }

                                // if  key is date field push lte also
                                if (selectedFilterType === 'gte' && formattedDate !== 'Invalid date' && !invalidLength) {
                                    isDateFilterEnabled = true;
                                    await filterCriteria['criteria'].push({ key: fieldName, value: formattedDate, type: 'lt' });
                                }
                                if (invalidLength || invalidDate) {
                                    delete filterCriteria['criteria'][key];
                                }
                            }
                            if (filterObj[key].value && filterObj[key].value.length < 3) {
                                // to check whether  key already exits in filter criteria or not
                                let obj2 = filterCriteria.criteria.find(x => x.key == fieldName);
                                let index2 = filterCriteria.criteria.indexOf(obj2);
                                await filterCriteria.criteria.splice(index2, 1);
                            }
                        });
                        if (this.state.startDate && this.state.endDate && !isDateFilterEnabled) {
                            let startDate = (moment(this.state.startDate).format('YYYY-MM-DD'));
                            let endDate = (moment(this.state.endDate).format('YYYY-MM-DD'));
                            let findstartDate = filterCriteria["criteria"].findIndex(obj => obj.key === "date" && obj.type === "gte")
                            let findendDate = filterCriteria["criteria"].findIndex(obj => obj.key === "date" && obj.type === "lte")
                            if (findstartDate === -1 && findendDate === -1) {

                                let startrangeObj = { 'key': 'date', 'value': startDate, 'type': 'gte' }
                                let endrangeObj = { 'key': 'date', 'value': endDate, 'type': 'lte' }
                                filterCriteria.criteria.push(startrangeObj);
                                filterCriteria.criteria.push(endrangeObj);
                            }
                            // this.getTableListDataFromServer(filterCriteria);
                        }
                        if (invalidDate) {
                            // showToasterMessage('Invalid Date Format', 'error');
                            return;
                        } if (invalidLength) {
                            return;
                        }

                    }
                    else if (event.filters == {} && filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length == 0) {
                        filterCriteria['criteria'] = [];
                    }
                } else if (type == 'dropdownFilter') {
                    let fieldName = event.target.name;
                    let selectedFilterValue = event.target.value;
                    if (event && event.target && event.target.name == 'status') {
                        selectedFilterType = 'in';
                    }
                    else if (event && event.target && event.target.name == 'projectName') {
                        selectedFilterType = 'in';
                    }
                    else {
                        selectedFilterType = 'eq';
                    }
                    if (filterCriteria && filterCriteria.criteria) {
                        let obj = filterCriteria.criteria.find(x => x.key == fieldName);
                        let index = filterCriteria.criteria.indexOf(obj);
                        if (index == -1) {

                            await filterCriteria['criteria'].push({
                                key: fieldName, value: selectedFilterValue,
                                type: selectedFilterType
                            });

                        } else {
                            filterCriteria['criteria'].splice(index, 1, {
                                key: fieldName, value: selectedFilterValue,
                                type: selectedFilterType
                            });
                        }


                    }
                }


                if (filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length > 0) {
                    if (filterCriteria.criteria && filterCriteria.criteria[filterCriteria.criteria.length - 1] && filterCriteria.criteria[filterCriteria.criteria.length - 1].value && filterCriteria.criteria[filterCriteria.criteria.length - 1].value.length < 2) {
                        if (this.props.type == 'Timesheet') {
                            if (!this.state.haveProject) {
                                return;
                            }
                            if (this.state.haveProject) {
                                if (filterCriteria.criteria.length > 1) {
                                    return;
                                } else {
                                    await this.onPageChange(pageEvent, filterCriteria);
                                }
                            }
                        } else {
                            if (!this.state.haveStatus) {
                                return;
                            }
                            if (this.state.haveStatus) {
                                if (filterCriteria.criteria.length > 1) {
                                    return;
                                } else {
                                    await this.onPageChange(pageEvent, filterCriteria);
                                }
                            }
                        }
                    } else {

                        await this.onPageChange(pageEvent, filterCriteria)
                    }

                } else {
                    filterCriteria.criteria = [];
                    await this.onPageChange(pageEvent, filterCriteria);
                }

            } else {
                this.setState({
                    isLoading: false
                })
            }
        }
    }

    applydefaultTemplateForSelectedRows = async () => {
        let loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'))
        const shortName = new Date().toLocaleString('en-US', { month: 'short' });
        const DateName = new Date().getDate();
        let selectednote
        if (this.props.type === "ClaimsManagement") {
            selectednote = `Seller: \nSeller Proof:\nName Proof:\nAddress Proof:\nFEIN Proof:\nHolder Proof:`
        } else {
            selectednote = null
        }
        return selectednote;
    }

    getNotesUpdate = async (event) => {
        if (this.props.type) {
            let selectedRows = event
            this.setState({ selectedRows: selectedRows, totalSelectedRows: selectedRows.length })
            let z = selectedRows.find(elem => (elem.matchMatchStatus === "Filed" || elem.matchMatchStatus === "Paid" || elem.matchMatchStatus === "Abandoned" || elem.matchMatchStatus === "Denied" || elem.matchMatchStatus === "RFI Received"))
            // if (z && (this.state.loginRole !== "Pod Lead" && this.state.loginRole !== "Admin" && this.state.loginRole !== "Recovery Manager")) {
            //   this.editorComponentRef.setFiledStatus("true")
            // } else {
            //   this.editorComponentRef.setFiledStatus("false")
            // }
            let propertyDisable = selectedRows.find(elem => (elem.matchMatchStatus === "Filed" || elem.matchMatchStatus === "Paid" || elem.matchMatchStatus === "Abandoned" || elem.matchMatchStatus === "Denied" || elem.matchMatchStatus === "RFI Received"))
            if (propertyDisable) {
                this.editorComponentRef.setFiledNote("true")
            } else {
                this.editorComponentRef.setFiledNote("false")
            }
            this.setState({ selectedRows: selectedRows, totalSelectedRows: selectedRows.length })
            // this.setState({ selectedRows: event.value })
            let selectednote = await this.applydefaultTemplateForSelectedRows()
            if (event && event.value && event.value.length === 0 && this.props.type !== "ClaimsManagement") {
                this.editorComponentRef.handlePropertiesEditChange("", "Cancel")
            }
            // if (selectedRows.length === 1 && (selectedRows[0].matchStatusNotes && selectedRows[0].matchStatusNotes.length > 0 || selectedRows[0].PropertyNote && selectedRows[0].PropertyNote.length > 0)) {
            if (selectedRows.length === 1) {
                if (this.props.type === "ClaimsManagement") {
                    if (selectedRows[selectedRows.length - 1].PropertyNote && selectedRows[selectedRows.length - 1].PropertyNote[0]) {
                        selectednote = `${selectedRows[selectedRows.length - 1].PropertyNote[0].note}`
                    }
                    await this.setState({
                        selectednote: selectednote
                    })
                    this.editorComponentRef.updatedNote(selectednote, "note")
                    this.editorComponentRef.enableCopyProof("true")
                    if (selectedRows[0].FileFor && selectedRows[0].FileFor.length > 0) {
                        this.editorComponentRef.updatedfileFor(selectedRows[0].FileFor, "fileFor")
                    }
                    if (selectedRows[0].addressProof && selectedRows[0].addressProof.length > 0) {
                        this.editorComponentRef.updateAddressProof(selectedRows[0].addressProof, "AddressProof")
                    }
                    if (selectedRows[0].feinProof && selectedRows[0].feinProof.length > 0) {
                        this.editorComponentRef.updateAddressProof(selectedRows[0].feinProof, "FeinProof")
                    }
                    if (selectedRows[0].holderProof && selectedRows[0].holderProof.length > 0) {
                        this.editorComponentRef.updateAddressProof(selectedRows[0].holderProof, "HolderProof")
                    }
                    if (selectedRows[0].nameProof && selectedRows[0].nameProof.length > 0) {
                        this.editorComponentRef.updateAddressProof(selectedRows[0].nameProof, "NameProof")
                    } if (selectedRows[0].sellerProof && selectedRows[0].sellerProof.length > 0) {
                        this.editorComponentRef.updateAddressProof(selectedRows[0].sellerProof, "SellerProof")
                    }
                } else {
                    if (selectedRows[0].matchStatusNotes && selectedRows[0].matchStatusNotes.length > 0) {
                        selectednote = `${selectedRows[0].matchStatusNotes[0].note}`
                        await this.setState({
                            selectednote: selectednote
                        })
                    }
                    if (selectedRows[0].fileFor && selectedRows[0].fileFor.length > 0) {
                        this.editorComponentRef.updatedfileFor(selectedRows[0].fileFor, "fileFor")
                    }
                    if (selectedRows[0].claimID && selectedRows[0].claimID.length > 0) {
                        this.editorComponentRef.updateClaimID(selectedRows[0].claimID, "claimID")
                    }
                    if (selectedRows[0].addressProof && selectedRows[0].addressProof.length > 0) {
                        this.editorComponentRef.updateAddressProof(selectedRows[0].addressProof, "AddressProof")
                    }
                    if (selectedRows[0].feinProof && selectedRows[0].feinProof.length > 0) {
                        this.editorComponentRef.updateAddressProof(selectedRows[0].feinProof, "FeinProof")
                    }
                    if (selectedRows[0].holderProof && selectedRows[0].holderProof.length > 0) {
                        this.editorComponentRef.updateAddressProof(selectedRows[0].holderProof, "HolderProof")
                    }
                    if (selectedRows[0].nameProof && selectedRows[0].nameProof.length > 0) {
                        this.editorComponentRef.updateAddressProof(selectedRows[0].nameProof, "NameProof")
                    } if (selectedRows[0].sellerProof && selectedRows[0].sellerProof.length > 0) {
                        this.editorComponentRef.updateAddressProof(selectedRows[0].sellerProof, "SellerProof")
                    }
                }
                this.editorComponentRef.updatedNote(selectednote, "note")
                this.editorComponentRef.enableCopyProof("true")
            } else {
                await this.setState({
                    selectednote: selectednote
                })
                if (this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "ClaimsManagement" || this.props.type === "Deal Sizing Report For Companies") {
                    this.editorComponentRef.updatedNote(selectednote, "noteEmpty")
                    this.editorComponentRef.updatedfileFor()
                    this.editorComponentRef.enableCopyProof("false")
                    if (selectedRows.length === 0) {
                        this.editorComponentRef.removeCopiedFields()
                    }

                    this.editorComponentRef.updateAddressProof("empty", "AddressProof")
                    this.editorComponentRef.updateAddressProof("empty", "FeinProof")
                    this.editorComponentRef.updateAddressProof("empty", "HolderProof")
                    this.editorComponentRef.updateAddressProof("empty", "NameProof")
                    this.editorComponentRef.updateAddressProof("empty", "SellerProof")

                    this.editorComponentRef.updateClaimID()
                }
            }
            if (this.props.type === "ClaimsManagement" && this.state.selectedRows.length === 0) {
                this.editorComponentRef.emptyStatus()
                this.editorComponentRef.enableCopyProof("false")
                if (selectedRows.length === 0) {
                    this.editorComponentRef.removeCopiedFields()
                }
                this.editorComponentRef.updateAddressProof("empty", "AddressProof")
                this.editorComponentRef.updateAddressProof("empty", "FeinProof")
                this.editorComponentRef.updateAddressProof("empty", "HolderProof")
                this.editorComponentRef.updateAddressProof("empty", "NameProof")
                this.editorComponentRef.updateAddressProof("empty", "SellerProof")
            }
        }
    }
    getreturnSelectedRows = (event) => {
        if (event) {
            this.setState({
                selectedRows: []
            })
        } else {
            return this.state.selectedRows
        }
    }
    checkDataisEdited = () => {
        var newArray = this.state.allUsersData.filter(function (el) {
            return el.edited === "yes"
        });
        return newArray
    }
    //select multiple rows to delete
    onSelectRowsUpdate = async (event) => {
        let selectedRows = event.value

        selectedRows = selectedRows
        let newArray2 = this.checkDataisEdited()
        if (this.props.type == "ClaimsManagement" && newArray2.length > 0) {
            this.OpenConfirmaionModelorSave()
        } else {
            this.setState({
                selectedRows: selectedRows,
                totalSelectedRows: selectedRows && selectedRows.length ? selectedRows.length : 0
            })
            if (this.props.type) {
                if (this.props.type === "Properties" || this.props.type === "ClaimsManagement" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "Deal Sizing Report For Companies") {
                    this.getNotesUpdate(selectedRows)
                }
            } else {
                this.props.sendSelctedRecordtoDb(selectedRows)
            }
        }
        // let addressArr = []
        // for (var obj of event.value) {
        //   let index = addressArr.findIndex(addressArr => addressArr === obj.businessAddressLine1)
        //   if (index === -1) {
        //     addressArr.push(obj.businessAddressLine1)
        //   };
        // }
        // if (addressArr && addressArr.length === 1 && this.state.validatedArray && this.state.validatedArray.length === 0) {
        //   await this.setState({
        //     addressArr: addressArr,
        //     showSelectederror: false,
        //     errormsg: ""
        //   })
        // }

        // await this.setState({
        //   addressArr: addressArr,
        // })
        // await this.getActions()
    }

    //openConfirmationModal
    openConfirmationModal = async (rowData, status, type) => {
        this.setState({
            isOpenFormModal: false
        }, async () => {
            this.setState({
                isOpenFormModal: false,
                openConfirmationModal: true,
                leaveStatus: status,
                item: rowData,
                confirmText: type
            })
        })
    }

    closeConfirmationModal() {
        this.setState({
            openConfirmationModal: false
        })
    }
    changeNote = (Comments) => {
        this.setState({
            isnotesModal: true,
            Comments: Comments
        })
    }
    changeNoteClaims = (Comments, sno, item) => {
        if (sno) {
            this.setState({ claimNotesEdit: true })
        } else {
            this.setState({ claimNotesEdit: false })
        }

        let commentArr = []
        if (Comments) {
            commentArr.push({ note: Comments, created: null })
        }
        this.setState({
            isnotesModal: true,
            Comments: Comments,
            notesSno: item
        })
    }
    getSavedData = async (id) => {
        let Data = []
        Data = id.split(/\r?\n/)
        let filterCriteria = { limit: 1, page: 1, sortfield: "OppClaimID", direction: "desc" }
        filterCriteria.criteria = [{ key: "CompanyID", value: this.state.PropertyData.selectedClaims.EstateID, type: "eq" }, { key: "OppClaimID", value: Data, type: "in" }]
        let Url = `nsClaim/childClaims?filter=${JSON.stringify(filterCriteria)}`;
        return fetchMethodRequest('GET', Url)
            .then(async (response) => {
                if (response && response.claims && response.claims[0]) {
                    return response.claims[0]
                } else if (response && response.errorMessage) {
                    this.getErrorResponseFromServer(response.errorMessage)
                }
            }).catch((err) => {
                return err;
            });
    }
    cancelNotes = async (type, id) => {
        this.setState({
            isnotesModal: false,
            openpaymentsModal: false,
            selectedRowPayments: ""
        })
        let responsesaveData = JSON.parse(JSON.stringify(this.state.responsesaveData))
        if (type === "Payments" && id) {
            let recordedData = await this.getSavedData(id)
            let allData = this.state.allUsersData
            let index = allData.findIndex(obj => obj["_id"].OppClaimID === id)
            allData[index].Payments = recordedData.Payments
            await this.setState({
                allUsersData: allData,
            })
        }
    }
    saveClaimNotes = (comment, item, Data, editComment, edited) => {
        let allUsersData = this.state.allUsersData
        let z = allUsersData.find(elem => elem.OppClaimID === item.OppClaimID)
        let claimNotes = Data && Data.length > 0 ? Data : []
        if (editComment !== undefined) {
            // claimNotes.shift()
            // claimNotes.unshift({ note: editComment })
            claimNotes[0].note = editComment.note
        }
        if (comment !== undefined) {
            claimNotes.unshift({ note: comment })
        }

        if (z) {
            //with z values hit the api call
            z.ClaimNotes = claimNotes
            if (edited) {
                z.edited = "yes"
                localStorage.setItem("ISEdited", "true")
            }
        }
        // allUsersData[this.state.notesSno]["ClaimNotes"] = comment ? comment : ""
        this.setState({
            isnotesModal: false,
            claimNotesEdit: false,
            allUsersData: allUsersData
        })
    }
    getNotesDetails(val, column, data, index) {
        return (
            <div style={{ display: "flex", width: column.bodyStyle.width }}>
                <div style={val.length > 0 ? { display: "flex", width: column.bodyStyle.width - 30 } : {}}>
                    <span className="kanban_title" data-toggle="tool-tip"
                        title={val}
                    >
                        {val}
                    </ span >
                </div>
                <div className="d-flex">
                    {val.length > 0 ?
                        <span className="text-right" style={{ display: "flex" }}>
                            {/* <FontAwesomeIcon icon={faCopy}
                className='pl-1'
                data-toggle="tool-tip"
                // validate={[required]}
                title="click to see all Notes"
                style={{ width: 20, color: "red" }}
                onClick={() => this.clipToCopyBoard(data)}
              /> */}
                            <FontAwesomeIcon icon={faExternalLinkAlt}
                                className='pl-1'
                                data-toggle="tool-tip"
                                // validate={[required]}
                                title="click to see all Notes"
                                style={{ width: 20, color: "grey" }}
                                onClick={() => this.changeNote(data)}
                            />
                        </span>
                        : null}
                </div>
            </div>)
    }
    getNotesDetailsClaim(val, column, data, item) {
        return (
            <div style={{ display: "flex", width: column.bodyStyle.width }}>
                <div style={val.length > 0 ? { display: "flex", width: column.bodyStyle.width - 30 } : {}}>
                    <span className="kanban_title" data-toggle="tool-tip"
                        title={val}
                    >
                        {val}
                    </ span >
                </div>
                <div>

                    {val != null || val.length > 0 || column.editing ?
                        <span className="text-right" style={{ display: "grid" }}>
                            <FontAwesomeIcon icon={faExternalLinkAlt}
                                className='pl-1'
                                data-toggle="tool-tip"
                                // validate={[required]}
                                title="click to see all Notes"
                                style={{ width: 20, color: "grey" }}
                                onClick={() => this.changeNoteClaims(data, column.editing, item)}
                            />
                        </span>
                        : null}
                </div>
            </div>)
    }


    setFilterCriteriaForActivities = async (editRowDataID) => {
        let filterCriteria = this.state.filterCriteria;
        filterCriteria['criteria'].push(
            { key: 'contextId', value: editRowDataID, type: 'eq' }
        )
        await this.setState({
            filterCriteria: filterCriteria
        })
    }

    getTooltipFromOtptions(options, name) {
        if (options) {
            for (let i = 0; i < options.length; i++) {
                if (options[i].value === name) {
                    return options[i].tooltip;
                }
            }
        }
        return '';
    }

    viewSellerModal = async (id) => {
        await this.setState({
            issellerModalView: true,
            sellerID: id,
            sellerEditType: "view"
        });
    }

    getCompanyDetails = async (id) => {
        let filterCriteria = {}
        filterCriteria['criteria'] = [{ key: "CompanyID", value: id, type: 'eq' }];
        fetchMethodRequest('GET', `businessNames/companies?filter=${JSON.stringify(filterCriteria)}`)
            .then(async (response) => {
                if (response) {
                    let data = response.companies[0];
                    this.openViewModal(data, 'view')
                } else if (response && response['errorMessage']) {
                    this.getErrorResponseFromServer(response)
                }
            }).catch((err) => {
                return err;
            });
    }
    openLink = async (item, type) => {
        await this.setState({ openLinked: true, openCompanyId: item.CompanyID })
        // return <Link to={(`/companies/${JSON.stringify(item.CompanyID)}`)}></Link>
    }
    //Copy Function
    clipToCopyBoard(data) {
        // if (data[0].created) {
        //   let arr = data.sort(function (a, b) {
        //     return new Date(a.created) - new Date(b.created);
        //   });
        //   data = arr[0].note
        // }
        navigator.clipboard.writeText(data)
    }
    getcollectedData = (item) => {
        let initialValue = 0
        if (item && item["Payments"] && item["Payments"].length > 0) {
            let sum = item["Payments"].reduce(function (accumulator, curValue) {
                return accumulator + curValue.checkAmount
            }, initialValue)


        }
        let dollarUS = this.getFormattedCurrencyValue()
        if (item["PaymentsData"] && item["PaymentsData"][0]) {
            let flatArray = [].concat(...item["PaymentsData"][0]);
            var paymentAmount = flatArray.reduce(function (a, b) { return a + b; }, 0);
            if (paymentAmount !== 0) {
                return dollarUS.format(paymentAmount)
            } else { return "" }
        } else {
            return ""
        }

    }
    customCount(item, field, checkType) {
        let dollarUS = this.getFormattedCurrencyValue()
        if (checkType === "maxCount" && field === "greenMax" && item.green !== undefined && item.green["PropertyMaxValueAmountSum"]) {
            return dollarUS.format(parseInt(item.green["PropertyMaxValueAmountSum"])).slice(0, -3)
        } else if (checkType === "maxCount" && field === "blueMax" && item.blue !== undefined && item.blue["PropertyMaxValueAmountSum"]) {
            return dollarUS.format(parseInt(item.blue["PropertyMaxValueAmountSum"])).slice(0, -3)
        } if (checkType === "minCount" && field === "greenMin" && item.green !== undefined && item.green["PropertyMinValueAmountSum"]) {
            return dollarUS.format(parseInt(item.green["PropertyMinValueAmountSum"])).slice(0, -3)
        } else if (checkType === "minCount" && field === "blueMin" && item.blue !== undefined && item.blue["PropertyMinValueAmountSum"]) {
            return dollarUS.format(parseInt(item.blue["PropertyMinValueAmountSum"])).slice(0, -3)
        } else {
            return ""
        }
    }

    handleUsersData = async (indexeddata, type) => {
        let allData = this.state.allUsersData
        let responsesaveData = JSON.parse(JSON.stringify(this.state.responsesaveData))
        let index = allData.findIndex(obj => obj["_id"].OppClaimID === indexeddata["_id"].OppClaimID)
        allData[index] = indexeddata;
        responsesaveData[index].Payments = indexeddata.Payments
        allData[index].edited = "yes"
        localStorage.setItem("ISEdited", "true")
        await this.setState({
            allUsersData: allData,
            selectedRowPayments: indexeddata,
            responsesaveData: JSON.parse(JSON.stringify(responsesaveData)),

        })
        if (!type && this.paymentsModalRef) {
            this.paymentsModalRef.getUpdatedData(indexeddata)
        }
        this.closeFormModal()
    }
    handleHourstominutes = (totalMinutes) => {
        // var days = Math.floor(item.durationInMinutes / 24 / 60);
        // const hours = Math.floor(totalMinutes / 60);
        // const minutes = totalMinutes % 60;
        // console.log("days", days)
        if (totalMinutes) {
            return totalMinutes.toFixed(1);
        } else {
            return ""
        }
    }

    padToTwoDigits(num) {
        return num.toString().padStart(2, "0");
    }

    //change table body values//
    // hari need to move to derived class or controller
    changeFieldValues = (item, column) => {
        var propType = this.props.type
        let self = this, tableItem;
        tableItem = self.getTableFieldItem(column.field);
        if (tableItem && tableItem.fieldType) {
            if (tableItem.fieldType === "Link") {
                if (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") {
                    if (this.state.screenPermissions === "Edit") {
                        return item[column.field]
                    } else if (this.state.screenPermissions === "View") {
                        return <div className='textElipses'>
                            <span
                                onClick={(this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? () => this.viewSellerModal(item.DealID) : () => this.openViewModal(item, 'view')}>
                                {item[column.field]}</span>
                        </div >
                    }

                } else {
                    return <div className='textElipses'>
                        <span
                            onClick={this.props.type === "Investor" ? () => this.getCompanyDetails(item.InvestorID, 'view') : () => this.openViewModal(item, 'view')}>
                            {item[column.field]}</span>
                    </div >
                }
                // }
            } else if (tableItem.fieldType === "openWindow") {
                return <div className='textElipses'>
                    <a href="#"> <span
                        onClick={() => this.openFormModal(item, 'edit')}>
                        {item[column.field]}</span></a>
                </div >

            } else if (tableItem.fieldType === "CityField") {
                return <span>
                    <span>{item.HQCity}</span> {item.HQCity && item.HQState_Province ? "," : ""}<span>{item.HQState_Province}</span>
                </span>
            } else if (tableItem.fieldType === "hrsField") {
                let valdata = this.handleHourstominutes(item[column.field] ? item[column.field] : 0)
                return <span>
                    <span>{valdata}</span>
                </span>
            } else if (tableItem.fieldType === "StrArray") {
                let val = item[column.field]
                return <span data-toggle="tool-tip"
                    title={val ? val.join(" , ") : ""}>
                    {val ? val.join(" , ") : ""}
                </span >
            }
            else if (tableItem.fieldType === "profile") {
                return <span>
                    <img
                        src={item[column.field] ? `${config.imgUrl}/${item[column.field]} ` : configImages.defaultImg}
                        alt=""
                        className="appLogo" />
                </span >
            } else if (tableItem.fieldType === "nameIssue") {
                let val = item[column.field]
                return <span>
                    {val === null ? "N/A" : val}
                </span >
            } else if (tableItem.fieldType === "ShowData") {
                return <div className='row ml-0'><span
                    onClick={() => this.showNotesData(item)}
                    style={{ color: "#354f6e", cursor: "pointer" }}
                    data-toggle="tool-tip"
                    title={this.state.getNotesresponse && this.state.getNotesresponse.length > 0 && this.state.getNotesresponse[0] && this.state.getNotesresponse[0].propertyid && this.state.getNotesresponse[0].propertyid === item.propertyID ? "Status:  " + this.state.getNotesresponse[0].MatchStatus + "\n\nNotes:  " + this.state.getNotesresponse[0].StatusNotes :
                        this.state.isValidData && this.state.getNotesresponse && this.state.getNotesresponse.length === 0 && this.state.validatedNotesId === item.propertyID ? "This reccord has no Data" :
                            "Please click before to see the status"}>
                    {item[column.field]}
                </span>
                    {
                        this.state.getNotesresponse && this.state.getNotesresponse.length > 0 && this.state.getNotesresponse[0] && this.state.getNotesresponse[0].propertyid === item.propertyID ?
                            <span><FontAwesomeIcon
                                style={{ width: "14px", float: "left", marginRight: 5, marginTop: 4, marginLeft: 4 }}
                                icon={faCopy}
                                onClick={() => this.clipToCopyBoard(this.state.getNotesresponse[0].StatusNotes)}
                                color={'red'}
                                data-toggle="tool-tip"
                                title={"Click to copy the notes"}
                            /></span> : null
                    }
                </div >

            } else if (tableItem.fieldType === "ActiveIndicator") {
                if (item[column.field] === "Yes" || item[column.field] === true) {
                    return <span>
                        {"True"}
                    </span >
                } else if (item[column.field] === "No" || item[column.field] === false) {
                    return <span>
                        {"False"}
                    </span >
                }
            } else if (tableItem.fieldType === "Boolean") {
                if (item[column.field] === true) {
                    return <span>
                        {"True"}
                    </span >
                } else if (item[column.field] === false) {
                    return <span>
                        {"False"}
                    </span >
                }
            }
            else if (tableItem.fieldType === "Percentage") {
                let val = Math.round(item[column.field] * 100)
                return <span>
                    {val}
                </span >
            } else if (tableItem.fieldType === "maxCount" || tableItem.fieldType === "minCount") {
                let val;
                val = this.customCount(item, tableItem.field, tableItem.fieldType)
                return <span>
                    {val}
                </span >
            } else if (tableItem.fieldType === "StatesArray") {
                let val = item[column.field].length
                return <span>
                    {val}
                </span >
            }
            else if (tableItem.fieldType === "StatesArray1") {
                let val = item[column.field] === 0 ? 0 : item[column.field]
                return <span>

                    {val}
                </span >
            } else if (tableItem.fieldType === "ShowData1") {
                let val = item[column.field]
                return <span>
                    {val === 0 ? 0 : val}
                </span >
            } else if (tableItem.fieldType === "formatCurrency") {
                let dollarUS = this.getFormattedCurrencyValue()
                let val
                if (column.field === "PropertyKnownMaxValueAmountSum" || column.field === "PropertyEstimatedMaxValueAmountSum") {
                    val = item[column.field].toFixed(0)
                    val = dollarUS.format(val)
                    val = val.split(".")[0]
                } else {
                    val = dollarUS.format(item[column.field])
                }
                // let val = item[column.field]
                return <span style={(column.field === "PropertyKnownMaxValueAmountSum" || column.field === "PropertyEstimatedMaxValueAmountSum") && this.props.type === "UP Opportunity – V2" && item["EstateName"] === "Total" ? { fontWeight: "bold" } : {}}>
                    {item[column.field] !== undefined && item[column.field] !== null && item[column.field] !== "" && val !== "$NaN" ? val : "N/A"}
                </span >
            } else if (tableItem.fieldType === "currency") {
                return <span>
                    {item[column.field] > 0 ?
                        <span>{item[column.field].toFixed(1)}</span> : <span>{column.field.includes("Hours") ? "" : 0}</span>}
                </span>
            }
            else if (tableItem.fieldType === "dateTime") {
                return <span>
                    {item && item[column.field] ?
                        <span>{dateFormats.formatDate(item[column.field], "MM/DD/YY")}</span> : ""}
                </span>
            }
            else if (tableItem.fieldType === "redirect") {
                if (this.props.type === "UP Opportunity – V2" && item["EstateName"] === "Total") {
                    return <span style={{ marginLeft: "19px", fontWeight: "bold" }
                    }> {item[column.field]}</span >
                }
                // ?sellerName = ${item[column.field] }& sellerId=${ this.state.PropertyData.selectedSeller.EstateID }
                return <span>
                    {/* {this.props.type === "Deal Sizing Report For Companies" ? <a className="pl-0" onClick={() => this.getUrlbyParams(type === "US Opportunity" ?'summary':'deals',item)} href={`${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(item.BusinessNameID)}`}> {item[column.field]}</a> : */}
                    <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={this.state.PropertySharebody} onClick={() => this.getUrlbyParams(propType === "UP Opportunity" || this.props.type === "UP Opportunity – V2" || propType === "Ready To File" || propType === "On Hold" ? 'summary' : 'deals', item, column.field)} >{item[column.field]}</a>

                </span>
            } else if (tableItem.fieldType === "website") {
                return <span>
                    <span>
                        <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={`http://${item[column.field]}`}>{item[column.field]}</a>
                    </span >
                </span >
            } else if (tableItem.isLink === "yes") {
                return <span>
                    <span>
                        <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={`http://${item["MostRecent10KFormUrl"]}`}>{item[column.field]}</a>
                    </span >
                </span >
            }

            else if (tableItem.fieldType === "bucketCount") {
                let val = this.getvalfromObj(item[column.field], "COUNT(ns᎐CompanyID)")
                return <span>
                    <span>{val}</span>
                </span >
            }
            else if (tableItem.fieldType === "Badge") {
                let mcolor = self.getColorFromOptions(tableItem.options, item.status);
                return <div className="row ml-0 mr-0" style={{ width: '100%', display: 'contents' }}>
                    <span style={{ width: '10px', marginRight: 5 }}
                        data-toggle="tool-tip"
                        title={item.status}>
                        <FontAwesomeIcon
                            style={{ width: "9px", float: "left", marginRight: 5, marginTop: 4 }}
                            icon={faCircle}
                            color={mcolor === 'success' ? '#5cb85c' : mcolor === 'warning' ? 'red' : mcolor === 'danger' ? 'red' : ""}
                            data-toggle="tool-tip"
                            title={item.status}
                        />
                    </span>
                    <span className="datatable_status_field">{item[column.field]}</span>
                </ div >
            } else if (tableItem.fieldType === "Role") {
                let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
                return <Badge color={mcolor} style={tableItem.style}>{item[column.field]}</Badge>
            } else if (tableItem.fieldType === "icon") {
                let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
                let tooltip = self.getTooltipFromOtptions(tableItem.options, item[column.field]);
                return <FontAwesomeIcon
                    style={{ color: mcolor, cursor: tooltip ? 'pointer' : '' }}
                    color='white'
                    icon={tableItem.iconName}
                    data-toggle="tool-tip"
                    title={tooltip}
                />
            } else if (tableItem.fieldType === "Array") {
                let val;
                val = this.flattenArray(item[column.field]);
                if (tableItem.openNotes) {
                    let data = this.getNotesDetails(val, column, item[column.field])
                    return <>{data}</>
                } else {
                    return <span style={tableItem.style} data-toggle="tool-tip"
                        title={val}
                    >
                        {val}
                    </ span >
                }

            } else if (tableItem.fieldType === "ArrayNote") {
                let val = item[column.field]
                val = this.flattenArray(item[column.field]);
                if (tableItem.openNotes1) {
                    let data = this.getNotesDetailsClaim(val, column, item[column.field], item)
                    return <>{data}</>
                } else {
                    return <span style={tableItem.style} data-toggle="tool-tip"
                        title={val}
                    >
                        {val}
                    </ span >
                }

            } else if (tableItem.fieldType === "slicedArray") {
                let val;
                val = this.slicedArray(item[column.field], column.field);
                return <span style={tableItem.style} data-toggle="tool-tip"
                    title={val}
                >
                    {val}
                </ span >
            }
            else if (tableItem.fieldType === "PaymentArray") {
                let val;
                val = this.flattenArray(item[column.field], item);
                return <div className='row ml-0' style={item && !item.ChildCount ? { textAlign: "end" } : { justifyContent: "end", marginRight: 10 }}
                >
                    <div style={{
                        alignSelf: "center", width: "85%"
                    }} >
                        {val}
                    </div>

                </ div >
            } else if (tableItem.fieldType === "PaymentBtn") {
                return <div className='' style={{ alignSelf: "center" }}>
                    {item && item.ChildCount ?
                        <div className='d-flex' style={{ justifyContent: "space-around" }}>
                            < Button
                                color="primary"
                                size="sm"
                                outline
                                // disabled={true/*this.props.type === "crMatrix" && item.Answers > 0 ? true : false*/}
                                className="mb-0 m-1 ml-0 p-1"
                                style={{ width: "50px" }}
                                onClick={() => this.openFormModal(item, 'edit', "Payments")}>
                                <div style={{ alignSelf: "center" }}>
                                    {'+'}
                                </div>
                            </Button>
                        </div>
                        : null}
                </div>
            }
            else if (tableItem.fieldType === "SumAmount") {
                let val = this.getcollectedData(item);
                return <div>
                    {item && item.ChildCount ?
                        <>
                            <span className="mr-2">
                                {val}
                            </span>
                            {val !== "" ?
                                <span>
                                    <FontAwesomeIcon
                                        style={{ color: "grey", cursor: 'pointer' }}
                                        color='lightgrey'
                                        icon={faExternalLinkAlt}
                                        data-toggle="tool-tip"
                                        title={"click to see payment details"}
                                        onClick={() => this.setState({
                                            openpaymentsModal: true,
                                            selectedRowPayments: item
                                        })}
                                    />
                                </span>
                                : null}
                        </>
                        : ""}
                </div>
            }
            else if (tableItem.fieldType === "ClaimReason") {
                // let flatArray = [].concat(...item["PaymentsData"]);
                // var paymentAmount = flatArray.reduce(function (a, b) { return a + b; }, 0);
                let val = item.ClaimReason;
                // if (paymentAmount === 0) {
                return <span>

                    {val ? val : null}
                </span>
            }
            else if (tableItem.fieldType === "Analyst") {

                // let flatArray = [].concat(...item["PaymentsData"]);
                // var paymentAmount = flatArray.reduce(function (a, b) { return a + b; }, 0);
                let val = item.Analyst;
                // if (paymentAmount === 0) {
                return <span>

                    {val ? val : null}
                </span>
            }
            else if (tableItem.fieldType === "ClaimStatus") {

                // let flatArray = [].concat(...item["PaymentsData"]);
                // var paymentAmount = flatArray.reduce(function (a, b) { return a + b; }, 0);
                let val = item.ClaimStatus;
                // this.setClaimReason(val)
                // if (paymentAmount === 0) {
                return <span>

                    {val ? val : "Claim-Pending"}
                </span>
                // } else if (paymentAmount < item["ClaimAmount"]) {
                //   return <span
                //     onClick={() => this.openFormModal(item, 'edit', 'ClaimStatus')}>
                //     {val ? val : "Claim-Partially Paid"}
                //   </span>
                // } else if (paymentAmount >= item["ClaimAmount"]) {
                //   return <span
                //     onClick={() => this.openFormModal(item, 'edit', 'ClaimStatus')}>
                //     {val ? val : "Claim-Paid"}
                //   </span>
                // }

                // if (item["PropertyStatus"] === "Denied" && item["PropertyReason"] === "Appropriate Reason" && paymentAmount === 0) {
                //   return <span>
                //     <span>{"Claim-Denied"}</span>
                //   </span >
                // } else if (item["PropertyStatus"] === "Abandoned" && item["PropertyReason"] === "Appropriate Reason" && paymentAmount === 0) {
                //   return <span>
                //     <span>{"Claim-Abandoned"}</span>
                //   </span >
                // } else if (item["PropertyStatus"] === "Filed" && item["PropertyReason"] === "Pending" && paymentAmount === 0) {
                //   return <span>
                //     <span>{"Claim-Pending"}</span>
                //   </span >
                // } else if (item["PropertyStatus"] === "Filed" && item["PropertyReason"] === "Pending" && paymentAmount >= item["ClaimAmount"]) {
                //   return <span>
                //     <span>{"Claim-Paid"}</span>
                //   </span >
                // } else if (item["PropertyStatus"] === "Filed" && item["PropertyReason"] === "Pending" && paymentAmount < item["ClaimAmount"]) {
                //   return <span>
                //     <span>{"Claim-Partially Paid"}</span>
                //   </span >
                // }
            } else if (tableItem.fieldType === "splitVal") {
                let val = item[column.field].split("-");
                return <span style={tableItem.style} >
                    <span>{val[0]}</span>
                </span>
                // }
            } else if (tableItem.fieldType === "label" && tableItem.field === "company") {
                // let val = item[column.field].split("-");
                return <span style={tableItem.style} >
                    <span data-toggle="tool-tip" title={item[column.field]}><b style={{ fontSize: 14 }} >{item[column.field]}</b></span>
                    {item.round ? <span> ({item.round}) </span> : null}
                </span>
                // }
            } else if (tableItem.fieldType === "label") {
                // let val = item[column.field].split("-");
                return <span style={tableItem.style} >
                    <span data-toggle="tool-tip" title={item[column.field]}><b style={{ fontSize: 14 }} >{item[column.field]}</b></span>
                </span>
                // }
            }
            else if (tableItem.fieldType === "number") {
                let val = item[column.field]
                return <span >
                    {val ? val : 0}
                </span>
            } else if (tableItem.fieldType === "BoolText") {
                let val = item.activeIndicator ? item.activeIndicator : item.DWRowIsCurrent ? item.DWRowIsCurrent : false
                const value = val.toString()
                if (value) {
                    return <div>< FontAwesomeIcon
                        className="status_logo"
                        color={value === "true" ? '#5cb85c' : 'red'}
                        icon={faCircle}
                        data-toggle="tool-tip"
                        title={value === "true" ? 'Active' : 'Inactive'} />
                        <span >{item[column.field]}</span></div>
                }
            } else if (tableItem.fieldType === "Complex") {
                let data = this.ObjectbyString(item, column.field)
                return <span>
                    {data}
                </span>
            } else if (tableItem.fieldType === "hasPropertyCoOwner" || tableItem.fieldType === "confirmedOnSite") {
                if (item[column.field]) {
                    return <span>
                        {"Yes"}
                    </span>
                } else {

                    return <span>
                        {"No"}
                    </span>
                }
            } else if (tableItem.fieldType === "Actions") {
                return (
                    <div
                        style={{ justifyContent: 'center' }}>
                        {(this.props.editRequired && this.props.type !== "Weekly Recovery Plan") ?
                            < Button
                                color="primary"
                                size="sm"
                                outline
                                disabled={this.props.type === "crMatrix" && item.Answers > 0 ? true : false}
                                className="mb-0 m-1 ml-0 p-1"
                                onClick={() => this.openFormModal(item, 'edit')}>
                                Edit
                            </Button>
                            : ''
                        }
                        {
                            (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? <a href={`${window.location.protocol}//${window.location.host}/sellersEdit/${item.DealID}`} target="_blank"> <FontAwesomeIcon icon={faExternalLinkAlt}
                                className='pl-1'
                                data-toggle="tool-tip"
                                // validate={[required]}
                                title="click to open in next tab"
                                style={{ width: 20, color: "grey", marginLeft: "-20px" }}
                            // onClick={() => this.openSellerInNewTab(item)}
                            /> </a>
                                : ''
                        }
                        {
                            (this.props.deleteRequired) ?
                                this.props.type === "Weekly Recovery Plan" ?
                                    <FontAwesomeIcon
                                        className="status_logo"
                                        // color={value === "true" ? '#5cb85c' : 'red'}
                                        style={{ cursor: "pointer" }}
                                        icon={faMinus}
                                        data-toggle="tool-tip"
                                        // title={value === "true" ? 'Active' : 'Inactive'}
                                        onClick={() => this.deleteConfirmAction(item._id, "Delete")}
                                    />
                                    :
                                    < Button
                                        color="danger"
                                        size="sm"
                                        outline
                                        className="mb-0 m-1 p-1"
                                        onClick={() => this.deleteConfirmAction(this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue" ? item : item._id, "Delete")}>
                                        {this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue" ? "Cancel" : "Delete"}
                                    </Button>
                                : null

                        }
                    </div >
                )
            } else if (tableItem.fieldType === "Date") {
                let val = item[column.field]
                return <span >
                    {val}
                </span>
            } else if (tableItem.fieldType === "Time") {
                let val = item[column.field]
                return <span >
                    {val}
                </span>
            }
        } else {
            if ((item && item[column.field]) && typeof item[column.field] !== 'object') {
                if (column.field === "APAType") {
                    let z = this.state.Apa.find(elem => elem.value === item[column.field])

                    if (z) {
                        //with z values hit the api call
                        return z.label
                    }
                } else {

                    if (this.props.type === "UP Opportunity – V2" && item["EstateName"] === "Total") {
                        return <span style={{ fontWeight: "bold" }
                        }> {item[column.field]}</span >
                    }
                    return <span data-toggle="tool-tip" title={item[column.field]} >{item[column.field]}</span>;
                }
            }
        }
    }

    clickConfirm() {
        this.closeConfirmationModal();
    }

    ObjectbyString = (o, s) => {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    rowClassName(item) {
        if (item && item.status) {
            if (item.status === 'Blocked') {
                return (
                    { 'p-highlightRow': (item.status === 'Blocked') }
                )
            }
        } else if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt") && item && item.ScoringStatus && item.ScoringStatus !== null) {
            return {
                'p-disabledRow': (item.ScoringStatus !== null)
            }
        } else if (this.props.type === "Claims" && item && item.oppClaimID && item.oppClaimID !== null) {
            return {
                'p-disabledRow': (item.oppClaimID !== null)
            }
        }
        else if (item && item.matchMatchStatus && this.props.type === "Property Editor") {
            if (item.matchMatchStatus) {
                return (
                    {
                        'p-highlighteddarkRow': (item.matchMatchStatus === 'Filed' || item.matchMatchStatus === "Paid" || item.matchMatchStatus === 'Validated-Ready to File' || item.matchMatchStatus === "Validated-Seller Negative Notice"),
                        'p-highlightRow': (!item.matchMatchStatus.includes("Matched") && item.matchMatchStatus !== 'Filed' && item.matchMatchStatus !== "Paid" && item.matchMatchStatus !== 'Validated-Ready to File' && item.matchMatchStatus !== "Validated-Seller Negative Notice"),
                        'p-disabledRow': ((item.matchMatchStatus === 'Filed' || item.matchMatchStatus === "Paid") && this.state.activeTab === "SP")
                    }
                    // 'p-disabledRow': (item.matchMatchStatus === 'Filed' || item.matchMatchStatus === "Paid")
                )
            }
        }
    }

    handleDateInterval(startDate, endDate) {
        if (startDate) {
            this.setState({
                startDate: startDate
            })
        }
        if (endDate) {
            this.setState({
                endDate: endDate
            })
        }
    }
    // ShowHideColumns Button Click Handler
    //Settings Modal open
    openShowHideColumnsModal = () => {
        this.setState({
            isOpenShowHideColumnsModal: true,
        })
    }

    getSettings = async () => {
        this.props.getSettings();
    }

    searchInDateRange() {
        if (this.state.startDate && this.state.endDate) {
            let filterCriteria = {};
            let startDate = (moment(this.state.startDate).format(config.dateDayMonthFormat));
            let endDate = (moment(this.state.endDate).format(config.dateDayMonthFormat));
            filterCriteria['limit'] = 20;
            filterCriteria['page'] = 1;
            filterCriteria['criteria'] = [
                {
                    'key': 'date',
                    'value': startDate,
                    'type': 'gte'
                },
                {
                    'key': 'date',
                    'value': endDate,
                    'type': 'eq'
                }];
            this.setState({ filterCriteria: filterCriteria });
            this.getDataFromServer(filterCriteria);
        } else {
            alert('no dates selected');
        }
    }
    toggle = async (id) => {
        let openId = this.state.openId;
        this.setState({ openId: openId === id ? '' : id });
    }

    print = () => {
        window.print();
    }

    toogleMultiSelect = async () => {
        await this.setState({
            showMultiSelectDropDown: !this.state.showMultiSelectDropDown
        })
    }

    onGlobalSearchChange = async (e) => {
        let suggestions = this.state.suggestions;
        suggestions[0] = { "label": `Search By First Name ${e.query}` }
        await this.setState({ suggestions: suggestions })
    }


    getScreenHeader() {
        const { t } = this.props;
        return (
            <div>
                <div className='row'>
                    <div className='col-sm-12 row ' style={{ width: "100%", justifyContent: "space-between" }}>
                        <h4><span className='postionRelative pt-2'>
                            {this.props.fontAwesome ?
                                <FontAwesomeIcon
                                    className={'tableheadericon'}
                                    color="#354f6e"
                                    icon={this.props.type === "Status" ? faToggleOff : this.props.type === "Properties" ? faCity : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? faUsers : this.props.type === "Process Documents" ? faFolder : ""} /> :
                                <span className={`tableheadericon lnr lnr-${this.props.icon}`} />}
                            <b
                            ><Link to={(this.props.routeTo)} onClick={this.closeFormModal} className="tableType" >
                                    {t(this.props.type)}
                                </Link>{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
                            </b>
                        </span></h4>
                        {this.props.type === "Company" ? this.chooseCompanyType() : null}
                        <span className='float-right pl-3 ' style={{ display: "inline-flex" }}>
                            {!this.state.isOpenFormModal && <div>
                                <div
                                    className="p-inputgroup searchField"
                                >{this.props.isClearFilters ?
                                    <span>
                                        <Button color="primary"
                                            size="sm"
                                            outline
                                            style={{ height: 36 }}
                                            className="py-2 px-5 mb-0 mx-1"
                                            onClick={() => this.getremoveFilters()}>Clear Filters
                                        </Button>
                                    </span> : null}
                                    {this.props.globalSearch ?
                                        <span className="p-input-icon-left">
                                            <i className="pi pi-search" style={{ display: "block", fontSize: "14px" }} />
                                            <span className="p-input-icon-right">
                                                <InputText
                                                    type="search"
                                                    name='globalSearch'
                                                    id='globalSearch'
                                                    // value={this.state.selectFilterValue}
                                                    style={{ height: 37, paddingLeft: "2.5rem" }}
                                                    onKeyPress={(e) => this.onFilterGlobalChange(e)}
                                                    onChange={(e) => this.onFilterGlobalChange(e, 'globalSearch')}
                                                    placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                                                    size={(this.props.type === "Company" || this.props.type === "Investor") ? "25" : "20"} />
                                            </span>
                                        </span> : null}
                                </div>
                            </div>}
                            {this.props.addRequried ?
                                <Button color="primary"
                                    style={{ padding: "7px 25px" }}
                                    outline
                                    className="mb-0 ml-1 mr-0"
                                    onClick={(e) => this.openFormModal('add')}>
                                    <FontAwesomeIcon
                                        icon='plus'
                                        className='Addbtn' size='sm'
                                        data-toggle="tool-tip" title={t("Add")}
                                        onClick={(e) => this.openFormModal('add')}
                                    />
                                    {this.props.type === "List View" || this.props.type === "Recovery Planner V2" ? 'Add New Task' : 'Add'}
                                </Button>
                                : null}

                        </span>

                    </div >
                    <div className=' pb-1'>
                        <span className='pt-2'>
                            {/* Export to csv */}
                            <span className='float-left pt-2' >
                                {this.props.filterRequired ? <span className='mr-3' ref={this.closeMultiSelectDropdown}>
                                    <span className='multiSelectDropDownCard '>
                                        <span onClick={this.toogleMultiSelect}>
                                            {t('Filters')}
                                        </span>
                                        <span onClick={this.toogleMultiSelect}>
                                            <FontAwesomeIcon
                                                icon={faChevronDown}
                                                className='ml-1'
                                                color='grey'
                                            />
                                        </span>
                                        {this.state.showMultiSelectDropDown ?
                                            <Card className=' itemsWarperCard' >
                                                {
                                                    this.state.tablefieldsToShow && this.state.tablefieldsToShow.length > 0 ? this.state.tablefieldsToShow.map((item, index) => {
                                                        return (
                                                            item.displayInFilter && item.show && < div >
                                                                <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                                                <ul className='pl-0'>
                                                                    {
                                                                        item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                                                            return (
                                                                                <div className='multiSelectWrapperItems' onClick={() => this.changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                                                                    <span className='chekcIconAdjust'>
                                                                                        <Checkbox
                                                                                            // onChange={e => setChecked(e.checked)}
                                                                                            checked={optionsItem.checkIcon}>
                                                                                        </Checkbox>
                                                                                        {/* <FontAwesomeIcon
                                                icon='check'
                                                color={optionsItem.checkIcon ? 'grey' : 'white'}
                                              /> */}
                                                                                    </span>
                                                                                    <span className='chekcIconAdjustLabel'>
                                                                                        {t(optionsItem.label)}
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        }) : null
                                                                    }
                                                                </ul>
                                                                <hr className='my-0'></hr>
                                                            </div>
                                                        )
                                                    }) : null
                                                }
                                            </Card>
                                            : null}
                                    </span>

                                </span> : null}

                                {!this.state.isOpenFormModal && this.state.actionsTypes && this.state.actionsTypes.length > 0 ? <DropDown
                                    style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
                                    className='mr-3'
                                    // appendTo={document.body}
                                    name='actions'
                                    value={this.state.actions}
                                    disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                                    options={this.state.actionsTypes}
                                    placeholder={t('Actions')}
                                    onChange={(e) => this.onActionsChange(e, 'dropdownFilter')}
                                /> : null}

                                {!this.state.isOpenFormModal && this.props.printRequried ?
                                    <Button color="primary"
                                        size="sm"
                                        className="p-1 ml-auto  mb-0 mt-1"
                                        onClick={() => this.print()}
                                    >
                                        <FontAwesomeIcon
                                            icon='print'
                                            className='pl-1' size='lg'
                                            data-toggle="tool-tip" title={t("Print")}
                                            onClick={() => this.print()}
                                        />
                                    </Button>
                                    : null}
                                {!this.state.isOpenFormModal && this.props.exportRequried && <Button color="primary"
                                    className='px-2 ml-2 mb-0 mt-1'
                                    size={'sm'} onClick={this.getDataToExport}>
                                    <FontAwesomeIcon
                                        icon='file'
                                        data-toggle="tool-tip" title={t("Export To CSV")}
                                        className='p-1' size='lg' />
                                </Button>}
                                {!this.state.isOpenFormModal && this.props.exportRequried &&
                                    <CSVLink
                                        data={this.state.exportData}
                                        // style={{ textDecoration: "wrap" }}
                                        headers={this.state.exportHeaders}
                                        filename={`${this.props.type}.csv`}
                                        className="hidden text-gray py-0 px-1"
                                        ref={(r) => this.csvLinkRef = r}
                                        target="_blank" >
                                    </CSVLink>}
                                {!this.state.isOpenFormModal && this.props.sample ?
                                    <Button color="primary"
                                        size="sm"
                                        className="p-1 mt-1 mb-0"
                                        onClick={() => this.bulkUploadMoadal()}>
                                        <FontAwesomeIcon
                                            icon='upload'
                                            className='pl-1' size='lg'
                                            data-toggle="tool-tip" title={t("Bulk Upload")}
                                            onClick={() => this.bulkUploadMoadal()} />
                                    </Button>
                                    : null}

                                {!this.state.isOpenFormModal && this.props.gridRequried ? <ButtonGroup className='mb-0 mr-3'>
                                    <Button color="primary"
                                        outline={this.state.viewType === 'grid' ? false : true}
                                        size="sm"
                                        className="p-1 ml-auto mt-1 mb-0"
                                        onClick={() => this.changeViewType('grid')}
                                    >
                                        <FontAwesomeIcon
                                            icon='grip-horizontal'
                                            className='pl-1' size='lg'
                                            data-toggle="tool-tip" title={t("Grid")}
                                            onClick={() => this.changeViewType('grid')}
                                        />
                                    </Button>
                                    <Button color="primary"
                                        size="sm"
                                        outline={this.state.viewType === 'list' ? false : true}
                                        className="p-1 ml-auto mt-1 mb-0"
                                        onClick={() => this.changeViewType('list')}
                                    >
                                        <FontAwesomeIcon
                                            icon='list'
                                            className='pl-1' size='lg'
                                            data-toggle="tool-tip" title={t("List")}
                                            onClick={() => this.changeViewType('list')}
                                        />
                                    </Button>
                                </ButtonGroup> : null}

                                {!this.state.isOpenFormModal && this.props.settingsRequired ? <Button color="primary"
                                    size="sm"
                                    className="p-1 ml-auto mt-1 mb-0"
                                    onClick={() => this.openShowHideColumnsModal()}
                                >
                                    <FontAwesomeIcon
                                        icon='cog'
                                        className='pl-1' size='lg'
                                        data-toggle="tool-tip" title={t("Settings")}
                                        onClick={() => this.openShowHideColumnsModal()}
                                    />
                                </Button> : null}

                                {/* Add button */}
                                {/* {this.props.addRequried ?
                  <Button color="primary"
                    // size="sm"
                    //className='p-1 m-1'
                    outline
                    className="mb-0 ml-1 mt-1 mr-0"
                    onClick={(e) => this.openFormModal('add')}>
                    <FontAwesomeIcon
                      icon='plus'
                      className='Addbtn' size='sm'
                      data-toggle="tool-tip" title={t("Add")}
                      onClick={(e) => this.openFormModal('add')}
                    />
                    {this.props.type==="List View" ? 'Add New Task' : 'Add'}
                  </Button>
                  : null} */}

                                {/* priority  in Issues*/}
                                {/* {!this.state.isOpenFormModal && <Button
                  color="secondary"
                  size="sm"
                  // className="p-1 ml-auto"
                  className={this.props.addRequried ? "p-1 mt-1 mb-0" : 'p-1 ml-auto mt-1 mb-0'}
                  onClick={() => this.getDataFromServer(this.state.filterCriteria, 'refresh')}>
                  <FontAwesomeIcon
                    icon='sync-alt'
                    size='lg'
                    data-toggle="tool-tip" title={t("Refresh")}
                    color={config.templateColor}
                    className='refreshIcon pl-1' />
                </Button>} */}
                            </span>
                        </span>
                    </div>
                </div >
            </div >
        )
    }
    chooseCompanyType = () => {
        let type = localStorage.getItem("CompanyType")
        return (
            <div className='row mb-2'>
                {/* <div className="field-radiobutton mt-2">
          <Checkbox value="Private" onChange={() => this.onShowPrivateCompanies()} checked={this.state.isTopFilter} />
          {/* <label htmlFor="city1" className='mb-0'>Pitchbook</label> */}
                {/* </div> */}
                <div className="p-input-icon-left mr-2">
                    <i className="pi pi-search" />
                    <span className="p-input-icon-right">
                        <InputText
                            type="search"
                            name='globalSearch'
                            id='globalSearch'
                            style={this.props.type ? { height: 35, paddingLeft: "2.5rem", marginTop: 2 } : { display: "none" }}
                            onKeyPress={(e) => this.onFilterGlobalChange(e)}
                            onChange={(e) => this.onFilterGlobalChange(e, 'globalSearch')}
                            // value={this.state.selectFilterValue}
                            placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                            size={"35"} />
                    </span>
                </div>
                <div className='row mt-2 float-right'>
                    <div className="field-radiobutton mr-2">
                        <RadioButton inputId="city1" className="ml-2 mr-2" name="city" value="Private" onChange={() => this.onShowPrivateCompanies("Private", 'results')} checked={type === 'Private'} />
                        <label htmlFor="city1" className='mb-0'>Pitchbook</label>
                    </div>
                    <div className="field-radiobutton mr-2">
                        <RadioButton inputId="city2" className="ml-2 mr-2" name="city" value="Public" onChange={() => this.onShowPrivateCompanies("Public", 'results')} checked={type === 'Public'} />
                        <label htmlFor="city2" className='mb-0'>SEC</label>
                    </div>
                </div>
            </div>
        )
    }

    // chooseCompanyType = () => {
    //   return (
    //     <div className='row mb-2'>
    //       <div className="field-radiobutton mt-2 d-block">
    //         {/* <label className="mr-1 kanban_filter_label mr-2" style={{ marginTop: 4 }}>
    //           Parent Entities Only
    //         </label> */}
    //         <Dropdown
    //           value={this.state.selectedCountrie}
    //           appendTo={document.body}
    //           options={countriesSF}
    //           // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
    //           onChange={(e) => this.setState({ selectedCountrie: e.value })}
    //           style={{ height: 35 }}
    //         // filter={true}
    //         // disabled={item.field === "crRound" && (props.rowData["dealType"] === "BK - Refresh" || props.rowData["dealType"] === "BK - New") ? true : false}


    //         // placeholder={item.header}
    //         />
    //         {/* <label htmlFor="city1" className='mb-0'>Pitchbook</label> */}
    //       </div>
    //       <div className='mt-2 mx-2'>
    //         <Dropdown
    //           value={this.state.companyFilterType}
    //           appendTo={document.body}
    //           options={[{ label: "Starts With", value: "sw" }, { label: "Contains", value: "regexOr" }]}
    //           // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
    //           onChange={(e) => this.setState({ companyFilterType: e.value, addedMatchforCompany: true })}
    //           style={{ height: 35 }}
    //         // filter={true}
    //         // disabled={item.field === "crRound" && (props.rowData["dealType"] === "BK - Refresh" || props.rowData["dealType"] === "BK - New") ? true : false}


    //         // placeholder={item.header}
    //         />

    //       </div>
    //       <div className="p-input-icon-left mt-1">
    //         <i className="pi pi-search" />
    //         <span className="p-input-icon-right">
    //           <InputText
    //             type="search"
    //             name='companySearch'
    //             id='companySearch'
    //             style={{ height: 36, paddingLeft: "2.5rem", marginTop: 2 }}
    //             // onKeyPress={(e) => this.onFilterGlobalChange(e)}
    //             // onChange={(e) => this.setState({ companySearch: e.target.value })}
    //             // value={this.state.companySearch}
    //             placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
    //             size={"35"} />

    //         </span>

    //       </div>
    //       <Button color="primary" outline className="mx-2 my-2"
    //         onClick={() => this.onFilterdateChange(document.getElementById("companySearch").value, "CompanyName", "", this.state.companyFilterType)}>
    //         Go</Button>
    //       <Button color="primary" outline
    //         className='p-1 mx-2 my-2'
    //         // disabled={this.props.type ==="Manually Loaded Properties"}
    //         size={'sm'} onClick={() => this.onFilterdateChange("", "CompanyName", "", this.state.companyFilterType)}>
    //         Clear
    //       </Button>
    //     </div>
    //   )
    // }

    onShowPrivateCompanies = async (event) => {
        await this.setState({
            isTopFilter: this.state.isTopFilter === true ? false : true,
            city: event
            // allUsersData: []
        })
        await this.setState({
            isRedirectTo: true
        })
        localStorage.setItem("CompanyType", event)
        this.getTableFieldsOrder();
        this.getDataFromServer(this.state.filterCriteria)
    }


    toggle = async (e) => {
        let element = document.getElementById(e);
        element.classList.add("active");
    }

    showActive = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            showActive: !prevState.showActive
        }));
        this.onMultiSelectFilterChange('status', 'Active', this.state.showActive ? 'pop' : 'push')
    }

    showInactive = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            showInactive: !prevState.showInactive
        }));
        this.onMultiSelectFilterChange('status', 'Inactive', this.state.showInactive ? 'pop' : 'push')
    }

    showPending = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            showPending: !prevState.showPending
        }));
        this.onMultiSelectFilterChange('status', 'Pending', this.state.showPending ? 'pop' : 'push')
    }

    onFilterGlobalChange = (event) => {
        if (event.key === "Enter") {
            if (event.target.value.length > 0) {
                this.onFilterChange(event.target.value)
            } else {
                let filterCriteria = this.state.filterCriteria
                if (this.props.type === "Company") {
                    localStorage.setItem('SearchedCompany', "")
                }
                filterCriteria.globalSearch = {}
                delete filterCriteria.globalSearch
                this.setState({
                    filterCriteria: filterCriteria,
                    page: "1",
                    first: 0,
                });
                this.getDataFromServer(filterCriteria)
            }
        }
    }



    getHeader() {
        // let screenPermissions = this.state.screenPermissions;
        const { t, handleSubmit } = this.props;
        return (
            <div>
                <div>
                    <div className="row ml-0 mr-0" style={this.props.type === "SearchTerms" || this.props.type === "searchTermOnSite" ? { display: "none" } : null} >
                        <div className="col-12 px-0 pb-2 d-flex justify-content-between"
                            style={this.props.dontShowTitle && this.props.type !== "Property Editor" && this.props.type !== "Deal Statistics" ? { height: "20px" } : this.props.type === "Property Editor" ? { height: "0px" } : { height: "auto" }}
                        >
                            <span className="float-left">
                                <p className="d-flex">
                                    {/* {this.props.type === "Status" ? <span className="tableheadericon">
                  <FontAwesomeIcon icon={faToggleOff} /></span>
                  :
                  <span className={this.props.type !== "crMatrix" ? `tableheadericon lnr lnr-${this.props.icon}` : "tableheadericon ml-3 lnr lnr-layers"} />} */}
                                    {!this.props.dontShowTitle ?
                                        this.props.fontAwesome ?
                                            <FontAwesomeIcon
                                                className={'tableheadericon mt-2'}
                                                color="#354f6e"
                                                icon={this.props.type === "Status" ? faToggleOff : this.props.type === "Properties" ? faCity : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? faUsers : this.props.type === "Process Documents" ? faFolder : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" ? faBuilding : ""} /> :
                                            this.props.dontShowTitle ? null : <span className={`tableheadericon mt-2 lnr lnr-${this.props.icon}`} />
                                        : null}<b
                                        >{this.props.dontShowTitle ? null : <Link to={(this.props.routeTo)} style={this.props.type === "Legal Entity Suffix" || this.props.type === "Available To File - Statuses" || this.props.type === "State-UP Filing Sub Category" || this.props.type === "State-UP Filing Category" || this.props.type === "Legal Entity Abbreviation" || this.props.type === "Claim Status" || this.props.type === "Claim Reason" || this.props.type === "Property Status" || this.props.type === "Property Reason" || this.props.type === "Business Name Issue Code" ? { fontSize: "18px", marginRight: "auto", position: "initial" } : { fontSize: "18px", marginRight: "auto" }} onClick={this.closeFormModal} className="tableType" >
                                            {t(this.props.type === "crMatrix" ? "CR Matrix" : this.props.type === "ClaimsManagement" ? "Claims Management" : this.props.type === "Up Prop Confirmation" ? "UP Property – Site confirmation" : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? "Deal Mgmt" : this.props.type)}
                                        </Link>
                                        }{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
                                    </b>
                                    {this.props.type === "ClaimSummary" ? this.getBackButton() : null}
                                    {this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue" ? this.props.getHeaderforSchedule() : this.props.updatedDate ? <div className='mt-3'><b >{`(As of Date & Time(ct)) :`}</b> <span>{dateFormats.formatDate(moment(this.props.updatedDate).utcOffset('-06:00'), "MM/DD/YY hh:mm a")}</span></div> : null}
                                </p>
                            </span>
                            <span className={!this.props.dontShowTitle ? 'mx-2' : "mx-auto"} style={{ maxWidth: "630px" }} >
                                {/* <p className="topbar__center topBarImageAlignment sdfsds" style={{ color: this.state.errorcolor === "green" ? "green" : "red" }} >{this.state.errorResponse ? this.state.errorMessage : null}</p> */}
                                <p className="topbar__centerresponse topBarImageAlignment" style={{ color: "green" }}>{this.state.successResponse ? this.state.successMessage : null}</p>
                                <p className="topbar__info topBarImageAlignment" style={{ color: "blue" }}>{!this.state.errorResponse && this.state.propertyStatus && this.state.propertyStatus.length > 0 ? `The results are sorted by ${this.state.propertyStatus}` : null}</p>
                                <p className="topbar__info topBarImageAlignment" style={{ color: "blue" }} >{this.state.softWarning ? this.state.softMessage : null}</p>
                            </span>
                            <span
                            // className={!this.props.dontShowTitle ? "pt-2" : "d-none"}
                            >
                                <span className="float-right d-flex" style={this.props.type === "Activity Details Reports" ? { marginLeft: "4px" } : {}}>
                                    <span >
                                        {this.props.exportRequried &&
                                            <Button color="primary" outline
                                                className='px-2 ml-2 mb-0 mr-0'
                                                style={{ height: 37 }}
                                                // disabled={this.props.type ==="Manually Loaded Properties"}
                                                size={'sm'} onClick={this.getDataToExport}>
                                                <FontAwesomeIcon
                                                    icon='download'
                                                    data-toggle="tool-tip" title={t("Export To CSV")}
                                                    className='pl-1' size='lg' />
                                            </Button>}
                                        {this.props.exportRequried && <CSVLink
                                            data={this.state.exportData}
                                            headers={this.state.exportHeaders}
                                            filename={`${this.props.type}.csv`}
                                            className="hidden text-white p-0"
                                            ref={(r) => this.csvLinkRef = r}
                                            target="_blank" >
                                        </CSVLink>}
                                    </span>
                                    {this.props.isClearFilters && this.props.type !== "ClaimsManagement" ?
                                        < Button color="primary"
                                            outline
                                            size="sm"
                                            style={{ height: 37 }}
                                            className={this.props.type === "Users" ? "py-2 px-5 mb-0 mx-1 mr-2" : "py-2 px-5 mb-0 mx-1"}
                                            onClick={() => this.getremoveFilters()}>Clear Filters
                                        </Button> : null}
                                    {this.props.sample ? (
                                        <Button
                                            color="primary"
                                            size="sm"
                                            className="p-1 ml-auto mt-1"
                                            onClick={() => this.bulkUploadMoadal()}
                                        >
                                            <FontAwesomeIcon
                                                icon="upload"
                                                className="pl-1"
                                                size="lg"
                                                data-toggle="tool-tip"
                                                title="Bulk Upload"
                                                onClick={() => this.bulkUploadMoadal()}
                                            />
                                        </Button>
                                    ) : null}
                                    {this.props.settingsRequired ?
                                        <Button color="primary"
                                            size="sm"
                                            className="p-1 ml-4 mb-0 mt-1"
                                            onClick={() => this.openShowHideColumnsModal()}
                                        >
                                            <FontAwesomeIcon
                                                icon='cog'
                                                className='pl-1' size='lg'
                                                data-toggle="tool-tip" title="Settings"
                                                onClick={() => this.openShowHideColumnsModal()}
                                            />
                                        </Button>
                                        : null}
                                    {/* deal */}
                                    <div className={this.props.isShownnFieldType ? "d-flex mt-n4 ml-2" : ""} style={this.props.isShownnFieldType ? { display: "block" } : { display: "none" }}>
                                        <span >
                                            <div
                                                className={"mr-2 mt-2"}
                                                style={this.props.isShownnFieldType ? { display: "grid" } : { display: "none" }}
                                            >
                                                <span>View By</span>
                                                <Dropdown
                                                    value={this.state.selectedViewType}
                                                    // appendTo={document.body}
                                                    style={{ borderLeft: "1px solid lightgray", height: 34 }}
                                                    options={config.viewTypes}
                                                    onChange={(e) => this.selectedViewType(e.target.value)}
                                                />
                                            </div>
                                        </span>
                                        <div className="mb-0" >
                                            {/* <span style={{ color: "white" }}>Group By</span> */}
                                            <Button color="primary" outline
                                                className="mb-0" style={this.props.isShownnFieldType ? { cursor: "pointer", marginTop: 29, marginLeft: 5 } : { display: "none" }}
                                                onClick={() => this.openShowHideColumnsModal()}
                                            >
                                                Column Order
                                            </Button>
                                        </div>
                                    </div>

                                    {this.props.statusFilter ?
                                        this.props.type === "Users" ?
                                            <span className="m-n2" >
                                                <div className="btn-group col-xs-12" data-toggle="buttons">
                                                    <label className={`btnlabel btn btn-outline-primary  button_season${this.state.showActive ? ' active' : ''}`}
                                                        id="active">
                                                        <input type="checkbox" className="filterButton" name="season_revenue_1" id="season_revenue_1" value="Januar" onClick={e => this.showActive(e)} />Active
                                                    </label>
                                                    <label className={`btnlabel btn btn-outline-primary  button_season${this.state.showInactive ? ' active' : ''}`}
                                                    >
                                                        <input type="checkbox" className="filterButton" name="season_revenue_2" id="season_revenue_2" value="Februar" onClick={e => this.showInactive(e)} />Inactive
                                                    </label>
                                                    <label className={`btnlabel btn btn-outline-primary  button_season${this.state.showPending ? ' active' : ''}`}>
                                                        <input type="checkbox" className="filterButton" name="season_revenue_3" id="season_revenue_3" value="März" onClick={e => this.showPending(e)} />Pending
                                                    </label>
                                                </div>
                                            </span>
                                            : <span>
                                                {/* <SelectButton className='ml-2' value={this.state.selectedvalueforStatus} options={this.props.SelectedForOptions} onChange={(e) => this.selectedOption(e.value, "status")} /> */}
                                            </span>
                                        : ""}
                                    {/* Add button */}

                                    {this.props.addRequried && this.state.screenPermissions === "Edit" && !this.state.isPreviousWeek ?
                                        <Button color="primary"
                                            outline
                                            style={{ height: 37 }}
                                            // className="mb-0 mr-0 ml-2"
                                            className={(this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? "mb-0 mr-0" : "mb-0 mr-0 ml-2"}
                                            onClick={(e) => this.props.type === "Weekly Recovery Plan" ? this.addRow(this.state.allUsersData.length) : this.openFormModal('add')}
                                        >
                                            <FontAwesomeIcon
                                                icon='plus'
                                                className='Addbtn' size='sm'
                                                data-toggle="tool-tip" title={t("Add")}
                                                onClick={(e) => this.props.type === "Weekly Recovery Plan" ? this.addRow(this.state.allUsersData.length) : this.openFormModal('add')}
                                            />
                                            {this.props.type === "List View" || this.props.type === "Recovery Planner V2" ? 'Add New Task' : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? 'Add New Deal' : this.props.type === "crMatrix" ? 'Add New CR Matrix ' : 'Add'}
                                            {/* {this.props.type === "Seller View" ? 'Add New Seller' : 'Add'} */}
                                        </Button>

                                        : null}
                                    {(this.props.type === "Sellers" || this.props.type === "Deal Mgmt") && this.state.screenPermissions === "Edit" ? <Button color="primary"
                                        outline
                                        style={this.props.type === "List View" && this.state.userRole ? { display: "block", padding: "6px 25px", marginTop: "2px" } :
                                            this.props.type === "Users" ? { padding: "5px 25px" } :
                                                this.props.type !== "List View" ? { padding: "6px 25px" } :
                                                    { display: "none" }}
                                        className="mb-0 ml-1 mr-0"
                                        onClick={() => this.ValidateDealSizingRecords()}
                                    >
                                        Deal Sizing
                                    </Button> : null}
                                </span>

                                <span className="float-right pl-3">
                                    <>
                                        <div
                                            className={`${this.props.type === "Users" ? `p-inputgroup searchField mt-1 ` : `p-inputgroup searchField`}`}
                                        >
                                            {this.props.globalSearch ? <span className="p-input-icon-left">
                                                <i className="pi pi-search" />
                                                <span className="p-input-icon-right">
                                                    <InputText
                                                        type="search"
                                                        name='globalSearch'
                                                        id='globalSearch'
                                                        style={this.props.type ? { height: 35, paddingLeft: "2.5rem", marginTop: 2 } : { display: "none" }}
                                                        onKeyPress={(e) => this.onFilterGlobalChange(e)}
                                                        onChange={(e) => this.onFilterGlobalChange(e, 'globalSearch')}
                                                        // value={this.state.selectFilterValue}
                                                        placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                                                        size={(this.props.type === "Company" || this.props.type === "Investor" || this.props.type === "List View") ? "25" : "20"} />

                                                </span>
                                                {/* {this.props.type === "Investor" || this.props.type === "Company" ?
                        <Button color="primary"
                          // size="sm"
                          // className='p-1 mt-1'
                          outlines
                          className="mb-0 ml-1 mr-0"
                          onClick={() => this.onFilterChange(this.state.selectFilterValue)}>Search
                        </Button>
                        : null} */}
                                            </span> : null}
                                            {this.props.isEdit && !this.state.isPreviousWeek && this.props.type !== "ClaimsManagement" || this.props.type === "Recovery Planner V2" ? this.getSubmitBtnforEditedTable() : null}

                                        </div>
                                    </>
                                </span>
                            </span>
                        </div>
                    </div>
                    {this.props.type === "List View" ? this.gettaskFilters(handleSubmit) : null}
                    {/* {this.props.type === "Claims" ? this.getClaimField() : null} */}
                    {/* {this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" ? this.getFilerField() : null} */}
                    {this.state.uppropConfirmationMessage ? <div className='my-5' ><p><h5 style={{ color: "green" }}>{"Results:"}</h5></p><p><h5 style={{ color: "green" }}>{` Out of ${this.state.upconfirmProperties.totalPropertyCount} properties, ${this.state.upconfirmProperties.updated} were updated as Confirmed Onsite.`}</h5></p>
                        {/* <p><h5 style={{ color: "green" }}>{`${this.state.upconfirmProperties.alreadyValidated} Properties were already Validated/Confirmed  in the OPRA2.`}</h5></p> */}
                        <p><h5 style={{ color: "green" }}>{`${this.state.upconfirmProperties.notUpdated} Properties were NOT found in the OPRA, below are those properties:`}</h5></p><p></p><p><h5 style={{ color: "green" }}>{"Please load these properties in OPRA manually."}</h5></p></div> : null}
                    {this.state.uppropPropertyIds && this.state.uppropPropertyIds.length > 0 ?
                        <Row className='ml-0 mr-0 d-flex justify-content-end mb-2' style={{ width: "863px" }} >
                            <label>Copy All</label>
                            <FontAwesomeIcon icon={faCopy}
                                className='ml-2'
                                color='grey'
                                data-toggle="tool-tip"
                                // onClick={() => { navigator.clipboard.writeText(this.state.exhibit1String) }}
                                onClick={() => { this.copyToClipboardProperties() }}
                                title={"Copy All"}
                                style={{ width: 21, marginTop: "3px" }}
                            />
                        </Row>
                        : null}
                    {/* {this.state.uppropPropertyIds ?
            // this.state.uppropPropertyIds.map(element => {
            //   // return <h4 style={{ color: "green" }}>{element}</h4>
            // })
            //  return (
            <div className="App">
              <table>
                <tr>
                  <th>Properties Inserted In Opra</th>
                  <th>Property Id</th>
                  <th>Copy</th>
                </tr>
                {this.state.uppropPropertyIds.map((element, index) => {
                  return (
                    <tr key={element}>
                      <td>{<Checkbox
                        label='check'
                        type='checkbox'
                        // component={renderCheckBoxField}
                        name={'validated'}
                        className=" ml-2"
                        checked={this.state[`element${index}`]}
                        onChange={(e) => this.setState({ [`element${index}`]: this.state[`element${index}`] ? false : true })}
                      // onChange={(e) => this.setState(e, "isexcludeValidated")}
                      />}</td>
                      <td>{element}</td>
                      <td>{<FontAwesomeIcon icon={faCopy}
                        className='ml-2'
                        color='grey'
                        data-toggle="tool-tip"
                        // onClick={() => { navigator.clipboard.writeText(this.state.exhibit1String) }}
                        onClick={() => { this.copyToClipboardIndividual(element) }}
                        title={"Copy"}
                        style={{ width: 18 }}
                      />}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
            // );
            : null} */}
                    {this.state.uppropPropertyIds && this.state.uppropPropertyIds.length > 0 ?
                        <div className="disabledSelection">
                            <DataTable value={this.state.uppropPropertyIds} style={{ width: "873px" }}
                                selection={this.state.selectedProperyId} onSelectionChange={e => this.setState({ selectedProperyId: e.value })}

                            >
                                <Column header="Property Inserted In OPRA" selectionMode="multiple" style={{ textAlign: 'center', width: "109px" }} />

                                <Column header="Property Id" body={this.PropertyIdDisplay} style={{ textAlign: 'center' }} />
                                <Column header="Copy" body={this.copyTemplate} style={{ textAlign: 'center', width: "109px" }} />

                            </DataTable>
                        </div> : null}


                    {this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "ClaimsManagement" || this.props.type === "Deal Sizing Report For Companies" ? this.getFilerField() : null}
                    {this.props.type === "ClaimSummary" ? this.getClaimSummaryField() : null}

                    {
                        this.props.type === "Deal Sizing Report For Private Equity Portfolio" || this.props.type === "Weekly Recovery Plan" || this.props.type === "Recovery Planner V2" || this.props.type === "Weekly Recovery Report" || this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? <div className='row' style={this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report" || this.props.type === "Recovery Planner V2" ? { justifyContent: "space-between", height: 70, marginRight: 10 } : { justifyContent: "space-between" }}>

                            {this.props.type !== "Deal Sizing Report For Private Equity Portfolio" ?
                                <div style={{ paddingLeft: 16 }}>
                                    <span className='mr-2'>
                                        <FontAwesomeIcon
                                            color={(this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report") && this.state.disableIcon ? 'lightgrey' : 'blue'}
                                            icon={faArrowCircleLeft}
                                            style={(this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report") && this.state.disableIcon ? { pointerEvents: "none" } : { cursor: "pointer" }}
                                            data-toggle="tool-tip"
                                            onClick={() => this.getPreviousMonday(this.state.startDateReports)}
                                        />
                                    </span>
                                    <u style={{ fontSize: 14 }}>{this.state.startDateReports} - {this.state.lastDateReports}</u>
                                    <span className='ml-2'>
                                        <FontAwesomeIcon
                                            color={(this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report") && this.state.disableIcon ? 'lightgrey' : 'blue'}
                                            // color={"blue"}
                                            icon={faArrowCircleRight}
                                            style={(this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Plan") && this.state.disableIcon ? { pointerEvents: "none" } : { cursor: "pointer" }}
                                            data-toggle="tool-tip"
                                            onClick={() => this.getNextMondayasync(this.state.startDateReports)}
                                        />
                                    </span>
                                </div>
                                : null}
                            {!this.state.isOpenFormModal && this.props.type === "Utilization - US Team" ?
                                <div className='topbar__center '>
                                    {this.getUtilizationFilters()}
                                </div>
                                : null}
                            {this.props.type !== "Deal Sizing Report For Private Equity Portfolio" && this.props.type !== "Weekly Recovery Plan" && this.props.type !== "Recovery Planner V2" && this.props.type !== "Weekly Recovery Report" ?
                                <div className='' style={{ display: "grid" }}>
                                    <span className="pr-3" style={{ paddingRight: "25", fontSize: 14 }}>(Time Spent in <b>hours</b>) Company</span>
                                    <p style={{ textAlign: "center", fontSize: 14 }}>  <span style={{ color: "brown" }}>BK</span>  <span>CR</span></p>
                                </div>
                                : null}
                            {this.props.type === "Weekly Recovery Report" ?
                                <div style={{ display: "contents" }}>
                                    <div
                                        className="p-inputgroup searchField ml-auto" style={{ display: "grid", width: 200 }}
                                    >
                                        <span>Sort By</span>
                                        <MultiSelect
                                            className={"hideheader"}
                                            style={{ width: "100%", height: 32 }}
                                            appendTo={document.body}
                                            value={this.state.propertyStatus}
                                            maxSelectedLabels={1}
                                            disabled={this.state.allUsersData.length === 0 ? true : false}
                                            options={this.state.FilterSortOptions}
                                            onChange={(e) => this.onpropertiessortBy(e.value)} />
                                    </div>
                                    <div className="mb-0 ml-2" >
                                        {/* <span style={{ color: "white" }}>Group By</span> */}
                                        <Button color="primary" outline
                                            className="mb-0" style={{ cursor: "pointer", marginTop: 21 }}
                                            onClick={() => this.submitSort()}
                                        >
                                            Go
                                        </Button>
                                    </div></div> : this.props.type === "Recovery Planner V2" ?
                                    <div
                                        className="p-inputgroup searchField ml-auto" style={{ display: "grid", width: 200 }}
                                    >
                                        <span>Department : </span>
                                        <MultiSelect
                                            className={"hideheader"}
                                            style={{ width: "100%", height: 32 }}
                                            appendTo={document.body}
                                            options={this.props.taskDepartmentItems}
                                            value={this.state.selectedDepartment}
                                            onChange={(e) => this.onChangeDepartment(e, 'department', 'eq')} />
                                    </div> : null}
                        </div> : null
                    }
                    {
                        this.props.statusFilter &&
                            this.props.type !== "Users" ?
                            <div className='d-flex'>
                                <SelectButton className='mb-1' value={this.state.selectedvalueforStatus} options={this.props.SelectedForOptions} onChange={(e) => this.selectedOptionData(e.value, "status")} />

                            </div> : null}

                </div >

            </div >

        );
    }

    getCardHeader() {
        return (
            // <CardHeader className='cardHeader'>
            <div className="row m-0">
                {/* table name */}
                <div className='col-3 pl-0 pt-2'>
                    <h4><b>{this.props.type}{this.props.type === 'Activiti' ? 'es' : ''}</b></h4>
                </div>
                <span className='col-9 text-right'>
                    <Row>
                        {/* Export to csv */}
                        {/* <Button color="primary"
                    className='p-1 ml-auto'
                    size={'sm'} onClick={this.getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title="Export To CSV"
                      className='pl-1' size='lg' />
                  </Button>
                  <CSVLink
                    data={this.state.exportData}
                    filename={`${this.props.type}.csv`}
                    className="hidden text-white"
                    ref={(r) => this.csvLinkRef = r}
                    target="_blank" >
                  </CSVLink> */}
                        {/* {this.props.sample ?
              <Button color="primary"
                size="sm"
                className="p-1"
                onClick={this.bulkUploadMoadal}>
                <FontAwesomeIcon
                  icon='upload'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Bulk Upload"
                  onClick={this.bulkUploadMoadal} />
              </Button>
              : null} */}
                        {/* Add Settings Button */}
                        {this.props.settingsRequired ?
                            <Button color="primary"
                                size="sm"
                                className="p-1 ml-auto mt-1"
                                onClick={() => this.openShowHideColumnsModal()}
                            >
                                <FontAwesomeIcon
                                    icon='cog'
                                    className='pl-1' size='lg'
                                    data-toggle="tool-tip" title="Settings"
                                    onClick={() => this.openShowHideColumnsModal()}
                                />
                            </Button>
                            : null}
                        {/* Add button */}
                        {this.props.addRequried ?
                            <Button color="primary"
                                size="sm"
                                className="p-1 ml-auto"
                                onClick={() => this.openFormModal('add')}>
                                <FontAwesomeIcon
                                    icon='plus'
                                    className='pl-1' size='lg'
                                    data-toggle="tool-tip" title="Add"
                                    onClick={() => this.openFormModal('add')}
                                />
                            </Button>
                            : null}
                        {/* priority  in Issues*/}
                        <Button
                            outline
                            color="secondary"
                            size="sm"
                            // className="p-1 ml-auto"
                            className={this.props.addRequried ? "p-1" : 'p-1 ml-auto'}
                            onClick={() => this.getDataFromServer(this.state.filterCriteria, 'refresh')}>
                            <FontAwesomeIcon
                                icon='sync-alt'
                                size='lg'
                                data-toggle="tool-tip" title="Refresh"
                                color={config.templateColor}
                                className='refreshIcon pl-1' />
                        </Button>
                    </Row>
                </span>

            </div>
            // </CardHeader>
        )
    }

    cancelUserPwdResetModal = async () => {
        await this.setState({
            openUserPasswordResetModal: false,
            actions: ''
        })
    }

    rowEditorValidator(rowData) {

    }

    openAdUnitsCollapse(rowData) {
        let body = rowData.data
        if (this.props.type === "ClaimsManagement") {
            return null
        } else {
            fetchMethodRequest('POST', 'NsScoredProperties/dealSizingstats', body)
                .then(async (response) => {
                    if (response && response.stats) {
                        let allUsersData = this.state.allUsersData
                        allUsersData[rowData.data.Sno - 1].Stats = response.stats
                        await this.setState({
                            allUsersData: allUsersData,
                            SnoData: rowData.data.Sno
                        })
                    } else if (response && response.errorMessage) {
                        this.getErrorMessage(response.errorMessage, "", "error")
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }

    getFormattedCurrencyValue = () => {
        let dollarUS = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: config.currency,
        });
        return dollarUS
    }


    //getRow Expand collapse for Deal Sizing in Sellers
    rowExpansionTemplate = (data) => {
        let dollarUS = this.getFormattedCurrencyValue()
        return (
            data && data.Stats && data.Stats.Green ?
                <div style={{ padding: '1em 1em 1em 1em' }}>
                    <table className='' style={{ marginLeft: "5%", width: 800 }}>
                        <thead>
                            <tr >
                                <th style={{ color: "green" }}>Green ({data.Stats.Green.count})</th>
                                <th style={{ color: "blue" }}>Blue({data.Stats.Blue.count})</th>
                                <th style={{ color: "#ff9700" }}>Yellow({data.Stats.Yellow.count})</th>
                                <th style={{ color: "brown" }}>Brown({data.Stats.Brown.count})</th>
                                <th style={{ color: "black" }}>Black({data.Stats.Black.count})</th>
                                <th style={{ color: "#008080" }}>Teal({data.Stats.Teal.count})</th>
                            </tr></thead><tbody>
                            <tr >
                                <td>{dollarUS.format(Math.round(data.Stats.Green.total))}</td>
                                <td>{dollarUS.format(Math.round(data.Stats.Blue.total))}</td>
                                <td>{dollarUS.format(Math.round(data.Stats.Yellow.total))}</td>
                                <td>{dollarUS.format(Math.round(data.Stats.Brown.total))}</td>
                                <td>{dollarUS.format(Math.round(data.Stats.Black.total))}</td>
                                <td>{dollarUS.format(Math.round(data.Stats.Teal.total))}</td>
                            </tr></tbody>
                    </table>
                </div>
                : this.props.type === "ClaimsManagement" ?
                    <>
                        <div style={{ padding: '0px' }}>
                            <div className="child_Companie">
                                <DataTable value={data.GrandChildCompanies}
                                    scrollable
                                    scrollHeight="200px"
                                    responsiveLayout="scroll"
                                    onSelectionChange={(e) => this.onSelectRowsUpdate(e)}
                                    selection={this.state.selectedRows}
                                    selectionMode={"multiple"}
                                    metaKeySelection={false}
                                    expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                    rowExpansionTemplate={this.rowExpansionTemplate}
                                    dataKey={!this.state.expandAll ? "" : "DealID"}
                                >

                                    <Column textAlign="left" field="" header="" selectionMode="multiple" bodyStyle={{ width: 60 }}></Column>
                                    {/* <Column field="AssetType" bodyStyle={{ width: 150, }}></Column>
                <Column field="CompanyID" bodyStyle={{ width: 150 }}></Column>
                <Column field="EstateName" bodyStyle={{ width: 150 }}></Column>
                <Column field="PropertyID" bodyStyle={{ width: 150 }}></Column>
                <Column field="SourceState" bodyStyle={{ width: 150 }}></Column>
                <Column field="BusinessName" bodyStyle={{ width: 150 }}></Column>
                <Column field="OppClaimID" bodyStyle={{ width: 150 }}></Column>
                <Column field="StateClaimID" bodyStyle={{ width: 150 }}></Column>
                <Column field="Payments" fieldType="PaymentArray" body={this.changeFieldValues} bodyStyle={{ width: 150 }}></Column>
                <Column field="PropertyStatus" bodyStyle={{ width: 150 }}></Column>
                <Column field="PropertyReason" bodyStyle={{ width: 150 }}></Column>
                <Column field="PropertyNote" body={this.changeFieldValues} bodyStyle={{ width: 150 }}></Column>
                <Column field="ClaimAmount" bodyStyle={{ width: 150, "textAlign": "right" }}></Column>
                <Column field="FileFor" bodyStyle={{ width: 150, "textAlign": "right" }}></Column>
                <Column field="" bodyStyle={{ width: 150, "textAlign": "right" }}></Column>
                <Column field="" bodyStyle={{ width: 150, "textAlign": "right" }}></Column>
                <Column field="" bodyStyle={{ width: 150, "textAlign": "right" }}></Column>
                <Column field="" bodyStyle={{ width: 150, "textAlign": "right" }}></Column>
                <Column field="" bodyStyle={{ width: 150, "textAlign": "right" }}></Column>
                <Column field="" bodyStyle={{ width: 50, "textAlign": "right" }}></Column> */}
                                    {this.state.dynamicColumns}

                                </DataTable>
                            </div>
                        </div >
                    </ >
                    :
                    <div className="p-md-10 ml-4" style={{ fontWeight: 'bold' }}>No Records are found</div>

        );
    }

    handleFormattingData(allUsersData) {
        for (var record of allUsersData) {
            if (record.analysts && typeof record.analysts === "object" && record.analysts[0] === undefined) {
                let analystArr = []
                analystArr.push(record.analysts)
                record.analysts = analystArr
            } if (record.validationTarget) {
                record.validationTarget = parseFloat(record.validationTarget)
            }
            if (record.fileTarget) {
                record.fileTarget = parseFloat(record.fileTarget)
            } if (record.transitionTarget) {
                record.transitionTarget = parseFloat(record.transitionTarget)
            } if (record.searchTarget) {
                record.searchTarget = parseFloat(record.searchTarget)
            } if (record.forecastSearchHours) {
                record.forecastSearchHours = parseFloat(record.forecastSearchHours)
            } if (record.forecastValidationHours) {
                record.forecastValidationHours = parseFloat(record.forecastValidationHours)
            } if (record.forecastFileHours) {
                record.forecastFileHours = parseFloat(record.forecastFileHours)
            } if (record.forecastTransitionHours) {
                record.forecastTransitionHours = parseFloat(record.forecastTransitionHours)
            }
        }
        return allUsersData;
    }

    handleCheckValidations = async () => {
        var allUsersData = this.state.allUsersData
        if (this.props.type === "Weekly Recovery Plan") {
            allUsersData = await this.handleFormattingData(allUsersData)
            //for changing dataType
            if (window.location.pathname === "/nonSiteDiscoveryPlan") {
                var newArray = allUsersData.filter(function (el) {
                    return el.dealType === "" ||
                        el.company === "" ||
                        // (el.dealType === "CR - New" || el.dealType === "CR - Refresh") && el.crRound === "" ||
                        el.analysts === [] ||
                        el.validationTarget === "" ||
                        // el.searchTarget === "" ||
                        el.fileTarget === "" ||
                        el.transitionTarget === "" ||
                        // el.forecastSearchHours === "" ||
                        el.forecastTransitionHours === "" ||
                        el.forecastValidationHours === "" ||
                        el.forecastFileHours === ""
                });
                var targetLimit = allUsersData.filter(function (el) {
                    return el.validationTarget > 100 ||
                        // el.searchTarget > 100 ||
                        el.fileTarget > 100 ||
                        el.transitionTarget > 100
                });
            } else {
                var newArray = allUsersData.filter(function (el) {
                    return el.dealType === "" ||
                        el.company === "" ||
                        el.analysts === [] ||
                        // (el.dealType === "CR - New" || el.dealType === "CR - Refresh") && el.crRound === "" ||
                        // el.validationTarget === "" ||
                        el.searchTarget === "" ||
                        // el.fileTarget === "" ||
                        // el.transitionTarget === "" ||
                        el.forecastSearchHours === ""
                    // el.forecastTransitionHours === "" ||
                    // el.forecastValidationHours === "" ||
                    // el.forecastFileHours === ""
                });
                var targetLimit = allUsersData.filter(function (el) {
                    return el.searchTarget > 100
                    // el.validationTarget > 100 ||

                    // el.fileTarget > 100 ||
                    // el.transitionTarget > 100
                });
            }
            var usersDataArr = [];
            allUsersData.filter(function (el) {
                if (usersDataArr && usersDataArr.length !== 0) {
                    let index = usersDataArr.findIndex(filterarr => filterarr === el.analysts[0].userName)
                    if (index === -1) {
                        usersDataArr.push(el.analysts[0].userName)
                    };
                } else {
                    usersDataArr.push(el.analysts[0].userName)
                }
            })
            var totalUserHours = {}
            for (var user of usersDataArr) {
                var userforecasrHours;
                allUsersData.filter(function (el) {
                    if (user === el.analysts[0].userName) {
                        userforecasrHours = el.forecastSearchHours + el.forecastValidationHours + el.forecastFileHours + el.forecastTransitionHours
                        if (totalUserHours[user]) {
                            totalUserHours[user] = totalUserHours[user] + userforecasrHours
                        } else {
                            totalUserHours[user] = userforecasrHours
                        }
                    }
                });
            }
            let userNameArr = [];
            for (var obj in totalUserHours) {
                if (totalUserHours[obj] > 40 || totalUserHours[obj] < 32)
                    userNameArr.push(obj + "  ")
                // userNameArr.push(" ")
            }
            if (newArray && newArray.length === 0 && targetLimit && targetLimit.length === 0) {
                if (userNameArr && userNameArr.length > 0) {
                    this.setState({
                        softMessage: `${userNameArr} has total forecasted hours of less than 32 hrs Or  more than 40 hrs`,
                        softWarning: true
                    })
                }

                this.sendmultiRecords(allUsersData)
            } else {
                await this.setState({
                    isSubmitted: true
                })
            }
        }
        else if (this.props.type === "Recovery Planner V2") {
            allUsersData = allUsersData.filter(function (el) {
                return el.edited === "yes"
            });
            for (var obj of allUsersData) {
                obj.assignToId = typeof obj.assignTo === "string" ? obj.assignToId : obj.assignTo.userId
                obj.assignTo = typeof obj.assignTo === "string" ? obj.assignTo : obj.assignTo.userName
            }
            this.sendmultiRecords(allUsersData)
        }
        else if (this.props.type === "ClaimsManagement") {
            var newArray = allUsersData.filter(function (el) {
                return (el.ClaimStatus !== null && el.ClaimReason === null) || (el.ClaimStatus === null && el.ClaimReason !== null)
                // (el.dealType === "CR - New" || el.dealType === "CR - Refresh") && el.crRound === "" ||
            });
            if (newArray && newArray.length === 0) {
                let OverAllCLaims = {}
                OverAllCLaims.claims = []
                for (let allUser of allUsersData) {
                    if (allUser.edited === "yes") {
                        let Claims = []
                        allUser.GrandChildCompanies.forEach(obj => {
                            Claims.push({ _id: obj._id })
                        })
                        OverAllCLaims.claims.push({ claims: Claims, claimStatus: allUser.ClaimStatus, NextActionDate: allUser.NextActionDate, ClaimNotes: allUser.ClaimNotes, ClaimReason: allUser.ClaimReason, Analyst: allUser.Analyst, StateClaimID: allUser.StateClaimID, Payments: allUser.Payments })
                    }
                }
                this.sendmultiRecords(OverAllCLaims)
                await this.setState({
                    isSubmitted: false
                })

            } else {

                await this.setState({
                    isSubmitted: true
                })
            }

        }
        else {
            var datatoFormat = allUsersData
            if (this.props.isOnlyEditted) {
                allUsersData = allUsersData.filter(function (el) {
                    return el.edited === "yes"
                });

            }
            allUsersData = this.props.handleFieldValues ? await this.props.handleFieldValues(allUsersData) : allUsersData
            this.sendmultiRecords(allUsersData)
        }
    }
    sendStateMultiRecords = async () => {
        let allUserData = this.state.allUsersData
        let OverAllStates = {}
        OverAllStates.models = []
        for (let allUser of allUserData) {
            if (allUser.edited === "yes") {
                if (allUser.effectiveFromDate) {
                    allUser.effectiveFromDate = new Date(allUser.effectiveFromDate.setTime(allUser.effectiveFromDate.getTime() - (allUser.effectiveFromDate.getTimezoneOffset() * 60 * 1000)))
                }
                OverAllStates.models.push(allUser)
            }
        }
        let userBody, Method
        userBody = OverAllStates
        Method = "PUT"
        this.setState({
            isLoading: true, reasonByRow: false

        })
        return fetchMethodRequest(Method, `${this.props.multiApi}`, userBody)
            .then(async (response) => {
                if (response && response.respMessage && response.respMessage) {
                    this.getErrorMessage(response.respMessage, "", "success")
                } else if (response && response.errorMessage) {
                    this.getErrorMessage(response.errorMessage, "", "error")
                }

                // await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
                this.CancelMultiLineStates("isCancel")
                await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
                this.CancelMultiLineStates("isCancel")
                this.setState({
                    isLoading: false, reasonByRow: false

                })
            }).catch((err) => {
                return err;
            });
    }

    sendmultiRecords = async (allUsersData) => {
        let userBody, multiMethod
        if (this.props.type === "ClaimsManagement") {
            userBody = allUsersData
            multiMethod = "PUT"
        } else {
            multiMethod = this.props.multiMethod
            let multiApiKey = this.props.multiApiKey
            userBody = {}
            userBody[multiApiKey] = allUsersData
        }
        this.setState({
            isLoading: true
        })

        return fetchMethodRequest(multiMethod, `${this.props.multiApi}`, userBody)
            .then(async (response) => {
                if (response && response.respMessage && response.respMessage) {
                    this.getErrorMessage(response.respMessage, "", "success")
                } else if (response && response.errorMessage) {
                    this.getErrorMessage(response.errorMessage, "", "error")
                }
                await this.getDataFromServer(this.state.filterCriteria)
                this.CancelMultiLine()

                this.setState({
                    isLoading: false, reasonByRow: false

                })
            }).catch((err) => {
                return err;
            });

    }

    CancelMultiLine = (type) => {
        let k = document.getElementsByClassName("p-row-editor-cancel-icon").length
        let element = document.getElementsByClassName("p-row-editor-cancel-icon")
        if (k > 0) {
            for (let i = 0; i <= k; i++) {
                if (element[i]) {
                    element[i].click()
                }
                if (element[0]) {
                    element[0].click()
                }
            }
        }
        localStorage.removeItem("ISEdited")
        if (type) {
            this.getDataFromServer(this.state.filterCriteria)
        }
    }
    CancelMultiLineStates = (type) => {
        let k = document.getElementsByClassName("p-row-editor-cancel-icon").length
        let element = document.getElementsByClassName("p-row-editor-cancel-icon")
        if (k > 0) {
            for (let i = 0; i <= k; i++) {
                if (element[i]) {
                    element[i].click()
                }
                if (element[0]) {
                    element[0].click()
                }
            }
        }
        if (type) {
            this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
        }
    }


    getisLoading = async () => {
        await this.setState({
            isLoading: true,
            progress: 0
        })
        await this.handlePercentage("1")
    }

    getSubmitBtnforEditedTable() {
        return (
            <div>

                <div className='topBarImageAlignment'>
                    <Button color="primary" outline
                        className='p-1 mx-1 mb-0'
                        size={'sm'}
                        style={{ height: 37 }}
                        onClick={() => this.handleCheckValidations()}>
                        <FontAwesomeIcon
                            icon={faSave}
                            data-toggle="tool-tip" title={"Save"}
                            className='pl-1' size='lg' />Save
                    </Button>
                    <Button color="danger" outline
                        className='p-1 mx-1 mb-0'
                        size={'sm'}
                        style={{ height: 37 }}
                        onClick={() => this.CancelMultiLine('isCancel')}>
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            data-toggle="tool-tip" title={"Cancel"}
                            className='pl-1' size='lg' />Cancel
                    </Button>
                </div></div >
        )

    }
    addRowForStateAttributes() {
        let data

        data = {
            "category": this.state.stateCategory ? this.state.stateCategory : "",
            "subCategory": this.state.stateSubCategory ? this.state.stateSubCategory : "",
            activeIndicator: "",
            note: "",
            "effectiveFromDate": "",
            state: this.state.UpState,
            dealType: this.state.stateDealType

        }

        let allUsersData = this.state.allUsersData
        allUsersData.push(data)
        this.setState({
            allUsersData: JSON.parse(JSON.stringify(allUsersData)),
            addRow: true,
            isSubmitted: false,
        })
        let k = document.getElementsByClassName("p-row-editor-init-icon").length
        setTimeout(() => {
            if (document.getElementsByClassName("p-row-editor-init-icon")[k])
                document.getElementsByClassName("p-row-editor-init-icon")[k].click();
        }, 30)
    }

    addRow() {
        let data
        if (window.location.pathname === "/nonSiteDiscoveryPlan") {
            data = {
                "dealType": "",
                "company": "",
                "analysts": [],
                "validationTarget": "",
                // "reviewTarget": "",
                "fileTarget": "",
                "transitionTarget": "",
                "searchTarget": 0,
                "crRound": "",
                "forecastSearchHours": 0,
                "forecastValidationHours": "",
                // "forecastReviewHours": "",
                "forecastFileHours": "",
                "forecastTransitionHours": "",
                // "transitionHours": "",
                "comments": "",
                "startDate": moment(this.state.startDateReports).format(config.dbOnlyDateFormat),
                "stopDate": moment(this.state.lastDateReports).format(config.dbOnlyDateFormat),
            }
        } else {
            data = {
                "dealType": "",
                "company": "",
                "analysts": [],
                "crRound": "",
                "validationTarget": 0,
                // "reviewTarget": "",
                "fileTarget": 0,
                "transitionTarget": 0,
                "searchTarget": "",
                "forecastSearchHours": "",
                "forecastValidationHours": 0,
                // "forecastReviewHours": "",
                "forecastFileHours": 0,
                "forecastTransitionHours": 0,
                "transitionHours": 0,
                "comments": "",
                "startDate": moment(this.state.startDateReports).format(config.dbOnlyDateFormat),
                "stopDate": moment(this.state.lastDateReports).format(config.dbOnlyDateFormat),
            }
        }
        let allUsersData = this.state.allUsersData
        allUsersData.push(data)
        this.setState({
            allUsersData: allUsersData,
            addRow: true,
            isSubmitted: false,
        })
        let k = document.getElementsByClassName("p-row-editor-init-icon").length
        setTimeout(() => {
            if (document.getElementsByClassName("p-row-editor-init-icon")[k])
                document.getElementsByClassName("p-row-editor-init-icon")[k].click();
        }, 30)
    }

    //when the row is saved
    onRowEditSave = async (e) => {
        if (this.rowEditorValidator(e.data)) {
        } else {
        }
    }

    onRowEditCancel = async (e) => {
        let allUsersData = this.state.allUsersData
        if (!e.data._id || e.data._id === undefined) {
            allUsersData.splice(e.index, 1)
            let k = document.getElementsByClassName("p-row-editor-init-icon").length
            setTimeout(() => {
                if (document.getElementsByClassName("p-row-editor-init-icon")[k]) {
                    document.getElementsByClassName("p-row-editor-init-icon")[k].click();
                }
            }, 100)
        } else if (e.data._id && e.data._id.length > 0) {
            let responsesaveData = JSON.parse(JSON.stringify(this.state.responsesaveData))
            allUsersData[e.index] = responsesaveData[e.index]
        }
        await this.setState({
            allUsersData: allUsersData
        })
    }

    getValues(props) {
        let data = []
        if (props && props.rowData && props.rowData["analysts"]) {
            for (var analyst of props.rowData["analysts"]) {
                data.push(analyst)
            }
        }
        return data;
    }
    setClaimReason = async (value, allUsersData, index) => {
        let ReasonsArray = [];
        let filterCriteria = {};
        filterCriteria['criteria'] = [{ key: 'Status', value: value, type: 'eq', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
        return fetchMethodRequest('GET', `${apiCalls.ClaimReasons}?filter=${JSON.stringify(filterCriteria)}`)
            .then(async (response) => {
                if (response && response["claimReasons"].length > 0) {
                    let claimReasons = response["claimReasons"]
                    for (let reason of claimReasons) {
                        ReasonsArray.push({ label: reason.reason, value: reason.reason })
                    }
                    allUsersData[index]["filterClaimReason"] = ReasonsArray
                    for (let allUserData of allUsersData) {
                        allUserData.filterClaimReason = allUserData.filterClaimReason ? allUserData.filterClaimReason : allUserData.claimReasonsRow
                    }
                    await this.setState({ filterClaimReason: ReasonsArray, reasonByRow: true, allUsersData: allUsersData })
                } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
                    await this.setState({
                        sessionWarning: true,

                    })
                }
            }).catch((err) => {
                return err;
            })
    }

    onEditorValueChange = async (props, value, item) => {

        if (props) {
            let allUsersData = [...props.value];
            if (props.field === "company" && typeof value === "object") {
                value = value.estateName
            }
            allUsersData[props.rowIndex]["edited"] = "yes"
            localStorage.setItem("ISEdited", "true")
            if (item && item.field && item.field !== "comments" && value) {
                if (item.keyfilter === "pint") {
                    allUsersData[props.rowIndex][props.field] = parseInt(value ? value : 0)
                } else {
                    allUsersData[props.rowIndex][props.field] = value ? value : ""
                }
            } else {
                if (props.field === "dealType" && (value === "BK - Refresh" || value === "BK - New")) {
                    allUsersData[props.rowIndex]["crRound"] = ""
                }
                allUsersData[props.rowIndex][props.field] = value ? value : ""
            }
            if (value && !props.field.includes("Target")) {
                await this.setState({ allUsersData: allUsersData, showerrorfield: false });
            } else if (value && props.field.includes("Target") && value <= "100") {
                await this.setState({ allUsersData: allUsersData, showerrorfield: false });
            } else {
                await this.setState({
                    validfield: item ? item.field : "",
                    showerrorfield: true
                })
            }
            if (props.field === "ClaimStatus" && this.props.type === "ClaimsManagement") {
                this.setClaimReason(value, this.state.allUsersData, props.rowIndex)
                // await this.setState({ filteredAnalysts: [{ label: "yes", value: "no" }] })
            }
        }
    }

    textEditor(options, item) {
        return (
            <div >
                <div>
                    <InputText
                        type="text"
                        name={options.rowData[item.field]}
                        value={options.rowData[item.field]}
                        onChange={(e) => this.onEditorValueChange(options, e.target.value, item)}
                        // placeholder={item.header}
                        keyfilter={item.keyfilter ? item.keyfilter : null}
                    // validateOnly={true}
                    />
                </div>
                {
                    ((this.state.isSubmitted && item.field !== "comments" && options.rowData[item.field] === "") || (this.state.showerrorfield && item.field !== "comments" && item.keyfilter && options.rowData[item.field] > 100)) ?
                        <div className="mb-1" style={{ height: '10px' }}>
                            {<span className="form__form-group-error">{item.keyfilter && item.field.includes("Target") ? "Pls enter 0 to 100" : "Pls fill the field"}</span>}
                        </div>
                        : null
                }
            </div >
        )
    }

    textAreaEditor(options, item) {
        return (
            <div >
                <div>
                    <InputTextarea
                        type="textArea"
                        name={options.rowData[item.field]}
                        value={options.rowData[item.field]}
                        onChange={(e) => this.onEditorValueChange(options, e.target.value, item)}
                        // placeholder={item.header}
                        keyfilter={item.keyfilter ? item.keyfilter : null}
                    // validateOnly={true}
                    />
                </div>
                {
                    ((this.state.isSubmitted && item.field !== "comments" && options.rowData[item.field] === "") || (this.state.showerrorfield && item.field !== "comments" && item.keyfilter && options.rowData[item.field] > 100)) ?
                        <div className="mb-1" style={{ height: '10px' }}>
                            {<span className="form__form-group-error">{item.keyfilter && item.field.includes("Target") ? "Pls enter 0 to 100" : "Pls fill the field"}</span>}
                        </div>
                        : null
                }
            </div >
        )
    }

    multiSelectEditor(props) {
        let analysts = this.getValues(props)
        return (
            <div >
                <div style={{ height: 30 }}>
                    <MultiSelect
                        value={analysts}
                        appendTo={document.body}
                        options={this.state.filteredAnalysts}
                        onChange={(e) => this.onEditorValueChange(props, e.value)}

                    />
                </div>
                {
                    this.state.isSubmitted && analysts.length === 0 ?
                        <div className="mb-1" style={{ height: '10px' }}>
                            {<span className="form__form-group-error">{"Pls fill the field"}</span>}
                        </div>
                        : null
                }
            </div>
        );
    }

    dropDownEditor(props, item) {
        let val;
        if (item.field === "analysts" && props.rowData[item.field][0]) {
            val = props.rowData[item.field][0]
        } if (item.field === "assignTo" && typeof props.rowData[item.field] === "string") {
            val = { userName: props.rowData[item.field], userId: props.rowData["assignToId"] }
        }
        return (
            <div >
                <Dropdown
                    value={val ? val : props.rowData[item.field]}
                    appendTo={document.body}
                    options={item.field === "analysts" || item.field === "assignTo" || (item.field === "CreatedBy" && this.props.type === "ClaimsManagement") ? this.state.filteredAnalysts : item.field === "ClaimStatus" && this.state.reasonByRow ? this.state.claimStatusArray : item.field === "ClaimReason" && this.state.reasonByRow ? props.rowData["filterClaimReason"] : item.field === "ClaimReason" ? props.rowData["claimReasonsRow"] : (this.props.type === "State Attributes" && item.field === "subCategory") ? this.state.stateSubCategories : item.options ? item.options : []}
                    // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
                    onChange={(e) => this.onEditorValueChange(props, e.value)}
                    // style={{ width: 150 }}
                    filter={true}
                    disabled={item.field === "crRound" && (props.rowData["dealType"] === "BK - Refresh" || props.rowData["dealType"] === "BK - New") ? true : false}


                // placeholder={item.header}
                />
                {/* <Select /> */}
                <div>
                    {this.props.type === "ClaimsManagement" && this.state.isSubmitted && item.field !== "Analyst" && props.rowData[item.field] === null ?
                        < div className="mb-1" style={{ height: '10px' }}>
                            {<span className="form__form-group-error">{"Please fill the field"}</span>}
                        </div>
                        :
                        this.props.type !== "ClaimsManagement" && this.state.isSubmitted && (((item.field !== "crRound") && (props.rowData[item.field] === null || (props.rowData[item.field].length === 0 || item.field === "analysts" && props.rowData[item.field] && props.rowData[item.field][0] && props.rowData[item.field][0].length === 0))))
                            ?
                            < div className="mb-1" style={{ height: '10px' }}>
                                {<span className="form__form-group-error">{"Please fill the field"}</span>}
                            </div>
                            : null
                    }
                </div>
            </div>)
    }

    searchItems = async (event) => {
        let userBody = {
            str: event ? event.query : ""
        }
        return fetchMethodRequest('POST', "businessnames/estateNames", userBody)
            .then(async (response) => {
                if (response) {
                    let dropdownData = [];
                    if (response["estatesOwnedFrom2021"] && response["estatesOwnedFrom2021"].length && response["estatesOwnedFrom2021"].length > 0) {
                        dropdownData = response["estatesOwnedFrom2021"]
                    } else if (response && response.errorMessage) {
                        this.getErrorResponseFromServer(response)
                    }
                    if (dropdownData && dropdownData.length === 0) {
                        this.setState({
                            filteredSuggestions: [],
                            noData: true
                        });
                    } else {
                        dropdownData = dropdownData;
                    }
                    await this.setState({
                        filteredSuggestions: dropdownData
                    });
                }
            }).catch((err) => {
                return err;
            });
    }
    // getDatePicker(props, item) {
    //   return (
    //     < >
    //       <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
    //         appendTo={document.body}
    //         monthNavigator={true}
    //         yearNavigator={true}
    //         yearRange="1940:2530"
    //         placeholder='mm/dd/yy'
    //         // onChange={(e) => this.setState({ [item.field]: e.value })}
    //         // inputClassName={`p-column-filter p-column-${item.field}`}
    //         name={item.field}
    //         dateFormat="mm/dd/yy"
    //         value={props.value && props.value[props.rowIndex] && props.value[props.rowIndex][item.field] ? new Date(props.value[props.rowIndex][item.field]) : null}
    //         onChange={(e) => this.onEditorValueChange(props, e.value)}
    //       // onSelect={(e) => this.onEditorValueChange(props, e)}
    //       />
    //     </>
    //   )
    // }
    onDateChange = (props, e, type) => {
        if (!type) {
            this.onEditorValueChange(props, e.value)
        } else {
            let allUsersData = [...props.value];
            allUsersData[props.rowIndex][props.field] = e ? e.value : ""
            allUsersData[props.rowIndex]["edited"] = "yes"
            localStorage.setItem("ISEdited", "true")
            this.setState({
                allUsersData: allUsersData
            })
        }
    }

    getDatePicker(props, item) {
        return (
            < >
                <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                    appendTo={document.body}
                    monthNavigator={true}
                    yearNavigator={true}
                    yearRange="1940:2530"
                    minDate={this.props.type.includes("Scheduler") ? new Date() : ""}
                    name={item.field}
                    placeholder='mm/dd/yyyy'
                    dateFormat="mm/dd/yy"
                    value={props.value && props.value[props.rowIndex] && props.value[props.rowIndex][item.field] && (props.value[props.rowIndex][item.field].length >= 10 || props.value[props.rowIndex][item.field].length === undefined) ? new Date(props.value[props.rowIndex][item.field]) : props.value && props.value[props.rowIndex] && props.value[props.rowIndex][item.field] && props.value[props.rowIndex][item.field].length < 11 ? props.value[props.rowIndex][item.field] : null}
                    onChange={(e) => this.onDateChange(props, e, 'type')}
                    onSelect={(e) => this.onDateChange(props, e)}
                />
            </>
        )
    }

    getAutoComplete(props, item) {
        return (
            <div >
                <div className='pl-0'>
                    <AutoComplete
                        value={props.value[props.rowIndex]["company"]}
                        suggestions={this.state.filteredSuggestions}
                        completeMethod={this.searchItems}
                        minLength={1}
                        field="estateName"
                        dropdown={true}
                        onChange={(e) => this.onEditorValueChange(props, e.value)}
                        appendTo={document.body} />
                </div>
                <div>
                    {
                        this.state.isSubmitted && props.rowData[item.field].length === 0 ?
                            <div className="mb-1" style={{ height: '10px' }}>
                                {<span className="form__form-group-error">{"Please fill the field"}</span>}
                            </div>
                            : null
                    }
                </div>
            </div >
        )
    }

    getSortedData(field, isAsc) {
        let allUsersData = this.props.getSortedData(this.state.allUsersData, field, isAsc)
        this.setState({
            allUsersData: allUsersData
        })
    }

    removeFrozenRecords = async (e, type) => {
        let columnsData = JSON.parse(localStorage.getItem(`${this.props.type}_column_order`));
        let columns
        if (columnsData && columnsData.details) {
            columns = columnsData.details[this.state.selectedViewType]
        } else {
            columns = columnsData
        }

        for (let i = 0; i < columns.length; i++) {
            columns[i].frozen = false
        }
        await localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columnsData))
        await this.selectedViewType(e, "Frozen")
    }

    handleDataChangingOption = async (e) => {
        if (e && e.selectedOption) {
            if (e.selectedOption === "unsort" || e.selectedOption === "asc" || e.selectedOption === "desc") {
                if (this.props.type !== "UP Opportunity") {
                    await this.sortChange(e)
                } else {
                    await this.getSortedData(e.sortField, e.selectedOption)
                }
            } else if (e.selectedOption === "hide") {
                await this.columnHandlingfromHeader(e.selectedOption, e.sortField)
            } else if (e.selectedOption === "show") {
                this.openShowHideColumnsModal()
            } else if (e.selectedOption === "filter") {
                await this.handleFilterInputField(e.sortField)
                // this.AddingFilterInput(e.selectedOption, field)
            } else if (e.selectedOption === "clear_filter") {
                this.onColumnResizeEnd()
                await this.handleFilterInputField(e.sortField, "clear")
                // this.AddingFilterInput(e.selectedOption, field)
            } else if (e.selectedOption === "freeze") {
                await this.removeFrozenRecords("freeze", "clear")
                this.onColumnResizeEnd()
                // this.AddingFilterInput(e.selectedOption, field)
            } else if (e.selectedOption === "unfreeze") {
                await this.removeFrozenRecords("unfreeze")
                this.onColumnResizeEnd()
                // this.AddingFilterInput(e.selectedOption, field)
            }
        }
    }


    handleFilterInputField(val, type) {
        let filters = {}, newFil = {}, filteredObj = {};
        if (type) {
            newFil[val] = {
                value: "",
                matchMode: "clear"
            };
            filters[val] = newFil[val]
            filteredObj.filters = filters
            let value = this.state.activeTab ? this.state.activeTab + val : val
            let data = document.getElementsByClassName(`p-column-filter-${value}`)
            if (data && data[0] && data[0].getElementsByClassName(`p-column-filter`) && data[0].getElementsByClassName(`p-column-filter`)[0] && data[0].getElementsByClassName(`p-column-filter`)[0].value) {

                data[0].getElementsByClassName(`p-column-filter`)[0].value = ""
            }
            if (data && data[0] && data[0].getElementsByClassName(`p-column-filter`) && data[0].getElementsByClassName(`p-column-filter`)[0] && data[0].getElementsByClassName(`p-column-filter`)[0].name) {

                let getName = data[0].getElementsByClassName(`p-column-filter`)[0].name
                this.setState({
                    [getName]: ""
                })
            }
            // if (val === "hasPropertyCoOwner") {
            this.setState({
                [val]: ""
            })
            // }
            document.getElementsByClassName(`p-column-filterMode-${val}`)[0].selectedIndex = 0
            document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
            this.onFilterChange(filteredObj)
        } else {
            let value = this.state.activeTab ? (this.state.activeTab + val) : val
            if ((document.getElementsByClassName(`p-column-filter-${value}`) && document.getElementsByClassName(`p-column-filter-${value}`)[0]) !== undefined) {
                if (document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display === "none") {
                    document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "block"
                } else {
                    document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
                }
            }

        }
        this.onColumnResizeEnd()
    }

    columnHandlingfromHeader(val, field) {
        let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
        let columns = JSON.parse(colOrder);
        if (columns && columns.details) {
            columns = columns.details[this.state.selectedViewType];

        } else {
            columns = columns
        }
        let index = columns.findIndex(obj => obj.field === field)
        if (val === "hide") {
            if (index !== -1) columns[index].show = columns[index].show === true ? false : true;
        }
        if (columns && columns.details) {
            columns.details[this.state.selectedViewType] = columns;
        }
        localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columns))

        this.getTableFieldsOrder()
        this.setState({
            isLoading: false,
        })
    }

    getAdditionalFilter = async () => {
        if (this.props.type === "Company") {
            let counindex = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "HQCountry")
            if (counindex !== -1) this.state.filterCriteria["criteria"].splice(counindex, 1)
            this.state.filterCriteria["criteria"].push({
                key: "HQCountry",
                value: this.state.selectedCountrie,
                type: "eq",
                add_Filter: true
            })
            await this.setState({ filterCriteria: this.state.filterCriteria })
        }
    }


    // //Custum Date Column Filter Onchange
    onFilterdateChange = async (e, field, type, dropdown) => {
        let value
        if (!type && !dropdown && e.value !== null) {
            value = e.value.toLocaleDateString('zh-Hans-CN', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            })
        } else {
            value = e
        }
        if (e && !type) {
            let filters = {}, newFil = {}, filteredObj = {};
            newFil[field] = {
                value: value,
                matchMode: dropdown ? dropdown : "string"
            };

            filters[field] = newFil[field]
            filteredObj.filters = filters
            await this.setState({
                [field]: e.value ? e.value : value ? value : ""
            })
            if (value && value.length === 0) {
                this.handleFilterInputField(field, "clear")

            } else {
                if (this.props.type === "Company" && field === "CompanyName") {
                    let index = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "CompanyName" && obj.add_Filter === true)
                    this.state.filterCriteria["criteria"].splice(index, 1)
                    await this.setState({
                        addedMatchforCompany: true
                    })

                }
                //          this.getAdditionalFilter()
                await this.onFilterChange(filteredObj)
                await this.setState({
                    addedMatchforCompany: false
                })
            }
        } else {
            await this.setState({
                [field]: e.value !== null ? e.value : e === "string" ? e : ""
            })
            if (this.props.type === "Company" && value.length === 0) {
                let index = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "companyName" && obj.add_Filter === true)
                this.state.filterCriteria["criteria"].splice(index, 1)
                let counindex = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "HQCountry" && obj.add_Filter === true)
                this.state.filterCriteria["criteria"].splice(counindex, 1)
                document.getElementById("companySearch").value = ""
                //          this.getAdditionalFilter()
                this.getDataFromServer(this.state.filterCriteria)
            }
        }
    }

    CalenderComponent(item) {
        return (
            < >
                <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                    appendTo={document.body}
                    monthNavigator={true}
                    yearNavigator={true}
                    yearRange="1940:2530"
                    // onChange={(e) => this.setState({ [item.field]: e.value })}
                    inputClassName={`p-column-filter p-column-${item.field}`}
                    name={item.field}
                    dateFormat="mm/dd/yy"
                    value={this.state[item.field] ? this.state[item.field] : document.getElementsByClassName(`p-column-filter p-column-${item.field}`).value}
                    onChange={(e) => this.onFilterdateChange(e, item.field, "change")}
                    onSelect={(e) => this.onFilterdateChange(e, item.field)} />
            </>
        )
    }
    DropDownComponent(item) {
        return (
            <div >
                <Dropdown
                    value={this.state[item.field]}
                    appendTo={document.body}
                    // options={[{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                    options={item.field === "confirmedOnsite" ? [{ label: "Yes", value: "true" }, { label: "No", value: "false" }] : [{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                    // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
                    onChange={(e) => this.onFilterdateChange(e.value, item.field, "", "num")}
                // style={{ width: 150 }}
                // filter={true}
                // disabled={item.field === "crRound" && (props.rowData["dealType"] === "BK - Refresh" || props.rowData["dealType"] === "BK - New") ? true : false}


                // placeholder={item.header}
                />
                {/* <Select /> */}
                <div>
                </div>
            </div>)
    }

    MultiSelectComponent(item, delta) {
        return (
            <div >
                <MultiSelect
                    value={this.state[item.field]}
                    appendTo={document.body}
                    options={item.field === "matchMatchStatus" ? this.props.settingsData : item.field === "matchStatusReason" ? this.props.shownreasons : item.filterOptions}
                    style={{ width: item.width - 55, marginRight: 3 }}
                    maxSelectedLabels={2}
                    // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
                    onChange={(e) => this.setState({ [item.field]: e.value })}
                />

                <FontAwesomeIcon
                    color='green'
                    icon={faCheckCircle}
                    data-toggle="tool-tip"
                    className='my-2 mx-0'
                    onClick={(e) => this.onFilterdateChange(this.state[item.field], item.field, "", "string")}
                />
                {/* <Select /> */}
                <div>
                </div>
            </div>)
    }

    //after selecting type 
    onSeletFilterType(value, type) {
        value = this.props.activeTab ? this.state.activeTab.toString() + value : value
        let selectedcolumn = document.getElementsByClassName(`p-column-filter-${value}`)
        if (selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0]) {
            if (!type) {
                selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].focus()
            } else {
                selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].blur()
            }
        }
    }
    getFilterOptionValues(column) {
        if (this.props.type === "Property Editor") {
            if (this.state.activeTab === "SP") {
                if (column.filter) {
                    return this.state.dataChangeOptionswithoutSortwithFilter
                } if (!column.filter) {
                    return this.state.dataChangeOptionswithoutSortwithoutFilter
                }
            } else {
                if (column.filter && !column.sorted) {
                    return this.state.dataChangeOptionswithFilterPE
                } if (!column.filter && !column.sorted) {
                    return this.state.dataChangeOptionswithoutFilterPE
                } if (column.sorted) {
                    return this.state.dataChangeOptionswithoutSortwithoutFilter

                }
            }
        } else {
            if (column.filter) {
                return this.state.dataChangeOptionswithFilter
            } if (!column.filter) {
                return this.state.dataChangeOptionswithoutFilter
            }
        }

    }


    getColumns(e, d) {
        const { t } = this.props
        const self = this;
        self.e = e;
        self.d = d;
        if (this.state.tablefieldsToShow && this.state.tablefieldsToShow.length > 0) {
            return this.state.tablefieldsToShow.map((item, i) => {
                let column = (item.show &&
                    <Column key={item.field + i}
                        rowReorder={item.field === "reOrder" ? true : false}
                        expander={item.fieldType === "expander" ? true : false}
                        rowEditor={item.fieldType === "rowEditor" ? true : false}
                        editor={item.editField === "text" ?
                            (options) => this.textEditor(options, item) :
                            item.editField === "textArea" ?
                                (options) => this.textAreaEditor(options, item) :
                                // item.editField === "MultidropDown" ?
                                //   (options) => this.multiSelectEditor(options, item) :
                                item.editField === "dropDown" ?
                                    (options) => this.dropDownEditor(options, item) :
                                    item.editField === "autoComplete" ?
                                        (options) => this.getAutoComplete(options, item) :
                                        item.editField === "date" ?
                                            (options) => this.getDatePicker(options, item) :
                                            null}
                        style={{
                            maxwidth: item.width,
                            padding: 2,
                        }}
                        bodyStyle={{
                            textOverflow: item.field === "image" || item.field === 'reOrder' ? 'none' : 'ellipsis',
                            overflow: item.header === "Actions" ? 'initial' : 'hidden',
                            whiteSpace: 'nowrap',
                            width: item.width,
                            fontSize: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? 14 : this.props.type === "Recovery Planner V2" ? 10 : 12,
                            textAlign: item.fieldType === "Badge" ? "left" : item.textAlign,
                            verticalAlign: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? "baseline" : "inherit"
                        }
                        }
                        filterMatchMode={item.filterType === "num" ? "num" : "string"}
                        isAllowInt={item.allowInt ? true : false}
                        field={item.field}
                        getID={item && item.getID ? item.getID : null
                        }
                        activeTab={this.state.activeTab}
                        editRequired={self.e}
                        deleteRequired={self.d}
                        shownField={this.state.shownFieldTab}
                        dataChangeOptions={this.getFilterOptionValues(item)}
                        header={t(item.header)}
                        changeFieldName={item.changeFieldName}
                        body={self.changeFieldValues}
                        headerStyle={{
                            height: this.state.colunHeight ? this.state.colunHeight : "auto",
                            padding: "6px 15px",
                            width: item.width,
                            fontSize: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? 16 : 13,
                            fontWeight: this.props.type === "Utilization - US Team" || this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report" || this.props.type === "Utilization - Integrity" ? 'bold' : '500',
                            color: "white",
                            backgroundColor: config.templateColor,
                            textAlign: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? 'center' : 'left'
                        }}
                        frozen={
                            item.frozen ? true : false
                        }
                        filter={item.filter ? item.filter : false}
                        // filterElementnonText={item.filterType === "dropdown" ? this.DropDownComponent(item) : item.filterType === "multiSelect" ? this.MultiSelectComponent(item) : item.fieldType === 'Date' || item.fieldType === 'Time' || item.fieldType === "scheduledTime" || item.fieldType === "dateTime" ? this.CalenderComponent(item) : null}
                        // filterElement={item.filter && item.filterType === "num" ?
                        //     <select className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} name="viewroom" id="viewroom" onChange={(e) => this.onSeletFilterType(item.field)}>
                        //         <option value="eq">Equals</option>
                        //         <option value="lt">Less Than</option>
                        //         <option value="gt">Greater Than</option>
                        //         <option value="lte">Less Than or Equals</option>
                        //         <option value="gte">Greater Than or Equals</option>
                        //         <option value="nin">Exists</option>
                        //         <option value="in">Not Exists</option>
                        //     </select> : item.filter && item.filterType !== "num" ?
                        //         this.props.type !== "Property Editor" && this.props.type !== "Deal Statistics" ?
                        //             <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                        //                 <option value="regexOr">Includes</option>
                        //                 <option value="eq">Equals</option>
                        //                 <option value="sw">Starts With</option>
                        //                 <option value="ew">Ends With</option>
                        //                 <option value="nregexOr">Excludes</option>
                        //                 <option value="nin">Exists</option>
                        //                 <option value="in">Not Exists</option>
                        //             </select>
                        //             : item.filterType === "dropdown" || item.filterType === "multiSelect" ?
                        //                 <select name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                        //                     {item.filterType === "dropdown" ? <option value="eq">Equals</option> : <option value="multi">Exists</option>}
                        //                 </select>
                        //                 : <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                        //                     {item.isFuzzy ? <option value="fuzzyregexOr">Includes Fuzzy</option> : null}
                        //                     <option value="regexOr">Includes</option>

                        //                     <option value="eq">Equals</option>
                        //                     <option value="nregexOr">Excludes</option>
                        //                     <option value="nin">Exists</option>
                        //                     <option value="in">Not Exists</option>
                        //                 </select>

                        //         : null
                        // }
                        sortable={item.sortable ? true : false}
                        // sortable={false}
                        filterPlaceholder={item.placeholder ? item.placeholder : ''}
                        selectionMode={this.state.activeTab !== "Teal" ? item.selectionMode : ""}
                    />
                )

                return column;
            })
        }
    }


    toggleFilterDropdown = async (field) => {
        await this.setState({ collapsefilter: this.state.collapsefilter === true ? false : true, openedField: field });
    }

    //Drag and drop rows
    onRowReorder = (e, l) => {
        const { allUsersData } = this.state
        allUsersData[e.dragIndex][this.props.reorderkey] = allUsersData[e.dropIndex][this.props.reorderkey]
        let userBody = allUsersData[e.dragIndex]
        // if (this.props.type === "Departments") {
        //   userBody.sequence = e.dropIndex
        // }
        let url
        if (l) {
            url = l
        } else {
            url = this.getAPIUrl();
        }
        return fetchMethodRequest('PUT', `${url}/${e.value[e.dropIndex]._id}`, userBody)
            .then(async (response) => {
                if (response && response.respCode) {
                    this.getDataFromServer(this.state.filterCriteria)
                } else if (response && response.errorMessage) {
                    this.getErrorResponseFromServer(response)
                }
                this.setState({
                    isLoading: false
                })
            }).catch((err) => {
                return err;
            });

    }


    getEditSiteDiscoveryFooter() {
        return <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={5} />
                <Column footer={this.state.totalForcastSiteDiscovery ? this.state.totalForcastSiteDiscovery.toFixed(1) : null} />
                < Column footer={this.state.totalActualSiteDiscovery ? this.state.totalActualSiteDiscovery.toFixed(1) : null} />
                <Column footer="" />
                <Column footer="" />
                <Column footer="" />
            </Row>
            <Row>
                <Column footer="Averages(%):" colSpan={5} />
                <Column colSpan={2} footer={this.state.totalForcastSiteDiscovery ? ((this.state.totalActualSiteDiscovery / this.state.totalForcastSiteDiscovery) * 100).toFixed(1) : null} />
                <Column footer="" />
                <Column footer="" />
                <Column footer="" />
            </Row>
        </ColumnGroup>;
    }

    getSiteDiscoveryFooter() {
        return <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={5} />
                <Column footer={this.state.totalForcastSiteDiscovery ? this.state.totalForcastSiteDiscovery.toFixed(1) : null} />
                < Column footer={this.state.totalActualSiteDiscovery ? this.state.totalActualSiteDiscovery.toFixed(1) : null} />
                <Column footer="" />
            </Row>
            <Row>
                <Column footer="Averages(%):" colSpan={5} />
                <Column colSpan={2} footer={this.state.totalForcastSiteDiscovery ? ((this.state.totalActualSiteDiscovery / this.state.totalForcastSiteDiscovery) * 100).toFixed(1) : null} />
                <Column footer="" />
            </Row>
        </ColumnGroup>;
    }

    getEditNonDiscoveryFooter() {
        const ActualReportsHours = this.state.totalActualHours
        const ForecastReportsHours = this.state.totalForcastHours
        return <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={7} />
                <Column footer={this.state.totalForcastTransitionHours ? this.state.totalForcastTransitionHours.toFixed(1) : null} />
                <Column footer={this.state.totalActualTransitionHours ? this.state.totalActualTransitionHours.toFixed(1) : null} />
                <Column footer={this.state.totalForcastValidation ? this.state.totalForcastValidation.toFixed(1) : null} />
                < Column footer={this.state.totalActualValidation ? this.state.totalActualValidation.toFixed(1) : null} />
                <Column footer={this.state.totalForcastFile ? this.state.totalForcastFile.toFixed(1) : null} />
                <Column footer={this.state.totalActualFile ? this.state.totalActualFile.toFixed(1) : null} />
                <Column footer={this.state.totalSideProjectActual ? this.state.totalSideProjectActual.toFixed(1) : null} />
                <Column footer="" />
                <Column footer="" />
                <Column footer="" />
            </Row>
            <Row>
                <Column footer="Averages(%):" colSpan={7} />
                <Column colSpan={2} footer={this.state.totalForcastTransitionHours ? ((this.state.totalActualTransitionHours / this.state.totalForcastTransitionHours) * 100).toFixed(1) : null} />
                {/* <Column colSpan={2} footer={this.state.totalForcastSiteDiscovery ? ((this.state.totalActualSiteDiscovery / this.state.totalForcastSiteDiscovery) * 100).toFixed(1) : null} /> */}
                <Column colSpan={2} footer={this.state.totalForcastValidation ? ((this.state.totalActualValidation / this.state.totalForcastValidation) * 100).toFixed(1) : null} />
                <Column colSpan={2} footer={this.state.totalForcastFile ? ((this.state.totalActualFile / this.state.totalForcastFile) * 100).toFixed(1) : null} />
                <Column footer={this.state.totalSideProjectActual ? this.state.totalSideProjectActual.toFixed(1) : null} />
                <Column footer="" />
            </Row>

            <Row>
                <Column footer="Total Actual Hours:" colSpan={7} />
                <Column colSpan={10} footer={ActualReportsHours ? ActualReportsHours.toFixed(1) : null} />
            </Row>
            <Row>
                <Column footer="Total Planned Hours:" colSpan={7} />
                <Column colSpan={10} footer={ForecastReportsHours ? ForecastReportsHours.toFixed(1) : null} />
            </Row>
            <Row>
                <Column footer="Average Utilization(%):" colSpan={7} />
                <Column colSpan={10} footer={((ActualReportsHours / ForecastReportsHours) * 100).toFixed(1)} />
            </Row>
        </ColumnGroup>;
    }

    getNonDiscoveryFooter() {
        const ActualReportsHours = this.state.totalActualHours
        const ForecastReportsHours = this.state.totalForcastHours
        return <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={7} />
                <Column footer={this.state.totalForcastTransitionHours ? this.state.totalForcastTransitionHours.toFixed(1) : null} />
                <Column footer={this.state.totalActualTransitionHours ? this.state.totalActualTransitionHours.toFixed(1) : null} />
                <Column footer={this.state.totalForcastValidation ? this.state.totalForcastValidation.toFixed(1) : null} />
                < Column footer={this.state.totalActualValidation ? this.state.totalActualValidation.toFixed(1) : null} />
                <Column footer={this.state.totalForcastFile ? this.state.totalForcastFile.toFixed(1) : null} />
                <Column footer={this.state.totalActualFile ? this.state.totalActualFile.toFixed(1) : null} />
                <Column footer={this.state.totalSideProjectActual ? this.state.totalSideProjectActual.toFixed(1) : null} />
                <Column footer="" />
            </Row>
            <Row>
                <Column footer="Averages(%):" colSpan={7} />
                <Column colSpan={2} footer={this.state.totalForcastTransitionHours ? ((this.state.totalActualTransitionHours / this.state.totalForcastTransitionHours) * 100).toFixed(1) : null} />
                {/* <Column colSpan={2} footer={this.state.totalForcastSiteDiscovery ? ((this.state.totalActualSiteDiscovery / this.state.totalForcastSiteDiscovery) * 100).toFixed(1) : null} /> */}
                <Column colSpan={2} footer={this.state.totalForcastValidation ? ((this.state.totalActualValidation / this.state.totalForcastValidation) * 100).toFixed(1) : null} />
                <Column colSpan={2} footer={this.state.totalForcastFile ? ((this.state.totalActualFile / this.state.totalForcastFile) * 100).toFixed(1) : null} />
                <Column colSpan={1} footer={this.state.totalSideProjectActual ? this.state.totalSideProjectActual.toFixed(1) : null} />
                <Column colSpan={2} footer="" />
            </Row>

            <Row>
                <Column footer="Total Actual Hours:" colSpan={7} />
                <Column colSpan={10} footer={ActualReportsHours ? ActualReportsHours.toFixed(1) : null} />
            </Row>
            <Row>
                <Column footer="Total Planned Hours:" colSpan={7} />
                <Column colSpan={10} footer={ForecastReportsHours ? ForecastReportsHours.toFixed(1) : null} />
            </Row>
            <Row>
                <Column footer="Average Utilization(%):" colSpan={7} />
                <Column colSpan={10} footer={((ActualReportsHours / ForecastReportsHours) * 100).toFixed(1)} />
            </Row>
        </ColumnGroup>;
    }

    getReportsFooter() {
        const ActualReportsHours = this.state.totalActualHours + this.state.totalActualSiteDiscovery
        const ForecastReportsHours = this.state.totalForcastHours + this.state.totalForcastSiteDiscovery
        return <ColumnGroup>
            {/* {this.state.allUsersData && this.state.allUsersData.length > 0 ? */}
            {/* <div> */}
            <Row>
                <Column footer="Total:" colSpan={8} />
                <Column footer={this.state.totalForcastTransitionHours ? this.state.totalForcastTransitionHours.toFixed(1) : null} />
                <Column footer={this.state.totalActualTransitionHours ? this.state.totalActualTransitionHours.toFixed(1) : null} />
                <Column footer={this.state.totalForcastSiteDiscovery ? this.state.totalForcastSiteDiscovery.toFixed(1) : null} />
                < Column footer={this.state.totalActualSiteDiscovery ? this.state.totalActualSiteDiscovery.toFixed(1) : null} />
                <Column footer={this.state.totalForcastValidation ? this.state.totalForcastValidation.toFixed(1) : null} />
                < Column footer={this.state.totalActualValidation ? this.state.totalActualValidation.toFixed(1) : null} />
                <Column footer={this.state.totalForcastFile ? this.state.totalForcastFile.toFixed(1) : null} />
                <Column footer={this.state.totalActualFile ? this.state.totalActualFile.toFixed(1) : null} />
                <Column footer={this.state.totalSideProjectActual ? this.state.totalSideProjectActual.toFixed(1) : null} />
                <Column footer="" />
            </Row>
            <Row>
                <Column footer="Averages(%):" colSpan={8} />
                <Column colSpan={2} footer={this.state.totalForcastTransitionHours ? ((this.state.totalActualTransitionHours / this.state.totalForcastTransitionHours) * 100).toFixed(1) : null} />
                <Column colSpan={2} footer={this.state.totalForcastSiteDiscovery ? ((this.state.totalActualSiteDiscovery / this.state.totalForcastSiteDiscovery) * 100).toFixed(1) : null} />
                <Column colSpan={2} footer={this.state.totalForcastValidation ? ((this.state.totalActualValidation / this.state.totalForcastValidation) * 100).toFixed(1) : null} />
                <Column colSpan={2} footer={this.state.totalForcastFile ? ((this.state.totalActualFile / this.state.totalForcastFile) * 100).toFixed(1) : null} />
                <Column footer={this.state.totalSideProjectActual ? this.state.totalSideProjectActual.toFixed(1) : null} />
                <Column footer="" />
            </Row>

            <Row>
                <Column footer="Total Actual Hours:" colSpan={8} />
                <Column colSpan={10} footer={ActualReportsHours ? ActualReportsHours.toFixed(1) : null} />
            </Row>
            <Row>
                <Column footer="Total Planned Hours:" colSpan={8} />
                <Column colSpan={10} footer={ForecastReportsHours ? ForecastReportsHours.toFixed(1) : null} />
            </Row>
            <Row>
                <Column footer="Average Utilization(%):" colSpan={8} />
                <Column colSpan={10} footer={((ActualReportsHours / ForecastReportsHours) * 100).toFixed(1)} />
            </Row>
            {/* </div> */}
            {/* : null} */}
        </ColumnGroup>;
    }
    handleColumnresize = async (e, type) => {
        if (this.props.type === "ClaimsManagement") {
            let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
            colOrder = JSON.parse(colOrder)
            let details = colOrder.details
            let Defaults
            // let Defaults = details["Default"]
            if (details) {
                Defaults = details[this.state.selectedViewType]
            } else {
                Defaults = colOrder
            }

            let z = Defaults.find(elem => elem.field === e.column.field)
            if (z) {
                z.width = z.width + e.delta

            }
            localStorage.setItem(
                `${this.props.type}_column_order`,
                JSON.stringify(colOrder)
            );
            await this.setState({ resized: true })
            this.setDynamicColumns()
        } if (this.props.type === "Property Editor") {
            if (e.column.frozen === true) {

            }
        }
        this.onColumnResizeEnd(e)
    }
    onColumnResizeEnd = (e) => {
        let colunHeight, columnHeight1, largest;
        if (this.props.frozen && document.getElementsByClassName("p-datatable-thead").length === 2) {
            colunHeight = document.getElementsByClassName("p-datatable-thead")[1].clientHeight
            columnHeight1 = document.getElementsByClassName("p-datatable-thead")[0].clientHeight
            if (colunHeight >= columnHeight1) {
                largest = colunHeight;
            }
            else {
                largest = columnHeight1;
            }
        } else {
            largest = document.getElementsByClassName("p-datatable-thead")[0].clientHeight

        }
        if (this.state.columnHeight !== largest) {
            this.setState({
                colunHeight: largest,
            })
        }
        if (e && e.column.frozen) {
            let frozenwidth = this.state.frozenWidth
            this.setState({
                frozenwidth: frozenwidth + e.delta
            })
            document.getElementsByClassName("p-datatable-scrollable-view p-datatable-frozen-view")[0].style.width = this.state.frozenWidth
        }
    }

    getDataTable() {
        let self = this;
        self.editRequired = this.props.editRequried;
        self.deleteRequired = this.props.deleteRequried;
        let footerGroup = !this.state.isPreviousWeek && this.state.screenPermissions === "Edit" && window.location.pathname === "/siteDiscoveryPlan" ? this.getEditSiteDiscoveryFooter()
            : (this.state.isPreviousWeek || this.state.screenPermissions !== "Edit") && window.location.pathname === "/siteDiscoveryPlan" ?
                this.getSiteDiscoveryFooter() : !this.state.isPreviousWeek && this.state.screenPermissions === "Edit" && window.location.pathname === "/nonSiteDiscoveryPlan" ? this.getEditNonDiscoveryFooter()
                    : (this.state.isPreviousWeek || this.state.screenPermissions !== "Edit") && window.location.pathname === "/nonSiteDiscoveryPlan" ?
                        this.getNonDiscoveryFooter() :
                        window.location.pathname === "/reportPlan" ?
                            this.getReportsFooter() : null
        let headerGroup =
            !this.state.isPreviousWeek && this.state.screenPermissions === "Edit" && window.location.pathname === "/siteDiscoveryPlan" ? this.props.getEditSiteDiscoveryColumns
                : (this.state.isPreviousWeek || this.state.screenPermissions !== "Edit") && window.location.pathname === "/siteDiscoveryPlan" ?
                    this.props.getSiteDiscoveryColumns : !this.state.isPreviousWeek && this.state.screenPermissions === "Edit" && window.location.pathname === "/nonSiteDiscoveryPlan" ? this.props.getEditNonDiscoveryColumns
                        : (this.state.isPreviousWeek || this.state.screenPermissions !== "Edit") && window.location.pathname === "/nonSiteDiscoveryPlan" ?
                            this.props.getNonDiscoveryColumns :
                            window.location.pathname === "/reportPlan" ?
                                this.props.getReportColumns : null
        return (
            <div className={this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? "stickyHeader" : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") || this.props.type === "Claims" ? "disabledSelection" : ''}>
                {/* <div style={{
          backgroundColor: "lightblue",
          border: "1px solid black",
          width: "500px",
          overflow: "auto",
          overflowX: "scroll"
        }
        }>
        </div> */}
                {/* <Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader" /> */}
                <DataTable
                    onColumnResizeEnd={(e) => this.handleColumnresize(e, this.props.type)}
                    // onColumnResizeEnd={(e) => this.onColumnResizeEnd(e)}
                    rowGroupMode={this.props.type !== "Deal Sizing" && this.props.type !== "Claims" && this.props.type !== "Deal Sizing Queue" && this.props.type !== "Regular Scoring Queue" && this.props.type !== "Deal Sizing Scheduler" && this.props.type !== "Regular Scoring Scheduler" && this.props.type !== "ClaimsManagement" && this.props.type !== "State Attributes" && this.props.type !== "Recovery Planner V2" ? "rowspan" : ""}
                    groupField={this.props.groupField}
                    headerColumnGroup={this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report" ? headerGroup : null}
                    footerColumnGroup={this.state.allUsersData && this.state.allUsersData.length > 0 ? footerGroup : ""}
                    groupRowSpan="2"
                    //Column Grop Props
                    //edit props
                    editMode="row"
                    // dataKey={this.props.type !== "Property Editor" && this.props.type !== "Sellers" && this.props.type !== "Deal Sizing" && this.props.type !== "Claims" ? "id" : this.props.type === "Sellers" ? "DimSellerID" : this.props.type === "Deal Sizing" ? "Sno" : this.props.type === "Claims" ? "propertyID" : "_id"}
                    dataKey={this.props.dataKey ? this.props.dataKey : "_id"}
                    onRowEditSave={this.onRowEditSave}
                    onRowEditCancel={this.onRowEditCancel}
                    rowEditorValidator={this.rowEditorValidator}
                    sortField={this.props.sortedField}
                    frozenWidth={this.props.frozen && this.state.isFrozenColumns ? this.state.frozenWidth : ""}
                    sortOrder={1}
                    responsiveLayout="scroll"
                    rowClassName={(e) => this.rowClassName(e)}
                    ref={(el) => this.dt = el}
                    value={this.state.allUsersData}
                    expandedRows={this.state.expandedRows}
                    onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                    onRowExpand={(data) => this.openAdUnitsCollapse(data)}
                    rowExpansionTemplate={(e) => this.rowExpansionTemplate(e)}
                    onRowReorder={this.onRowReorder}
                    // header={this.getHeader()}
                    totalRecords={this.state.totalRecordsLength}
                    paginator={false}
                    lazy={true}
                    resizableColumns={true}
                    columnResizeMode="expand"
                    onSort={this.handleDataChangingOption}
                    globalFilter={this.state.globalFilter}
                    // onFilter={this.isConfirmFilter}
                    onFilter={this.onFilterChange}
                    scrollable={true}
                    // selection={false}
                    selectionMode={this.props.isSelectMode}
                    onSelectionChange={(e) => this.onSelectRowsUpdate(e)}
                    selection={this.state.selectedRows}
                    scrollHeight={this.props.scrollHeight ? this.props.scrollHeight : "1000px"}
                    emptyMessage={this.state.errorResponse ? this.state.errorMessage : configMessages.noRecords}
                    sortMode="single"
                    metaKeySelection={false}
                    loading={this.state.isLoading}
                    style={this.state.allUsersData && this.state.allUsersData.length === 0 ?
                        { textAlign: 'center', marginTop: 4 }
                        : { marginTop: 30 }}
                >
                    {self.getColumns(self.editRequired, self.deleteRequired)}
                </DataTable>
            </div >


        )
    }

    getTabInfo() {
        return null;
    }

    getPaginator() {
        if (this.state.totalRecordsLength > 10 && this.state.isShowTable)
            return (
                <PaginatorComponent
                    totalRecords={this.state.totalRecordsLength}
                    first={this.state.first}
                    rows={this.state.rows}
                    onPageChange={this.onPageChange}
                    isWeb={true}
                />

            )


    }

    //getGridView
    getGridView() {
        return (
            <div className='row   ml-lg-0 pr-1'>
                {
                    this.state.allUsersData && this.state.allUsersData.length > 0 ?
                        this.state.allUsersData.map((item, i) => {
                            return (
                                <div className='col-sm-6 col-md-4 col-lg-3 px-1' key={i}>
                                    <Card className='pb-2' >
                                        <CardBody className={this.props.type ? 'tableCardBody' : 'modalTablePadding'} style={{ borderRadius: "0px" }}>
                                            {
                                                this.state.tablefieldsToShow && this.state.tablefieldsToShow.length && this.state.tablefieldsToShow.length > 0 ?
                                                    this.state.tablefieldsToShow.map((elememt, index) => {
                                                        return (
                                                            <div className={(elememt.displayInSettings === true) ? `col-12  ` : 'd-none'} key={index}>
                                                                <div >
                                                                    {/* {this.changeFieldValues(item, element)} */}
                                                                    {
                                                                        elememt.fieldType === 'Link' ?
                                                                            <div className='d-flex'>
                                                                                <span
                                                                                    style={elememt.style}
                                                                                    onClick={() => this.openViewModal(item, 'view')}>
                                                                                    <b> {item[elememt.field]}</b>
                                                                                </span>
                                                                            </div>
                                                                            :
                                                                            elememt.fieldType === 'Badge' ?
                                                                                <div style={elememt.style}>
                                                                                    <Badge color={item.status === 'Active' ? 'success' : item.status === 'Inactive' ? 'warning' : item.status === 'Pending' ? 'danger' : item.status === 'Reject' ? 'error' : item.status === 'Completed' ? 'primary' : 'info'}>
                                                                                        {item[elememt.field]}
                                                                                    </Badge>
                                                                                </div> :
                                                                                elememt.fieldType === 'Role' ?
                                                                                    <div style={elememt.style}>
                                                                                        <Badge pill
                                                                                            color='success'
                                                                                        >
                                                                                            {item[elememt.field]}
                                                                                        </Badge>
                                                                                    </div>
                                                                                    : elememt.fieldType === 'Date' ?
                                                                                        <div>
                                                                                            {dateFormats.formatDate(item[elememt.field], config.dateDayMonthFormat)}
                                                                                        </div>
                                                                                        : elememt.fieldType === 'Time' ?
                                                                                            <div>
                                                                                                {dateFormats.formatDate(item[elememt.field], config.timeFormat)} - ${dateFormats.formatDate(item['toTime'], config.timeFormat)}
                                                                                            </div>
                                                                                            : elememt.fieldType === 'Array' ?
                                                                                                <span style={element.style}>
                                                                                                    {this.flattenArray(item[elememt.field])}
                                                                                                </span>
                                                                                                : <div style={elememt.style}>
                                                                                                    <span
                                                                                                    >{item[elememt.field]}</span></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : null
                                            }
                                        </CardBody>
                                    </Card>
                                </div>

                            )
                        }) : null
                }
            </div>
        )
    }

    getClaimStatus = async () => {
        let StatusArray = [];
        let filterCriteria = {}
        filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
        return fetchMethodRequest('GET', `${apiCalls.ClaimStatuses}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
            if (response && response["claimStatuses"] && response["claimStatuses"].length > 0) {
                let propertyStatuses = response["claimStatuses"]
                for (let status of propertyStatuses) {
                    StatusArray.push({ label: status.status, value: status.status })
                }
                this.setState({ claimStatusArray: StatusArray })
            }
        })

    }

    getAnalysts = async () => {
        let filterCriteria = {}
        if (this.props.type === "Weekly Recovery Plan") {
            if (window.location.pathname === "/nonSiteDiscoveryPlan") {
                filterCriteria['criteria'] = [{
                    key: "role", value: ["Recovery Analyst", "Recovery Manager", "Filer", "Reviewer", "Transition Manager", "Transition Analyst"], type: 'in'
                }];
            } else {
                filterCriteria['criteria'] = [{
                    key: "role", value: ["Search Manager", "Search Analyst"], type: 'in'
                }];
            }
        } else if (this.props.type === "ClaimsManagement") {
            filterCriteria['criteria'] = [];
        } else {
            if (this.props.type !== "Recovery Planner V2") {
                filterCriteria['criteria'] = [{
                    key: "role", value: ["Transition Manager", "Transition Analyst", "Recovery Analyst", "Filer", "Reviewer"], type: 'in'
                }];
            }
        }
        return fetchMethodRequest('GET', `${apiCalls.Users}?filter=${JSON.stringify(filterCriteria)}`)
            .then(async (response) => {
                if (response && response.users && response.users.length > 0) {
                    let data = response.users, tempData = [];
                    for (let i = 0; i < data.length; i++) {
                        tempData.push({ 'label': data[i]['display_name'], "value": { userName: data[i]['display_name'], userId: data[i]['_id'] } })
                    }
                    tempData.sort(this.compare)
                    await this.setState({
                        filteredAnalysts: tempData
                    })
                } else if (response && response['errorMessage']) {
                    this.getErrorResponseFromServer(response)
                }
            }).catch((err) => {
                return err;
            })
    }

    //for Sorting the Menu lists in Roles
    compare(a, b) {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    }

    getFilteredReports = async (val, type) => {
        let filterCriteria = this.state.filterCriteria
        if (type === "analyst") {
            await this.setState({
                analysttype: val
            })
            if (typeof val === "object") {
                val = val.userName
            } else {
                val = val
            }
            let index = filterCriteria["criteria"].findIndex(item => item.key === "username");
            if (index === -1) {
                filterCriteria["criteria"].push({ key: "username", value: val, type: "eq" })
            } else {
                filterCriteria["criteria"][index].value = val
            }
        }
        if (type === "Seller") {
            await this.setState({
                sellertype: val,
            })
            let data;
            if (typeof val === "object") {
                data = val.estateName
            } else {
                data = val
            }
            await this.setState({
                seletedSellerType: data
            })
            if (typeof val === "object") {
                this.getDataFromServer(this.state.filterCriteria)
            }
        }
        if (type === "clear") {
            filterCriteria["criteria"] = [];
            await this.setState({
                seletedSellerType: null,
                sellertype: null,
                analysttype: null
            })
        }
        await this.setState({
            filterCriteria: filterCriteria
        })
        if (type !== 'Seller') {
            this.getDataFromServer(this.state.filterCriteria)
        }
    }

    getFilteredReportsselectedKey = async (v) => {
        if (v.key === "Enter") {
            this.getDataFromServer(this.state.filterCriteria)
        }
    }

    getUtilizationFilters() {
        return (
            <div className="d-flex">
                <div className='d-flex '>
                    <h5 className="mr-2 mt-2">Analyst</h5>
                    <Dropdown
                        value={this.state.analysttype}
                        onKeyPress={(e) => this.getFilteredReportsselectedKey(e, 'Seller')}
                        appendTo={document.body}
                        options={this.state.filteredAnalysts}
                        onChange={(e) => this.getFilteredReports(e.value, 'analyst')}
                        style={{ width: 200, height: 35 }}
                    />
                </div >
                <div className="ml-3 d-flex">
                    <h5 className="mr-2 mt-2">Deal</h5>
                    <AutoComplete
                        value={this.state.sellertype}
                        suggestions={this.state.filteredSuggestions}
                        completeMethod={this.searchItems}
                        minLength={1}
                        style={{ height: 35, width: "227px" }}
                        field="estateName"
                        dropdown={true}
                        // onKeyUp={(e) => this.getFilteredReports(e, 'Seller')}
                        onChange={(e) => this.getFilteredReports(e.value, 'Seller')}
                        onKeyUp={(e) => this.getFilteredReportsselectedKey(e, 'Seller')}
                        appendTo={document.body} />
                </div>
                <div className="ml-2 mb-0">
                    <Button color="primary" className="ml-2 mb-0" outline
                        onClick={(e) => this.getFilteredReports(e, "clear")}>
                        <FontAwesomeIcon
                            color='red'
                            icon={faTimes}
                            data-toggle="tool-tip"
                            onClick={(e) => this.getFilteredReports(e, "clear")}
                        />Clear
                    </Button>
                </div>
            </div >
        )
    }

    onpropertiessortBy = async (e, type, submit) => {
        let filterCriteria = this.state.filterCriteria
        if (e === "confirmation" && type) {
            await this.setState({
                confirmation: !this.state.confirmation
            })

        } if (e === "Properties" && type) {
            await this.setState({
                Properties: !this.state.Properties
            })
        } else if (e && !type) {
            let val = this.getArrayVal(JSON.stringify(e))
            val = val.replace(/\s*,\s*/g, ", ");
            let filterCriteria = this.state.filterCriteria
            if (val && (this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report")) {
                val = "dealType, " + val
            }
            filterCriteria["sortfield"] = val ? val : this.props.sortField
            await this.setState({
                sortField: val ? val : this.props.sortField,
                filterCriteria: filterCriteria
            });
            // let index;
            await this.setState({
                propertyStatus: e
            })

        }
    }

    //submit groupby
    submitSort = async () => {
        await this.getDataFromServer(this.state.filterCriteria)
    }

    selectedViewType = async (e, type) => {
        if (type) {
            await this.setState({
                isFrozenColumns: e == "unfreeze" ? false : true,
                frozenWidth: e == "unfreeze" ? 0 : this.state.frozenWidth
            })
        } else {
            await this.setState({
                selectedViewType: e,
            })
        }

        // if (e === "All Validated") { this.getremoveFilters() }
        this.getTableFieldsOrder();
        this.scrollWin()
        this.setDynamicColumns()
    }

    //DealSizing Api Call
    ValidateDealSizingRecords = async => {
        let selectedRowsId = [];
        let selectedRows = [...this.state.selectedRows]
        if (selectedRows && selectedRows.length && selectedRows.length > 0) {
            selectedRows.forEach((item, index) => {
                selectedRowsId.push(item.DealID)
            })
        }
        let body = {
            url: `${config.dealUrl}&estateIds=[${selectedRowsId}]`
        }
        return fetchMethodRequest('POST', apiCalls.Deals, body)
            .then(async (response) => {
                if (response) {
                    this.getSuccessMessage(response.respMessage, "", "success")
                    this.getDataFromServer(this.state.filterCriteria)
                    await this.setState({
                        selectedRows: [],
                        selectedRowsId: [],

                    })
                } else if (response && response.errorMessage) {
                    this.getErrorMessage(response.errorMessage, "", "error")
                }
            }).catch((err) => {
                return err;
            });
    }

    toggletab(tab, setGoButton) {
        let tagSwitch = false
        let filterCriteria = this.state.filterCriteria
        if (this.state.activeTab !== tab) {
            tagSwitch = true
            this.setState({
                activeTab: tab,
                page: 1,
                first: 0,
                allUsersData: [],
                showSelectederror: false,
                validatedArray: [],
                errormsg: "",
                selectedReason: "",
                selectednote: "",
                claimID: "",
                selectedStatus: "",
                isDisabledTab: true
            });

        }
        if (tab === "SP" && this.state.PropertyData && this.state.PropertyData.groupFlag) {
            let flag = this.state.PropertyData.groupFlag.slice(0, 6);
            filterCriteria.sortfield = `${flag}ParentPropertyID, PropertyReviewedFlag, ${this.state.PropertyData.groupFlag}, PropertyName, PropertyAddressLine1`
            filterCriteria.direction = "desc"
        } else if (tab === "SP" && this.state.PropertyData && !this.state.PropertyData.groupFlag) {
            filterCriteria.sortfield = "ParentPropertyID, Group1ParentPropertyID, Group1Flag, Group2ParentPropertyID, Group2Flag, Group3ParentPropertyID, Group3Flag, Group4ParentPropertyID, Group4Flag, Group5ParentPropertyID, Group5Flag, Group6ParentPropertyID, Group6Flag, Group7ParentPropertyID, Group7Flag, Group8ParentPropertyID, Group8Flag, Group9ParentPropertyID, Group9Flag, Group10ParentPropertyID, Group10Flag, PropertyName, PropertyAddressLine1"
            filterCriteria.direction = "desc"
        } else {
            filterCriteria.sortfield = this.props.sortField
        }

        filterCriteria["page"] = 1
        if (this.editorComponentRef) {
            this.editorComponentRef.updatedfileFor()
            this.editorComponentRef.updateClaimID()
            this.editorComponentRef.handlePropertiesEditChange("", "Cancel")
        }
        if (tab === "SP") {
            this.getTableFieldsOrder()
        }
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "bucket")
        if (index !== -1) filterCriteria["criteria"].splice(index, 1)
        filterCriteria.criteria.push({
            key: "bucket",
            value: tab,
            type: "eq",
            add_Filter: true
        })
        if (this.state.Counts && tab != "Green" && tagSwitch && this.state.Counts[tab] > 10000) {
            this.setState({ isDisabledTab: true, totalRecordsLength: this.state.Counts[tab], isChangeHit: false })
            this.getDataFromServer(filterCriteria, null, setGoButton)
        } else {
            this.setState({ showErrorMessage: false })
            if (this.state.PropertyData) {
                // this.getUrlbyParams()
                // this.getTableFieldsOrder(tab)
                // if(tab==="Teal"){
                //   document.getElementsByClassName("")
                // }
                this.getDataFromServer(filterCriteria, null, setGoButton)
            }
        }

    }

    getshowSelectederrormsg = async (errormsg, showSelectederror, color) => {
        await this.setState({
            errormsg: errormsg,
            showSelectederror: showSelectederror,
            errorcolor: color === "error" ? "red" : "green"
        })
    }

    getUrlbyParams = async (type, item, clickedField) => {
        let filtercriteria = this.state.filterCriteria
        let url, index, Subject, matchMatchStatusIndex, matchMatchReasonIndex;
        // if (this.props.type === "Deal Sizing-Properties") {
        // index = filtercriteria["criteria"].findIndex(obj => obj.key === "state")
        // } else {
        index = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState") : null
        matchMatchStatusIndex = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "MatchMatchStatus") : null
        matchMatchReasonIndex = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "MatchStatusReason") : null

        // }
        if (this.props.type === "UP Opportunity – V2") {
            url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${encodeURIComponent(item.EstateName)}&sellerId=${item.CompanyID}&bucket=${"SP"}&GroupFlag=${this.state.flagName ? this.state.flagName : this.props.tabOptions && this.props.tabOptions.length > 0 ? this.props.tabOptions[0].flagName : ''}${clickedField && clickedField === "Count10kTo50k" ? `&10kto50k=` + "true" : ""}${clickedField && clickedField === "CountAbove50k" ? `&50k=` + "true" : ""}${clickedField && clickedField === "CountPropertyUnknown" ? `&unknown=` + "true" : ""}${this.state.postBody && this.state.postBody.states ? `&state=` + `${this.state.postBody.states}` : ''}`
        } else {
            if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
                if (this.props.type === "Property Editor") {
                    if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
                        url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&bucket=${this.state.activeTab}${this.state.PropertyData.isValidate ? `&isValidated=` + "true" : ""}${this.state.PropertyData.isexcludeProperties ? `&isexcludeProperties=` + "true" : ""}${this.state.PropertyData.isexcludePossiblyPaid ? `&isexcludePossiblyPaid=` + "true" : ""}${this.state.PropertyData.isexcludeValidated ? `&isexcludeValidated=` + "true" : ""}${this.state.PropertyData.excludeCoOwners ? `&excludeCoOwners=` + "true" : ""}${this.state.PropertyData.selectedProperty_Id && this.state.PropertyData.selectedProperty_Id.length > 0 ? `&propertyId=` + encodeURIComponent(this.state.PropertyData.selectedProperty_Id) : ""}${this.state.PropertyData.selectedOpraStId && this.state.PropertyData.selectedOpraStId.length > 0 ? `&missingStatePropertyIds&statePropertyId=` + encodeURIComponent(this.state.PropertyData.selectedOpraStId) : ""}${this.state.PropertyData.isexcludeRoyalties ? `&excludeRoyalties=` + "true" : ""}`
                        if (this.state.PropertyData.selectedName) {
                            url = `${url}&nameId=${encodeURIComponent(this.state.PropertyData.selectedName)}`
                        }
                        if (filtercriteria.criteria[index] && filtercriteria.criteria[index].value) {
                            url = `${url}&state=${encodeURIComponent(filtercriteria.criteria[index].value)}`
                        }
                        if (filtercriteria.criteria[matchMatchStatusIndex] && filtercriteria.criteria[matchMatchStatusIndex].value) {
                            url = `${url}&selectedPropertyStatus=${encodeURIComponent(filtercriteria.criteria[matchMatchStatusIndex].value)}`
                        }
                        if (filtercriteria.criteria[matchMatchReasonIndex] && filtercriteria.criteria[matchMatchReasonIndex].value) {
                            url = `${url}&selectedPropertyReasons=${encodeURIComponent(filtercriteria.criteria[matchMatchReasonIndex].value)}`
                        }
                        if (this.state.PropertyData.excludeNotConfirmedOnSite) {
                            url = `${url}&excludeNotConfirmedOnSite=true`
                        }
                    } else if (this.state.PropertyData.selectedProperty_Id) {
                        url = `${window.location.protocol}://${window.location.host}/nsproperties?propertyId=${this.state.PropertyData.selectedProperty_Id}`
                    } else {
                        url = `${window.location.protocol}//${window.location.host}/nsproperties`
                    }
                } else {
                    if (type === "deals") {
                        url = `${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(item.BusinessNameID)}&bucket=${this.state.activeTab}`
                    } else {
                        url = `${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(this.state.PropertyData.selectedName)}&businessState=${filtercriteria.criteria[index] ? encodeURIComponent(filtercriteria.criteria[index].value) : null}&bucket=${this.state.activeTab}${this.state.PropertyData.isValidate ? `&isValidated=` + "true" : ""}${this.state.PropertyData.isexcludeProperties ? `&isexcludeProperties=` + "true" : ""}${this.state.PropertyData.isexcludePossiblyPaid ? `&isexcludePossiblyPaid=` + "true" : ""}${this.state.PropertyData.isexcludeValidated ? `&isexcludeValidated=` + "true" : ""}${this.state.PropertyData.excludeCoOwners ? `&excludeCoOwners=` + "true" : ""}${this.state.PropertyData.selectedProperty_Id && this.state.PropertyData.selectedProperty_Id.length > 0 ? `&propertyId=` + encodeURIComponent(this.state.PropertyData.selectedProperty_Id) : ""}${this.state.PropertyData.selectedOpraStId && this.state.PropertyData.selectedOpraStId.length > 0 ? `&statePropertyId=` + encodeURIComponent(this.state.PropertyData.selectedOpraStId) : ""}`

                    }
                }
                Subject = `Properties Sharing for Review`
            } else {//for redirecting to properties from other tables
                if (type === "summary" && item) {
                    if (this.props.type === "UP Opportunity") {
                        url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item["_id"]}&state=${this.state.summaryObject && this.state.summaryObject.states ? this.state.summaryObject.states : item.SourceState ? item.SourceState : null}&bucket=${this.state.activeTab ? this.state.activeTab : "Green"}&ranges=${encodeURIComponent(this.state.summarySelectedranges)}`

                    } else {
                        url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item["_id"].CompanyID}&state=${this.state.summaryObject && this.state.summaryObject.states ? this.state.summaryObject.states : item.SourceState ? item.SourceState : null}&bucket=${"All"}&selectedPropertyStatus=${this.props.type === "Ready To File" && this.state.activeTab === "Ready To File" && this.props.ReadyToFileStatus && this.props.ReadyToFileStatus.length > 0 ? encodeURIComponent(this.props.ReadyToFileStatus) : this.props.type === "On Hold" && this.state.activeTab === "On Hold" && this.props.OnHoldStatus && this.props.OnHoldStatus.length > 0 ? encodeURIComponent(this.props.OnHoldStatus) : null}`
                    }
                    let addFilters = this.state.addFilters
                    if (addFilters.length > 0) {
                        if (addFilters.includes("isExcludeRoyalties")) {
                            url = `${url}&excludeRoyalties=true`
                        } if (addFilters.includes("excludeCoOwners")) {
                            url = `${url}&excludeCoOwners=true`
                        } if (addFilters.includes("isexcludePossiblyPaid")) {
                            url = `${url}&isexcludePossiblyPaid=true`
                        }
                    }
                } else if (type === "deals") {
                    url = `${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(item.BusinessNameID)}`
                }
            }
        }
        await this.setState({
            PropertySharebody: url,
            ProertyShareSubject: Subject,
            customerEmail: ""
        })
    }

    copyToClipboardProperties = async () => {
        navigator.clipboard.writeText(this.state.uppropPropertyIds.join("\n"))

    }
    copyToClipboardIndividual = async (id) => {
        let notUpArray = this.state.notUpdateArray
        let z = notUpArray.find(elem => elem.id === id)
        if (z) {
            z.clicked = true
        }
        await this.setState({ notUpdateArray: notUpArray })
        return navigator.clipboard.writeText(id)

    }
    openClaimSummary = async () => {
        let newArray = this.checkDataisEdited()
        if (newArray && newArray.length > 0) {
            this.OpenConfirmaionModelorSave()
        } else {
            await this.setState({ openClaimSummary: true })
        }
    }
    copyToClipboard = async () => {
        let filtercriteria = this.state.filterCriteria
        let url, index, matchMatchStatusIndex, matchMatchReasonIndex;
        matchMatchStatusIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "MatchMatchStatus")
        matchMatchReasonIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "MatchStatusReason")

        // }
        if (this.props.type === "Deal Sizing-Properties") {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "sourceState")
        } else {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "sourceState")
        }
        if (this.props.type === "Property Editor") {
            await this.getUrlbyParams()
            url = this.state.PropertySharebody
        } else {
            if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
                url = `${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&bucket=${this.state.activeTab}${this.state.PropertyData.isValidate ? `&isValidated=` + "true" : ""}${this.state.PropertyData.isexcludeProperties ? `&isexcludeProperties=` + "true" : ""}${this.state.PropertyData.isexcludePossiblyPaid ? `&isexcludePossiblyPaid=` + "true" : ""}${this.state.PropertyData.isexcludeValidated ? `&isexcludeValidated=` + "true" : ""}${this.state.PropertyData.excludeCoOwners ? `&excludeCoOwners=` + "true" : ""}${this.state.PropertyData.selectedProperty_Id && this.state.PropertyData.selectedProperty_Id.length > 0 ? `&propertyId=` + encodeURIComponent(this.state.PropertyData.selectedProperty_Id) : ""}${this.state.PropertyData.selectedOpraStId && this.state.PropertyData.selectedOpraStId.length > 0 ? `&statePropertyId=` + encodeURIComponent(this.state.PropertyData.selectedOpraStId) : ""}`
                if (filtercriteria.criteria[index] && filtercriteria.criteria[index].value) {
                    url = `${url}&state=${encodeURIComponent(filtercriteria.criteria[index].value)}`
                }
                if (filtercriteria.criteria[matchMatchStatusIndex] && filtercriteria.criteria[matchMatchStatusIndex].value) {
                    url = `${url}&selectedPropertyStatus=${encodeURIComponent(filtercriteria.criteria[matchMatchStatusIndex].value)} `
                }
                if (filtercriteria.criteria[matchMatchReasonIndex] && filtercriteria.criteria[matchMatchReasonIndex].value) {
                    url = `${url}&selectedPropertyReasons=${encodeURIComponent(filtercriteria.criteria[matchMatchReasonIndex].value)} `
                }
            } else {
                url = `${window.location.protocol}://${window.location.host}/dealproperties?propertyId=${this.state.PropertyData.selectedProperty_Id}`
            }
        }
        navigator.clipboard.writeText(url)
        await this.setState({ copiedA: true })
    }
    PropertyIdDisplay(rowData, column) {

        // var src = "showcase/demo/images/car/" + rowData.brand + ".png";
        return <span>{rowData}</span>
    }
    copyTemplate = (rowData, column) => {
        let z
        let notUpArray = this.state.notUpdateArray
        if (notUpArray) {
            z = notUpArray.find(elem => elem.id === rowData)
        }

        return (
            <div className='' >
                <FontAwesomeIcon
                    icon={faCopy}
                    className='ml-2'
                    data-toggle="tool-tip" title={("Copy")}
                    // onClick={() => navigator.clipboard.writeText(rowData)}
                    onClick={() => { this.copyToClipboardIndividual(rowData) }}
                    style={{ width: 18, color: z && z.clicked ? "blue" : "black" }}
                />
            </div>
        )
    }
    getClaimSummaryField() {
        return (
            <div className='row mb-4 d-flex justify-content-end' >


                <div>
                    <Button color="primary"
                        className='p-1 py-0 mb-0 mt-1' style={{ height: "38px" }}
                        size={'sm'} onClick={this.getDataToExport}
                        outline
                        disabled={this.state.totalRecordsLength === 0 || this.state.isexported ? true : false}
                    >
                        <FontAwesomeIcon
                            icon='download'
                            data-toggle="tool-tip" title={"Export To CSV"}
                            className='pl-1' size='lg' />
                    </Button>
                    <Button color="primary"
                        size="sm"
                        outline
                        style={{ height: 30 }}
                        className="p-1 ml-2 mt-2 mb-1"
                        onClick={() => this.getremoveFilters()}>Clear Filters
                    </Button>
                    <CSVLink
                        data={this.state.exportData}
                        headers={this.state.exportHeaders}
                        filename={`${this.props.type}.csv`}
                        className="hidden text-gray"
                        ref={(r) => this.csvLinkRef = r}
                        target="_blank" >
                    </CSVLink>
                </div>

            </div>
        )
    }

    getNegativeNoticeSellers = () => {
        if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
            let url = `NsScoredProperties/GetNegativeNotice?estateName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&estateID=${this.state.PropertyData.selectedSeller.EstateID}`
            fetchMethodRequest('GET', url)
                .then(async (response) => {
                    if (response && response.dimSellers && response.negativeNotice !== null) {
                        await this.setState({
                            redirectSellerId: response.dimSellers[0].dimSellerID,
                            negativePeriod: response.negativeNotice.noticePeriod
                        })
                    } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                        await this.setState({
                            redirectSellerId: null,
                            negativePeriod: null
                        })
                    } else {
                        await this.setState({
                            redirectSellerId: null,
                            negativePeriod: null
                        })
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }
    getAccordionHeader() {
        return (
            <div className='d-flex'>
                <span>Editor</span>
                <span style={{ marginLeft: "40px", color: this.state.errorcolor }}>{this.state.errorResponse ? this.state.errorMessage : ""}</span>

            </div>
        )
    }

    getFilerField() {
        let settingsData = this.props.settingsData
        let isEditted = localStorage.getItem("ISEdited") ? true : false
        return (
            <div className='mb-4'>
                {
                    this.props.type === "Deal Sizing Report For Companies" ?
                        this.props.getTableForDSReport
                        : <div className="" style={{ marginTop: 10 }}>
                            {/* {this.state.totalRecordsLength > 10000 || this.state.totalRecordsLength === 0 ? */}
                            <div className='d-flex'>
                                {/* <span>Records:{this.state.totalRecordsLength}</span> */}
                                <span className='ml-2 errorColor' style={(this.state.totalRecordsLength > 1000 && this.props.type === "Properties") || this.state.totalRecordsLength > 10000 && (this.props.type === "Property Editor" && this.state.activeTab !== "SP" || this.props.type === "Deal Sizing-Properties") || this.state.showErrorMessage ? { display: 'flex' } : { display: 'none' }}>
                                    Please note the count of records need to be less than {this.props.type === "Properties" ? 1000 : 10000}, for you to see results.  Please select additional filter criteria and click Go.
                                </span></div>
                            {/* : */}
                            <>
                                <div style={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "ClaimsManagement" || this.props.type === "ClaimSummary" ? { display: "block" } : { display: "none" }} >
                                    <div style={{ width: "100%" }}>
                                        <div className="m-auto ml-0 mb-4" style={{ color: "red", textAlign: "center" }}>{this.state.redirectSellerId !== null && this.state.negativePeriod !== null ? <span>This deal has negative notice period of<a className='px-1'
                                            href={config.negativeNoticeUrl} target="_blank"><u>{this.state.negativePeriod}</u></a></span> : null}
                                        </div>
                                        <Nav tabs >
                                            {
                                                this.props.type !== "ClaimsManagement" && this.state.propertyEditorTabs && this.state.propertyEditorTabs.length > 0 ? this.state.propertyEditorTabs.map((item, index) => {
                                                    return (
                                                        <NavItem key={index}>
                                                            <NavLink
                                                                className={classnames({ active: this.state.activeTab === item.name })}
                                                                onClick={() => { this.toggletab(item.name); }}
                                                            >
                                                                <span data-toggle="tool-tip" title={item.tooltip ? item.tooltip : item.name} style={this.state.isDisabledTab ? { color: "lightgray" } : { color: item.color, }}>{item.name}({this.state.Counts ? this.state.Counts[item.name] ? this.state.Counts[item.name] : 0 : 0})</span>
                                                            </NavLink>
                                                        </NavItem>
                                                    )
                                                }) : null
                                            }
                                            <div style={{ margin: "auto", color: this.state.errorcolor }}>
                                                {this.state.showSelectederror ? this.state.errormsg : null}
                                            </div>
                                            <div style={{ margin: "auto", color: "green" }}>
                                                {this.state.columnOrderError ? this.state.columnOrderMsg : null}
                                            </div>
                                            <div style={{ margin: "auto", color: "green" }}>
                                                {this.state.copiedA ? "Link Copied Successfully." : null}
                                                {/* {this.state.propertyData&&!this.state.propertyData.select ? "Link Copied Successfully." : null} */}
                                            </div>
                                            <>{this.state.PropertyData && this.state.PropertyData.selectedSeller ? this.props.getModaltoCreateProperty : null}</>

                                            {/* {this.state.showSelectederror ? */}
                                            {/* {this.props.type === "ClaimsManagement" ? null : <FontAwesomeIcon icon={faCopy}
                        className='ml-4'
                        color='#354f6e'
                        data-toggle="tool-tip"
                        // onClick={() => { navigator.clipboard.writeText(this.state.exhibit1String) }}
                        onClick={() => { this.copyToClipboard() }}
                        title={"Copy"}
                        style={{ width: 18, marginTop: "20px", fontSize: "19px" }}
                      />} */}
                                            {this.props.type === "ClaimsManagement" ? null :
                                                <a className="lnr lnr-envelope shareIcon" onClick={() => this.getUrlbyParams()} href={`mailto:${this.state.customerEmail}?subject=${this.state.ProertyShareSubject}&body=Hi%0APlease%20review%20these%20properties.%0A%0A${encodeURIComponent(this.state.PropertySharebody)}%0A%0A%0AThanks%0AOPRA2%0Aopra@oakpointpartners.com`} >
                                                </a>
                                            }
                                            {this.props.type === "ClaimsManagement" ?
                                                <Button color="primary" outline
                                                    className='p-1 ml-2 mt-2 mb-1'
                                                    size={'sm'}
                                                    style={{ height: 30 }}
                                                    disabled={isEditted}
                                                    onClick={() => this.openClaimSummary()}>
                                                    <FontAwesomeIcon
                                                        icon=""
                                                        data-toggle="tool-tip" title={"Save"}
                                                        className='pl-1' size='lg' />{this.props.type === "ClaimsManagement" ? "Claim Summary" : "Save"}
                                                </Button>
                                                : null}
                                            {this.props.type === "ClaimsManagement" ?
                                                <Button color="primary" outline
                                                    className='p-1 ml-2 mt-2 mb-1'
                                                    size={'sm'}
                                                    style={{ height: 30 }}
                                                    onClick={() => this.handleCheckValidations()}>
                                                    <FontAwesomeIcon
                                                        icon={faSave}
                                                        data-toggle="tool-tip" title={"Save"}
                                                        className='pl-1' size='lg' />{this.props.type === "ClaimsManagement" ? "Save Claim" : "Save"}
                                                </Button>
                                                : null}

                                            {this.props.type === "ClaimsManagement" ?
                                                <Button color="danger" outline
                                                    className='p-1 ml-2 mt-2 mb-1'
                                                    size={'sm'}
                                                    style={{ height: 30 }}
                                                    onClick={() => this.CancelMultiLine('isCancel')}>
                                                    <FontAwesomeIcon
                                                        icon={faArrowCircleLeft}
                                                        data-toggle="tool-tip" title={"Cancel"}
                                                        className='pl-1' size='lg' />{this.props.type === "ClaimsManagement" ? "Cancel Claim Edit" : "Cancel"}
                                                </Button>
                                                : null}
                                            {this.props.type === "Property Editor" ?
                                                <Button color="primary"
                                                    className='p-1 py-0 mb-0 mt-1' style={{ height: "38px" }}
                                                    size={'sm'} onClick={this.getDataToExport}
                                                    outline
                                                    disabled={this.state.totalRecordsLength > 10000 || this.state.isexported || isEditted ? true : false}
                                                >
                                                    <FontAwesomeIcon
                                                        icon='download'
                                                        data-toggle="tool-tip" title={"Export To CSV"}
                                                        className='pl-1' size='lg' />
                                                </Button> :
                                                null
                                            }
                                            {this.props.type === "ClaimsManagement" ? null :
                                                <CSVLink
                                                    data={this.state.exportData}
                                                    headers={this.state.exportHeaders}
                                                    filename={`${this.state.PropertyData && this.state.PropertyData.selectedSeller ? this.state.PropertyData.selectedSeller.EstateName : this.props.type}-${this.state.activeTab}.csv`}
                                                    className="hidden text-gray p-0"
                                                    ref={(r) => this.csvLinkRef = r}
                                                    target="_blank" >
                                                </CSVLink>}
                                            <Button color="primary"
                                                size="sm"
                                                outline
                                                style={{ height: 30 }}
                                                className="p-1 ml-2 mt-2 mb-1"
                                                disabled={isEditted}
                                                onClick={() => this.getremoveFilters()}>Clear Filters
                                            </Button>
                                        </Nav>
                                    </div>
                                </div>
                            </>
                            {/* } */}
                        </div>
                }
            </div >
        )
    }

    //to remove datatable all Filters 
    getremoveFilters = async () => {
        let newArray = this.checkDataisEdited()
        if (newArray && newArray.length > 0) {
            this.OpenConfirmaionModelorSave()
        } else {
            await this.setState({
                clearFilters: true,
                page: 1,
                first: 0
            })
            let filteredNo = this.state.tablefieldsToShow.length
            for (let i = 0; i <= filteredNo; i++) {
                if (document.getElementsByClassName("p-column-filter")[i] && document.getElementsByClassName("p-column-filter")[i].value)
                    if (document.getElementsByClassName("p-column-filter")[i].value !== "") {
                        let getName = document.getElementsByClassName("p-column-filter")[i].name
                        document.getElementsByClassName("p-column-filter")[i].value = ""
                        if (document.getElementsByClassName(`p-column-filter p-column-${getName}`).value) {
                            document.getElementsByClassName(`p-column-filter p-column-${getName}`).value = ""
                        }
                        this.setState({
                            [getName]: ""
                        })
                    }
                if (document.getElementsByClassName("p-column-filterMode")[i]) {
                    document.getElementsByClassName("p-column-filterMode")[i].selectedIndex = 0
                }
                if (document.getElementsByClassName("p-column-filter p-column-DWDateInserted") && document.getElementsByClassName("p-column-filter p-column-DWDateInserted")[0] && document.getElementsByClassName("p-column-filter p-column-DWDateInserted")[0].value) {
                    document.getElementsByClassName("p-column-filter p-column-DWDateInserted")[0].value = ""
                }
                this.setState({
                    hasPropertyCoOwner: "",
                    matchMatchStatus: "",
                    matchStatusReason: "",
                    confirmedOnsite: "",
                    effectiveFromDate: "",
                })
                if (document.getElementsByClassName("p-column-filterComponent")[i] && document.getElementsByClassName("p-column-filterComponent")[i].style.display === "block") {
                    document.getElementsByClassName("p-column-filterComponent")[i].style.display = "none"
                }
            }
            // if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt")&&())

            this.setState({
                hasPropertyCoOwner: "",
                confirmedOnsite: ""
            })

            //removing inline filters
            let filterCriteria = this.state.filterCriteria
            var newArr = filterCriteria["criteria"].filter(object => {
                return object.add_Filter !== false;
            });
            filterCriteria["criteria"] = newArr
            filterCriteria["page"] = 1
            if (this.props.type === "ClaimSummary") {
                filterCriteria.sortfield = this.state.clearFiltersGroupBy
            }
            if (this.props.type !== "UP Opportunity" && this.props.type !== "State Attributes") { this.getDataFromServer(filterCriteria) } else if (this.props.type === "UP Opportunity") {
                await this.setState({
                    filterCriteria: filterCriteria
                })
                this.getUsersSummaryData(this.state.summaryObject, true)
            }
        }
    }

    getTotalCard() {
        if (this.state.viewType === 'list') {
            return (
                <Card style={this.props.type === "ClamisManagement" ? { paddingBottom: "10px", paddingTop: "15px", margin: 0 } : { paddingBottom: "10px", paddingTop: "0px", margin: 0 }} className={!this.props.className ? "customCard cardForListMargin m-0" : "cardForListMargin m-0"}>
                    <CardBody className={this.props.type ? 'tableCardBody' : 'modalTablePadding'} style={{ borderRadius: "0px" }}>
                        {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getScreenHeader() : this.state.displayViewOfForm === 'modal' ? this.getHeader() : null}
                        {this.getTabInfo()}
                        {this.props.isCustomHeader ? this.props.getHeaderforSchedule() : ""}
                        {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen'
                            ? this.getDataTable()
                            : this.state.displayViewOfForm === 'modal' && this.state.isShowTable ?
                                this.getDataTable() : null}
                        {this.state.isOpenFormModal && this.props.type !== "List View" ? this.getFormModal() : null}
                        <span className='float-right pt-2'>
                            <div className='col-12 px-0 pb-1'>
                                {!this.state.isOpenFormModal ? this.getPaginator() : null}
                            </div>
                        </span>
                    </CardBody>
                </Card >

            )
        } else if (this.state.viewType === 'grid') {
            return (
                <div>
                    <Card
                        className={' pb-2 cardForGridMargin'}>
                        <CardBody className='tableCardBody pb-0' style={{ borderRadius: "0px" }}>
                            {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getScreenHeader() : this.state.displayViewOfForm === 'modal' ? this.getHeader() : null}
                            {this.getTabInfo()}
                            {this.state.isOpenFormModal ? this.getFormModal() : null}
                        </CardBody>
                    </Card>
                    {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getGridView() : this.state.displayViewOfForm === 'modal' ? this.getGridView() : null}
                </div>
            )
        }
    }

    getTabTypeCard() {
        const { tabOptions } = this.props
        return (
            <Card style={{ paddingBottom: "10px", paddingTop: "15px", margin: 0 }} className={!this.props.className ? "customCard cardForListMargin m-0" : "cardForListMargin m-0"}>
                <CardBody className='tableCardBody' style={{ borderRadius: "0px" }}>
                    <div style={{ width: "100%" }}>
                        <Nav tabs >
                            {
                                tabOptions && tabOptions.length > 0 ? tabOptions.map((item, index) => {
                                    let tabName = item && item.name ? item.name : item
                                    return (
                                        <NavItem style={{ width: "20%" }}>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === tabName })}
                                                disabled={this.state.isLoading ? true : false}
                                                onClick={() => { this.getSelectedTabTypeInfo(item, index); }}
                                            >
                                                <h4>{item.name ? item.name : item}</h4>
                                            </NavLink>
                                        </NavItem>
                                    )
                                }) : null
                            }

                        </Nav>
                        {this.getTotalCard()}
                    </div>
                </CardBody>
            </Card>
        )
    }

    getMobileCard() {
        return (
            <div>
                <Loader loader={this.state.isLoading} progress={0} />
                {this.state.isOpenFormModal ? null : <div className='row mr-0 ml-0'>
                    <div className='col-sm-12 px-0 pb-2'>
                        <h4><span className='mobileScreenHaederView'><b >{this.props.type}</b></span>
                            <span className='float-right'>
                                <Button color="primary"
                                    size="sm"
                                    className="p-1 ml-auto mt-1 mb-0"
                                    onClick={() => this.openShowHideColumnsModal()}
                                >
                                    <FontAwesomeIcon
                                        icon='cog'
                                        className='pl-1' size='lg'
                                        data-toggle="tool-tip" title="Settings"
                                        onClick={() => this.openShowHideColumnsModal()}
                                    />
                                </Button>
                            </span>
                        </h4>
                    </div>
                </div>}
                {this.state.isOpenFormModal ? this.getMobileForm() : this.getGridView()}
                {!this.state.isOpenFormModal ? <PaginatorComponent
                    totalRecords={this.state.totalRecordsLength}
                    first={this.state.first}
                    rows={this.state.rows}
                    onPageChange={this.onPageChange}
                    isWeb={false}
                /> : null}
            </div >
        )
    }

    getArrayVal = (val) => {
        if (val) {
            val = val.replace(/\\n/g, ' ')
                .replace(/"/g, '')
                .replace(/\[/g, '')
                .replace(/]/g, '')
                .replace(/{/g, '')
                .replace(/}/g, '')
                .replace(/,/g, ' , ')
                .replace(/:/g, ' : ')
                .replace(/\\/g, ' ')
            // .replace(/,/g, ' ');
        }
        return val;
    }

    getvalfromObj = (val, field) => {
        if (val) {
            val = val[field]
        }
        return val;
    }

    slicedArray = (arrayVal) => {
        let arrLength;
        if (arrayVal && arrayVal.length)
            arrLength = arrayVal.length;
        if (arrayVal) {
            // val = 
            let statecp = []
            for (let sine in arrayVal) {
                statecp.push(arrayVal[sine].slice(-2))
            }
            arrayVal = statecp.join(', ')
        }
        if (arrLength > 3) {
            arrayVal = arrayVal.slice(0, 10);
            arrayVal = arrayVal + "  (" + arrLength + ")";
        } else {
            arrayVal = arrayVal
        }
        return arrayVal;
    }

    flattenArray = (arrayVal, item) => {
        let val = '';
        let arrLength = ""
        if (this.props.type === "List View" && arrayVal && arrayVal.length > 3) {
            arrLength = arrayVal.length
            arrayVal = arrayVal.slice(0, 3);
        }
        if (arrayVal) {
            val = JSON.stringify(arrayVal);
            if (this.props.type !== "Weekly Recovery Plan" && this.props.type !== "Weekly Recovery Report"
                && this.props.type !== "Utilization - US Team" && this.props.type !== "Utilization - Integrity" &&
                this.props.type !== "Process Documents") {
                if (this.props.type === "List View") {
                    if (arrLength > 3) {
                        val = this.getArrayVal(val) + "  (" + arrLength + ")";
                    } else {
                        val = this.getArrayVal(val)
                    }
                } else if (this.props.type === "ClaimsManagement" && arrayVal[0] && arrayVal[0].checkAmount) {
                    let dollarUS = this.getFormattedCurrencyValue()
                    val = arrayVal.map((arrayItem, index) =>
                        <p data-toggle="tool-tip"
                            title={arrayItem.checkAmount} onClick={() => this.openFormModal(item, 'edit', "Payments", arrayItem)}>{dollarUS.format(arrayItem.checkAmount)}</p>
                    );
                } else if (this.props.type === "ClaimsManagement" || this.props.type === "Claims" || this.props.type === "State Attributes" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
                    val = arrayVal.map((arrayItem) =>
                        arrayItem !== null ?
                            <p data-toggle="tool-tip"
                                title={arrayItem.note}>{arrayItem.note}</p>
                            : null
                    );
                } else {
                    val = this.getArrayVal(val)
                }
            } else if (this.props.type === "Process Documents") {
                val = arrayVal.map((arrayItem) =>
                    <p className='mb-1' style={{ height: 20 }} data-toggle="tool-tip"
                        title={arrayItem.documentName}><a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={`${arrayItem.documentUrl}`}>{arrayItem.documentName}</a></p>
                );
            } else {
                val = arrayVal.map((arrayItem) => {
                    if (this.props.type !== "Weekly Recovery Plan" && this.props.type !== "Weekly Recovery Report") {
                        return (
                            arrayItem.company === "Total" ?
                                <p style={{ color: arrayItem.dealType === "BK" ? "brown" : "black", marginTop: 1 }} data-toggle="tool-tip"
                                    title={arrayItem.company + " (" + arrayItem.spent.toFixed(1) + ")"}>{arrayItem.company + ": " + arrayItem.spent.toFixed(1) + " Hrs"}</p>
                                :
                                <p style={{ color: arrayItem.dealType === "BK" ? "brown" : "black", marginTop: 1 }} data-toggle="tool-tip"
                                    title={arrayItem.company + " (" + arrayItem.spent.toFixed(1) + ")"}>{arrayItem.company + " (" + arrayItem.spent.toFixed(1) + ") "}</p>
                        )
                    } else {
                        return (
                            <p title={arrayItem.userName}>{arrayItem.userName}</p>
                        )
                    }
                }
                );
            }
        }

        return val;
    }

    timeConversionString(date) {
        return moment(date).tz(moment.tz.guess()).format(config.dbOnlyDateFormat);
    }
    getPreviousMondayDate(date) {
        var prevMonday = new Date(date);
        prevMonday.setDate(prevMonday.getDate() - 7);
        return prevMonday;
    }
    //to get previous week dates
    getPreviousMonday = async (date) => {
        date = this.timeConversionString(date);
        // const dateCopy = new Date(new Date(date));
        // let addDays
        // if (moment.tz.guess() === "America/Chicago") {
        //   addDays = 7
        // } else if (moment.tz.guess() === "Asia/Calcutta") {
        //   addDays = 6
        // } else {
        //   addDays = 7
        // }

        // const previousMonday = new Date(
        //   dateCopy.setDate(
        //     dateCopy.getDate() - addDays,
        //   ),
        // );


        let datttttta = this.getPreviousMondayDate(date)
        var result = new Date(datttttta);
        result.setDate(result.getDate() + 4);
        let prevstartDateReports = dateFormats.formatDate(datttttta, "MM/DD/YY", "NSd");
        let prelastDateReports = dateFormats.formatDate(result, "MM/DD/YY", "NSd");
        await this.setState({
            allUsersData: [],
            disableIcon: true,
            startDateReports: prevstartDateReports,
            lastDateReports: prelastDateReports,
            errorResponse: false,
            page: 1,
            first: 0,
            errorMessage: "",
            softMessage: "",
            softWarning: false
        })
        this.state.filterCriteria["page"] = 1
        this.checkWhichWeek();
        this.getDataFromServer(this.state.filterCriteria);
    }

    //to check the selected week is previous or not
    checkWhichWeek = async () => {
        const selected = new Date(this.state.lastDateReports);
        const maxDate = new Date();
        maxDate.setHours(0, 0, 0, 0);
        maxDate.setDate(maxDate.getDate());
        if (selected < maxDate) {
            await this.setState({ isPreviousWeek: true })
            this.getTableFieldsOrder();
        } else {
            await this.setState({ isPreviousWeek: false })
            this.getTableFieldsOrder();
        }
    }

    setHeadersforxlsx = (tabName) => {
        let arrayFormatcolumns = []
        let data = JSON.parse(localStorage.getItem("tableFields_download"))
        let getHeadersfrom = data && data[tabName] ? data[tabName] : this.state.tablefieldsToShow
        for (var obj of getHeadersfrom) {
            if (obj.field !== "Actions" && obj.field !== "") {
                arrayFormatcolumns.push(obj.field)
            }
        }
        return arrayFormatcolumns
    }
    // Get info based on Selected Tab
    getSelectedTabTypeInfo = async (tabType, index, tableData) => {
        await this.setState({
            isLoading: true
        })
        this.handlePercentage("1")
        if (document.getElementById("globalSearch") && document.getElementById("globalSearch").value) {
            document.getElementById("globalSearch").value = ""
        }
        var key;
        let filterCriteria = this.state.filterCriteria, bodyData = {};
        if (filterCriteria && this.props.type !== "Deal Statistics") {
            filterCriteria.page = 1
            filterCriteria.criteria = []
        }
        if (this.props.type === "UP Opportunity – V2") {
            key = tabType.flagName
            filterCriteria.criteria = [{ "key": key, "value": true, "type": "eq", add_Filter: true }]
            bodyData = this.state.postBody
        }
        var sellerId;
        let tableFields
        if (this.props.type === "Deal Statistics" && tabType === "Data") {
            let CompanyID = filterCriteria["criteria"].findIndex(obj => obj.key === "CompanyID")
            if (CompanyID !== -1) {
                filterCriteria["criteria"].splice(CompanyID, 1)
            }
        }
        tableFields = await this.props.getTableFields(tabType.flagTimeStamp ? tabType.flagTimeStamp : tabType);
        if (!tableData) {
            let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
            let columns = JSON.parse(colOrder);
            if (columns) {
                await this.getTableFieldsOrder();
            } else {
                await this.updateColumnsOrder(null);
            }
        }
        var downloadData = this.state.downloadData
        if (this.props.xlsxExport && !tableData) {
            let obj = {
                name: this.state.activeTab,
                headers: this.setHeadersforxlsx(this.state.activeTab),
                data: this.state.allUsersData
            }

            // console.log(downloadData)
            let objId = downloadData && downloadData.length > 0 ? downloadData.findIndex(obj => obj.name === this.state.activeTab) : -1
            if (objId !== -1) {
                downloadData.splice(objId, 1)
            }
            downloadData.push(obj)
        }
        if (tabType === "Summary" && this.state.criteriaStatis) {
            filterCriteria = JSON.parse(this.state.criteriaStatis)
        }
        await this.setState({
            previousTab: this.state.activeTab,
            tableFields: tableFields,
            tabNo: index,
            sellerId: localStorage.getItem("selectedDeal") ? localStorage.getItem("selectedDeal") : "",
            ruleFlag: tabType.flagTimeStamp ? tabType.flagTimeStamp : "",
            // isLoading: false,
            flagName: tabType.flagName ? tabType.flagName : "",
            mobileListFields: tableData ? tableData : tableFields,
            tablefieldsToShow: tableData ? tableData : tableFields,
            downloadData: downloadData,
            filterCriteria: filterCriteria,
            originalTableFields: tableData ? tableData : tableFields,
            allUsersData: [],
            page: 1,
            limit: filterCriteria ? filterCriteria.limit : 10,
            first: 0,
            rows: filterCriteria ? filterCriteria.limit : 10,
            totalRecordsLength: 0,
            tabType: tabType.name ? tabType.name : tabType,
            activeTab: tabType.name ? tabType.name : tabType,
        })
        if (this.state.previousTab === "Data" && this.state.activeTab === "Summary") {
            tableFields = await this.dealStatsFilterRef.changeingColumnFieldsOrder()
            await this.setState({
                tablefieldsToShow: tableFields
            })
        }
        await this.getDataFromServer(filterCriteria, tabType, "", bodyData);

    }
    removeSelectedRows = async () => {
        this.setState({
            selectedRows: []
        })
    }
    //to get next week dates
    getNextMondayasync = async (date) => {
        var d = new Date(date);
        d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7));
        // date = moment(date).tz(moment.tz.guess()).format(config.dbOnlyDateFormat); // est time
        // const dateCopy = new Date(new Date(date).getTime());
        // let addDays
        // if (moment.tz.guess() === "America/Chicago") {
        //   addDays = 7
        // } else if (moment.tz.guess() === "Asia/Calcutta") {
        //   addDays = 8
        // } else {
        //   addDays = 7
        // }
        // const nextMonday = new Date(
        //   dateCopy.setDate(
        //     dateCopy.getDate() + addDays,
        //   ),
        // );
        var result = new Date(d);
        result.setDate(result.getDate() + 4);
        let nextstartDateReports = dateFormats.formatDate(d, "MM/DD/YY", "NSd");
        let nextlastDateReports = dateFormats.formatDate(result, "MM/DD/YY", "NSd");
        await this.setState({
            allUsersData: [],
            startDateReports: nextstartDateReports,
            lastDateReports: nextlastDateReports,
            disableIcon: true,
            errorResponse: false,
            page: 1,
            first: 0,
            errorMessage: "",
            softMessage: "",
            softWarning: false
        })
        this.state.filterCriteria["page"] = 1
        this.checkWhichWeek();
        this.getDataFromServer(this.state.filterCriteria);
    }

    getMobileForm() {
        return (
            <div className='row'>
                <div className='col-sm-12 px-1'>
                    <Card className='pb-0'>
                        <CardBody className='tableCardBody pb-0'>
                            {/* {this.getScreenHeader()} */}
                            {this.getFormModal()}
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }

    getFormFields = () => {
        this.formModalRef.getFormFields()
    }

    //Kanbam Modal in List View Screen
    getFormModal() {
        return (
            <FormModal
                onRef={(ref) => this.formModalRef = ref}
                city={this.state.city}
                handleUsersData={this.handleUsersData}
                getFilteredValues={this.getStateFIlingValues}
                fontAwesome={this.props.fontAwesome}
                onShowPrivateCompanies={this.onShowPrivateCompanies}
                getPublicCompanies={this.props.getPublicCompanies}
                getPublicAffiliateCompanies={this.props.getPublicAffiliateCompanies}
                openFormModal={this.state.isOpenFormModal}
                allUsersData={this.state.allUsersData}
                totalRecords={this.state.totalRecordsLength}
                getTableFields={this.props.getTableFields}
                getAcquisitionFields={this.props.getAcquisitionFields}
                getAffiliatesFields={this.props.getAffiliatesFields}
                getNewsFields={this.props.getNewsFields}
                getContactsFields={this.props.getContactsFields}
                getNamesAddressFields={this.props.getNamesAddressFields}
                getPreAPAFields={this.props.getPreAPAFields}
                first={this.state.first}
                icon={this.props.icon}
                rows={this.state.rows}
                getErrorMessage={this.getErrorMessage}
                closeFormModal={this.closeFormModal}
                openViewModal={this.openViewModal}
                attributeData={this.state.getAllresponse}
                closeRejectModal={this.closeRejectModal}
                type={this.props.type}
                tablefieldsToShow={this.state.tablefieldsToShow}
                originalTableFields={this.state.originalTableFields}
                formType={this.state.formType}
                formFields={this.state.formFields}
                getDataFromServer={this.getDataFromServer}
                onFilterChange={this.onFilterChange}
                selectFilterValue={this.state.selectFilterValue}
                editRequired={this.props.editRequired}
                getEditInfoKeys={this.props.getEditInfoKeys}
                filterExtension={this.props.filterExtension}
                idNotRequired={this.props.idNotRequired}
                apiUrl={this.props.formUrl ? this.props.formUrl : this.state.apiUrl}
                role={this.state.loginRole}
                tabType={this.state.tabType}
                getDoctorPostingFields={this.props.getDoctorPostingFields}
                categoryNames={this.props.categoryNames}
                companiesList={this.props.companiesList}
                getSettings={this.getSettings}
                filterCriteria={this.state.filterCriteria}
                menuList={this.props.menuList}
                routeTo={this.props.routeTo}
                displayViewOfForm={this.state.displayViewOfForm}
                displayBreadCrumbValue={this.state.displayBreadCrumbValue}
                displayBreadCrumbField={this.state.displayBreadCrumbField}
                userStatus={this.state.userStatus}
                actionsTypes={this.state.actionsTypes}
                entityType={this.props.entityType}
                sessionWarning={this.props.sessionWarning}
                setFilterCriteriaForActivities={this.setFilterCriteriaForActivities}
                selectedInfo={this.state.selectedInfo}
            />
        )
    }

    getOpenShowHideColumnsModal() {
        return (
            <ShowHideColumnsModal
                isOpenShowHideColumnsModal={this.state.isOpenShowHideColumnsModal}
                closeShowHideColumnsModal={this.closeShowHideColumnsModal}
                tableFields={this.state.originalTableFields}
                type={this.props.type}
                viewBy={this.state.selectedViewType}
                originalTableFields={this.state.tableFields}
                isShownnFieldType={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor"
                    || this.props.type === "Properties" || this.props.type === "ClaimsManagement"
                    || this.props.isShownnFieldType ? false : true}

            />
        )
    }

    //Delete Modal
    getDeleteRowModal() {
        return (
            <DeleteRowModal
                openDeleteModal={this.state.openDeleteModal}
                closeDeleteModal={this.closeDeleteModal}
                selectActions={this.state.selectActions}
                deleteSelectedRow={this.deleteSelectedRow}
                confirmModalText={this.state.confirmModalText}
                type={this.props.type}
            />
        )
    }

    //Session Expiry Modal
    getSessionExpiryModal() {
        return (
            <SessionExpiryModal
                SOpen={this.state.sessionExpiryModal}
            />
        )
    }


    render() {
        return (
            localStorage.getItem("loginCredentials") ?
                <div >
                    <Loader loader={this.state.isLoading} progress={this.state.progress} className={this.props.isMatchproperties ? "progress-loader_match" : this.props.type === "Legal Entity Suffix" || this.props.type === "Legal Entity Abbreviation" || this.props.type === "State-UP Filing Category" || this.props.type === "State-UP Filing Sub Category" || this.props.type === "Available To File - Statuses" ? "configuration" : "progress-loader"} />
                    {/* {this.props.windowLocation&&this.state.acti ? this.opennewComponentDidMount() : null} */}
                    {this.state.width <= 576 && this.getMobileCard()}
                    {/* {this.state.width >= 577 && this.getTotalCard()} */}
                    {this.props.tabType && this.state.width >= 577 ? this.getTabTypeCard() : this.state.width >= 577 && this.getTotalCard()}
                    {this.state.openDeleteModal ? this.getDeleteRowModal() : null}
                    {this.state.issellerModal ? <Redirect to="/sellersAdd" /> : null}
                    {this.state.iscrMatrixModal ? <Redirect to="/crMatrixAdd" /> : null}
                    {this.state.issellerModalEdit ? <Redirect to={`/sellersEdit/${this.state.sellerID}`} /> : null}
                    {this.state.issellerModalView ? <Redirect to={`/sellersView/${this.state.sellerID}`} /> : null}
                    {this.state.iscrMatrixModalEdit ? <Redirect to={`/crMatrixEdit/${this.state.crMatrixId}`} /> : null}
                    {this.state.isOpenShowHideColumnsModal ? this.getOpenShowHideColumnsModal() : null}
                    {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}
                    {this.state.sessionWarning ? this.getSessionWraningModal() : null}
                    {this.state.forceLogout ? <Redirect to="/log_in" /> : ""}
                    {this.state.isCompanyScreen ? <Redirect to="/company" /> : ""}
                    {this.state.openClaimSummary ? <Redirect to="/claimsummary" /> : null}
                    {this.state.goBackToClaims ? <Redirect to="/claims" /> : null}
                    {this.state.isApplyFilter ? this.getConfirmaionModel() : null}
                    {this.state.isopenConfirmaionModelforSave ? this.getConfirmaionModelforSave() : null}
                    {
                        this.state.CompanyDetailView ?
                            <Redirect
                                to={{
                                    pathname: `companyView/${this.state.redirectCompanyId}`,
                                    state: { companyId: this.state.redirectCompanyId }
                                }} />
                            : null
                    }
                    {
                        this.state.iskanbanModal && this.props.type === "Rules Configuration Workflow" && this.state.formType !== "edit" ?
                            <Redirect
                                to={`/ruleConfigurationadd`} />
                            : this.state.iskanbanModal && this.props.type === "Rules Configuration Workflow" && this.state.formType === "edit" ?
                                <Redirect
                                    to={{
                                        pathname: `ruleConfigurationadd/${this.state.taskrowData._id}`,
                                        state: { companyId: this.state.taskrowData._id }
                                    }} />
                                : null
                    }
                </div > :
                <div></div>
        );
    }
}



// const mapStateToProps = state => {
//   return {articles: state.articles };
// };
// const List = connect(mapStateToProps)(DataTables);

export default withTranslation('common')(DataTables);