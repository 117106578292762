import React from 'react';
import DataTables from '../../CommonDataTable/DataTableBucket';

// config file
export default class BucketDealSizing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: ''
    };
  }

  getTableFields = () => {

    return [];
  };

  getTableFields1 = () => {

    return [];
  };

  getFormFields = () => {
    return ([]);
  }

  render() {
    return (
      <span>
        <DataTables
          onRef={ref => (this.dataTableRef = ref)}
          addRequired={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={false}
          exportRequired={false}
          sample={false}
          getTableFields={this.getTableFields}
          getTableFields1={this.getTableFields1}
          formFields={this.getFormFields}
          printRequried={false}
          settingsRequired={true}
          filterRequired={true}
          gridRequried={true}
          exportToCsv={true}
          ScoringType={"DealSizing"}
          globalSearch={'Name String'}
          type='Buckets'
          routeTo={""}
          displayViewOfForm='screen'
          apiResponseKey={""}
          apiUrl={""}

        />
      </span>
    );
  }
}