import React from 'react';
import DataTables from '../../CommonDataTable/DataTableBucket';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import ViewModal from '../../CommonModals/viewModal';
import NewUserModal from '../../CommonModals/NewUserModal';

import FormModal from '../../../Form/FormModal';
import store from '../../../App/store';
import filePath from "../../../../config/configSampleFiles";
// config file
export default class Teachers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: ''
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getTableFields = () => {

    return [];
  };

  getTableFields1 = () => {

    return [];
  };

  getFormFields = () => {
    return ([]);
  }
  getFormFields1 = () => {
    return ([
    ]);
  }

  submit = async (item) => {

    this.setState({ isOpenFormModal: true })
    await this.formModalRef.getRowData(item, 'edit');

    console.log("Submit Button in sode          ")
  }
  getMobileTableFields = () => {
    let data = [{ "textAlign": "center", "width": 47, "field": "", "fieldType": "multiple", "header": "", "selectionMode": "multiple", "show": true, "mobile": true, "displayInSettings": true }, { "textAlign": "center", "width": 47, "field": "Sno", "header": "SNo", "filter": false, "sortable": false, "placeholder": "Search", "show": true, "mobile": true, "displayInSettings": true }, { "name": "propretyName", "type": "text", "placeholder": "propretyName", "label": "Matching Property Name", "id": "propretyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propretyName", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyString", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "nameScore", "type": "text", "placeholder": "nameScore", "label": "Name Score", "id": "nameScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "nameScore", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }]

    return data;
  };
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
    })
  }
  // submit1 = item => {
  //   let x = scoringsFields
  //   let objscorings = {}

  //   objscorings["_id"] = item[0]["_id"]
  //   for (let x2 of x) {
  //     objscorings[x2] = item[0][x2]
  //   }
  //   let formFields = this.getFormFields()

  //   formFields = formFields.filter(y => x.includes(y.name))

  //   this.setState({
  //     openNewUserModal: true,
  //     item: objscorings,
  //     newFormFields: formFields
  //   });
  // }

  cancelReset = async (type) => {
    this.setState({
      openNewUserModal: false
    });
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };
  render() {
    return (
      <span>
        <DataTables
          onRef={ref => (this.dataTableRef = ref)}
          // MobileTableFields={this.getMobileTableFields}
          // getTableFields={this.getTableFields}
          // formFields={this.getFormFields}
          // 
          addRequired={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={false}
          exportRequired={false}
          sample={false}
          // globalSearch={'Display Name/Email'}
          // type='Scorings'
          // apiUrl={apiCalls.Scorings}
          getTableFields={this.getTableFields}
          getTableFields1={this.getTableFields1}
          formFields={this.getFormFields}
          // exportRequried={true}

          printRequried={false}
          actionsTypes={[{
            'name': 'Delete',
            "options": [
              { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
            ]
          },
          // {
          //   'name': 'Block',
          //   "options": [
          //     { 'label': 'Block', 'value': 'Block', 'show': true, "multiple": false, }
          //   ]
          // },
          {
            'name': 'ResetPassword',
            "options": [
              { 'label': 'ResetPassword', 'value': 'ResetPassword', 'show': true, "multiple": false, }
            ]
          },
          {
            'name': 'Submit',
            'action': this.submit,
            "options": [
              { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
            ]
          },
            // {
            //   name: "newModel",
            //   action: this.submit1,
            //   options: [
            //     { label: "newModel", value: "newModel", show: true, multiple: false }
            //   ]
            // }

          ]}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={true}
          gridRequried={true}
          exportToCsv={true}

          // sampleFilePath={filePath.scorings}

          globalSearch={'Name String'}
          type='CompareStrings'
          routeTo={""}
          displayViewOfForm='screen'
          apiResponseKey={""}
          apiUrl={""}

        />
        {this.state.isOpenFormModal ? (
          <FormModal
            onRef={(ref) => (this.formModalRef = ref)}
            formType="edit"
            openFormModal={this.state.isOpenFormModal}
            formFields={this.props.formFields}
          />

        ) : null}
      </span>
    );
  }
}