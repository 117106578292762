import React from 'react';
import DataTables from '../../CommonDataTable/DataTableEstateMongo';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import ViewModal from '../../CommonModals/viewModal';
import NewUserModal from '../../CommonModals/NewUserModal';

import FormModal from '../../../Form/FormModal';
import store from '../../../App/store';
import filePath from "../../../../config/configSampleFiles";
// config file
export default class Scoring2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: ''
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = []
    return data;
  };

  getTableFields1 = () => {
    let data = [
      {
        "name": "OPRA_AddressDetailID",
        "type": "text",
        "placeholder": "OPRA_AddressDetailID",
        "label": "OPRA_AddressDetailID",
        "id": "OPRA_AddressDetailID",
        "textAlign": "Left",
        "show": true,
        "field": "OPRA_AddressDetailID",
        "header": "OPRA_AddressDetailID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "CompanyID",
        "type": "text",
        "placeholder": "CompanyID",
        "label": "CompanyID",
        "id": "CompanyID",
        "textAlign": "Left",
        "show": true,
        "field": "CompanyID",
        "header": "CompanyID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "DealID",
        "type": "text",
        "placeholder": "DealID",
        "label": "DealID",
        "id": "DealID",
        "textAlign": "Left",
        "show": true,
        "field": "DealID",
        "header": "DealID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "EstateName",
        "type": "text",
        "placeholder": "EstateName",
        "label": "EstateName",
        "id": "EstateName",
        "textAlign": "Left",
        "show": true,
        "field": "EstateName",
        "header": "EstateName",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessNameID",
        "type": "text",
        "placeholder": "BusinessNameID",
        "label": "BusinessNameID",
        "id": "BusinessNameID",
        "textAlign": "Left",
        "show": true,
        "field": "BusinessNameID",
        "header": "BusinessNameID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyID",
        "type": "text",
        "placeholder": "PropertyID",
        "label": "PropertyID",
        "id": "PropertyID",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyID",
        "header": "PropertyID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessName",
        "type": "text",
        "placeholder": "BusinessName",
        "label": "BusinessName",
        "id": "BusinessName",
        "textAlign": "Left",
        "show": true,
        "field": "BusinessName",
        "header": "BusinessName",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyName",
        "type": "text",
        "placeholder": "PropertyName",
        "label": "PropertyName",
        "id": "PropertyName",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyName",
        "header": "PropertyName",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "NameMatchScore",
        "type": "text",
        "placeholder": "NameMatchScore",
        "label": "NameMatchScore",
        "id": "NameMatchScore",
        "textAlign": "Left",
        "show": true,
        "field": "NameMatchScore",
        "header": "NameMatchScore",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "AddressLine1MatchScore",
        "type": "text",
        "placeholder": "AddressLine1MatchScore",
        "label": "AddressLine1MatchScore",
        "id": "AddressLine1MatchScore",
        "textAlign": "Left",
        "show": true,
        "field": "AddressLine1MatchScore",
        "header": "AddressLine1MatchScore",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "CityStateZipScore",
        "type": "text",
        "placeholder": "CityStateZipScore",
        "label": "CityStateZipScore",
        "id": "CityStateZipScore",
        "textAlign": "Left",
        "show": true,
        "field": "CityStateZipScore",
        "header": "CityStateZipScore",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessAddressLine1",
        "type": "text",
        "placeholder": "BusinessAddressLine1",
        "label": "BusinessAddressLine1",
        "id": "BusinessAddressLine1",
        "textAlign": "Left",
        "show": true,
        "field": "BusinessAddressLine1",
        "header": "BusinessAddressLine1",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessCity",
        "type": "text",
        "placeholder": "BusinessCity",
        "label": "BusinessCity",
        "id": "BusinessCity",
        "textAlign": "Left",
        "show": true,
        "field": "BusinessCity",
        "header": "BusinessCity",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessState",
        "type": "text",
        "placeholder": "BusinessState",
        "label": "BusinessState",
        "id": "BusinessState",
        "textAlign": "Left",
        "show": true,
        "field": "BusinessState",
        "header": "BusinessState",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessZipCode",
        "type": "text",
        "placeholder": "BusinessZipCode",
        "label": "BusinessZipCode",
        "id": "BusinessZipCode",
        "textAlign": "Left",
        "show": true,
        "field": "BusinessZipCode",
        "header": "BusinessZipCode",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyAddressLine1",
        "type": "text",
        "placeholder": "PropertyAddressLine1",
        "label": "PropertyAddressLine1",
        "id": "PropertyAddressLine1",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyAddressLine1",
        "header": "PropertyAddressLine1",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyState",
        "type": "text",
        "placeholder": "PropertyState",
        "label": "PropertyState",
        "id": "PropertyState",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyState",
        "header": "PropertyState",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyCity",
        "type": "text",
        "placeholder": "PropertyCity",
        "label": "PropertyCity",
        "id": "PropertyCity",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyCity",
        "header": "PropertyCity",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyZipCode",
        "type": "text",
        "placeholder": "PropertyZipCode",
        "label": "PropertyZipCode",
        "id": "PropertyZipCode",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyZipCode",
        "header": "PropertyZipCode",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "UserEmailID",
        "type": "text",
        "placeholder": "UserEmailID",
        "label": "UserEmailID",
        "id": "UserEmailID",
        "textAlign": "Left",
        "show": true,
        "field": "UserEmailID",
        "header": "UserEmailID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "ScoreDate",
        "type": "text",
        "placeholder": "ScoreDate",
        "label": "ScoreDate",
        "id": "ScoreDate",
        "textAlign": "Left",
        "show": true,
        "field": "ScoreDate",
        "header": "ScoreDate",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "ScoreUpdateDate",
        "type": "text",
        "placeholder": "ScoreUpdateDate",
        "label": "ScoreUpdateDate",
        "id": "ScoreUpdateDate",
        "textAlign": "Left",
        "show": true,
        "field": "ScoreUpdateDate",
        "header": "ScoreUpdateDate",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyMinValueAmount",
        "type": "text",
        "placeholder": "PropertyMinValueAmount",
        "label": "PropertyMinValueAmount",
        "id": "PropertyMinValueAmount",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyMinValueAmount",
        "header": "PropertyMinValueAmount",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyMaxValueAmount",
        "type": "text",
        "placeholder": "PropertyMaxValueAmount",
        "label": "PropertyMaxValueAmount",
        "id": "PropertyMaxValueAmount",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyMaxValueAmount",
        "header": "PropertyMaxValueAmount",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BatchName",
        "type": "text",
        "placeholder": "BatchName",
        "label": "BatchName",
        "id": "BatchName",
        "textAlign": "Left",
        "show": true,
        "field": "BatchName",
        "header": "BatchName",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "bucket",
        "type": "text",
        "placeholder": "bucket",
        "label": "bucket",
        "id": "bucket",
        "textAlign": "Left",
        "show": true,
        "field": "bucket",
        "header": "bucket",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "MatchingEstateNameIssues",
        "type": "text",
        "placeholder": "MatchingEstateNameIssues",
        "label": "MatchingEstateNameIssues",
        "id": "MatchingEstateNameIssues",
        "textAlign": "Left",
        "show": true,
        "field": "MatchingEstateNameIssues",
        "header": "MatchingEstateNameIssues",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "MatchMatchStatus",
        "type": "text",
        "placeholder": "MatchMatchStatus",
        "label": "MatchMatchStatus",
        "id": "MatchMatchStatus",
        "textAlign": "Left",
        "show": true,
        "field": "MatchMatchStatus",
        "header": "MatchMatchStatus",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "MatchStatusReason",
        "type": "text",
        "placeholder": "MatchStatusReason",
        "label": "MatchStatusReason",
        "id": "MatchStatusReason",
        "textAlign": "Left",
        "show": true,
        "field": "MatchStatusReason",
        "header": "MatchStatusReason",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyStatus",
        "type": "text",
        "placeholder": "PropertyStatus",
        "label": "PropertyStatus",
        "id": "PropertyStatus",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyStatus",
        "header": "PropertyStatus",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyStatusReason",
        "type": "text",
        "placeholder": "PropertyStatusReason",
        "label": "PropertyStatusReason",
        "id": "PropertyStatusReason",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyStatusReason",
        "header": "PropertyStatusReason",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "IsValidated",
        "type": "text",
        "placeholder": "IsValidated",
        "label": "IsValidated",
        "id": "IsValidated",
        "textAlign": "Left",
        "show": true,
        "field": "IsValidated",
        "header": "IsValidated",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "ValidatedBy",
        "type": "text",
        "placeholder": "ValidatedBy",
        "label": "ValidatedBy",
        "id": "ValidatedBy",
        "textAlign": "Left",
        "show": true,
        "field": "ValidatedBy",
        "header": "ValidatedBy",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "ValidatedDate",
        "type": "text",
        "placeholder": "ValidatedDate",
        "label": "ValidatedDate",
        "id": "ValidatedDate",
        "textAlign": "Left",
        "show": true,
        "field": "ValidatedDate",
        "header": "ValidatedDate",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyAlternateId",
        "type": "text",
        "placeholder": "PropertyAlternateId",
        "label": "PropertyAlternateId",
        "id": "PropertyAlternateId",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyAlternateId",
        "header": "PropertyAlternateId",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "elasticResultIndex",
        "type": "text",
        "placeholder": "elasticResultIndex",
        "label": "elasticResultIndex",
        "id": "elasticResultIndex",
        "textAlign": "Left",
        "show": true,
        "field": "elasticResultIndex",
        "header": "elasticResultIndex",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "StatePropertyId",
        "type": "text",
        "placeholder": "StatePropertyId",
        "label": "StatePropertyId",
        "id": "StatePropertyId",
        "textAlign": "Left",
        "show": true,
        "field": "StatePropertyId",
        "header": "StatePropertyId",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyDataSource",
        "type": "text",
        "placeholder": "PropertyDataSource",
        "label": "PropertyDataSource",
        "id": "PropertyDataSource",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyDataSource",
        "header": "PropertyDataSource",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "hasPropertyCoOwner",
        "type": "text",
        "placeholder": "hasPropertyCoOwner",
        "label": "hasPropertyCoOwner",
        "id": "hasPropertyCoOwner",
        "textAlign": "Left",
        "show": true,
        "field": "hasPropertyCoOwner",
        "header": "hasPropertyCoOwner",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "OPRAValidatedFlag",
        "type": "text",
        "placeholder": "OPRAValidatedFlag",
        "label": "OPRAValidatedFlag",
        "id": "OPRAValidatedFlag",
        "textAlign": "Left",
        "show": true,
        "field": "OPRAValidatedFlag",
        "header": "OPRAValidatedFlag",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "ClaimID",
        "type": "text",
        "placeholder": "ClaimID",
        "label": "ClaimID",
        "id": "ClaimID",
        "textAlign": "Left",
        "show": true,
        "field": "ClaimID",
        "header": "ClaimID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "FileFor",
        "type": "text",
        "placeholder": "FileFor",
        "label": "FileFor",
        "id": "FileFor",
        "textAlign": "Left",
        "show": true,
        "field": "FileFor",
        "header": "FileFor",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "SourceState",
        "type": "text",
        "placeholder": "SourceState",
        "label": "SourceState",
        "id": "SourceState",
        "textAlign": "Left",
        "show": true,
        "field": "SourceState",
        "header": "SourceState",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "HolderName",
        "type": "text",
        "placeholder": "HolderName",
        "label": "HolderName",
        "id": "HolderName",
        "textAlign": "Left",
        "show": true,
        "field": "HolderName",
        "header": "HolderName",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyDescription",
        "type": "text",
        "placeholder": "PropertyDescription",
        "label": "PropertyDescription",
        "id": "PropertyDescription",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyDescription",
        "header": "PropertyDescription",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "EscheatmentDate",
        "type": "text",
        "placeholder": "EscheatmentDate",
        "label": "EscheatmentDate",
        "id": "EscheatmentDate",
        "textAlign": "Left",
        "show": true,
        "field": "EscheatmentDate",
        "header": "EscheatmentDate",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "OpraAddedDate",
        "type": "text",
        "placeholder": "OpraAddedDate",
        "label": "OpraAddedDate",
        "id": "OpraAddedDate",
        "textAlign": "Left",
        "show": true,
        "field": "OpraAddedDate",
        "header": "OpraAddedDate",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyResidentialFlag",
        "type": "text",
        "placeholder": "PropertyResidentialFlag",
        "label": "PropertyResidentialFlag",
        "id": "PropertyResidentialFlag",
        "textAlign": "Left",
        "show": true,
        "field": "PropertyResidentialFlag",
        "header": "PropertyResidentialFlag",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "SS_PropertyAddressLine1",
        "type": "text",
        "placeholder": "SS_PropertyAddressLine1",
        "label": "SS_PropertyAddressLine1",
        "id": "SS_PropertyAddressLine1",
        "textAlign": "Left",
        "show": true,
        "field": "SS_PropertyAddressLine1",
        "header": "SS_PropertyAddressLine1",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "SS_PropertyAddressLine2",
        "type": "text",
        "placeholder": "SS_PropertyAddressLine2",
        "label": "SS_PropertyAddressLine2",
        "id": "SS_PropertyAddressLine2",
        "textAlign": "Left",
        "show": true,
        "field": "SS_PropertyAddressLine2",
        "header": "SS_PropertyAddressLine2",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "SS_PropertyCity",
        "type": "text",
        "placeholder": "SS_PropertyCity",
        "label": "SS_PropertyCity",
        "id": "SS_PropertyCity",
        "textAlign": "Left",
        "show": true,
        "field": "SS_PropertyCity",
        "header": "SS_PropertyCity",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "SS_PropertyState",
        "type": "text",
        "placeholder": "SS_PropertyState",
        "label": "SS_PropertyState",
        "id": "SS_PropertyState",
        "textAlign": "Left",
        "show": true,
        "field": "SS_PropertyState",
        "header": "SS_PropertyState",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "SS_PropertyZipCode",
        "type": "text",
        "placeholder": "SS_PropertyZipCode",
        "label": "SS_PropertyZipCode",
        "id": "SS_PropertyZipCode",
        "textAlign": "Left",
        "show": true,
        "field": "SS_PropertyZipCode",
        "header": "SS_PropertyZipCode",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "SS_PropertyLatitude",
        "type": "text",
        "placeholder": "SS_PropertyLatitude",
        "label": "SS_PropertyLatitude",
        "id": "SS_PropertyLatitude",
        "textAlign": "Left",
        "show": true,
        "field": "SS_PropertyLatitude",
        "header": "SS_PropertyLatitude",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "SS_PropertyLongitude",
        "type": "text",
        "placeholder": "SS_PropertyLongitude",
        "label": "SS_PropertyLongitude",
        "id": "SS_PropertyLongitude",
        "textAlign": "Left",
        "show": true,
        "field": "SS_PropertyLongitude",
        "header": "SS_PropertyLongitude",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "SS_LastRanDateUTC",
        "type": "text",
        "placeholder": "SS_LastRanDateUTC",
        "label": "SS_LastRanDateUTC",
        "id": "SS_LastRanDateUTC",
        "textAlign": "Left",
        "show": true,
        "field": "SS_LastRanDateUTC",
        "header": "SS_LastRanDateUTC",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "CreatedDate",
        "type": "text",
        "placeholder": "CreatedDate",
        "label": "CreatedDate",
        "id": "CreatedDate",
        "textAlign": "Left",
        "show": true,
        "field": "CreatedDate",
        "header": "CreatedDate",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "UpdatedDate",
        "type": "text",
        "placeholder": "UpdatedDate",
        "label": "UpdatedDate",
        "id": "UpdatedDate",
        "textAlign": "Left",
        "show": true,
        "field": "UpdatedDate",
        "header": "UpdatedDate",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "DateOfLastContact",
        "type": "text",
        "placeholder": "DateOfLastContact",
        "label": "DateOfLastContact",
        "id": "DateOfLastContact",
        "textAlign": "Left",
        "show": true,
        "field": "DateOfLastContact",
        "header": "DateOfLastContact",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "AlternateID",
        "type": "text",
        "placeholder": "AlternateID",
        "label": "AlternateID",
        "id": "AlternateID",
        "textAlign": "Left",
        "show": true,
        "field": "AlternateID",
        "header": "AlternateID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "ProspectiveStatus",
        "type": "text",
        "placeholder": "ProspectiveStatus",
        "label": "ProspectiveStatus",
        "id": "ProspectiveStatus",
        "textAlign": "Left",
        "show": true,
        "field": "ProspectiveStatus",
        "header": "ProspectiveStatus",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "CreatedBy",
        "type": "text",
        "placeholder": "CreatedBy",
        "label": "CreatedBy",
        "id": "CreatedBy",
        "textAlign": "Left",
        "show": true,
        "field": "CreatedBy",
        "header": "CreatedBy",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "DateIndexedInElastic",
        "type": "text",
        "placeholder": "DateIndexedInElastic",
        "label": "DateIndexedInElastic",
        "id": "DateIndexedInElastic",
        "textAlign": "Left",
        "show": true,
        "field": "DateIndexedInElastic",
        "header": "DateIndexedInElastic",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "AddressId",
        "type": "text",
        "placeholder": "AddressId",
        "label": "AddressId",
        "id": "AddressId",
        "textAlign": "Left",
        "show": true,
        "field": "AddressId",
        "header": "AddressId",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "NameId",
        "type": "text",
        "placeholder": "NameId",
        "label": "NameId",
        "id": "NameId",
        "textAlign": "Left",
        "show": true,
        "field": "NameId",
        "header": "NameId",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "batchId",
        "type": "text",
        "placeholder": "batchId",
        "label": "batchId",
        "id": "batchId",
        "textAlign": "Left",
        "show": true,
        "field": "batchId",
        "header": "batchId",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "NameIssueCode",
        "type": "text",
        "placeholder": "NameIssueCode",
        "label": "NameIssueCode",
        "id": "NameIssueCode",
        "textAlign": "Left",
        "show": true,
        "field": "NameIssueCode",
        "header": "NameIssueCode",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "NewNameIssueCode",
        "type": "text",
        "placeholder": "NewNameIssueCode",
        "label": "NewNameIssueCode",
        "id": "NewNameIssueCode",
        "textAlign": "Left",
        "show": true,
        "field": "NewNameIssueCode",
        "header": "NewNameIssueCode",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "NameSource",
        "type": "text",
        "placeholder": "NameSource",
        "label": "NameSource",
        "id": "NameSource",
        "textAlign": "Left",
        "show": true,
        "field": "NameSource",
        "header": "NameSource",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "ConfirmedOnsite",
        "type": "text",
        "placeholder": "ConfirmedOnsite",
        "label": "ConfirmedOnsite",
        "id": "ConfirmedOnsite",
        "textAlign": "Left",
        "show": true,
        "field": "ConfirmedOnsite",
        "header": "ConfirmedOnsite",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "ConfirmedOnsiteBy",
        "type": "text",
        "placeholder": "ConfirmedOnsiteBy",
        "label": "ConfirmedOnsiteBy",
        "id": "ConfirmedOnsiteBy",
        "textAlign": "Left",
        "show": true,
        "field": "ConfirmedOnsiteBy",
        "header": "ConfirmedOnsiteBy",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "ConfirmedOnsiteDate",
        "type": "text",
        "placeholder": "ConfirmedOnsiteDate",
        "label": "ConfirmedOnsiteDate",
        "id": "ConfirmedOnsiteDate",
        "textAlign": "Left",
        "show": true,
        "field": "ConfirmedOnsiteDate",
        "header": "ConfirmedOnsiteDate",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "ConfirmedOnsiteByUserId",
        "type": "text",
        "placeholder": "ConfirmedOnsiteByUserId",
        "label": "ConfirmedOnsiteByUserId",
        "id": "ConfirmedOnsiteByUserId",
        "textAlign": "Left",
        "show": true,
        "field": "ConfirmedOnsiteByUserId",
        "header": "ConfirmedOnsiteByUserId",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PreviouslyValidatedFlag",
        "type": "text",
        "placeholder": "PreviouslyValidatedFlag",
        "label": "PreviouslyValidatedFlag",
        "id": "PreviouslyValidatedFlag",
        "textAlign": "Left",
        "show": true,
        "field": "PreviouslyValidatedFlag",
        "header": "PreviouslyValidatedFlag",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 100,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },

    ]
    return data;
  };

  getFormFields = () => {
    return ([]);
  }


  submit = async (item) => {

    this.setState({ isOpenFormModal: true })
    await this.formModalRef.getRowData(item, 'edit');

    console.log("Submit Button in sode          ")
  }
  getMobileTableFields = () => {
    let data = [{ "textAlign": "center", "width": 47, "field": "", "fieldType": "multiple", "header": "", "selectionMode": "multiple", "show": true, "mobile": true, "displayInSettings": true }, { "textAlign": "center", "width": 47, "field": "Sno", "header": "SNo", "filter": false, "sortable": false, "placeholder": "Search", "show": true, "mobile": true, "displayInSettings": true }, { "name": "propertyName", "type": "text", "placeholder": "propertyName", "label": "Matching Property Name", "id": "propertyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyName", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyString", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "NameMatchScore", "type": "text", "placeholder": "NameMatchScore", "label": "Name Score", "id": "NameMatchScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "NameMatchScore", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }]

    return data;
  };
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
    })
  }

  cancelReset = async (type) => {
    this.setState({
      openNewUserModal: false
    });
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };
  render() {
    return (
      <span>
        <DataTables
          onRef={ref => (this.dataTableRef = ref)}
          // MobileTableFields={this.getMobileTableFields}
          // getTableFields={this.getTableFields}
          // formFields={this.getFormFields}
          // 
          addRequired={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={false}
          exportRequired={false}
          sample={false}
          getTableFields={this.getTableFields}
          getTableFields1={this.getTableFields1}
          formFields={this.getFormFields}
          // exportRequried={true}

          printRequried={false}
          actionsTypes={[{
            'name': 'Delete',
            "options": [
              { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
            ]
          },
          // {
          //   'name': 'Block',
          //   "options": [
          //     { 'label': 'Block', 'value': 'Block', 'show': true, "multiple": false, }
          //   ]
          // },
          {
            'name': 'ResetPassword',
            "options": [
              { 'label': 'ResetPassword', 'value': 'ResetPassword', 'show': true, "multiple": false, }
            ]
          },
          {
            'name': 'Submit',
            'action': this.submit,
            "options": [
              { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
            ]
          },
            // {
            //   name: "newModel",
            //   action: this.submit1,
            //   options: [
            //     { label: "newModel", value: "newModel", show: true, multiple: false }
            //   ]
            // }

          ]}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={true}
          gridRequried={true}
          exportToCsv={true}
          tabType={this.props.tabType}
          sampleFilePath={filePath.scorings}

          globalSearch={'Name String'}
          type='Scoring2Properties'
          routeTo={apiCalls.BatchStatus}
          displayViewOfForm='screen'
          apiResponseKey={"scoring2"}
          apiUrl={apiCalls.Scoring2}

        />
      </span>
    );
  }
}