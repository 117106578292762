

import React from 'react';
import {
  Button, Card, CardBody, Modal, ModalHeader, ModalBody,
  TabContent, TabPane, Nav, NavItem, NavLink, Row, Table
} from 'reactstrap';
import classnames from 'classnames';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { settings } from '../../../redux/actions/settingsAction'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '../../../shared/components/form/Select';
import { Redirect } from 'react-router-dom';
import Activities from '../../Cruds/Activities/index';
import Settings from '../../Cruds/Settings/index';
import ScoringTracker from '../../Cruds/BatchStatus';
import ErrorsAndExceptions from '../../Cruds/ErrorsAndExceptions/index';
import OPRAValidatedPropertyLookup from '../../Cruds/OPRAValidatedPropertyLookup/index';
import OPRABusinessNameIssueLookup from '../../Cruds/OPRABusinessNameIssueLookup/index';
import CompanyNamesSQL from '../CompanyNamesSQL/index';
import ScoringLayerConfiguration from "../../Cruds/ScoringLayerConfiguration"

import Buckets from '../Buckets/index';
// Toaster message
// import validate from '../Validations/validate';
// Loader
// import Loader from '../App/Loader';
// // Calendar
// //session expiry modal
// import store from '../App/store';
import configMessages from '../../../config/configMessages'
// import DealSizingThreshold from '../DealSizingThreshold/index';
const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);

class SettingsForm extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: '',
      options: [
        { label: 'OPRA Validated Property Lookup', value: 'OPRA Validated Property Lookup' },
        { label: 'OPRA Business Name Issue Lookup', value: 'OPRA Business Name Issue Lookup' }
        // { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
      ],
      isLoading: false,
      isUserUpdated: false,
      activeTab: '1'
    };
  }

  componentDidMount = async () => {
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    // let apiUrl=''
    this.setState({
      isLoading: true
    });

    // })
  }
  //send data to server

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  handleScreenChange = async (e) => {
    await this.setState({
      selectedScreen: e
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }

  }

  saveDataToServer() { }
  render() {
    const { handleSubmit } = this.props;
    const { options } = this.state
    let isSubmitting = false

    //
    return (
      <div style={{ width: "100%" }}>
        <Nav tabs >
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Audit Logs
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
              disabled={this.state.isTabdisabled || this.state.isChildTrue}
            >
              Scoring Tracker
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
              disabled={this.state.isTabdisabled || this.state.isChildTrue}
            >
              {"Errors & Exceptions"}
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); }}
              disabled={this.state.isTabdisabled || this.state.isChildTrue}
            >
              {"Settings"}
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink
              className={classnames({ active: this.state.activeTab === '5' })}
              onClick={() => { this.toggle('5'); }}
              disabled={this.state.isTabdisabled || this.state.isChildTrue}
            >
              {"Post Processing"}
            </NavLink>
          </NavItem>
          <NavItem >
            <NavLink
              className={classnames({ active: this.state.activeTab === '6' })}
              onClick={() => { this.toggle('6'); }}
              disabled={this.state.isTabdisabled || this.state.isChildTrue}
            >
              {"Companies SQL Queries"}
            </NavLink>
          </NavItem>
          {/* <NavItem >
            <NavLink
              className={classnames({ active: this.state.activeTab === '7' })}
              onClick={() => { this.toggle('7'); }}
              disabled={this.state.isTabdisabled || this.state.isChildTrue}
            >
              {"Scoring Layer Configuration"}
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          < TabPane tabId="1">
            {<Activities />}
          </TabPane>
          <TabPane tabId="2">
            {<ScoringTracker />}
          </TabPane>
          <TabPane tabId="3">
            {<ErrorsAndExceptions />}
          </TabPane>
          <TabPane tabId="4">
            {<Settings />}
          </TabPane>
          <TabPane tabId="5">
            <div className="" >
              <div className='col-sm-3'>
                <div className="form-group pt-0">
                  <div className="form-group pt-2">
                    <label>Choose Screen </label>
                    <div className="form__form-group-field">
                      <Field
                        name={'dateFormat'}
                        component={Select}
                        options={options}
                        onChange={(e) => this.handleScreenChange(e)}
                        placeholder={'Select Screen'}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div>
                {this.state.selectedScreen === "OPRA Validated Property Lookup" ?
                  <OPRAValidatedPropertyLookup /> :
                  this.state.selectedScreen === "OPRA Business Name Issue Lookup" ?
                    <OPRABusinessNameIssueLookup /> : null}
              </div>
            </div>
          </TabPane>
          <TabPane tabId="6">
            {<CompanyNamesSQL />}
          </TabPane>
          {/* <TabPane tabId="2">
            {<ScoringLayerConfiguration />}
          </TabPane> */}
        </TabContent>
      </div >
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SettingsForm = reduxForm({
  form: "Common Form", // a unique identifier for this form
  // validate,
  enableReinitialize: true,
})(SettingsForm);

// You have to connect() to any reducers that you wish to connect to yourself
SettingsForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  {
    load: loadAccount,// bind account loading action creator
    settingsLoad: settings,
  }
)(SettingsForm);

export default SettingsForm;






