import React from 'react';
import DataTables from '../../CommonDataTable/DataTableEstateMongo';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import ViewModal from '../../CommonModals/viewModal';
import NewUserModal from '../../CommonModals/NewUserModal';

import FormModal from '../../../Form/FormModal';
import store from '../../../App/store';
import filePath from "../../../../config/configSampleFiles";
// config file
export default class Comparision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: ''
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      {
        "name": "EstateID",
        "type": "text",
        "placeholder": "EstateID",
        "label": "EstateID",
        "id": "EstateID",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "EstateID",
        "header": "EstateID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 50,
        sortable: false,
        filter: true
      },
      {
        "name": "Status",
        "type": "text",
        "placeholder": "Status",
        "label": "Status",
        "id": "Status",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "Status",
        "header": "Status",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 50,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "StartTime",
        "type": "text",
        "placeholder": "StartTime",
        "label": "StartTime",
        "id": "StartTime",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "StartTime",
        "header": "StartTime",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Date",
        "width": 50,
        dateFormat: config.basicDateFromat,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "endTime",
        "type": "text",
        "placeholder": "endTime",
        "label": "End Time",
        "id": "endTime",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "endTime",
        "header": "End Time",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Date",
        "width": 50,
        dateFormat: config.basicDateFromat,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      }
    ]
    return data;
  };

  getTableFields1 = () => {
    let data = [
      {
        "name": "EstateID",
        "type": "text",
        "placeholder": "EstateID",
        "label": "EstateID",
        "id": "estateID",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "estateID",
        "header": "EstateID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 50,
        sortable: false,
        filter: false
      },
      {
        "name": "Status",
        "type": "text",
        "placeholder": "Status",
        "label": "Status",
        "id": "status",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "status",
        "header": "Status",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 50,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "Completed Count",
        "type": "text",
        "placeholder": "Completed Count",
        "label": "Completed Count",
        "id": "completedCount",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "completedCount",
        "header": "Completed Count",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 20,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "StartTime",
        "type": "text",
        "placeholder": "StartTime",
        "label": "StartTime",
        "id": "startTime",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "startTime",
        "header": "StartTime",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Date",
        "width": 50,
        dateFormat: config.basicDateFromat,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "endTime",
        "type": "text",
        "placeholder": "endTime",
        "label": "End Time",
        "id": "endTime",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "endTime",
        "header": "End Time",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Date",
        "width": 50,
        dateFormat: config.basicDateFromat,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      }
    ]
    return data;
  };

  getFormFields = () => {
    return ([]);
  }


  submit = async (item) => {

    this.setState({ isOpenFormModal: true })
    await this.formModalRef.getRowData(item, 'edit');

    console.log("Submit Button in sode          ")
  }
  getMobileTableFields = () => {
    let data = [{ "textAlign": "center", "width": 47, "field": "", "fieldType": "multiple", "header": "", "selectionMode": "multiple", "show": true, "mobile": true, "displayInSettings": true }, { "textAlign": "center", "width": 47, "field": "Sno", "header": "SNo", "filter": false, "sortable": false, "placeholder": "Search", "show": true, "mobile": true, "displayInSettings": true }, { "name": "propertyName", "type": "text", "placeholder": "propertyName", "label": "Matching Property Name", "id": "propertyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyName", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyString", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "NameMatchScore", "type": "text", "placeholder": "NameMatchScore", "label": "Name Score", "id": "NameMatchScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "NameMatchScore", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }]

    return data;
  };
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
    })
  }

  cancelReset = async (type) => {
    this.setState({
      openNewUserModal: false
    });
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };
  render() {
    return (
      <span>
        <DataTables
          onRef={ref => (this.dataTableRef = ref)}
          // MobileTableFields={this.getMobileTableFields}
          // getTableFields={this.getTableFields}
          // formFields={this.getFormFields}
          // 
          addRequired={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={false}
          exportRequired={false}
          sample={false}
          getTableFields={this.getTableFields}
          getTableFields1={this.getTableFields1}
          formFields={this.getFormFields}
          // exportRequried={true}

          printRequried={false}
          actionsTypes={[{
            'name': 'Delete',
            "options": [
              { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
            ]
          },
          // {
          //   'name': 'Block',
          //   "options": [
          //     { 'label': 'Block', 'value': 'Block', 'show': true, "multiple": false, }
          //   ]
          // },
          {
            'name': 'ResetPassword',
            "options": [
              { 'label': 'ResetPassword', 'value': 'ResetPassword', 'show': true, "multiple": false, }
            ]
          },
          {
            'name': 'Submit',
            'action': this.submit,
            "options": [
              { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
            ]
          },
            // {
            //   name: "newModel",
            //   action: this.submit1,
            //   options: [
            //     { label: "newModel", value: "newModel", show: true, multiple: false }
            //   ]
            // }

          ]}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={true}
          gridRequried={true}
          exportToCsv={true}
          tabType={this.props.tabType}
          sampleFilePath={filePath.scorings}

          globalSearch={'Name String'}
          type='Comparision'
          routeTo={apiCalls.BatchStatus}
          displayViewOfForm='screen'
          apiResponseKey={"comparisionHistory"}
          apiUrl={apiCalls.comparisionHistory}

        />
      </span>
    );
  }
}