/* eslint indent: "off" */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';

// Login
import Landing from '../Landing/LogIn';
// import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard/index';

import Activities from '../Cruds/Activities'
import ValidatedLookup from '../Cruds/OPRAValidatedPropertyLookup/index'
import BusinessNameIssueLookup from '../Cruds/OPRABusinessNameIssueLookup/index'
import EstateMongo from '../Cruds/EstateMongo/index'
import BatchStatus from '../Cruds/BatchStatus/index'
import Comparision from '../Cruds/Comparision/index'
import Configuration from '../Cruds/Configurations/index'
import NewScoring from '../Cruds/NewScoring/index'
import ErrorsAndExceptions from '../Cruds/ErrorsAndExceptions/index'

// Tables
import Employees from '../Cruds/Employees/index';
import Scorings from '../Cruds/Scorings/index';
import Buckets from '../Cruds/Buckets/index';
import ElasticQuery from '../Cruds/ElasticQuery/index';
import Elastic from '../Cruds/Elastic/index';
import SearchNames from '../Cruds/SearchNames/index';
import BucketScore from '../Cruds/BucketFromScore';
import CompareStringsAndGetScore from '../Cruds/CompareStringsAndGetScore';
import EstateName from '../Cruds/EstateNameResults/index';

import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
import UPOpportunity from '../Cruds/UPOpportunity/index'
import Users from '../Cruds/Users/index';
import Roles from '../Cruds/Role/index';

// 404 page not found 
import ErrorNotFound from '../Account/404/index';

const wrappedRoutes = () => {
  let sidebar = {
    collapse: true,
    show: false
  }
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  // console.log("loginCredentials", loginCredentials)
  if (loginCredentials) {
    return <div>
      <Layout
        sidebar={sidebar}
      />
      <div className="container__wrap">
        <Route exact path="/" component={Scorings} />
        <Route path="/users" component={Users} />
        <Route path="/roles" component={Roles} />
        <Route exact path="/scorings" component={Scorings} />
        <Route exact path="/buckets" component={Buckets} />
        <Route exact path="/dealbuckets" component={Buckets} />
        <Route exact path="/elasticQuery" component={ElasticQuery} />
        <Route exact path="/SearchNames" component={SearchNames} />
        <Route exact path="/bucketScore" component={BucketScore} />
        <Route exact path="/compareStrings" component={CompareStringsAndGetScore} />
        <Route exact path="/estateNameResult" component={EstateName} />
        <Route exact path="/activities" component={Activities} />
        <Route exact path="/ValidatedLookup" component={ValidatedLookup} />
        <Route exact path="/BusinessNameIssueLookup" component={BusinessNameIssueLookup} />
        <Route exact path="/estateMongo" component={EstateMongo} />
        <Route exact path="/batchStatus" component={BatchStatus} />
        <Route exact path="/comparision" component={Comparision} />
        <Route exact path="/configuration" component={Configuration} />
        <Route exact path="/NewScoring" component={NewScoring} />
        <Route exact path="/errorsAndExceptions" component={ErrorsAndExceptions} />
        <Route exact path="/upOpportunity" component={UPOpportunity} />
        <Route exact path="/elastic" component={Elastic} />

      </div>
    </div>
  }
  else {
    return <DefaultScreen />
  }
}

const DefaultScreen = () => (
  <Switch>
    <Route path="/" component={Landing} />
  </Switch>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/log_in" component={Landing} />
        <Route path="/logout" component={Landing} />
        <Route path="/" component={wrappedRoutes} />
        <Route component={ErrorNotFound} />

      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
