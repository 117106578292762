import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import Loader from '../../../App/Loader';
import fetch from '../../../../config/service';
import fetchMethodRequest from '../../../../config/service';
import RolePermissions from '../../CommonModals/Permissions';
import FormModal from '../../../Form/FormModal';

// config file
export default class Elastic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Elastic',
            loading: false,
            sessionWarning: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        let storeData = store.getState()
        let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
        return { languageData: languageData };
    }



    componentDidMount = () => {
        //Screen permisions value can be edit,view, no view
        return fetch('GET', apiCalls.Roles)
            .then(async (response) => {
                if (response && response.roles && response.roles.length > 0) {
                    let data = response.roles, tempData = [];
                    for (let i = 0; i < data.length; i++) {
                        tempData.push({ 'label': data[i]['role'], "value": data[i]['role'] })
                    }
                    await this.setState({
                        roleOptions: tempData
                    })
                } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
                    await this.setState({
                        sessionWarning: true,

                    })
                } else {
                    await this.setState({
                        roleOptions: []
                    })
                }
            }).catch((err) => {
                return err;
            });
    }
    getTableFields = () => {
        let data = [
            {
                "textAlign": "center",
                "width": 47,
                "field": "",
                "fieldType": "multiple",
                "header": "",
                "selectionMode": "multiple",
                "show": true,
                "mobile": true,
                "displayInSettings": true
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "fieldType": "Link",
                "field": "propertyId",
                "mobile": true,
                "header": "propertyId",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyOwnerName",
                "mobile": true,
                "header": "propertyOwnerName",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyAddressLine1",
                "mobile": true,
                "header": "propertyAddressLine1",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyCity",
                "mobile": true,
                "header": "propertyCity",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyStateAbbreviation",
                "mobile": true,
                "header": "propertyStateAbbreviation",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyZip",
                "mobile": true,
                "header": "propertyZip",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyStatus",
                "mobile": true,
                "header": "propertyStatus",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "addressId",

                "mobile": true,
                "header": "addressId",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "createdBy",
                "mobile": true,
                "header": "createdBy",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "createdDate",
                "mobile": true,
                "header": "createdDate",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "hasPropertyCoOwner",
                "mobile": true,
                "header": "hasPropertyCoOwner",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "holderName",
                "mobile": true,
                "header": "holderName",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "nameId",
                "mobile": true,
                "header": "nameId",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "opraAddedDate",
                "mobile": true,
                "header": "opraAddedDate",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyDataSource",
                "mobile": true,
                "header": "propertyDataSource",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyDescription",
                "mobile": true,
                "header": "propertyDescription",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyResidentialFlag",
                "mobile": true,
                "header": "propertyResidentialFlag",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyValueMax",
                "mobile": true,
                "header": "propertyValueMax",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "propertyValueMin",
                "mobile": true,
                "header": "propertyValueMin",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "sS_PropertyAddressLine1",
                "mobile": true,
                "header": "sS_PropertyAddressLine1",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "sS_PropertyAddressLine2",
                "mobile": true,
                "header": "sS_PropertyAddressLine2",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "sS_PropertyCity",
                "mobile": true,
                "header": "sS_PropertyCity",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "sS_PropertyLatitude",
                "mobile": true,
                "header": "sS_PropertyLatitude",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "sS_PropertyLongitude",
                "mobile": true,
                "header": "sS_PropertyLongitude",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "sS_PropertyState",
                "mobile": true,
                "header": "sS_PropertyState",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "sS_PropertyZipCode",
                "mobile": true,
                "header": "sS_PropertyZipCode",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "sourceState",
                "mobile": true,
                "header": "sourceState",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "statePropertyId",
                "mobile": true,
                "header": "statePropertyId",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 180,
                "fieldType": 'Actions',
                "field": "Actions",
                "header": "Actions",
                "filter": false,
                "displayInSettings": true,
                "sortable": false
            }
        ]
        // let screenPermissions = RolePermissions.screenPermissions('Elastic');
        // if (screenPermissions != 'Edit') {
        //     data.pop();
        // }
        return data;
    };


    getFormFields = () => {
        return ([
             {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyId",
                "mobile": true,
                "label": "Property Id",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "addressId",
                "mobile": true,
                "label": "addressId",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyOwnerName",
                "mobile": true,
                "label": "Property Owner Name",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            },{
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "createdBy",
                "mobile": true,
                "label": "createdBy",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyCity",
                "mobile": true,
                "label": "Property City",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "createdDate",
                "mobile": true,
                "label": "createdDate",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyStateAbbreviation",
                "mobile": true,
                "label": "Property State",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "hasPropertyCoOwner",
                "mobile": true,
                "label": "hasPropertyCoOwner",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyZip",
                "mobile": true,
                "label": "Property Zip",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "holderName",
                "mobile": true,
                "label": "holderName",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyValueMin",
                "mobile": true,
                "label": "Property Value Min",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "nameId",
                "mobile": true,
                "label": "nameId",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyValueMax",
                "mobile": true,
                "label": "Property Value Max",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "opraAddedDate",
                "mobile": true,
                "label": "opraAddedDate",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "sourceState",
                "mobile": true,
                "label": "Source State",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyAddressLine1",
                "mobile": true,
                "label": "propertyAddressLine1",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyDataSource",
                "mobile": true,
                "label": "propertyDataSource",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyDescription",
                "mobile": true,
                "label": "propertyDescription",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            },  {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyResidentialFlag",
                "mobile": true,
                "label": "propertyResidentialFlag",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "propertyStatus",
                "mobile": true,
                "label": "propertyStatus",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "sS_PropertyAddressLine1",
                "mobile": true,
                "label": "sS_PropertyAddressLine1",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "sS_PropertyAddressLine2",
                "mobile": true,
                "label": "sS_PropertyAddressLine2",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "sS_PropertyCity",
                "mobile": true,
                "label": "sS_PropertyCity",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "sS_PropertyLatitude",
                "mobile": true,
                "label": "sS_PropertyLatitude",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "sS_PropertyLongitude",
                "mobile": true,
                "label": "sS_PropertyLongitude",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "sS_PropertyState",
                "mobile": true,
                "label": "sS_PropertyState",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "sS_PropertyZipCode",
                "mobile": true,
                "label": "sS_PropertyZipCode",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "name": "statePropertyId",
                "mobile": true,
                "label": "statePropertyId",
                "displayInSettings": true,
                "filter": false,
                "sortable": false,
            }
        ]);
    }



    submit = async (item) => {

        this.setState({ isOpenFormModal: true })
        await this.formModalRef.getRowData(item, 'edit');

        console.log("Submit Button in sode          ")
    }
    closeFormModal = async () => {
        this.setState({
            isOpenFormModal: false,
        })
    }
    render() {
        return (
            <span>
                <DataTables
                    onRef={ref => (this.dataTableRef = ref)}
                    // MobileTableFields={this.getMobileTableFields}
                    // getTableFields={this.getTableFields}
                    // formFields={this.getFormFields}
                    // 
                    addRequired={true}
                    editRequired={false}
                    deleteRequired={false}
                    viewRequired={true}
                    exportRequired={false}
                    sample={false}
                    // globalSearch={'Display Name/Email'}
                    // type='Elastic'
                    // apiUrl={apiCalls.Elastic}
                    getTableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    // exportRequried={true}

                    printRequried={false}
                    actionsTypes={[{
                        'name': 'Delete',
                        "options": [
                            { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": false, },
                        ]
                    }, {
                        'name': 'Clone',
                        "options": [
                            { 'label': 'Clone', 'value': 'Clone', 'show': true, "multiple": false, },
                        ]
                    },
                        // {
                        //     'name': 'Submit',
                        //     'action': this.submit,
                        //     "options": [
                        //         { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
                        //     ]
                        // },
                    ]}
                    settingsRequired={true}
                    filterRequired={false}
                    gridRequried={false}
                    exportToCsv={false}

                    type='Elastic'
                    displayright={false}
                    icon='elastic'
                    routeTo='elastic'
                    displayViewOfForm='modal'
                    apiResponseKey='elastics'
                    apiUrl={apiCalls.Elastic}

                />
                {this.state.isOpenFormModal ? (
                    <FormModal
                        onRef={(ref) => (this.formModalRef = ref)}
                        formType="edit"
                        openFormModal={this.state.isOpenFormModal}
                        formFields={this.props.formFields}
                    />

                ) : null}
            </span>
        );
    }
    // render() {
    //     return (
    //         <span>
    //             <Loader loader={this.state.isLoading} />
    //             <DataTables
    //                 onRef={(ref) => (this.datatableref = ref)}
    //                 getTableFields={this.getTableFields}
    //                 formFields={this.getFormFields}
    //                 statusFilter={false}
    //                 isClearFilters={false}
    //                 className={false}
    //                 exportRequried={false}
    //                 printRequried={false}
    //                 addRequried={false}
    //                 editRequired={true}
    //                 deleteRequired={true}
    //                 viewRequired={true}
    //                 settingsRequired={false}
    //                 filterRequired={false}
    //                 gridRequried={false}
    //                 sample={false}
    //                 type='Elastic'
    //                 displayright={false}
    //                 icon='elastic'
    //                 routeTo='elastic'
    //                 displayViewOfForm='screen'
    //                 apiResponseKey='elastics'
    //                 apiUrl={apiCalls.Elastic}
    //                 entityType='employee'
    //                 sessionWarning={this.state.sessionWarning}
    //             />

    //         </span>
    //     );
    // }
}