
const apiCalls = {
    "loginUser": "api/auth/login",
    "loginEmail": "api/auth/loginEmail",
    "loginChangePassword": "auth/loginChangePassword", "MenuList": "api/menus", "Settings": "settings", "LocationImagePath": "uploads?uploadWhileCreate=true&uploadPath=location", "ProfileImagePath": "uploads?uploadWhileCreate=true&uploadPath=employee", "dashboard": "dashboard/counts", "Employee": "employees", "Employees": "employees", "Scoring": "scorings",
    "Scorings": "scorings",
    "EstateMongo": "api/estate/estateMongo",
    "SearchName": "api/estate/searchName",
    "BatchStatus": "api/seller/batchStatus",
    "EstateNameResults": "api/estate/estateresults",
    "Uploads": "bulkuploadStatus",
    "Users": "api/users",
    "Roles": "api/roles",
    "Elastic": "api/elastic",
    "comparisionHistory": "api/seller/comparisionHistory",
    "Scoring2": "api/scoring2"
}
export default apiCalls;
