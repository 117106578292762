import React from 'react';
import DataTables from '../../CommonDataTable/DataTableEstateMongo';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import ViewModal from '../../CommonModals/viewModal';
import NewUserModal from '../../CommonModals/NewUserModal';

import FormModal from '../../../Form/FormModal';
import store from '../../../App/store';
import filePath from "../../../../config/configSampleFiles";
// config file
export default class Teachers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: ''
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [
      // {"textAlign":"center","width":47,"field":"","fieldType":"multiple","header":"","selectionMode":"multiple","show":true,"mobile":true,"displayInSettings":true},
      // { "textAlign": "center", "width": 47, "field": "Sno", "header": "SNo", "filter": false, "sortable": false, "placeholder": "Search", "show": true, "mobile": true, "displayInSettings": true },
      {
        "name": "name",
        "type": "text",
        "placeholder": "name",
        "label": "name",
        "id": "name",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "name",
        "header": "name",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "grade",
        "type": "text",
        "placeholder": "grade",
        "label": "grade",
        "id": "grade",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "grade",
        "header": "grade",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "Id",
        "type": "text",
        "placeholder": "Id",
        "label": "ID",
        "id": "Id",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "Id",
        "header": "ID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "class",
        "type": "text",
        "placeholder": "class",
        "label": "class",
        "id": "class",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "class",
        "header": "class",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }

      },
      // { "name": "name", "type": "text", "placeholder": "name", "label": "Matching Property Name", "id": "name", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "name", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } },
      // { "name": "id", "type": "text", "placeholder": "id", "label": "Property Name String", "id": "id", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "id", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } },
      // { "name": "score", "type": "text", "placeholder": "score", "label": "Name Score", "id": "score", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "score", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }

      // { "name": "propertyName", "type": "text", "placeholder": "propertyName", "label": "Matching Property Name", "id": "propertyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyName", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } },
      // { "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyString", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } },
      // { "name": "NameMatchScore", "type": "text", "placeholder": "NameMatchScore", "label": "Name Score", "id": "NameMatchScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "NameMatchScore", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }
    ]
    return data;
  };

  getTableFields1 = () => {
    let data = [
      {
        "name": "bucket",
        "type": "text",
        "placeholder": "bucket",
        "label": "bucket",
        "id": "bucket",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "bucket",
        "header": "bucket",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Badge",
        "width": 30,
        sortable: true,
        // "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" },
        "options": [
          { label: 'Green', value: 'Green', color: 'success' },
          { label: 'Blue', value: 'Blue', color: 'primary' },
          { label: 'Yellow', value: 'Yellow', color: 'warning' },
          { label: 'Brown', value: 'Brown', color: 'secondary' },
          { label: 'Black', value: 'Black', color: 'dark' }
        ]
      },
      {
        "name": "OPRA_AddressDetailID",
        "type": "text",
        "placeholder": "OPRA_AddressDetailID",
        "label": "Address Detail ID",
        "id": "OPRA_AddressDetailID",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "OPRA_AddressDetailID",
        "header": "Address Detail ID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 25,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "CompanyID",
        "type": "text",
        "placeholder": "CompanyID",
        "label": "Estate Id",
        "id": "CompanyID",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "CompanyID",
        "header": "Estate Id",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 25,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessName",
        "type": "text",
        "placeholder": "BusinessName",
        "label": "Estate Name",
        "id": "BusinessName",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "BusinessName",
        "header": "Estate Name",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 80,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyID",
        "type": "text",
        "placeholder": "PropertyID",
        "label": "Property Id",
        "id": "PropertyID",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "PropertyID",
        "header": "Property Id",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 40,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyName",
        "type": "text",
        "placeholder": "PropertyName",
        "label": "Matching Property Name",
        "id": "PropertyName",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "PropertyName",
        "header": "Property Name",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 80,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "score",
        "type": "text",
        "placeholder": "score",
        "label": "Name Score",
        "id": "NameMatchScore",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Right",
        "show": true,
        "field": "NameMatchScore",
        "header": "Name Score",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Number",
        "width": 25,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "AddressLine1MatchScore",
        "type": "text",
        "placeholder": "AddressLine1MatchScore",
        "label": "Address Line Score",
        "id": "AddressLine1MatchScore",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Right",
        "show": true,
        "field": "AddressLine1MatchScore",
        "header": "Address Line Score",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Number",
        "width": 25,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "CityStateZipScore",
        "type": "text",
        "placeholder": "CityStateZipScore",
        "label": "Address Score",
        "id": "CityStateZipScore",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Right",
        "show": true,
        "field": "CityStateZipScore",
        "header": "Address Score",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Number",
        "width": 25,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessAddressLine1",
        "type": "text",
        "placeholder": "BusinessAddressLine1",
        "label": "Estate State",
        "id": "BusinessAddressLine1",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "BusinessAddressLine1",
        "header": "Estate Address Line",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 80,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessCity",
        "type": "text",
        "placeholder": "BusinessCity",
        "label": "Estate City",
        "id": "BusinessCity",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "BusinessCity",
        "header": "Estate City",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 40,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessState",
        "type": "text",
        "placeholder": "BusinessState",
        "label": "Estate State",
        "id": "BusinessState",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "BusinessState",
        "header": "Estate State",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 20,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "BusinessZipCode",
        "type": "text",
        "placeholder": "BusinessZipCode",
        "label": "Estate Zip",
        "id": "BusinessZipCode",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "BusinessZipCode",
        "header": "Estate Zip",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 20,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyAddressLine1",
        "type": "text",
        "placeholder": "PropertyAddressLine1",
        "label": "Property Adddress",
        "id": "PropertyAddressLine1",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "PropertyAddressLine1",
        "header": "Property Address Line",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 80,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyCity",
        "type": "text",
        "placeholder": "PropertyCity",
        "label": "Property City",
        "id": "PropertyCity",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "PropertyCity",
        "header": "Property City",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 40,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyState",
        "type": "text",
        "placeholder": "PropertyState",
        "label": "Property State",
        "id": "PropertyState",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "PropertyState",
        "header": "Property State",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 25,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "PropertyZipCode",
        "type": "text",
        "placeholder": "PropertyZipCode",
        "label": "Property Zip",
        "id": "PropertyZipCode",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "PropertyZipCode",
        "header": "Property Zip",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 25,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
    ]
    return data;
  };

  getFormFields = () => {
    return ([
      {
        "name": "propertyName",
        "type": "text",
        "placeholder": "propertyName",
        "label": "Estate Name",
        "id": "propertyName",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "show": true,
        "mobile": true,
        "displayInSettings": true,
      }, {
        "name": "propertyString",
        "type": "text",
        "placeholder": "propertyString",
        "label": "Property Name String",
        "id": "propertyString",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "show": true,
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" },
        "isAddFormHidden": false,
        "isEditFormHidden": false
      }, {
        "name": "NameMatchScore",
        "type": "text",
        "placeholder": "NameMatchScore",
        "label": "Name Score",
        "id": "NameMatchScore",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "show": true,
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" },
        "isAddFormHidden": false,
        "isEditFormHidden": false

      }]);
  }

  getFormFields1 = () => {
    return ([
      {
        "name": "propertyName", "type": "text", "placeholder": "propertyName", "label": "Matching Property Name", "id": "propertyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "show": true, "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }, "isAddFormHidden": false, "isEditFormHidden": false
      }, {
        "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "show": true, "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }, "isAddFormHidden": false, "isEditFormHidden": false
      }, {
        "name": "NameMatchScore", "type": "text", "placeholder": "NameMatchScore", "label": "Name Score", "id": "NameMatchScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "show": true, "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }, "isAddFormHidden": false, "isEditFormHidden": false
      }]);
  }

  submit = async (item) => {

    this.setState({ isOpenFormModal: true })
    await this.formModalRef.getRowData(item, 'edit');

    console.log("Submit Button in sode          ")
  }
  getMobileTableFields = () => {
    let data = [{ "textAlign": "center", "width": 47, "field": "", "fieldType": "multiple", "header": "", "selectionMode": "multiple", "show": true, "mobile": true, "displayInSettings": true }, { "textAlign": "center", "width": 47, "field": "Sno", "header": "SNo", "filter": false, "sortable": false, "placeholder": "Search", "show": true, "mobile": true, "displayInSettings": true }, { "name": "propertyName", "type": "text", "placeholder": "propertyName", "label": "Matching Property Name", "id": "propertyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyName", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyString", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "NameMatchScore", "type": "text", "placeholder": "NameMatchScore", "label": "Name Score", "id": "NameMatchScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "NameMatchScore", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }]

    return data;
  };
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
    })
  }

  cancelReset = async (type) => {
    this.setState({
      openNewUserModal: false
    });
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };
  render() {
    return (
      <span>
        <DataTables
          onRef={ref => (this.dataTableRef = ref)}
          // MobileTableFields={this.getMobileTableFields}
          // getTableFields={this.getTableFields}
          // formFields={this.getFormFields}
          // 
          addRequired={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={false}
          exportRequired={false}
          sample={false}
          getTableFields={this.getTableFields}
          getTableFields1={this.getTableFields1}
          formFields={this.getFormFields}
          // exportRequried={true}

          printRequried={false}
          actionsTypes={[{
            'name': 'Delete',
            "options": [
              { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
            ]
          },
          // {
          //   'name': 'Block',
          //   "options": [
          //     { 'label': 'Block', 'value': 'Block', 'show': true, "multiple": false, }
          //   ]
          // },
          {
            'name': 'ResetPassword',
            "options": [
              { 'label': 'ResetPassword', 'value': 'ResetPassword', 'show': true, "multiple": false, }
            ]
          },
          {
            'name': 'Submit',
            'action': this.submit,
            "options": [
              { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
            ]
          },
            // {
            //   name: "newModel",
            //   action: this.submit1,
            //   options: [
            //     { label: "newModel", value: "newModel", show: true, multiple: false }
            //   ]
            // }

          ]}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={true}
          gridRequried={true}
          exportToCsv={true}

          sampleFilePath={filePath.scorings}

          globalSearch={'Name String'}
          type='EstateMongo'
          routeTo={apiCalls.EstateMongo}
          displayViewOfForm='screen'
          apiResponseKey={apiCalls.EstateMongo}
          apiUrl={apiCalls.EstateMongo}

        />
      </span>
    );
  }
}