import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import ViewModal from '../../CommonModals/viewModal';
import NewUserModal from '../../CommonModals/NewUserModal';

import FormModal from '../../../Form/FormModal';
import store from '../../../App/store';
import filePath from "../../../../config/configSampleFiles";
// config file
export default class Teachers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: ''
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getTableFields1 = () => {
    let data = [
      // {"textAlign":"center","width":47,"field":"","fieldType":"multiple","header":"","selectionMode":"multiple","show":true,"mobile":true,"displayInSettings":true},
      // { "textAlign": "center", "width": 47, "field": "Sno", "header": "SNo", "filter": false, "sortable": false, "placeholder": "Search", "show": true, "mobile": true, "displayInSettings": true },
      {
        "name": "name",
        "type": "text",
        "placeholder": "name",
        "label": "name",
        "id": "name",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "name",
        "header": "name",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "grade",
        "type": "text",
        "placeholder": "grade",
        "label": "grade",
        "id": "grade",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "grade",
        "header": "grade",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "Id",
        "type": "text",
        "placeholder": "Id",
        "label": "ID",
        "id": "Id",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "Id",
        "header": "ID",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "class",
        "type": "text",
        "placeholder": "class",
        "label": "class",
        "id": "class",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "class",
        "header": "class",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }

      },
      // { "name": "name", "type": "text", "placeholder": "name", "label": "Matching Property Name", "id": "name", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "name", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } },
      // { "name": "id", "type": "text", "placeholder": "id", "label": "Property Name String", "id": "id", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "id", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } },
      // { "name": "score", "type": "text", "placeholder": "score", "label": "Name Score", "id": "score", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "score", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }

      // { "name": "propretyName", "type": "text", "placeholder": "propretyName", "label": "Matching Property Name", "id": "propretyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propretyName", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } },
      // { "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyString", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } },
      // { "name": "nameScore", "type": "text", "placeholder": "nameScore", "label": "Name Score", "id": "nameScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "nameScore", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }
    ]
    return data;
  };

  getTableFields = () => {
    let data = [
      {
        "name": "id",
        "type": "text",
        "placeholder": "esid",
        "label": "Estate Id",
        "id": "esid",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "esid",
        "header": "Estate Id",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 25,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "esname",
        "type": "text",
        "placeholder": "esname",
        "label": "Estate Name",
        "id": "esname",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "esname",
        "header": "Estate Name",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 80,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "esAddress",
        "type": "text",
        "placeholder": "esAddress",
        "label": "Estate State",
        "id": "esAddress",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "esaddress",
        "header": "Estate Address Line",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 80,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "escity",
        "type": "text",
        "placeholder": "escity",
        "label": "Estate City",
        "id": "escity",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "esCity",
        "header": "Estate City",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 40,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "esstate",
        "type": "text",
        "placeholder": "esstate",
        "label": "Estate State",
        "id": "esstate",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "esstate",
        "header": "Estate State",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 20,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "eszip",
        "type": "text",
        "placeholder": "eszip",
        "label": "Estate Zip",
        "id": "eszip",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "esZip",
        "header": "Estate Zip",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 20,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      }
    ]
    return data;
  };

  getFormFields = () => {
    return ([
      {
        "name": "propretyName",
        "type": "text",
        "placeholder": "propretyName",
        "label": "Estate Name",
        "id": "propretyName",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "show": true,
        "mobile": true,
        "displayInSettings": true,
      }, {
        "name": "propertyString",
        "type": "text",
        "placeholder": "propertyString",
        "label": "Property Name String",
        "id": "propertyString",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "show": true,
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" },
        "isAddFormHidden": false,
        "isEditFormHidden": false
      }, {
        "name": "nameScore",
        "type": "text",
        "placeholder": "nameScore",
        "label": "Name Score",
        "id": "nameScore",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "show": true,
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" },
        "isAddFormHidden": false,
        "isEditFormHidden": false

      }]);
  }

  getFormFields1 = () => {
    return ([
      {
        "name": "propretyName", "type": "text", "placeholder": "propretyName", "label": "Matching Property Name", "id": "propretyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "show": true, "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }, "isAddFormHidden": false, "isEditFormHidden": false
      }, {
        "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "show": true, "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }, "isAddFormHidden": false, "isEditFormHidden": false
      }, {
        "name": "nameScore", "type": "text", "placeholder": "nameScore", "label": "Name Score", "id": "nameScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "show": true, "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }, "isAddFormHidden": false, "isEditFormHidden": false
      }]);
  }

  submit = async (item) => {

    this.setState({ isOpenFormModal: true })
    await this.formModalRef.getRowData(item, 'edit');

    console.log("Submit Button in sode          ")
  }
  getMobileTableFields = () => {
    let data = [{ "textAlign": "center", "width": 47, "field": "", "fieldType": "multiple", "header": "", "selectionMode": "multiple", "show": true, "mobile": true, "displayInSettings": true }, { "textAlign": "center", "width": 47, "field": "Sno", "header": "SNo", "filter": false, "sortable": false, "placeholder": "Search", "show": true, "mobile": true, "displayInSettings": true }, { "name": "propretyName", "type": "text", "placeholder": "propretyName", "label": "Matching Property Name", "id": "propretyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propretyName", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyString", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "nameScore", "type": "text", "placeholder": "nameScore", "label": "Name Score", "id": "nameScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "nameScore", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }]

    return data;
  };
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
    })
  }
  // submit1 = item => {
  //   let x = scoringsFields
  //   let objscorings = {}

  //   objscorings["_id"] = item[0]["_id"]
  //   for (let x2 of x) {
  //     objscorings[x2] = item[0][x2]
  //   }
  //   let formFields = this.getFormFields()

  //   formFields = formFields.filter(y => x.includes(y.name))

  //   this.setState({
  //     openNewUserModal: true,
  //     item: objscorings,
  //     newFormFields: formFields
  //   });
  // }

  cancelReset = async (type) => {
    this.setState({
      openNewUserModal: false
    });
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };
  render() {
    return (
      <span>
        <DataTables
          onRef={ref => (this.dataTableRef = ref)}
          // MobileTableFields={this.getMobileTableFields}
          // getTableFields={this.getTableFields}
          // formFields={this.getFormFields}
          // 
          addRequired={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={false}
          exportRequired={false}
          sample={false}
          // globalSearch={'Display Name/Email'}
          // type='Scorings'
          // apiUrl={apiCalls.Scorings}
          getTableFields={this.getTableFields}
          getTableFields1={this.getTableFields1}
          formFields={this.getFormFields}
          // exportRequried={true}

          printRequried={false}
          // actionsTypes={[{
          //   'name': 'Delete',
          //   "options": [
          //     { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
          //   ]
          // },
          // // {
          // //   'name': 'Block',
          // //   "options": [
          // //     { 'label': 'Block', 'value': 'Block', 'show': true, "multiple": false, }
          // //   ]
          // // },
          // {
          //   'name': 'ResetPassword',
          //   "options": [
          //     { 'label': 'ResetPassword', 'value': 'ResetPassword', 'show': true, "multiple": false, }
          //   ]
          // },
          // {
          //   'name': 'Submit',
          //   'action': this.submit,
          //   "options": [
          //     { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
          //   ]
          // },
          //   // {
          //   //   name: "newModel",
          //   //   action: this.submit1,
          //   //   options: [
          //   //     { label: "newModel", value: "newModel", show: true, multiple: false }
          //   //   ]
          //   // }

          // ]}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          exportToCsv={false}


          globalSearch={'Name String'}
          type='EstateName'
          routeTo={apiCalls.Employees}
          displayViewOfForm='screen'
          apiResponseKey={apiCalls.EstateNameResults}
          apiUrl={apiCalls.EstateNameResults}

        />
        {this.state.isOpenFormModal ? (
          <FormModal
            onRef={(ref) => (this.formModalRef = ref)}
            formType="edit"
            openFormModal={this.state.isOpenFormModal}
            formFields={this.props.formFields}
          />

        ) : null}
        {this.state.openNewUserModal ? (
          <NewUserModal
            openNewUserModal={this.state.openNewUserModal}
            cancelReset={this.cancelReset}
            item={this.state.item}
            newFormFields={this.state.newFormFields}
            recordId={this.state.item._id}
            entityType="Scorings"
            apiUrl={apiCalls.Scorings}
          />
        ) : null}
      </span>
    );
  }
}