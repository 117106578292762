import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';
import ViewModal from '../../CommonModals/viewModal';
import FormModal from '../../../Form/FormModal';

// config file
export default class CompanyNamesSQL extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'CompanyNamesSQL',
			rolePermission: ""
		};
	}

	static getDerivedStateFromProps(props, state) {
		let storeData = store.getState()
		let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
		return { languageData: languageData };
	}

	componentDidMount = () => {
		//Screen permisions value can be edit,view, no view
		let screenPermissions = RolePermissions.screenPermissions('CompanyNamesSQL');
		if (screenPermissions) {
			this.setState({
				rolePermission: screenPermissions
			})
		}
	}

	getTableFields = () => {
		let { languageData } = this.state
		let data =
			[
				// {
				// 	"textAlign": "center",
				// 	"width": 47,
				// 	"field": "",
				// 	"fieldType": "multiple",
				// 	"header": "",
				// 	"selectionMode": "multiple",
				// 	"show": true,
				// 	"mobile": true,
				// 	"displayInSettings": true
				// },
				{
					textAlign: "left",
					width: 50,
					field: "Source",
					fieldType: "Link",
					mobile: true,
					header: "Source",
					filter: true,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
				},
				{
					textAlign: "left",
					width: 120,
					field: "SQLStatement",
					mobile: true,
					header: "SQLStatement",
					filter: true,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
				}
			]
		return data;
	};


	getFormFields = () => {
		let { languageData } = this.state
		return ([
			{
				'show': true,
				"value": "",
				"type": "text",
				"name": "Source",
				"label": "Source",
				"id": "Source",
				"placeholder": "Source",
				"required": true
			},
			{
				'show': true,
				"value": "",
				"type": "textarea",
				"name": "SQLStatement",
				"label": "SQLStatement",
				"id": "SQLStatement",
				"placeholder": "SQLStatement",
				"required": true
			}
		]);
	}

	submit = async (item) => {

		this.setState({ isOpenFormModal: true })
		await this.formModalRef.getRowData(item, 'edit');

		console.log("Submit Button in sode          ")
	}

	closeFormModal = async () => {
		this.setState({
			isOpenFormModal: false,
		})
	}

	render() {
		return (
			<span>
				<DataTables
					getTableFields={this.getTableFields}
					formFields={this.getFormFields}
					actionsTypes={[{
						'name': 'Delete',
						"options": [
							{ 'label': 'Delete', 'value': 'Delete', 'show': this.state.rolePermission && this.state.rolePermission == "Edit" ? true : false, "multiple": true, },
						]
					},
					]}
					exportRequried={false}
					printRequried={false}
					addRequried={false}
					editRequired={true}
					deleteRequired={false}
					viewRequired={false}
					settingsRequired={true}
					filterRequired={false}
					gridRequried={false}
					sample={false}
					// globalSearchFieldName='activity'
					// globalSearch={"Context/Email/Description"}
					type="CompanyNamesSQL"
					apiResponseKey={"companyNamesSQL"}
					apiUrl={"api/companyNamesSQL"}
					routeTo='companyNamesSQL'
					displayViewOfForm='modal'
				/>
				{this.state.isOpenFormModal ? (
					<FormModal
						onRef={(ref) => (this.formModalRef = ref)}
						formType="edit"
						openFormModal={this.state.isOpenFormModal}
						formFields={this.props.formFields}
					/>

				) : null}
			</span>
		);
	}
}