const imagePath = '../assets/';
const configImages = {
  defaultImg: require(`../assets/img/profile/defaultImg.png`),
  defaultUserImage: require(`../assets/img/profile/default_user_image.png`),
  employeesSampleXL: require(`../assets/files/scheduleSampleUpload.xlsx`),
  loginImage: require(`../assets/img/logo/1648107158366_careers-background-consulting-bw-business-world.jpg`),
  OakPointImage: require(`../assets/img/logo/OPP-Logo.png`),
  // loginLogo: require(`../assets/img/logo/loginLogo.jpg`),
  loaderImage: require(`../assets/loader.gif`)

};
export default configImages;
