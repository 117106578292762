import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ScoringLayerConfiguration from './components/ScoringLayerConfiguration';

const scoringLayerConfiguration = ({ t }) => (
  <div>
    <ScoringLayerConfiguration />
  </div>
);

scoringLayerConfiguration.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(scoringLayerConfiguration);
