// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envConfig from "../../../config/envConfig";
import { Badge, Button, Col, Row, Card, CardBody, CardHeader, Collapse, ButtonGroup, ButtonToolbar } from 'reactstrap';
import moment from 'moment';

import { Redirect, Link } from 'react-router-dom';

import '../../../scss/dashboardStyles.css';
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';

import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';

//Modals
import ShowHideColumnsModal from '../CommonModals/ShowHideColumnsModal';
import FormModal from '../../Form/FormModal';
import DeleteRowModal from '../CommonModals/DeleteRowModal';
import ViewModal from '../CommonModals/viewModal';
import BulkUploadModal from '../CommonModals/BulkUploadModal';
import ConfirmationModal from '../CommonModals/ConfirmationModal';
import SessionExpiryModal from '../CommonModals/SessionexpiryModal';
import PreviewModal from '../CommonModals/PreviewModal';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
// import UserPasswordResetModal from '../Modals/UserPasswordResetModal';
// fetch method for server call
import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';
import apiCall from '../../../config/apiCalls';

// Permissions
// import RolePermissions from '../Modals/Permissions';

// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Date Formate
import dateFormats from '../../UI/FormatDate/formatDate';
//Loader
import Loader from '../../App/Loader';
//store
import store from '../../App/store'
import validate from '../../Validations/validate'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'

import PaginatorComponent from './PaginatorComponent';
import { element } from 'prop-types';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog, faPrint, faGripHorizontal, faList } from "@fortawesome/free-solid-svg-icons";
import stringSimilarity from "string-similarity";
library.add(faCog, faPrint, faGripHorizontal, faList);
const Elastic_Indexes = require("../../../config/elasticIndexes");

// tooltip styles
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
}))(Tooltip);

const AnotherLightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
  },
}))(Tooltip);

class DataTables extends React.Component {
  constructor(props) {
    super(props);
    this.closeMultiSelectDropdown = React.createRef();
    this.state = {
      allUsersData: [],
      deleteRowDataId: {},
      tableFields: this.props.getTableFields(),
      originalTableFields: this.props.getTableFields(),
      tablefieldsToShow: this.props.getTableFields(),
      tableFields1: this.props.getTableFields1(),
      originalTableFields1: this.props.getTableFields1(),
      tablefieldsToShow1: this.props.getTableFields1(),
      isOpenShowHideColumnsModal: false,
      first: 0,
      rows: 100,
      sortified: false,
      direction: 'asc',
      filterCriteria: { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },
      selectedRows: '',
      selectedRowsId: '',
      totalRecords: [],
      exportData: [],
      isOpenFormModal: false,
      openBulkUploadModal: false,
      openViewModal: false,
      isLoading: false,
      isOpenRolesModal: false,
      isOpenConfirmationModal: false,
      selectedScreenPermission: '',
      loginRole: 'Admin',
      startDate: '',
      endDate: '',
      apiUrl: this.props.apiUrl,
      totalRecordsLength: 0,
      tabType: this.props.tabType,

      sessionExpiryModal: false,
      mobileListFields: [],
      dateFormat: '',
      openTemplateModal: false,
      isOpenProductsModal: false,
      selectschool: '',
      roleType: '',
      openId: 1,
      redirecToLogin: false,
      openDeleteModal: false,
      blockFamily: false,
      selectActions: '',
      viewType: 'list',
      actions: '',
      displayViewOfForm: this.props.displayViewOfForm,
      displayBreadCrumbField: '',
      displayBreadCrumbValue: '',
      width: window.innerWidth,
      height: window.innerHeight,
      globalSearch: '',
      showMultiSelectDropDown: false,
      multiSelectTypes: '',
      actionsTypes: '',
      userStatus: '',
      selectedAutoCompleteValue: '',
      suggestions: [{ "label": `No Results` }],
      singleSuggestions: [{ "label": `No Results` }],
      confirmModalText: '',
      confirmType: '',
      openUserPasswordResetModal: false,
      isPreviewModal: false,
      emailTemplateData: '',
      condition: 'OR',
      database: 'OPP',
      singleDatabase: "OPP",
      originalResponseData: [],
      EstateCount: 0,
      emailValidate: "no",
      ElasticQueryComplexity: 'SIMPLE',
      ElasticIndex: "both"
    };
  }

  componentDidMount = async () => {
    if (!document.getElementsByClassName("layout layout--collapse")[0]) {
      document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
    }
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    console.log(this.props)
    // this.setAutocompleteSuggestions()
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    window.addEventListener('resize', this.updateDimensions);
    document.addEventListener('mousedown', this.handleClickOutside)
    if (!loginCredentials) {
      await this.setState({ redirecToLogin: true })
    } else {
      await this.setState({
        redirecToLogin: false,
        displayViewOfForm: this.props.displayViewOfForm,
      })
    }
    // let sessionexpired = false
    // if (sessionexpired == "true") {
    //   await this.setState({ sessionExpiryModal: true })
    // }
    await this.getTimeFormat()
    await this.getLoginRole();
    await this.getActions();
    console.log(this.props.getTableFields1)
    // hari check where this should go
    if (this.props.getTableFields) {
      //let tablefields = await this.props.getTableFields();
      this.setState({
        tablefields: this.props.getTableFields(),
        originalTableFields: this.props.getTableFields(),
        isLoading: false,
        mobileListFields: this.props.getTableFields(),
      }, async () => {
        let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
        let columns = await JSON.parse(colOrder);
        if (columns) {
          await this.getTableFieldsOrder();
        } else {
          await this.updateColumnsOrder(null);
        }
      });
    }
    this.getDataFromServer(this.state.filterCriteria);
    this.setState({
      formFields: this.props.formFields
    })
  }
  setAutocompleteSuggestions = async (data) => {
    data = encodeURIComponent(data);
    await fetchMethodRequest('GET', `api/estate/estateNames?query=${data}`)
      // return fetch(`${config.elasticApiUrl}/estateNames`, { method: 'GET' })
      .then((response) => {
        if (response) {
          let dropdownData = [];
          if (response && response.details && response.details.length > 0) {
            response.details.forEach((doc) => {
              dropdownData.push({ "label": doc })
            })
            this.setState({
              suggestions: dropdownData,
              suggestionsBack: dropdownData,
              singleSuggestions: dropdownData
            })
          } else {
            this.setState({
              suggestions: [{ "label": `No Results` }],
              suggestionsBack: [{ "label": `No Results` }]
            })

          }
        }
      }).catch((err) => {
        return err;
      });
  }

  setSingleAutocompleteSuggestions = async (data) => {
    data = encodeURIComponent(data);
    await fetchMethodRequest('GET', `api/estate/companyNames?query=${data}&companySourceType=OPRA`)
      // return fetch(`${config.elasticApiUrl}/estateNames`, { method: 'GET' })
      .then((response) => {
        if (response) {
          let dropdownData = [];
          if (response && response.details && response.details.length > 0) {
            response.details.forEach((doc) => {
              dropdownData.push({ "label": doc })
            })
            this.setState({
              companySuggestions: dropdownData
            })
          } else {
            this.setState({
              companySuggestions: [{ "label": `No Results` }],
            })

          }
        }
      }).catch((err) => {
        return err;
      });
  }


  getActions = async () => {
    let selectedRows = this.state.selectedRows ? [... this.state.selectedRows] : [];
    let actionsTypes = this.props.actionsTypes ? [... this.props.actionsTypes] : [];
    console.log(actionsTypes)
    let result = [];
    if (actionsTypes && actionsTypes.length) {
      for (let i = 0; i < actionsTypes.length; i++) {
        if (actionsTypes[i]['options'] && actionsTypes[i]['options'].length > 0) {
          let options = actionsTypes[i]['options']
          for (let j = 0; j < options.length; j++) {
            if (options[j]['show'] === true) {
              if (selectedRows && selectedRows.length >= 2 && options[j]['multiple'] === true) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
              } else if (selectedRows && selectedRows.length === 1) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
              } else if (selectedRows && selectedRows.length === 0) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
              }
            }
          }
        }
      }
    }
    await this.setState({
      actionsTypes: result
    })
  }

  handleClickOutside = (event) => {
    if (
      this.closeMultiSelectDropdown.current &&
      !this.closeMultiSelectDropdown.current.contains(event.target)
    ) {
      this.setState({
        showMultiSelectDropDown: false
      });
    }
  };
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
    window.removeEventListener('resize', this.updateDimensions);
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  updateDimensions = async () => {
    await this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  getTimeFormat = () => {
    // let store=store.getState()
    // let dateFormat=store && store.commonData && store.commonData.data && store.commonData.data.timeFormat?store.commonData.data.timeFormat:'DD-MM-YYYY'
    // console.log('timeFormat',dateFormat)
  }

  // Handle Table fields to show in datatable
  getTableFieldsOrder() {
    this.setState({
      tablefieldsToShow: [],
      isLoading: true,
    })

    let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
    let columns = JSON.parse(colOrder);
    let tempTableFields = [];
    let newTableFields = [];
    let staticTableFields = [...this.state.originalTableFields];

    if (columns) {
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field && this.state.viewType === 'list') {
            let pushItem = staticTableFields[j];
            pushItem.show = columns[i].show;
            pushItem.displayInSettings = columns[i].displayInSettings;
            tempTableFields.push(pushItem);
            break;
          }
          if (columns[i].field === staticTableFields[j].field && this.state.viewType === 'grid') {
            let pushItem = staticTableFields[j];
            pushItem.displayInSettings = columns[i].displayInSettings;
            pushItem.mobile = columns[i].mobile;
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
    } else {
      tempTableFields = this.state.originalTableFields
    }

    newTableFields = tempTableFields;

    let TableFieldsToShow = [];
    if (newTableFields && newTableFields.length > 0) {
      newTableFields.map(item => {
        // TableFieldsToShow.push(item)
        if (item.show && this.state.viewType === 'list') {
          TableFieldsToShow.push(item)
        }
        if (item.mobile && this.state.viewType === 'grid') {
          TableFieldsToShow.push(item)
        }
      })
    }

    this.setState({
      tablefieldsToShow: TableFieldsToShow,
      isLoading: false,
    })
  }
  changeCheckIcon = async (index, subIndex, key, value) => {
    let tablefieldsToShow = this.state.tablefieldsToShow;
    if (tablefieldsToShow[index]['options'][subIndex]['checkIcon'] === true) {
      let filterCriteria = Object.assign({}, this.state.filterCriteria);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj)
        let objValue = obj.value.find(y => y === value)
        let i = obj.value.indexOf(objValue);
        filterCriteria.criteria[objIndex]['value'].splice(i, 1);
        let length = filterCriteria.criteria[objIndex]['value'] ? filterCriteria.criteria[objIndex]['value'].length : 0;
        if (length === 0) {
          filterCriteria.criteria.splice(objIndex, 1)
        }
      }
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      await this.setState({
        tablefieldsToShow: tablefieldsToShow,
        filterCriteria: filterCriteria
      })
      await this.onMultiSelectFilterChange(key, value, 'pop', index)
    } else {
      let filterCriteria = Object.assign({}, this.state.filterCriteria);
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      await this.setState({
        tablefieldsToShow: tablefieldsToShow
      })
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj);
        await this.onMultiSelectFilterChange(key, value, 'push', objIndex)
      } else {
        await this.onMultiSelectFilterChange(key, value, 'push', index)
      }

    }
  }
  changeViewType = async (viewType) => {
    await this.setState({ viewType: viewType })
    await this.getTableFieldsOrder()
  }
  // Handle Table fields order of display in DataTable
  updateColumnsOrder(currentOrder) {
    this.setState({
      isLoading: true,
    })
    let originalTableFields = [...this.state.originalTableFields];
    let order = currentOrder ? currentOrder : null;
    let updatedOrder = [];
    let unmatchedTableFields = [...this.state.originalTableFields];

    if (order && order.length > 0) {
      order.map(async item => {
        if (item && item.props && item.props.field) {
          originalTableFields.map(col => {
            if (item.props.field === col.field) {
              updatedOrder.push(
                {
                  field: col.field,
                  show: col.show,
                  header: col.header,
                  displayInSettings: col.displayInSettings,
                  mobile: col.mobile
                }
              )
            }
          })
        }
      })

      order.map(async item => {
        if (item && item.props && item.props.field) {
          for (let i = 0; i < unmatchedTableFields.length; i++) {
            if (item.props.field === unmatchedTableFields[i].field) {
              unmatchedTableFields.splice(i, 1)
            }
          }
        }
      })

      if (unmatchedTableFields && unmatchedTableFields.length > 0) {
        unmatchedTableFields.map(col => {
          updatedOrder.push(
            {
              field: col.field,
              show: false,
              header: col.header,
              displayInSettings: col.displayInSettings,
              mobile: false
            }
          )
        })
      }
    } else {
      originalTableFields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile
          }
        )
      });
    }

    localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
    this.getTableFieldsOrder(this.state.originalTableFields);
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    //console.log('store Data', storeData)
    let dateFormat = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.dateFormat ? storeData.settingsData.settings.dateFormat : "DD-MM-YYYY"
    //console.log('timeFormat', dateFormat)
    if (state.dateFormat != dateFormat) {
      return { dateFormat: dateFormat };

    }
    return { dateFormat: dateFormat };

  }

  getLoginRole() {
    let loginData = localStorage.getItem('loginCredentials');
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {
        this.setState({
          loginRole: loginData.role
        })
      }
    }
  }

  updateSlNoToData(itemdata) {
    let modifiedData = [];
    itemdata.forEach((item, index) => {
      if (this.props.flags) {
        let flags = this.props.flags
        if (item[flags['label']] == flags['value']) {
          modifiedData.push(item);
        }
      } else {
        if (this.state.first) {
          item.Sno = (index + 1) + this.state.first;
        } else {
          item.Sno = index + 1;
        }

        modifiedData.push(item);
      }
    });
    return modifiedData;
  }

  updateDateFormat(itemdata, dateFormat) {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < this.state.tablefieldsToShow.length; k++) {
        if ("Date" == this.state.tablefieldsToShow[k]['fieldType']) {
          itemdata[i][this.state.tablefieldsToShow[k]['field']] =
            dateFormats.formatDate(
              itemdata[i][this.state.tablefieldsToShow[k]['field']],
              this.state.tablefieldsToShow[k]['dateFormat'] ? this.state.tablefieldsToShow[k]['dateFormat'] : dateFormat);
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  //get table list data from server with filters if any
  getDataFromServer = async (filterCriteria, type) => {
    let url = this.getAPIUrl();
    //let url = this.props.apiUrl;
    let dateFormat = this.state.dateFormat
    if (url) {
      let apiUrl;
      this.setState({
        isLoading: true,
        selectedRows: '',
      });
      if (type == 'refresh') {
        if (document.getElementById("globalSearch") && document.getElementById('globalSearch').value) {
          document.getElementById('globalSearch').value = '';
        }
        let tablefieldsToShow = this.state.tablefieldsToShow;
        if (tablefieldsToShow && tablefieldsToShow.length > 0) {
          for (let i = 0; i < tablefieldsToShow.length; i++) {
            let options = tablefieldsToShow[i].options;
            if (options && options.length) {
              for (let j = 0; j < options.length; j++) {
                options[j]['checkIcon'] = false
              }
            }
          }
        }
        await this.setState({
          globalSearch: '',
          allUsersData: [],
          status: '',
          selectschool: '',
          roleType: '',
          tablefieldsToShow: tablefieldsToShow
        });

      }
      if (!filterCriteria || !filterCriteria['criteria']) {
        let filterCriteria = {};
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
      }
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        delete filterCriteria['sortfield'];
        delete filterCriteria['direction'];
      }
      if (type === 'refresh' && filterCriteria) {
        delete filterCriteria.globalSearch;
        filterCriteria['criteria'] = [];
        filterCriteria['direction'] = 'desc';
        filterCriteria['sortfield'] = 'created';
      }
      if (this.props.params) {
        console.log(this.props.params)
        let params = this.props.params
        for (let i in params) {
          console.log(i)
          if (i) {
            let obj = {}
            obj['key'] = i
            obj['value'] = params[i]
            obj['type'] = 'regexOr'
            filterCriteria['criteria'].push(obj)
            console.log(obj)
          }
        }
      }
      //applyng filter when a row item is deleted 
      if (filterCriteria == undefined) {
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      if (this.props.filterExtension) {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${this.props.filterExtension}`
      }
      else if (this.props.hasQueryInCall) {
        console.log('hi')
        apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
      }
      else {
        // apiUrl = this.getFilterUrl(filterCriteria, type);
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      console.log("apiUrl", apiUrl)
      fetchMethodRequest('GET', apiUrl)
        .then(async (response) => {
          let apiResponseKey = this.props.apiResponseKey;
          let sessionexpired = localStorage.getItem('sessionexpired')
          if (sessionexpired == "true") {
            await this.setState({ sessionExpiryModal: true })
          }
          let responseData = [], totalRecordsLength = this.state.totalRecordsLength;
          if (apiResponseKey === 'scorings') {
            response.details = await JSON.parse(response.details)
          }
          console.log("response.details", response.details)
          if (response && response.details && response.details.length > 0) {
            responseData = response.details;
            totalRecordsLength = response.details.length;
          } else {
            if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
              if (response.pagination && response.pagination.totalCount) {
                totalRecordsLength = response.pagination.totalCount;
              }
              responseData = this.updateSlNoToData(response[apiResponseKey]);
              responseData = this.updateDateFormat(responseData, this.state.dateFormat);
            } else {
              if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
                totalRecordsLength = response.pagination.totalCount;
              }
            }
          }
          await this.setState({
            allUsersData: responseData,
            isLoading: false,
            totalRecordsLength: totalRecordsLength
          })
          return responseData;
        }).catch((err) => {
          return err;
        });

    }
  }
  sortData = async (value, responseData) => {
    let responseDataModified = []
    responseDataModified = await responseData.sort(function (a, b) {
      var x = Number(b[value]); var y = Number(a[value]);
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    return responseDataModified
  }
  getSearchDataFromServer = async (filterCriteria, type) => {
    // if (this.state.ElasticIndex == undefined) {
    // showToasterMessage("Please select an Elastic index.", 'info');
    // } else {
    console.log("this.state.gettable data", this.state.tablefieldsToShow1)
    let flag = this.state.database == 'TEST' ? 'elastic' : 'opp'
    let request;
    let ThresholdValues = {
      name: Number(this.state.percent) ? Number(this.state.percent) : 0,
      addressLine: Number(this.state.addLinePercent) ? Number(this.state.addLinePercent) : 0,
      CityStateZip: Number(this.state.addPercent) ? Number(this.state.addPercent) : 0
    }
    if (type == "submitJob") {
      // Job submitted successful.  You will get the results in an email"
      showToasterMessage("Job submitted successful.You will get the results in an email.", 'success');
      var value = encodeURIComponent(this.state.searchValue);
      if (type == 'submitJob') {
        if (flag == 'elastic') {
          request = fetch(`${config.elasticApiUrl}/api/estate/?query=${value}&job=true&email=${this.state.RecevingEmail}&ScoringThresholds=${JSON.stringify(ThresholdValues)}&typeOfIndex=${this.state.ElasticIndex}&companySourceType=OPRA&ScoringType=RegularScoring&ElasticQueryComplexity=${this.state.ElasticQueryComplexity}`, { method: "GET" })
        } else if (flag == 'opp') {
          request = fetch(`${config.elasticApiUrl}/api/estate/opp?query=${value}&job=true&email=${this.state.RecevingEmail}&ScoringThresholds=${JSON.stringify(ThresholdValues)}&batchName=${this.state.batchName}&typeOfIndex=${this.state.ElasticIndex}&companySourceType=OPRA&ScoringType=RegularScoring&ElasticQueryComplexity=${this.state.ElasticQueryComplexity}`, { method: "GET" })
        }
      }
      return request
        .then((response) => {
          if (response) {
            if (response.respCode && response.respMessage) {

            } else if (response.errorMessage) {
              // display message
              // showToasterMessage(response.errorMessage, 'error');
            }
          }
        })
    } else {
      this.setState({
        suggestions: this.state.suggestionsBack
      })
      let url = this.getAPIUrl();
      //let url = this.props.apiUrl;
      let dateFormat = this.state.dateFormat
      if (url) {
        let apiUrl;
        let apiUrl1;
        this.setState({
          isLoadingsearch: true,
          selectedRows: '',
        });
        if (type == 'refresh') {
          if (document.getElementById("globalSearch") && document.getElementById('globalSearch').value) {
            document.getElementById('globalSearch').value = '';
          }
          let tablefieldsToShow = this.state.tablefieldsToShow;
          if (tablefieldsToShow && tablefieldsToShow.length > 0) {
            for (let i = 0; i < tablefieldsToShow.length; i++) {
              let options = tablefieldsToShow[i].options;
              if (options && options.length) {
                for (let j = 0; j < options.length; j++) {
                  options[j]['checkIcon'] = false
                }
              }
            }
          }
          await this.setState({
            globalSearch: '',
            allUsersData: [],
            status: '',
            selectschool: '',
            roleType: '',
            tablefieldsToShow: tablefieldsToShow
          });

        }
        if (!filterCriteria || !filterCriteria['criteria']) {
          let filterCriteria = {};
          filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
        }
        if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
          delete filterCriteria['sortfield'];
          delete filterCriteria['direction'];
        }
        if (type === 'refresh' && filterCriteria) {
          delete filterCriteria.globalSearch;
          filterCriteria['criteria'] = [];
          filterCriteria['direction'] = 'desc';
          filterCriteria['sortfield'] = 'created';
        }
        if (this.props.params) {
          console.log(this.props.params)
          let params = this.props.params
          for (let i in params) {
            console.log(i)
            if (i) {
              let obj = {}
              obj['key'] = i
              obj['value'] = params[i]
              obj['type'] = 'regexOr'
              filterCriteria['criteria'].push(obj)
              console.log(obj)
            }
          }
        }
        //applyng filter when a row item is deleted 
        if (filterCriteria == undefined) {
          filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
        }
        if (this.props.filterExtension) {
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${this.props.filterExtension}`
        }
        else if (this.props.hasQueryInCall) {
          console.log('hi')
          apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
        }
        else {
          // apiUrl = this.getFilterUrl(filterCriteria, type);
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
        }
        console.log("this.state.searchValue", this.state.searchValue)
        localStorage.setItem("searchData", this.state.searchValue);
        // fetchMethodRequest('GET', apiUrl, {}, 'sql', this.state.searchValue)
        //   .then(async (response) => {
        //     let apiResponseKey = this.props.apiResponseKey;
        //     let sessionexpired = false
        //     if (sessionexpired == "true") {
        //       await this.setState({ sessionExpiryModal: true })
        //     }
        //     let responseData = [], totalRecordsLength = this.state.totalRecordsLength;
        //     if (apiResponseKey === 'scorings') {
        //       response.details = await JSON.parse(response.details)
        //     }
        //     console.log("response.details", response.details)
        //     if (response && response.details && response.details.length > 0) {
        //       responseData = response.details;
        //       totalRecordsLength = response.details.length;
        //     } else {
        //       if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
        //         if (response.pagination && response.pagination.totalCount) {
        //           totalRecordsLength = response.pagination.totalCount;
        //         }
        //         responseData = this.updateSlNoToData(response[apiResponseKey]);
        //         responseData = this.updateDateFormat(responseData, this.state.dateFormat);
        //       } else {
        //         if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
        //           totalRecordsLength = response.pagination.totalCount;
        //         }
        //       }
        //     }
        //     await this.setState({
        //       allUsersData: responseData,
        //       isLoading: false,
        //       totalRecordsLength: totalRecordsLength
        //     })
        //     return responseData;
        //   }).catch((err) => {
        //     return err;
        //   });

        ThresholdValues = {
          name: Number(this.state.singlePercent) ? Number(this.state.singlePercent) : 0,
          addressLine: Number(this.state.singleAddressPercentage) ? Number(this.state.singleAddressPercentage) : 0,
          CityStateZip: Number(this.state.singleAddPercent) ? Number(this.state.singleAddPercent) : 0
        }

        fetchMethodRequest('GET', apiUrl, {}, flag, this.state.singleSearchValue, this.state.RecevingEmail, JSON.stringify(ThresholdValues), this.state.companyName, JSON.stringify(this.state.PropertyIds), this.state.ElasticIndex, this.state.ElasticQueryComplexity)
          .then(async (response) => {
            let apiResponseKey = this.props.apiResponseKey;
            let sessionexpired = localStorage.getItem('sessionexpired')
            if (sessionexpired == "true") {
              await this.setState({ sessionExpiryModal: true })
            }
            let responseData = [], totalRecordsLength = this.state.totalRecordsLength;
            let chunkResponseData = [];
            // if (apiResponseKey === 'scorings') {
            //   response.details = await JSON.parse(response.details)
            // }
            // console.log("response.details", response.details)
            console.log("response", response, this.state.percent)
            if (response && response.details && response.details.length > 0) {

              // let EstateCount = response.esCount;
              // let dotNetArr1 = response.dotNetTime1.split(":");
              // let dotNetArr2 = response.dotNetTime2.split(":");
              // let DotNetmin = Number(dotNetArr2[1]) - Number(dotNetArr1[1]);
              // let DotNetsec = Number(dotNetArr2[2]) - Number(dotNetArr1[2]);
              // let DotNetTime = (DotNetmin * 60) + DotNetsec

              // let ESArr1 = response.esTime1.split(":");
              // let ESArr2 = response.esTime2.split(":");
              // let ESmin = Number(ESArr2[1]) - Number(ESArr1[1]);
              // let ESsec = Number(ESArr2[2]) - Number(ESArr1[2]);
              // let ElasticTime = (ESmin * 60) + ESsec

              // this.setState({
              //   DotNetTime: (DotNetTime - ElasticTime).toFixed(3),
              //   ElasticTime: ElasticTime.toFixed(3),
              //   EstateCount: EstateCount
              // })
              // console.log("this.state.DotNetTime", this.state.DotNetTime);
              responseData = response.details;
              // console.log("this.state.searchValue.toLowerCase()", this.state.searchValue.toLowerCase())
              await responseData.forEach(doc => {
                // var similarity = stringSimilarity.compareTwoStrings(doc.esname.toLowerCase(), doc.name.toLowerCase());
                // let num = (similarity * 100).toFixed(0);

                // console.log("this.state.searchValue.toLowerCase(), doc.name.toLowerCase()", this.state.searchValue.toLowerCase(), doc.name.toLowerCase())
                // var similaritytest = stringSimilarity.compareTwoStrings('circuit city estate test', 'circuit');
                // console.log("similaritytest", similarity)
                // let EstateAddressLine = `${doc.esaddress ? doc.esaddress.toLowerCase() : ''}`
                // let PropertyAddressLine = `${doc.address ? doc.address.toLowerCase() : ''}`

                // let EstateAddress = `${doc.esCity ? doc.esCity.toLowerCase() : ''}${doc.esstate ? doc.esstate.toLowerCase() : ''}`
                // let PropertyAddress = `${doc.city ? doc.city.toLowerCase() : ''}${doc.state ? doc.state.toLowerCase() : ''}`
                // let EstateZip = `${doc.esZip ? doc.esZip.toLowerCase() : ""}`
                // let PropertyZip = `${doc.zip ? doc.zip.toLowerCase() : ''}`

                // console.log("EstateAddress PropertyAddress ", EstateAddress, PropertyAddress)
                // var similarity2 = stringSimilarity.compareTwoStrings(EstateAddress, PropertyAddress);
                // var similarityZip = stringSimilarity.compareTwoStrings(EstateZip, PropertyZip);
                // var similarityAddressLine = stringSimilarity.compareTwoStrings(EstateAddressLine, PropertyAddressLine);

                // let num2 = (0.1).toFixed(0);
                // if (EstateAddress != '' && PropertyAddress != '') {
                //   num2 = (similarity2 * 100).toFixed(0);
                // }

                // let numZip = (0.1).toFixed(0);
                // if (EstateZip != '' && PropertyZip != '') {
                //   numZip = (similarityZip * 100).toFixed(0);
                // }

                // let numAddressLine = (similarityAddressLine * 100).toFixed(0);

                // if (num2 == 100 || numZip == 100) {
                //   doc.AddressScore = 100
                // } else {
                // doc.nameScore = Number(num)
                // doc.addressLineScore = Number(numAddressLine)
                // doc.addressScore = Number(num2) > Number(numZip) ? Number(num2) : Number(numZip)
                // }

              })
              // let namePercent = parseInt(this.state.percent);
              // let addressPercent = parseInt(this.state.addPercent);
              // let thresholdvalue = namePercent > addressPercent ? namePercent : addressPercent
              // responseData = responseData.filter(function (obj) {
              //   return obj.AddressScore >= thresholdvalue && obj.score >= thresholdvalue
              // })

              // await responseData.sort(function (a, b) {
              //   var x = b.AddressScore; var y = a.AddressScore;
              //   return ((x < y) ? -1 : ((x > y) ? 1 : 0));
              // });

              // let name = Number(this.state.percent);
              // let addressLine = Number(this.state.addLinePercent);
              // let CityStateZip = Number(this.state.addPercent);
              // let condition = this.state.condition;
              // let sorted = "NotDone"
              // if (name & addressLine & CityStateZip) {
              //   if (condition == "OR") {
              //     // filter data with (name || (addressLine && CityStateZip))
              //     responseData = responseData.filter(function (obj) {
              //       console.log("OBJ", obj)
              //       return ((Number(obj.nameScore) >= name) || ((Number(obj.addressLineScore) >= addressLine) && (Number(obj.addressScore) >= CityStateZip)))
              //     })
              //   } else if (condition == "AND") {
              //     // filter data with (name && addressLine && CityStateZip)
              //     responseData = responseData.filter(function (obj) {
              //       console.log("OBJ", obj)
              //       return ((Number(obj.nameScore) >= name) && ((Number(obj.addressLineScore) >= addressLine) && (Number(obj.addressScore) >= CityStateZip)))
              //     })
              //   }
              //   responseData = await this.sortData("addressLineScore", responseData)
              // } else if (name & addressLine) {
              //   if (condition == "OR") {
              //     // filter data with (name || addressLine )
              //     responseData = responseData.filter(function (obj) {
              //       console.log("OBJ", obj)
              //       return ((Number(obj.nameScore) >= name) || (Number(obj.addressLineScore) >= addressLine))
              //     })
              //   } else if (condition == "AND") {
              //     // filter data with (name && addressLine )
              //     responseData = responseData.filter(function (obj) {
              //       console.log("OBJ", obj)
              //       return ((Number(obj.nameScore) >= name) && (Number(obj.addressLineScore) >= addressLine))
              //     })
              //   }
              //   responseData = await this.sortData("addressLineScore", responseData)
              // } else if (name & CityStateZip) {
              //   if (condition == "OR") {
              //     // filter data with (name || CityStateZip )
              //     responseData = responseData.filter(function (obj) {
              //       console.log("OBJ", obj)
              //       return ((Number(obj.nameScore) >= name) || (Number(obj.addressScore) >= CityStateZip))
              //     })
              //   } else if (condition == "AND") {
              //     // filter data with (name && CityStateZip )
              //     responseData = responseData.filter(function (obj) {
              //       console.log("OBJ", obj)
              //       return ((Number(obj.nameScore) >= name) && (Number(obj.addressScore) >= CityStateZip))
              //     })
              //   }
              //   responseData = await this.sortData("addressScore", responseData)
              // } else if (addressLine & CityStateZip) {
              //   // if (condition == "OR") {
              //   // filter data with (addressLine || CityStateZip )
              //   responseData = responseData.filter(function (obj) {
              //     console.log("OBJ", obj)
              //     return ((Number(obj.addressLineScore) >= addressLine) && (Number(obj.addressScore) >= CityStateZip))
              //   })
              //   responseData = await this.sortData("addressLineScore", responseData)
              //   // } else if (condition == "AND") {
              //   //   // filter data with (addressLine && CityStateZip)
              //   // }
              // } else if (name) {
              //   // filter data with (name)
              //   responseData = responseData.filter(function (obj, responseData) {
              //     return Number(obj.nameScore) >= name
              //   })
              //   responseData = await this.sortData("nameScore", responseData)
              // } else if (addressLine) {
              //   // filter data with (name)
              //   responseData = responseData.filter(function (obj) {
              //     return Number(obj.addressLineScore) >= addressLine
              //   })
              //   responseData = await this.sortData("addressLineScore", responseData)
              // } else if (CityStateZip) {
              //   // filter data with (name)
              //   responseData = responseData.filter(function (obj) {
              //     return Number(obj.addressScore) >= CityStateZip
              //   })
              //   responseData = await this.sortData("addressScore", responseData)
              // }


              // if ((this.state.percent && this.state.addPercent) || this.state.addPercent) {
              //   if (this.state.percent && this.state.addPercent) {
              //     let namePercent = parseInt(this.state.percent);
              //     let addressPercent = parseInt(this.state.addPercent);
              //     let thresholdvalue = namePercent > addressPercent ? namePercent : addressPercent
              //     // console.log("this.state.condition", this.state.condition, namePercent, addressPercent)
              //     if (this.state.condition == 'OR') {
              //       responseData = responseData.filter(function (obj) {
              //         console.log("OBJ", obj)
              //         return (obj.AddressScore >= addressPercent) || (obj.score >= namePercent)
              //       })
              //     } else {
              //       responseData = responseData.filter(function (obj) {
              //         return (obj.AddressScore >= addressPercent) && (obj.score >= namePercent)
              //       })
              //     }
              //   } else {
              //     let num = parseInt(this.state.addPercent);
              //     responseData = responseData.filter(function (obj) {
              //       return obj.AddressScore >= num
              //     })
              //   }
              //   await responseData.sort(function (a, b) {
              //     var x = b.AddressScore; var y = a.AddressScore;
              //     // var xx = b.score; var yy = a.score;
              //     return ((x < y) ? -1 : ((x > y) ? 1 : 0));
              //   });
              // } else if (this.state.percent) {
              //   let num = parseInt(this.state.percent);
              //   responseData = responseData.filter(function (obj) {
              //     return obj.score >= num
              //   })
              //   await responseData.sort(function (a, b) {
              //     var x = b.score; var y = a.score;
              //     return ((x < y) ? -1 : ((x > y) ? 1 : 0));
              //   });
              // }



              totalRecordsLength = responseData.length;
              chunkResponseData = await this.chunkArray(responseData, this.state.rows)
              // console.log("chunkResponseData", chunkResponseData)
            } else {
              if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
                if (response.pagination && response.pagination.totalCount) {
                  totalRecordsLength = response.pagination.totalCount;
                }
                responseData = this.updateSlNoToData(response[apiResponseKey]);
                responseData = this.updateDateFormat(responseData, this.state.dateFormat);
              } else {
                if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
                  totalRecordsLength = response.pagination.totalCount;
                }
              }
            }
            console.log("responseData", responseData)
            if (type == 'exportToCsv') {
              let exportData = responseData;
              exportData.forEach((item) => {
                for (let key in item) {
                  if (item[key] && item[key].name) {
                    item[key] = item[key].name;
                  }
                }
              });
              this.setState({
                exportData: exportData,
                isLoadingsearch: false
              }, () => {
                // click the CSVLink component to trigger the CSV download
                this.csvLinkRef.link.click();
              });
            } else {
              await this.setState({
                originalResponseData: responseData,
                chunkResponseData: chunkResponseData,
                allUsersData1: chunkResponseData.length > 0 ? chunkResponseData[0] : [],
                isLoadingsearch: false,
                totalRecordsLength: totalRecordsLength
              })
            }
            return responseData;
          }).catch((err) => {
            return err;
          });
      }
    }
    // }
  }
  getAPIUrl() {
    return this.props.apiUrl;
  }
  getFilterUrl(filterCriteria, type) {
    return this.props.apiUrl;
  }

  //Get all data of current screen with filters applied from server to export to CSV
  getDataToExport = async () => {
    this.setState({ isLoading: true })
    let filterCriteria = this.state.filterCriteria;
    delete filterCriteria['limit'];
    delete filterCriteria['page'];
    let url = this.getAPIUrl();
    let apiUrl;
    apiUrl = `${this.state.apiUrl}?filter = ${JSON.stringify(filterCriteria)}&type=exportToCsv`;
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          await this.setState({ sessionExpiryModal: true })
        }
        if (response && response[url]) {
          let exportData = response[url];
          exportData.forEach((item) => {
            for (let key in item) {
              if (item[key] && item[key].name) {
                item[key] = item[key].name;
              }
            }
          });
          this.setState({
            exportData: exportData,
            isLoading: false
          }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLinkRef.link.click();
          });
        }
      }).catch((err) => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  }

  //open Form modal
  openFormModal = async (rowData, type) => {
    await this.setState({
      isOpenFormModal: true,
      formType: type ? type : 'add',
      selectedRows: ''
    });
    if (type === 'view') {
      if (this.state.isOpenFormModal && this.formModalRef) {
        await this.formModalRef.getViewData(rowData);
      }
    }
    if (type == 'edit') {
      if (this.state.isOpenFormModal && this.formModalRef) {
        await this.formModalRef.getRowData(rowData, 'edit');
      }
    }
  }

  //close form modal
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
    })
  }
  submitActionsData = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          this.setState({ sessionExpiryModal: true })
        }
        await this.setState({
          openDeleteModal: false,
          actions: '',
          selectedRows: '',
          selectedRowsId: ''
        });
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          this.getDataFromServer();
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }
  handleActions = async (apiType) => {
    console.log(apiType)
    let apiUrl = this.state.apiUrl;
    let method, url, body = {}
    if (this.state.confirmType === 'Delete' || this.state.confirmType === 'Block' || this.state.confirmType === 'UnBlock') {
      url = `${apiUrl}/${apiType}`;
      method = 'POST';
      body = {
        selectedIds: this.state.selectedRowsId
      }
    }
    this.submitActionsData(method, url, body)
  }
  // delete selected row
  deleteSelectedRow = async () => {
    if (this.state.confirmType === 'Delete') {
      this.handleActions('multiDelete')
    } else if (this.state.confirmType === 'Block') {
      this.handleActions('multiblock?block=true')
    } else if (this.state.confirmType === 'UnBlock') {
      this.handleActions('multiblock?unblock=true')
    } else if (this.state.confirmType === 'ResetPassword') {
      await this.setState({
        openUserPasswordResetModal: true,
        openDeleteModal: false,
        selectedRows: this.state.selectedRows
      })
    }
  }

  //close delete modal
  closeDeleteModal = async () => {
    this.setState({
      openDeleteModal: false,
      actions: ''
    })
  }

  // conformation for delete item
  deleteConfirmAction = async (rowData, selectActions) => {
    let selectedRowsId = [];
    console.log(selectActions)
    let selectedRows = [...this.state.selectedRows]
    console.log(selectedRows)
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id)
      })
      await this.setState({
        selectedRowsId: selectedRowsId,
        openDeleteModal: true,
        selectActions: selectActions,
      })
    }
    if (rowData) {
      this.setState({
        deleteRowDataId: rowData,
        openDeleteModal: true,
        selectActions: selectActions,
      });
    }
  }

  //change dropdown elememt
  changeDropDownElement = (event) => {
    this.dt.filter(event.target.value, event.target.name, 'equals');
    this.setState({
      [event.target.name]: event.value
    });
  }


  getTableFieldItem(field) {
    for (let i = 0; i < this.state.tablefieldsToShow.length; i++) {
      if (this.state.tablefieldsToShow[i].field == field) {
        return this.state.tablefieldsToShow[i];
      }
      //condition to match fieldType
      if (this.state.tablefieldsToShow[i].fieldType == field) {
        return this.state.tablefieldsToShow[i]['field'];
      }
    }
    return null;
  }

  getTableFieldItem1(field) {
    for (let i = 0; i < this.state.tablefieldsToShow1.length; i++) {
      if (this.state.tablefieldsToShow1[i].field == field) {
        return this.state.tablefieldsToShow1[i];
      }
      //condition to match fieldType
      if (this.state.tablefieldsToShow1[i].fieldType == field) {
        return this.state.tablefieldsToShow1[i]['field'];
      }
    }
    return null;
  }

  getColorFromOptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value == name) {
          return options[i].color;
        }
      }
    }
    return 'success';
  }
  getUserData(_id, type) {
    let data = this.state.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }
  getIconValue(rowData, labelKey) {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  openViewModal = async (rowData, type) => {
    let rowDataIndex = this.getUserData(rowData['_id'], '_id');
    let _id = rowData['_id'];
    let status = rowData['status'] ? rowData['status'] : ''
    let keys = Object.keys(rowData);

    let formFields = [];
    if (formFields) {
      if (this.props.type) {
        if (rowData) {
          let values, fieldType, searchField, self = this, icon = false;

          // hari get all the labels from 
          keys.forEach(async function (key) {
            let labelKey = await self.getTableFieldItem(key);
            if (labelKey == null) {
              labelKey = key;
            } else {
              let val = rowData[key];
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              fieldType = labelKey.fieldType ? labelKey.fieldType : null
              searchField = labelKey.searchField ? labelKey.searchField : null
              labelKey = labelKey.header
              if (val) {
                if (fieldType && searchField && fieldType == "relateAutoComplete") {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key][searchField]
                  }
                } else {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key]
                  }
                }
                formFields.push(values);
                icon = false;
              }
            }
            // if (key == 'fromTime' || key == 'toTime') {
            //   let date = dateFormats.addDaysToDate(rowData[key], 1);
            //   date = dateFormats.formatDate(date, config.timeFormat);
            //   values = {
            //     label: key,
            //     value: date
            //   }
            //   formFields.push(values);
            // }
          });
        }
      }
      if (this.state.displayViewOfForm === 'modal') {
        await this.setState({
          // openViewModal: true
          isOpenFormModal: true,
          userStatus: status,
          formType: type,
        });
        // await this.viewModalRef.getRowData(formFields);
        await this.formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
      } else if (this.state.displayViewOfForm === 'screen') {
        let displayBreadCrumbField = this.getTableFieldItem('Link');
        await this.setState({
          isOpenFormModal: true,
          formType: type,
          displayBreadCrumbValue: rowData[displayBreadCrumbField],
          displayBreadCrumbField: displayBreadCrumbField,
          userStatus: status
        });
        await this.formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
      }
    }
    await this.getDataFromServer();

  }

  closeViewModal = async () => {
    this.setState({
      openViewModal: false
    });
  }
  //on changing pagination
  onPageChange = async (event) => {
    // console.log("event?????????????????????????????????????????????", event)
    let filterCriteria = this.state.filterCriteria;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
      if ((this.state.rows !== event.rows) && this.state.originalResponseData.length > 0) {
        let chunkResponseData = await this.chunkArray(this.state.originalResponseData, event.rows);
        this.setState({
          chunkResponseData: chunkResponseData,
          allUsersData1: chunkResponseData.length > 0 ? chunkResponseData[0] : [],
        })
      }
      this.setState({
        rows: event.rows,
        page: event.page,
        first: event.first
      })
    }
    if (this.state.originalResponseData.length > 0) {
      this.setState({
        allUsersData1: this.state.chunkResponseData[event.page],
      })
    }
    // this.getDataFromServer(filterCriteria)
  }

  chunkArray = async (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = myArray.slice(index, index + chunk_size);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }
    return tempArray;
  }

  onPageChangeAccordion = async (event) => {
    let filterCriteria = this.state.filterCriteria;
    let id = this.state.openId;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
      this.setState({
        [`rows${id}`]: event.rows,
        page: event.page,
        [`first${id}`]: event.first
      })
    }
    // this.getDataFromServer(filterCriteria)
  }
  //sorting fields
  sortChange = async (event) => {
    this.setState({ selectedRows: '' })
    let sortCount = this.state.sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount == 0 ? sortCount + 1 : 0;
      let sortField = event['sortField'];
      let filterCriteria = {
        direction: sortCount == 0 ? "desc" : 'asc',
        sortfield: sortField,
      }
      this.setState({
        sortCount: sortCount,
        isLoadingsearch: true
      });
      if (this.state.rows && this.state.originalResponseData.length > 0) {
        let originalResponseData = this.state.originalResponseData;
        originalResponseData = await originalResponseData.sort(function (a, b) {
          var x = b[sortField]; var y = a[sortField];
          if (sortCount == 0) {
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
          } else {
            return ((x > y) ? -1 : ((x < y) ? 1 : 0));
          }
        });
        let chunkResponseData = await this.chunkArray(originalResponseData, this.state.rows);

        this.setState({
          chunkResponseData: chunkResponseData,
          allUsersData1: chunkResponseData.length > 0 ? chunkResponseData[0] : [],
        })
      }
      this.setState({
        isLoadingsearch: false
      })
      // this.getDataFromServer(filterCriteria);
    }
  }
  confirmActionType = async (type) => {
    console.log(type)
    if (type === 'Delete') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Delete',
      })
      this.deleteConfirmAction()
    } else if (type === 'Block') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Block',
      })
      this.deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Reset Password',
        openUserPasswordResetModal: true,
        openDeleteModal: false,
        selectedRows: this.state.selectedRows
      })
      // this.deleteConfirmAction()
    }
  }

  //onActionsChange
  onActionsChange = async (event, type) => {
    console.log(event)
    if (type == 'dropdownFilter') {
      await this.setState({
        [event.target.name]: event.target.value,
      })
      console.log(this.state.actionsTypes)
      for (let i in this.state.actionsTypes) {
        if (this.state.actionsTypes[i].value === event.target.value) {
          if (this.state.actionsTypes[i].action) {
            this.state.actionsTypes[i].action(this.state.selectedRows)
          }
        }
      }
      this.confirmActionType(event.target.value)
    }
  }

  onMultiSelectFilterChange = async (key, value, type, index) => {
    let filterCriteria = this.state.filterCriteria;
    if (filterCriteria && type === 'push') {
      let v = [], length = '';
      if (filterCriteria['criteria'] && filterCriteria['criteria'][index] && filterCriteria['criteria'][index]["value"]) {
        v = [...filterCriteria['criteria'][index]["value"]];
        length = v.length;
        v[length] = value;
        filterCriteria['criteria'][index]["value"] = v;
      } else {
        v[0] = value
        filterCriteria['criteria'].push({
          key: key,
          value: v,
          type: 'in'
        });
      }

      await this.setState({
        filterCriteria: filterCriteria
      })
      await this.getDataFromServer(this.state.filterCriteria)
    } else {
      this.getDataFromServer(this.state.filterCriteria)
    }
  }
  // on search get data from server
  onFilterChange = async (event, type) => {
    this.setState({
      isLoading: true,
      selectedRows: ''
    })
    console.log(type)
    if (type === 'dropdownFilter') {
      await this.setState({
        [event.target.name]: event.target.value
      })
    }
    let fieldName = '', filterCriteria = this.state.filterCriteria,
      selectedFilterValue, selectedFilter, selecterFilterType, formattedTime, formattedDate, isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        if (fieldName == 'date' || fieldName == 'created' || fieldName == 'updated') {
          isDateFilter = true;
          selectedFilterValue = selectedFilter && selectedFilter.value && selectedFilter.value.length == 10 ?
            selectedFilter.value : null;
          let date = dateFormats.addDaysToDate(selectedFilter.value, 1);
          formattedDate = dateFormats.formatDate(date, config.dayYearDateFormat);
          selecterFilterType = 'eq'
        } else {
          selecterFilterType = 'regexOr'
          selectedFilterValue = selectedFilter && selectedFilter.value ? selectedFilter.value : null;
        }
      } else {
        fieldName = event.target.name;
        selectedFilterValue = event && event.target && event.target.value ? event.target.value : null;
        this.setState({ globalSearch: selectedFilterValue })
      }
      if (type == 'dropdownFilter') {
        selecterFilterType = 'eq'
      }
      if ((selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length >= 3)) {
        if (fieldName == 'createdBy') {
          fieldName = 'createdBy.name';
        }
        if (fieldName == 'phone') {
          fieldName = 'phone';
        }
        if (fieldName == 'globalSearch') {
          filterCriteria.globalSearch = {
            value: selectedFilterValue,
            type: 'user'
          }
          console.log(event.target.value.length)
          if (event.target.value.length == 0) {
            console.log('delete')
            delete filterCriteria.globalSearch;
          }
          // this.setState({ globalSearch: selectedFilterValue })
        } else {
          if (selecterFilterType == 'gte') {
            let obj = filterCriteria.criteria.find(x => x.key == fieldName);
            let index = filterCriteria.criteria.indexOf(obj);
            if (index != -1) {
              filterCriteria['criteria'].splice(index, 1, {
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            } else {
              filterCriteria['criteria'].push({
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            }
          } else {
            if (fieldName == 'updated') {
              fieldName = 'updated.name'
            }
            if (filterCriteria['criteria'].length == 0 && selecterFilterType != 'lte') {
              filterCriteria['criteria'].push({
                key: fieldName,
                value: selectedFilterValue,
                type: selecterFilterType
              });
            } else {
              let obj = filterCriteria.criteria.find(x => x.key == fieldName);
              let index = filterCriteria.criteria.indexOf(obj);
              if (selecterFilterType == 'lte') {
                if (selectedFilterValue.length == 10) {
                  filterCriteria['criteria'].splice(0, 1, ({
                    key: fieldName,
                    value: selectedFilterValue.substring(6, 10) + '-' + selectedFilterValue.substring(3, 5) + '-' + selectedFilterValue.substring(0, 2) + 'T23:59:59Z',
                    type: selecterFilterType
                  }));
                }
              }
              if (index != -1 && selecterFilterType != 'lte') {
                filterCriteria['criteria'].splice(index, 1, {
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              } else if (selecterFilterType != 'lte') {
                filterCriteria['criteria'].push({
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              }
            }
          }
          await this.setState({
            filterCriteria: filterCriteria
          });
        }

        await this.getDataFromServer(this.state.filterCriteria)
      } else {
        if (selectedFilterValue == null && !isDateFilter) {
          let obj = filterCriteria.criteria.find(x => x.key == fieldName);
          let index = filterCriteria.criteria.indexOf(obj);
          filterCriteria.criteria.splice(index, 1);
          if (fieldName == 'globalSearch') {
            filterCriteria.globalSearch = {}
            delete filterCriteria.globalSearch
          }
          await this.setState({
            filterCriteria: filterCriteria
          });
          await this.getDataFromServer(filterCriteria)
        }
      }
      this.setState({
        isLoading: false
      })
    }
  }

  //open Bulk Modal
  bulkUploadMoadal = () => {
    this.setState({ openBulkUploadModal: true })
  }

  //close Bulk Modal
  closeBulkModal = async () => {
    // this.setState({
    //   openBulkUploadModal: false
    // }, () => {
    //   this.bulkUploadMoadalRef.handleClickCount();
    // })
    this.setState({
      openBulkUploadModal: false
    })
  }

  //select multiple rows to delete
  onSelectRowsUpdate = async (event) => {
    await this.setState({ selectedRows: event.value })
    await this.getActions()
  }

  //openConfirmationModal
  openConfirmationModal = async (rowData, status, type) => {
    this.setState({
      isOpenFormModal: false
    }, async () => {
      this.setState({
        isOpenFormModal: false,
        openConfirmationModal: true,
        leaveStatus: status,
        item: rowData,
        confirmText: type
      })
    })
  }

  closeConfirmationModal() {
    this.setState({
      openConfirmationModal: false
    })
  }
  setFilterCriteriaForActivities = async (editRowDataID) => {
    let filterCriteria = this.state.filterCriteria;
    filterCriteria['criteria'].push(
      { key: 'contextId', value: editRowDataID, type: 'eq' }
    )
    await this.setState({
      filterCriteria: filterCriteria
    })
  }
  getTooltipFromOtptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].tooltip;
        }
      }
    }
    return '';
  }
  //change table body values//
  // hari need to move to derived class or controller
  changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = self.getTableFieldItem(column.field);
    if (!tableItem) {
      tableItem = self.getTableFieldItem1(column.field);
    }
    if (tableItem) {
      if (tableItem.fieldType == "Link") {
        return <div className='textElipses'>
          <span
            style={{ whiteSpace: 'break-spaces' }}
            onClick={() => this.openViewModal(item, 'view')}>
            {item[column.field]}</span>
        </div >
      } else if (tableItem.fieldType == "wrap") {
        return <div className='textElipses'>
          <span
            style={{ whiteSpace: 'break-spaces' }}
            onClick={() => { }}
          >
            {item[column.field]}</span>
        </div >
      } else if (tableItem.fieldType == "Badge") {
        let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
        return <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>
      } else if (tableItem.fieldType == "Role") {
        let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
        return <Badge color={mcolor} style={tableItem.style}>{item[column.field]}</Badge>
      } else if (tableItem.fieldType === "icon") {
        let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
        let tooltip = self.getTooltipFromOtptions(tableItem.options, item[column.field]);
        return <FontAwesomeIcon
          style={{ color: mcolor, cursor: tooltip ? 'pointer' : '' }}
          color='white'
          icon={tableItem.iconName}
          data-toggle="tool-tip"
          title={tooltip}
        />
      } else if (tableItem.fieldType === "Array") {
        let val = this.flattenArray(item[column.field]);
        return <span style={tableItem.style} title={val}>
          {val}
        </span>
      } else if (tableItem.fieldType === "Complex") {
        let data = this.ObjectbyString(item, column.field)
        return <span>
          {data}
        </span>
      } else if (tableItem.fieldType == "Actions") {
        return (
          <div className='row'
            style={{ justifyContent: 'center' }}>
            <div>
              {(this.props.preview) ? <FontAwesomeIcon
                className='genderIconAlignment'
                color='#17a2b8'
                icon='file'
                data-toggle="tool-tip"
                title='Preview'
                style={{ color: '#17a2b8', width: '15', cursor: 'pointer', marginRight: 9 }}
                onClick={() => this.openPreviewModal(item)} /> : ''}

            </div>
            {/* } */}
          </div >
        )
      } else if (tableItem.fieldType == "relateAutoComplete") {
        // return tableItem.searchField ? item[column.field][tableItem.searchField] : null;
        return tableItem.searchField && item[column.field] && item[column.field][tableItem.searchField] ? item[column.field][tableItem.searchField] : null;

      } else if (tableItem.fieldType == 'score') {
        var similarity = stringSimilarity.compareTwoStrings(this.state.searchValue.toLowerCase(), item['name'].toLowerCase());
        console.log("item[column.field];", similarity);
        return similarity * 100;
      } else if (tableItem.fieldType == 'Number') {
        return <span>
          {/* {item[column.field] ? item[column.field] : '0'} */}
          {item[column.field] ? ((item[column.field] == 'NaN') ? '0' : (item[column.field] * 1).toFixed(0)) : '0'}

        </span>
      } else {
        // if (item[column.field] === 0) {
        //   return item[column.field];
        // }
        if ((item[column.field]) && typeof item[column.field] !== 'object') {
          return item[column.field];
        }
      }
    }
  }
  clickConfirm() {
    this.closeConfirmationModal();
  }
  ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  rowClassName(item) {
    if (item.status === 'Blocked') {
      return (
        { 'p-highlightRow': (item.status === 'Blocked') }
      )
    }
  }
  openPreviewModal = async (item) => {
    this.setState({
      isPreviewModal: true,
      emailTemplateData: item ? item : item
    })
  }
  closePreviewModal = () => {
    this.setState({
      isPreviewModal: false,
      emailTemplateData: ''
    })
  }
  handleDateInterval(startDate, endDate) {
    if (startDate) {
      this.setState({
        startDate: startDate
      })
    }
    if (endDate) {
      this.setState({
        endDate: endDate
      })
    }
  }
  // ShowHideColumns Button Click Handler
  //Settings Modal open
  openShowHideColumnsModal = () => {
    this.setState({
      isOpenShowHideColumnsModal: true,
    })
  }
  getSettings = async () => {
    // this.props.getSettings();
  }

  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal
  closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
    if (type && type === 'confirm' && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];

      this.setState({
        isOpenShowHideColumnsModal: false,
      })
      if (this.state.viewType === 'grid') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.mobile = value;
            }
          });
        })
      }
      if (this.state.viewType === 'list') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.show = value;
            }
          });
        })
      }
      fields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile
          }
        )
      });

      localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
      await this.getTableFieldsOrder(this.state.originalTableFields);
    } else if (type && type === 'confirm') {
      let fields = [...changedTableFields];
      this.setState({
        isOpenShowHideColumnsModal: false,
      })
      localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(fields));

      await this.getTableFieldsOrder(this.state.originalTableFields);
    } else {
      this.setState({
        isOpenShowHideColumnsModal: false,
      })
    }
  }
  searchInDateRange() {
    if (this.state.startDate && this.state.endDate) {
      let filterCriteria = {};
      let startDate = (moment(this.state.startDate).format(config.dateDayMonthFormat));
      let endDate = (moment(this.state.endDate).format(config.dateDayMonthFormat));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        {
          'key': 'date',
          'value': startDate,
          'type': 'gte'
        },
        {
          'key': 'date',
          'value': endDate,
          'type': 'eq'
        }];
      this.setState({ filterCriteria: filterCriteria });
      this.getDataFromServer(filterCriteria);
    } else {
      alert('no dates selected');
    }
  }
  toggle = async (id) => {
    let openId = this.state.openId;
    this.setState({ openId: openId == id ? '' : id });
  }

  print = () => {
    window.print();
  }
  toogleMultiSelect = async () => {
    await this.setState({
      showMultiSelectDropDown: !this.state.showMultiSelectDropDown
    })
  }
  onGlobalSearchChange = async (e) => {
    let suggestions = this.state.suggestions;
    // suggestions[0] = { "label": `Search By First Name ${e.query}` }
    await this.setState({ suggestions: suggestions })

  }



  onSearchChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      searchValue: e.target.value.label ? e.target.value.label : e.target.value,
    })
    this.setAutocompleteSuggestions(e.target.value)

  }

  onSingleGlobalSearchChange = async (e) => {
    let singleSuggestions = this.state.singleSuggestions;
    // suggestions[0] = { "label": `Search By First Name ${e.query}` }
    await this.setState({ singleSuggestions: singleSuggestions })

  }

  onSingleSearchChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      singleSearchValue: e.target.value.label ? e.target.value.label : e.target.value,
    })
    this.setAutocompleteSuggestions(e.target.value)

  }



  onCompanySearchChange = async (e) => {
    let companySuggestions = this.state.companySuggestions;
    // suggestions[0] = { "label": `Search By First Name ${e.query}` }
    await this.setState({ companySuggestions: companySuggestions })

  }

  onCompanyChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      companyName: e.target.value.label ? e.target.value.label : e.target.value,
    })
    this.setSingleAutocompleteSuggestions(this.state.singleSearchValue)

  }

  onEmailChange = async (e) => {
    console.log("event.target.value", e.target.value)
    let re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
      this.setState({
        enableSubmit: "yes",
        emailValidate: "no",
        RecevingEmail: e.target.value
      })
    } else if (e.target.value == "") {
      this.setState({
        enableSubmit: "no",
        emailValidate: "no",
        RecevingEmail: e.target.value
      })
    } else {
      this.setState({
        enableSubmit: "no",
        emailValidate: "yes",
        RecevingEmail: e.target.value
      })
    }


  }

  onPercentChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      percent: e.target.value
    })
  }

  onSinglePercentChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      singlePercent: e.target.value
    })
  }

  onaddPercentChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      addPercent: e.target.value
    })
  }

  onSingleAddPercentChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      singleAddPercent: e.target.value
    })
  }

  onElasticMaxLimitChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      ElasticMaxLimit: e.target.value
    })
  }

  onOppLimitChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      oppLimit: e.target.value
    })
  }

  onPropertyIdsChange = async (e) => {
    console.log("onPropertyIdsChange", e.target.value)
    const str = e.target.value;
    var result = str.split(/\r?\n/);
    console.log("result", result)
    this.setState({
      propertyIdsValue: e.target.value,
      PropertyIds: result
    })
  }

  onBatchNameChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      batchName: e.target.value
    })
  }

  onAddressLineChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      addLinePercent: e.target.value
    })
  }

  onSingleAddressLineChange = async (e) => {
    console.log("event.target.value", e.target.value)
    this.setState({
      singleAddressPercentage: e.target.value
    })
  }

  handleCondition = async (e) => {
    // console.log("CONDITION", e)
    this.setState({
      condition: e
    })
  }

  handleDatabase = async (e) => {
    // console.log("CONDITION", e)
    this.setState({
      database: e
    })
  }

  onElasticQueryComplexityChange = async (e) => {
    // console.log("CONDITION", e)
    this.setState({
      ElasticQueryComplexity: e
    })
  }

  handleSingleDatabase = async (e) => {
    // console.log("CONDITION", e)
    this.setState({
      singleDatabase: e
    })
  }

  onElasticIndexChange = async (e) => {
    console.log("eeeee", e.target.value)
    this.setState({
      ElasticIndex: e.target.value
    })
  }

  handleFocus = (event) => event.target.select();

  getScreenHeader() {
    const { t } = this.props;
    return (
      // <div className="row">
      //   {/* global Search */}
      //   <div className='col-6'>
      //   </div>
      //   <div className='col-6'>
      //     <div className='serachAlignment float-right' >
      //       <div className="p-inputgroup">
      //         <span className="p-inputgroup-addon">
      //           <i className="pi pi-search" ></i>
      //         </span>
      //         <InputText
      //           type="search"
      //           name='globalSearch'
      //           onChange={(e) => this.onFilterChange(e)}
      //           placeholder={this.props.globalSearch ? this.props.globalSearch : 'search'}
      //           size="30" />
      //       </div>
      //     </div>
      //   </div>
      // </div >

      <div>
        <div className='row'>
          {/* global Search */}
          <div className='col-sm-12'>
            <h4>
              <span className='postionRelative pt-2'>
                <b
                ><Link to={(this.props.routeTo)} onClick={this.closeFormModal}>
                    {t(this.props.type == 'Scorings' ? 'Main' : this.props.type)}
                  </Link>{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
                </b>
              </span>
            </h4>
          </div>
          {/* className='col-sm-12' */}




        </div>
        <div className='row mr-0 ml-0 p-1' style={{ textAlign: "center", alignSelf: 'center' }}>
          <div className="col-sm-6" style={{ textAlign: "center" }}>
            <Dropdown
              style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
              className='mr-3'
              // appendTo={document.body}
              name='ElasticIndex'
              value={this.state.ElasticIndex}
              // disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
              options={Elastic_Indexes.array}
              placeholder={t('Please select Elastic Index')}
              onChange={(e) => this.onElasticIndexChange(e)}
            />
          </div>
          <div className="col-sm-6" style={{ textAlign: "center" }}>
            <div className='row mr-0 ml-0 p-1'>
              <span className='pl-4 m-1' style={{ verticalAlign: 'middle' }}>
                < input
                  name='SIMPLE'
                  type='radio'
                  value={this.state.ElasticQueryComplexity}
                  checked={this.state.ElasticQueryComplexity == 'SIMPLE' ? true : false}
                  onChange={() => this.onElasticQueryComplexityChange('SIMPLE')}
                />
              </span>
              <span className='pr-1 mr-1'>
                Simple
              </span>
              <span className='pl-4 m-1' style={{ verticalAlign: 'middle' }}>
                < input
                  name='COMPLEX'
                  type='radio'
                  value={this.state.ElasticQueryComplexity}
                  checked={this.state.ElasticQueryComplexity == 'COMPLEX' ? true : false}
                  onChange={() => this.onElasticQueryComplexityChange('COMPLEX')}
                />
              </span>
              <span className='pr-1 mr-1'>
                Complex
              </span>
            </div>

          </div>
        </div>
        <div className='row mr-0 ml-0 p-1'>
          <div className="col-sm-6" style={{ textAlign: "center" }}>
            <div className='pl-3' style={{ textAlign: "left" }}><h3><b>Full Estate Test</b></h3></div>
            {/* Test Opp */}
            <div className='row mr-0 ml-0 p-1'>
              {/* <span className='pl-4 m-1' style={{ verticalAlign: 'middle' }}>
                < input
                  name='TEST'
                  type='radio'
                  value={this.state.database}
                  checked={this.state.database == 'TEST' ? true : false}
                  onChange={() => this.handleDatabase('TEST')}
                />
              </span>
              <span className='pr-1 mr-1'>
                Test Data
              </span> */}
              <span className='pl-4 m-1' style={{ verticalAlign: 'middle' }}>
                < input
                  name='OPP'
                  type='radio'
                  value={this.state.database}
                  checked={this.state.database == 'OPP' ? true : false}
                  onChange={() => this.handleDatabase('OPP')}
                />
              </span>
              <span className='pr-1 mr-1'>
                OPP Data
              </span>
            </div>
            <div className='row mr-0 ml-0 p-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Estate Name'}
              </div>
              <div className='col-sm-6' style={{ textAlign: "left" }}>
                <AutoComplete
                  // onSelect={(e) => console.log(e)}
                  // multiple
                  value={this.state['searchValue']}
                  suggestions={this.state.suggestions}
                  completeMethod={(e) => this.onGlobalSearchChange(e)}
                  field={'label'}
                  placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                  onChange={(e) => this.onSearchChange(e)}
                  onFocus={this.handleFocus}
                  size="30"
                // onChange={(e) => this.setState({ selectedAutoCompleteValue: e.value })}
                // onChange={(e) => console.log(e)}
                />
                {/* <InputText
                  type="search"
                  name='globalSearch'
                  id='globalSearch'
                  value={this.state['searchValue']}
                  onFocus={this.handleFocus}
                  onChange={(e) => this.onSearchChange(e)}
                  placeholder={this.props.globalSearch ? t(this.props.globalSearch) : t('Search')}
                  size="30" /> */}
              </div>
            </div>
            <div className='row mr-0 ml-0 p-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Name Score Threshold '}
              </div>
              <div className='col-sm-6' style={{ textAlign: "left" }}>
                <InputText
                  type="search"
                  name='percent'
                  id='percent'
                  value={this.state['percent']}
                  onChange={(e) => this.onPercentChange(e)}
                  placeholder={'Percentage'}
                  size="10" />
              </div>
            </div>
            <div className='row mr-0 ml-0 pt-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Address Line 1 Match Threshold '}
              </div>
              <div className='col-sm-6' style={{ textAlign: "left" }}>
                <InputText
                  type="search"
                  name='addLinePercent'
                  id='addLinePercent'
                  value={this.state['addLinePercent']}
                  onChange={(e) => this.onAddressLineChange(e)}
                  placeholder={'Percentage'}
                  size="10" />
              </div>
            </div>
            <div className='row mr-0 ml-0 pt-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'City & State OR Zip Match Threshold '}
              </div>
              <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-4' style={{ textAlign: "left" }}>
                  <div className='row'>
                    <div className='col-sm-2' style={{ textAlign: "left", placeSelf: "center" }}>
                      <InputText
                        type="search"
                        name='addPercent'
                        id='addPercent'
                        value={this.state['addPercent']}
                        onChange={(e) => this.onaddPercentChange(e)}
                        placeholder={'Percentage'}
                        size="10" />
                    </div>

                  </div>
                </div>
                {/* <div className='col-sm-8' style={{ textAlign: "left", placeSelf: "center" }}>
                  <div className='row ' style={{ width: 'max-content' }}>
                    {"Name + (Address Line 1 & City/State/Zip)"}
                  </div>
                  <div className='row '>
                    <span className='pl-4 m-1' style={{ verticalAlign: 'middle' }}>
                      <input
                        name='OR'
                        type='radio'
                        value={this.state.condition}
                        checked={this.state.condition == 'OR' ? true : false}
                        onChange={() => this.handleCondition('OR')}
                      />
                    </span>
                    <span className='pr-1 mr-1'>
                      OR
                    </span>
                    <span className='pl-4 m-1' style={{ verticalAlign: 'middle' }}>
                      < input
                        name='AND'
                        type='radio'
                        value={this.state.condition}
                        checked={this.state.condition == 'AND' ? true : false}
                        onChange={() => this.handleCondition('AND')}
                      />
                    </span>
                    <span className='pr-1 mr-1'>
                      AND
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className='row mr-0 ml-0 pt-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Email(To send Reports job)'}
              </div>
              <div className='col-sm-6' style={{ textAlign: "left" }}>
                <InputText
                  type="email"
                  name='email'
                  id='email'
                  value={this.state['RecevingEmail']}
                  onFocus={this.handleFocus}
                  onChange={(e) => this.onEmailChange(e)}
                  placeholder={"Email"}
                  // validate={[validate]}
                  validateOnly={true}
                  keyfilter={/^(?=.{1, 50}$)[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2, 4})$/}
                  validatorMessage="Please enter valid Email"
                  size="30" />
                <br />
                {this.state.emailValidate == "no" ? null : <small id="username2-help" className="p-error block">Please enter valid Email</small>}

              </div>
              {/* {this.state.emailValidate ? null : <span>Please enter valid Email</span>} */}
            </div>
            <div className='row mr-0 ml-0 pt-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Batch Name'}
              </div>
              <div className='col-sm-6' style={{ textAlign: "left" }}>
                <InputText
                  type="search"
                  name='batchName'
                  id='batchName'
                  value={this.state['batchName']}
                  onChange={(e) => this.onBatchNameChange(e)}
                  placeholder={'Enter Batch Name'}
                  size="30" />
              </div>
            </div>
            {/* <div className='row mr-0 ml-0 pt-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Elastic Max Results Limit '}
              </div>
              <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-4' style={{ textAlign: "left" }}>
                  <div className='row'>
                    <div className='col-sm-2' style={{ textAlign: "left", placeSelf: "center" }}>
                      <InputText
                        type="search"
                        name='oppLimit'
                        id='oppLimit'
                        value={this.state['oppLimit']}
                        onChange={(e) => this.onOppLimitChange(e)}
                        placeholder={'Max Limit'}
                        size="10" />
                    </div>

                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-sm-6" style={{ textAlign: "center" }}>
            <div className='pl-3' style={{ textAlign: "left" }}><h3><b>Single Name Test</b></h3></div>
            <div className='row mr-0 ml-0 p-1'>
              <span className='pl-4 m-1' style={{ verticalAlign: 'middle' }}>
                < input
                  name='singleOPP'
                  type='radio'
                  value={this.state.singleDatabase}
                  checked={this.state.singleDatabase == 'OPP' ? true : false}
                  onChange={() => this.handleDatabase('OPP')}
                />
              </span>
              <span className='pr-1 mr-1'>
                OPP Data
              </span>
            </div>
            <div className='row mr-0 ml-0 p-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Estate Name'}
              </div>
              <div className='col-sm-6' style={{ textAlign: "left" }}>
                <AutoComplete
                  value={this.state['singleSearchValue']}
                  suggestions={this.state.singleSuggestions}
                  completeMethod={(e) => this.onSingleGlobalSearchChange(e)}
                  field={'label'}
                  placeholder={'Estate Name Search'}
                  onChange={(e) => this.onSingleSearchChange(e)}
                  onFocus={this.handleFocus}
                  size="30"
                />
              </div>
            </div>
            <div className='row mr-0 ml-0 p-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Company Name'}
              </div>
              <div className='col-sm-6' style={{ textAlign: "left" }}>
                <AutoComplete
                  value={this.state['companyName']}
                  suggestions={this.state.companySuggestions}
                  completeMethod={(e) => this.onCompanySearchChange(e)}
                  field={'label'}
                  placeholder={'Company Name Search'}
                  onChange={(e) => this.onCompanyChange(e)}
                  onFocus={this.handleFocus}
                  size="30"
                />
              </div>
            </div>
            <div className='row mr-0 ml-0 p-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Name Score Threshold '}
              </div>
              <div className='col-sm-6' style={{ textAlign: "left" }}>
                <InputText
                  type="search"
                  name='singlePercent'
                  id='singlePercent'
                  value={this.state['singlePercent']}
                  onChange={(e) => this.onSinglePercentChange(e)}
                  placeholder={'Percentage'}
                  size="10" />
              </div>
            </div>
            <div className='row mr-0 ml-0 pt-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Address Line 1 Match Threshold '}
              </div>
              <div className='col-sm-6' style={{ textAlign: "left" }}>
                <InputText
                  type="search"
                  name='singleAddressPercentage'
                  id='singleAddressPercentage'
                  value={this.state['singleAddressPercentage']}
                  onChange={(e) => this.onSingleAddressLineChange(e)}
                  placeholder={'Percentage'}
                  size="10" />
              </div>
            </div>
            <div className='row mr-0 ml-0 pt-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'City & State OR Zip Match Threshold '}
              </div>
              <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-4' style={{ textAlign: "left" }}>
                  <div className='row'>
                    <div className='col-sm-2' style={{ textAlign: "left", placeSelf: "center" }}>
                      <InputText
                        type="search"
                        name='singleAddPercent'
                        id='singleAddPercent'
                        value={this.state['singleAddPercent']}
                        onChange={(e) => this.onSingleAddPercentChange(e)}
                        placeholder={'Percentage'}
                        size="10" />
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {/* <div className='row mr-0 ml-0 pt-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'Elastic Max Results Limit '}
              </div>
              <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-4' style={{ textAlign: "left" }}>
                  <div className='row'>
                    <div className='col-sm-2' style={{ textAlign: "left", placeSelf: "center" }}>
                      <InputText
                        type="search"
                        name='ElasticMaxLimit'
                        id='ElasticMaxLimit'
                        value={this.state['ElasticMaxLimit']}
                        onChange={(e) => this.onElasticMaxLimitChange(e)}
                        placeholder={'Max Limit'}
                        size="10" />
                    </div>

                  </div>
                </div>
              </div>
            </div> */}
            <div className='row mr-0 ml-0 pt-1'>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                {'OPRA Property Id '}
                {`(Copy and paste in the box;
                   One Id per line)`}
              </div>
              <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-4' style={{ textAlign: "left" }}>
                  <div className='row'>
                    <div className='col-sm-2' style={{ textAlign: "left", placeSelf: "center" }}>
                      <textarea
                        type="search"
                        name='propertyIdsValue'
                        id='propertyIdsValue'
                        value={this.state['propertyIdsValue']}
                        onChange={(e) => this.onPropertyIdsChange(e)}
                        placeholder={'Property Ids'}
                        // size="10"
                        height="20"
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {/* elastic and .net server times */}
            {/* <div className='col-sm-12' style={{ textAlign: "left", verticalAlign: 'middle' }}>
              <div className='row mr-0 ml-0 p-1' style={{ verticalAlign: 'middle' }}>
                <span className='col-sm-12'>
                  Elastic Server Time: {this.state.ElasticTime ? this.state.ElasticTime : '_ _'} sec
                </span>
              </div>
              <div className='row mr-0 ml-0 p-1' style={{ verticalAlign: 'middle' }}>
                <span className='col-sm-12'>
                  .Net Server Time: {this.state.DotNetTime ? this.state.DotNetTime : '_ _'} sec
                </span>
              </div>
              <div className='row mr-0 ml-0 p-1' style={{ verticalAlign: 'middle' }}>
                <span className='col-sm-12'>
                  Estate Name Results Count (max capped at 20): {this.state.EstateCount}
                </span>
              </div>
              <div className='row mr-0 ml-0 p-1' style={{ verticalAlign: 'middle' }}>
                <span className='col-sm-12'>
                  Elastic Search Count: {this.state.totalRecordsLength}
                </span>
              </div>
            </div> */}
            {/* <div className='row mr-0 ml-0 p-1' style={{ verticalAlign: 'middle' }}>

              <div className='col-sm-12 px-4' style={{
                textAlign: "right"
              }}>
                <h4>Estate Name Results Count (max capped at 20): {this.state.EstateCount}</h4>
                <h4>Elastic Search Count: {this.state.totalRecordsLength}</h4>
              </div>
            </div> */}
          </div>
          {/* Go */}
          <div className='col-sm-12' style={{ textAlign: "center", alignSelf: 'center' }}>
            <div className='row mr-0 ml-0 p-1'>

              {/* <div className='col-sm-6' style={{ textAlign: "right", alignSelf: 'center' }}> */}
              {/* <div className='row mr-0 ml-0 p-1'> */}
              <div className='col-sm-6' style={{ textAlign: "left", alignSelf: 'center' }}>
                {this.state.searchValue &&
                  <Button color="primary"
                    className='p-1 m-2'
                    size={'sm'} //onClick={this.getSearchDataFromServer(this.state.filterCriteria, 'exportToCsv')}
                    onClick={() => this.getSearchDataFromServer(this.state.filterCriteria, 'submitJob')}
                    disabled={this.state.enableSubmit == "yes" && this.state.batchName ? false : true}
                  >
                    Submit Job
                  </Button>}
                {this.state.searchValue &&
                  <CSVLink
                    data={this.state.exportData}
                    filename={`${this.props.type}.csv`}
                    className="hidden text-white"
                    ref={(r) => this.csvLinkRef = r}
                    target="_blank" >
                  </CSVLink>}
                {this.state.printRequried ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto  mb-0 mt-1"
                    onClick={() => this.print()}
                  >
                    <FontAwesomeIcon
                      icon='print'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Print")}
                      onClick={() => this.print()}
                    />
                  </Button>
                  : null}
              </div>
              <div className='col-sm-6' style={{ textAlign: "left", alignSelf: 'center' }}>
                {this.state.singleSearchValue &&
                  <Button color="primary"
                    className='p-1 m-2'
                    size={'sm'} //onClick={this.getSearchDataFromServer(this.state.filterCriteria, 'exportToCsv')}
                    onClick={() => this.getSearchDataFromServer(this.state.filterCriteria, 'exportToCsv')}
                  >
                    Export to CVS
                  </Button>}
                {this.state.singleSearchValue &&
                  <CSVLink
                    data={this.state.exportData}
                    filename={`${this.props.type}.csv`}
                    className="hidden text-white"
                    ref={(r) => this.csvLinkRef = r}
                    target="_blank" >
                  </CSVLink>}
                {this.state.printRequried ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto  mb-0 mt-1"
                    onClick={() => this.print()}
                  >
                    <FontAwesomeIcon
                      icon='print'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Print")}
                      onClick={() => this.print()}
                    />
                  </Button>
                  : null}
                {/* </div>
                  <div className='col-sm-6' style={{ textAlign: "right", alignSelf: 'center' }}> */}
                <Button
                  color="primary"
                  size="sm"
                  disabled={this.state.singleSearchValue ? false : true}
                  className={'p-1 m-2'}
                  style={{ width: "75px" }}
                  onClick={() => this.getSearchDataFromServer(this.state.filterCriteria, 'refresh')}>
                  Go
                  {/* <FontAwesomeIcon
                        icon='sync-alt'
                        size='lg'
                        data-toggle="tool-tip" title={t("Refresh")}
                        color={config.templateColor}
                        className='refreshIcon pl-1' /> */}
                </Button>
                {/* <div className='col-sm-7 px-4' style={{
              textAlign: "right"
            }}> */}
                {/* Note: The Estate Names limited to 20 names from the EstateToAddress table. */}
                {/* </div> */}
              </div>
              {/* </div> */}
              {/* </div> */}

            </div>
          </div>
          {/* Paginator */}
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {this.getPaginator()}
              </div>
            </span>
            <Loader loader={this.state.isLoadingsearch} />
          </div>
        </div>
      </div >
    )
  }
  onGlobalSerachFilterChange = async (event) => {
    if (event) {
      await this.setState({
        [event.target.name]: event.target.value
      })
      await this.onFilterChange(event)
    }

  }
  getHeader() {
    const { t } = this.props;
    return (
      // <div className="row">
      //   {/* global Search */}
      //   <div className='col-6'>
      //   </div>
      //   <div className='col-6'>
      //     <div className='serachAlignment float-right' >
      //       <div className="p-inputgroup">
      //         <span className="p-inputgroup-addon">
      //           <i className="pi pi-search" ></i>
      //         </span>
      //         <InputText
      //           type="search"
      //           name='globalSearch'
      //           onChange={(e) => this.onFilterChange(e)}
      //           placeholder={this.props.globalSearch ? this.props.globalSearch : 'search'}
      //           size="30" />
      //       </div>
      //     </div>
      //   </div>
      // </div >

      <div>
        <div className='row'>
          {/* global Search */}
          <div className='col-sm-12'>
            <h4 >
              <span className='postionRelative pt-2'>
                <b
                ><Link to={(this.props.routeTo)} onClick={this.closeFormModal}>
                    {t(this.props.type)}
                  </Link>{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
                </b>
              </span>
              <span className='float-right pl-3 '>
                {this.props.globalSearch && <div>
                  <div
                    className="p-inputgroup"
                  >
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-search" ></i>
                    </span>
                    <InputText
                      type="search"
                      name='globalSearch'
                      id='globalSearch'
                      value={this.state['globalSearch']}
                      onChange={(e) => this.onFilterChange(e)}
                      placeholder={this.props.globalSearch ? (this.props.globalSearch) : 'search'}
                      size="30" />

                    {/* <AutoComplete
                      // onSelect={(e) => console.log(e)}
                      multiple
                      value={this.state.selectedAutoCompleteValue}
                      suggestions={this.state.suggestions}
                      completeMethod={(e) => this.onGlobalSearchChange(e)}
                      field={'label'}
                      placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                      onChange={(e) => this.setState({ selectedAutoCompleteValue: e.value })}
                    // onChange={(e) => console.log(e)}
                    /><span className='searchIconGlobal'>
                      <i className="pi pi-search" ></i>
                    </span> */}
                    {/* <input
                        type="search"
                        name='globalSearch'
                        id='globalSearch'
                        value={this.state['globalSearch']}
                        onChange={(e) => this.onFilterChange(e)}
                        placeholder={this.props.globalSearch ? (this.props.globalSearch) : 'search'}
                      /> */}

                  </div>
                </div>}
              </span>
            </h4>
          </div>
          <div className='col-12  pb-1'>
            <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {this.getPaginator()}
              </div>
            </span>
            <span className='pt-2'>
              {/* Export to csv */}
              <span className='float-left pt-2' >
                {this.props.filterRequired ?
                  <span className='mr-3' ref={this.closeMultiSelectDropdown}>
                    <span className='multiSelectDropDownCard '>
                      <span onClick={this.toogleMultiSelect}>
                        {t('Filters')}
                      </span>
                      <span onClick={this.toogleMultiSelect}>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className='ml-1'
                          color='grey'
                        />
                      </span>
                      {
                        this.state.showMultiSelectDropDown ?
                          <Card className=' itemsWarperCard' >
                            {
                              this.state.tablefieldsToShow && this.state.tablefieldsToShow.length > 0 ? this.state.tablefieldsToShow.map((item, index) => {
                                return (
                                  item.displayInFilter && item.show && < div >
                                    <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                    <ul className='pl-0'>
                                      {
                                        item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                          return (
                                            <div className='multiSelectWrapperItems' onClick={() => this.changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                              <span className='chekcIconAdjust'>
                                                <Checkbox
                                                  // onChange={e => setChecked(e.checked)}
                                                  checked={optionsItem.checkIcon}>
                                                </Checkbox>
                                                {/* <FontAwesomeIcon
                                           icon='check'
                                           color={optionsItem.checkIcon ? 'grey' : 'white'}
                                         /> */}
                                              </span>
                                              <span className='chekcIconAdjustLabel'>
                                                {t(optionsItem.label)}
                                              </span>
                                            </div>
                                          )
                                        }) : null
                                      }
                                    </ul>
                                    <hr className='my-0'></hr>
                                  </div>
                                )
                              }) : null
                            }
                          </Card>
                          : null}
                    </span>

                  </span>
                  : null}


                {/* {!this.state.isOpenFormModal ? <MultiSelect
                  style={{
                    lineHeight: 0,
                    position: 'relative',
                    top: 4,
                    paddingTop: 9,
                    paddingBottom: 9,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  appendTo={document.body}
                  className='mr-3'
                  name='multiActions'
                  placeholder={'Filters'}
                  maxSelectedLabels={2}
                  selectedItemsLabel='Aldfdefel'
                  value={this.state['multiActions'] ? this.state['multiActions'] : null}
                  options={this.props.actionsTypes}
                  onChange={(e) => this.onFilterChange(e, 'dropdownFilter')}
                /> : null} */}

                <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
                  className='mr-3'
                  // appendTo={document.body}
                  name='actions'
                  value={this.state.actions}
                  disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                  options={this.state.actionsTypes}
                  placeholder={t('Actions')}
                  onChange={(e) => this.onActionsChange(e, 'dropdownFilter')}
                />

                {this.props.printRequried ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto  mb-0 mt-1"
                    onClick={() => this.print()}
                  >
                    <FontAwesomeIcon
                      icon='print'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Print")}
                      onClick={() => this.print()}
                    />
                  </Button>
                  : null}
                {this.props.exportRequired && <Button color="primary"
                  className='p-1 ml-auto mb-0 mt-1'
                  size={'sm'} onClick={this.getDataToExport}>
                  <FontAwesomeIcon
                    icon='file'
                    data-toggle="tool-tip" title={t("Export To CSV")}
                    className='pl-1' size='lg' />
                </Button>}
                {this.props.exportRequired && <CSVLink
                  data={this.state.exportData}
                  filename={`${this.props.type}.csv`}
                  className="hidden text-white"
                  ref={(r) => this.csvLinkRef = r}
                  target="_blank" >
                </CSVLink>}
                {this.props.sample ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 mt-1 mb-0"
                    onClick={() => this.bulkUploadMoadal()}>
                    <FontAwesomeIcon
                      icon='upload'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Bulk Upload")}
                      onClick={() => this.bulkUploadMoadal()} />
                  </Button>
                  : null}

                {this.props.gridRequried && <ButtonGroup className='mb-0 mr-3'>
                  <Button color="primary"
                    outline={this.state.viewType === 'grid' ? false : true}
                    size="sm"
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => this.changeViewType('grid')}
                  >
                    <FontAwesomeIcon
                      icon='grip-horizontal'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Grid")}
                      onClick={() => this.changeViewType('grid')}
                    />
                  </Button>
                  <Button color="primary"
                    size="sm"
                    outline={this.state.viewType === 'list' ? false : true}
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => this.changeViewType('list')}
                  >
                    <FontAwesomeIcon
                      icon='list'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("List")}
                      onClick={() => this.changeViewType('list')}
                    />
                  </Button>
                </ButtonGroup>}
                {this.props.settingsRequired && <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => this.openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Settings")}
                    onClick={() => this.openShowHideColumnsModal()}
                  />
                </Button>}

                {/* Add button */}
                {this.props.addRequired ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => this.openFormModal('add')}>
                    <FontAwesomeIcon
                      icon='plus'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Add")}
                      onClick={() => this.openFormModal('add')}
                    />
                  </Button>
                  : null}

                {/* priority  in Issues*/}
                <Button
                  color="secondary"
                  size="sm"
                  // className="p-1 ml-auto"
                  className={this.props.addRequired ? "p-1 mt-1 mb-0" : 'p-1 ml-auto mt-1 mb-0'}
                  onClick={() => this.getDataFromServer(this.state.filterCriteria, 'refresh')}>
                  <FontAwesomeIcon
                    icon='sync-alt'
                    size='lg'
                    data-toggle="tool-tip" title={t("Refresh")}
                    color={config.templateColor}
                    className='refreshIcon pl-1' />
                </Button>

              </span>
            </span>
          </div>
        </div>

      </div >
    )
  }
  getCardHeader() {
    return (
      // <CardHeader className='cardHeader'>
      <div className="row m-0">
        {/* table name */}
        <div className='col-3 pl-0 pt-2'>
          <h4><b>{this.props.type}{this.props.type == 'Activiti' ? 'es' : ''}</b></h4>
        </div>
        <span className='col-9 text-right'>
          <Row>
            {/* Export to csv */}
            {/* <Button color="primary"
                    className='p-1 ml-auto'
                    size={'sm'} onClick={this.getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title="Export To CSV"
                      className='pl-1' size='lg' />
                  </Button>
                  <CSVLink
                    data={this.state.exportData}
                    filename={`${this.props.type}.csv`}
                    className="hidden text-white"
                    ref={(r) => this.csvLinkRef = r}
                    target="_blank" >
                  </CSVLink> */}
            {/* {this.props.sample ?
              <Button color="primary"
                size="sm"
                className="p-1"
                onClick={this.bulkUploadMoadal}>
                <FontAwesomeIcon
                  icon='upload'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Bulk Upload"
                  onClick={this.bulkUploadMoadal} />
              </Button>
              : null} */}
            {/* Add Settings Button */}
            {this.props.settingsRequired ?
              <Button color="primary"
                size="sm"
                className="p-1 ml-auto mt-1"
                onClick={() => this.openShowHideColumnsModal()}
              >
                <FontAwesomeIcon
                  icon='cog'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Settings"
                  onClick={() => this.openShowHideColumnsModal()}
                />
              </Button>
              : null}
            {/* Add button */}
            {this.props.addRequired ?
              <Button color="primary"
                size="sm"
                className="p-1 ml-auto"
                onClick={() => this.openFormModal('add')}>
                <FontAwesomeIcon
                  icon='plus'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Add"
                  onClick={() => this.openFormModal('add')}
                />
              </Button>
              : null}
            {/* priority  in Issues*/}
            <Button
              color="secondary"
              size="sm"
              // className="p-1 ml-auto"
              className={this.props.addRequired ? "p-1" : 'p-1 ml-auto'}
              onClick={() => this.getDataFromServer(this.state.filterCriteria, 'refresh')}>
              <FontAwesomeIcon
                icon='sync-alt'
                size='lg'
                data-toggle="tool-tip" title="Refresh"
                color={config.templateColor}
                className='refreshIcon pl-1' />
            </Button>
          </Row>
        </span>

      </div>
      // </CardHeader>
    )
  }
  cancelUserPwdResetModal = async () => {
    await this.setState({
      openUserPasswordResetModal: false,
      actions: ''
    })
  }

  getColumns(e, d) {
    const { t } = this.props
    const self = this;
    self.e = e;
    self.d = d;
    if (this.state.tablefieldsToShow && this.state.tablefieldsToShow.length > 0) {
      return this.state.tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            editRequired={self.e}
            deleteRequired={self.d}
            header={t(item.header)}
            changeFieldName={item.changeFieldName}
            body={self.changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 12,
              // color: config.whiteColor, backgroundColor: config.templateColor
            }}
            filter={item.filter ? item.filter : false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : 'search'}
            filterElement={item.filterElement ?
              <div style={{ height: 26 }}>
                <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.1 }}
                  appendTo={document.body}
                  name={item.field}
                  value={this.state[item.field]}
                  options={item.filterElement}
                  onChange={(e) => this.onFilterChange(e, 'dropdownFilter')} />
              </div>
              : null
            }
            selectionMode={item.selectionMode}
          />
        )

        return column;
      })
    }

  }

  getColumns1(e, d) {
    const { t } = this.props
    const self = this;
    self.e = e;
    self.d = d;
    if (this.state.tablefieldsToShow1 && this.state.tablefieldsToShow1.length > 0) {
      return this.state.tablefieldsToShow1.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            editRequired={self.e}
            deleteRequired={self.d}
            header={t(item.header)}
            changeFieldName={item.changeFieldName}
            body={self.changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 12,
              // color: config.whiteColor, backgroundColor: config.templateColor
            }}
            filter={item.filter ? item.filter : false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : 'search'}
            filterElement={item.filterElement ?
              <div style={{ height: 26 }}>
                <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.1 }}
                  appendTo={document.body}
                  name={item.field}
                  value={this.state[item.field]}
                  options={item.filterElement}
                  onChange={(e) => this.onFilterChange(e, 'dropdownFilter')} />
              </div>
              : null
            }
            selectionMode={item.selectionMode}
          />
        )

        return column;
      })
    }

  }

  getDataTable(flag) {
    let self = this;
    self.editRequired = this.props.editRequried;
    self.deleteRequired = this.props.deleteRequried;
    console.log("flag", flag, this.state.allUsersData1)
    return (
      <div>
        <Loader loader={this.state.isLoading} />
      <DataTable
        rowClassName={this.rowClassName}
        ref={(el) => this.dt = el}
        value={flag == "one" ? this.state.allUsersData : this.state.allUsersData1}
        // header={this.getHeader()}
        totalRecords={this.state.totalRecordsLength}
        paginator={false}
        lazy={true}
        resizableColumns={true}
        columnResizeMode="expand"
        onSort={this.sortChange}
        globalFilter={this.state.globalFilter}
        onFilter={this.onFilterChange}
        scrollable={true}
        // selection={false}
        onSelectionChange={e => this.onSelectRowsUpdate(e)}
        scrollHeight='1000px'
        // style={{ marginTop: 0 }}
        emptyMessage={configMessages.noRecords}
        sortMode="single"
        // sortField="fname"
        // sortOrder={-1}
        selection={this.state.selectedRows}
        // selectionMode={'multiple'}
        metaKeySelection={false}
        style={this.state.allUsersData && this.state.allUsersData.length == 0 ?
          { textAlign: 'center' }
          : null}
      >
        {flag == "one" ? self.getColumns(self.editRequired, self.deleteRequired) : self.getColumns1(self.editRequired, self.deleteRequired)}

      </DataTable>
      </div>
    )
  }
  getTabInfo() {
    //console.log('from datatable  getTabinfo');
    return null;
  }

  getPaginator() {
    return (
      <PaginatorComponent
        totalRecords={this.state.totalRecordsLength}
        first={this.state.first}
        rows={this.state.rows}
        onPageChange={this.onPageChange}
        isWeb={true}
      />
    )
  }

  //getGridView
  getGridView() {
    console.log(this.state.allUsersData)
    console.log(this.state.tablefieldsToShow)
    return (
      <div className='row   ml-lg-0 pr-1'>
        {
          this.state.allUsersData && this.state.allUsersData.length > 0 ?
            this.state.allUsersData.map((item, i) => {
              return (
                <div className='col-sm-6 col-md-4 col-lg-3 px-1' key={i}>
                  <Card className='pb-2' >
                    <CardBody className='tableCardBody'>
                      {
                        this.state.tablefieldsToShow && this.state.tablefieldsToShow.length && this.state.tablefieldsToShow.length > 0 ?
                          this.state.tablefieldsToShow.map((elememt, index) => {
                            return (
                              <div className={(elememt.displayInSettings === true) ? `col-12  ` : 'd-none'} key={index}>
                                <div >
                                  {/* {this.changeFieldValues(item, element)} */}
                                  {
                                    elememt.fieldType === 'Link' ?
                                      <div className='d-flex'>
                                        <span
                                          style={elememt.style}
                                          onClick={() => this.openViewModal(item, 'view')}>
                                          <b> {item[elememt.field]}</b>
                                        </span>
                                      </div>
                                      :
                                      elememt.fieldType === 'Badge' ?
                                        <div style={elememt.style}>
                                          <Badge color={item.status == 'Active' ? 'success' : item.status == 'Inactive' ? 'warning' : item.status == 'Pending' ? 'danger' : item.status == 'Reject' ? 'error' : item.status == 'Completed' ? 'primary' : 'info'}>
                                            {item[elememt.field]}
                                          </Badge>
                                        </div> :
                                        elememt.fieldType === 'Role' ?
                                          <div style={elememt.style}>
                                            <Badge pill
                                              color='success'
                                            >
                                              {item[elememt.field]}
                                            </Badge>
                                          </div>
                                          : elememt.fieldType === 'Date' ?
                                            <div>
                                              {dateFormats.formatDate(item[elememt.field], config.dateDayMonthFormat)}
                                            </div>
                                            : elememt.fieldType === 'Time' ?
                                              <div>
                                                {dateFormats.formatDate(item[elememt.field], config.timeFormat)} - ${dateFormats.formatDate(item['toTime'], config.timeFormat)}
                                              </div>
                                              : elememt.fieldType === 'Array' ?
                                                <span style={element.style}>
                                                  {this.flattenArray(item[elememt.field])}
                                                </span>
                                                : <div style={elememt.style}>
                                                  <span
                                                  >{item[elememt.field]}</span></div>
                                  }
                                </div>
                              </div>
                            )
                          }) : null
                      }
                    </CardBody>
                  </Card>
                </div>

              )
            }) : null
        }
      </div>
    )
  }

  getTotalCard() {
    //   console.log(this.state.displayViewOfForm)
    if (this.state.viewType === 'list') {
      return (
        <Card className='cardForListMargin' >
          <CardBody className='tableCardBody'>
            {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getScreenHeader() : this.state.displayViewOfForm === 'modal' ? this.getHeader() : null}
            {this.getTabInfo()}
            <div className='row mr-0 ml-0'>
              {/* <div className='col-sm-6 px-4 pb-4'>
                {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? 'OPRA Results' : ''}
                {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen'
                  ? this.getDataTable('one') : this.state.displayViewOfForm === 'modal' ? this.getDataTable('one') : null}
              </div> */}
              <div className='col-sm-12 px-4 pb-4'>
                {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ?
                  <div className='row mr-0 ml-0'>
                    <div className='col-sm-4'>
                      {'New Score Results'}
                    </div>
                    {/* <div className='col-sm-4'>
                      <Button color="primary"
                        size="sm"
                        className="p-1 ml-auto mt-1 mb-0"
                        onClick={() => this.openFormModal('add')}>
                        {'Add Estate'}
                        <FontAwesomeIcon
                          icon='plus'
                          className='pl-1' size='lg'
                          data-toggle="tool-tip" title={"Add"}
                          onClick={() => this.openFormModal('add')}
                        />
                      </Button>
                    </div>
                    <div className='col-sm-4'>
                      <Button color="primary"
                        size="sm"
                        className="p-1 ml-auto mt-1 mb-0"
                        onClick={() => this.openFormModal('add')}>
                        {'Add Property'}
                        <FontAwesomeIcon
                          icon='plus'
                          className='pl-1' size='lg'
                          data-toggle="tool-tip" title={"Add"}
                          onClick={() => this.openFormModal('add')}
                        />
                      </Button>
                    </div> */}
                  </div> : ''}
                {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen'
                  ? this.getDataTable('two') : this.state.displayViewOfForm === 'modal' ? this.getDataTable('two') : null}
              </div>
            </div>
            {this.state.isOpenFormModal ? this.getFormModal() : null}
          </CardBody>
        </Card>
      )
    } else if (this.state.viewType === 'grid') {
      return (
        <div>
          <Card
            className={' pb-2 cardForGridMargin'}>
            <CardBody className='tableCardBody pb-0'>
              {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getScreenHeader() : this.state.displayViewOfForm === 'modal' ? this.getHeader() : null}
              {this.getTabInfo()}
              {this.state.isOpenFormModal ? this.getFormModal() : null}
            </CardBody>
          </Card>
          {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getGridView() : this.state.displayViewOfForm === 'modal' ? this.getGridView() : null}
        </div>
      )
    }
  }

  getMobileCard() {
    return (
      <div>
        <Loader loader={this.state.isLoading} />
        {this.state.isOpenFormModal ? null : <div className='row mr-0 ml-0'>
          <div className='col-sm-12 px-0 pb-2'>
            <h4><span className='mobileScreenHaederView'><b >{this.props.type}</b></span>
              <span className='float-right'>
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => this.openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Settings"
                    onClick={() => this.openShowHideColumnsModal()}
                  />
                </Button>
              </span>
            </h4>
          </div>
        </div>}
        {this.state.isOpenFormModal ? this.getMobileForm() : this.getGridView()}
        {!this.state.isOpenFormModal ? <PaginatorComponent
          totalRecords={this.state.totalRecordsLength}
          first={this.state.first}
          rows={this.state.rows}
          onPageChange={this.onPageChange}
          isWeb={false}
        /> : null}
      </div >
    )
  }

  flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }
  getMobileForm() {
    return (
      <div className='row'>
        <div className='col-sm-12 px-1'>
          <Card className='pb-0'>
            <CardBody className='tableCardBody pb-0'>
              {/* {this.getScreenHeader()} */}
              {this.getFormModal()}
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
  getFormFields = () => {
    this.formModalRef.getFormFields()
  }
  getFormModal() {
    return (
      <FormModal
        onRef={(ref) => this.formModalRef = ref}
        openFormModal={this.state.isOpenFormModal}
        allUsersData={this.state.allUsersData}
        totalRecords={this.state.totalRecordsLength}
        first={this.state.first}
        rows={this.state.rows}
        closeFormModal={this.closeFormModal}
        closeRejectModal={this.closeRejectModal}
        type={this.props.type}
        tablefieldsToShow={this.state.tablefieldsToShow}
        originalTableFields={this.state.originalTableFields}
        formType={this.state.formType}
        formFields={this.props.formFields}
        getDataFromServer={this.getDataFromServer}
        editRequired={this.props.editRequired}
        getEditInfoKeys={this.props.getEditInfoKeys}
        filterExtension={this.props.filterExtension}
        idNotRequired={this.props.idNotRequired}
        apiUrl={this.props.formUrl ? this.props.formUrl : this.state.apiUrl}
        role={this.state.loginRole}
        tabType={this.state.tabType}
        getDoctorPostingFields={this.props.getDoctorPostingFields}
        categoryNames={this.props.categoryNames}
        companiesList={this.props.companiesList}
        getSettings={this.getSettings}
        filterCriteria={this.state.filterCriteria}
        menuList={this.props.menuList}
        routeTo={this.props.routeTo}
        displayViewOfForm={this.state.displayViewOfForm}
        displayBreadCrumbValue={this.state.displayBreadCrumbValue}
        displayBreadCrumbField={this.state.displayBreadCrumbField}
        userStatus={this.state.userStatus}
        actionsTypes={this.state.actionsTypes}
        entityType={this.props.entityType}
        setFilterCriteriaForActivities={this.setFilterCriteriaForActivities}
      />
    )
  }

  getViewModal() {
    return (
      <ViewModal
        type={this.props.type}
        openViewModal={this.state.openViewModal}
        displayViewOfForm={this.state.displayViewOfForm}
        rowData={this.state.rowData}
        formFields={this.props.formFields}
        onRef={(ref) => (this.viewModalRef = ref)}
        closeViewModal={this.closeViewModal}
        locationId={this.state.locationId}
        drawers={this.state.drawers}
      />
    )
  }

  getOpenShowHideColumnsModal() {
    return (
      <ShowHideColumnsModal
        viewType={this.state.viewType}
        isOpenShowHideColumnsModal={this.state.isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={this.closeShowHideColumnsModal}
        tableFields={this.state.originalTableFields}
        type={this.props.type}
      />
    )
  }
  getDeleteRowModal() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openDeleteModal}
        closeDeleteModal={this.closeDeleteModal}
        selectActions={this.state.selectActions}
        deleteSelectedRow={this.deleteSelectedRow}
        confirmModalText={this.state.confirmModalText}
      />
    )
  }
  getPreviewModal() {
    return (
      <PreviewModal
        isPreviewModal={this.state.isPreviewModal}
        closePreviewModal={this.closePreviewModal}
        emailTemplateData={this.state.emailTemplateData}
        type={this.props.type}
      />
    )
  }
  getBulkUploadModal() {
    return (
      <BulkUploadModal
        onRef={(ref) => this.bulkUploadMoadalRef = ref}
        bulkApi={this.props.bulkApi}
        type={this.props.type}
        sample={this.props.sample}
        sampleFilePath={this.props.sampleFilePath}
        openBulkUploadModal={this.state.openBulkUploadModal}
        closeBulkModal={this.closeBulkModal}
        reload={this.getDataFromServer}
      />
    )
  }

  getConfirmatioinModal() {
    return (
      <ConfirmationModal
        formType={this.state.formType}
        onRef={(ref) => (this.confirmRef = ref)}
        openConfirmationModal={this.state.openConfirmationModal}
        closeConfirmationModal={this.closeConfirmationModal}
        confirm={this.clickConfirm}
        text={this.state.confirmText}
      />
    )
  }

  getSessionExpiryModal() {
    return (
      <SessionExpiryModal
        SOpen={this.state.sessionExpiryModal}
      />
    )
  }

  render() {
    return (
      <div >
        {this.state.width <= 576 && this.getMobileCard()}
        {this.state.width >= 577 && this.getTotalCard()}
        {this.state.isOpenShowHideColumnsModal ? this.getOpenShowHideColumnsModal() : null}
        {this.state.openDeleteModal ? this.getDeleteRowModal() : null}
        {this.state.openViewModal ? this.getViewModal() : null}
        {this.props.sample ? this.getBulkUploadModal() : null}
        {this.state.openConfirmationModal ? this.getConfirmatioinModal() : null}
        {this.state.sessionExpiryModal ? this.getSessionExpiryModal() : null}
        {this.state.isPreviewModal ? this.getPreviewModal() : null}
      </div >
    );
  }
}

const mapStateToProps = state => {
  return { articles: state.articles };
};
const List = connect(mapStateToProps)(DataTables);

export default withTranslation('common')(DataTables);
