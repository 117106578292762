//download packages react-data-export,xlsx
import React from "react";
import ReactExport from "react-data-export";
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default class ExcelReact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			languageData: "",
		};
	}

	render() {
		const { sheets, type } = this.props
		return (

			<ExcelFile filename={this.props.EstateName}
				element={
					<Button color="primary"
						outline
						className="mb-0"
						style={{ display: "none" }}
					>
						<FontAwesomeIcon
							icon='download'
							id="download_excel"
							data-toggle="tool-tip" title={"Export To CSV"}
							style={{ width: 22, height: 18, marginLeft: "10px" }}
							className='pl-1' size='lg' />
					</Button>
				}
			>
				{sheets && sheets.length > 0 ?
					sheets.map((sheet, i) => {
						return sheet && sheet.headers && sheet.headers.length > 0 ? < ExcelSheet data={sheet.data} name={sheet.name}>
							{sheet.headers.map((item, i) => {
								return <ExcelColumn label={item.label ? item.label : item} value={item.field ? item.field : item} />

							})}
						</ExcelSheet> :
							< ExcelSheet data={[]} name={sheet.name}>
								<ExcelColumn label={""} value={""} /></ExcelSheet>
					})
					: ""}
			</ ExcelFile >
		);
	}
}