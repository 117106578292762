import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Buckets from './components/ElasticQuery';

const buckets = ({ t }) => (
  <div>
    <Buckets />
  </div>
);

buckets.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(buckets);
