import PropTypes from 'prop-types';
import { default as React, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Button, Spinner } from 'reactstrap';
import { reduxForm } from 'redux-form';
import fetchRequest, { sendLoginRequestForToken } from '../../../../config/service';
// import {settingsAction} from '../../../../redux/actions/settingsAction'
// import { settings } from '../../../../redux/actions/settingsAction';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
// Validate input
import validate from '../../../Validations/validate';

// const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)


class LogInButton extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoginSuccess: false,
            isLoading: false,
            loginRole: '',
            errorMessage: null,
            listView: false,
            isTaskUpdate: false
        };
    }

    componentDidMount = async () => {
        let url = window.location.href
        url = url.substring(1);
        let k = url.split("=")
        // Reload.reload()//for testing new Changes are present

        if (k.length > 1) {
            url = new URL(window.location.href);

            // let n = k[1].split('&')
            // let validId = this.containsSpecialChars(n[0])
            var taskId = url.searchParams.get("id");

            if (taskId && taskId.length === 24) {
                localStorage.setItem("taskId", taskId)
            }
            // let n = k[1].split('&');
            // localStorage.setItem("taskId", n[0])
            localStorage.setItem("loggedUserId", k[2])
            let loggeddetails = localStorage.getItem('loginCredentialsId');
            taskId = localStorage.getItem('taskId');
            if (taskId)
                await this.setState({
                    isTaskUpdate: true
                })
        }
        this.getLoginRememberInfo();
    }

    getLoginRememberInfo = async () => {
        let keysToRemove = ['sessionexpired', 'SerchedCompany', 'loginCredentials', "loginCredentialsId", "rolePermissions", 'loginBody', 'sessionexpired'];
        let Storage = Object.keys(window.localStorage);
        for (let key of Storage) {
            let result = key.includes("_column_order");
            if (result) {
                keysToRemove.push(key);
            }
        }
        if (!this.state.isTaskUpdate) {
            for (let key of keysToRemove) {
                localStorage.removeItem(key);
            }
        }
        let loginRememberInfo = localStorage.getItem('loginRememberInfo');
        loginRememberInfo = loginRememberInfo ? JSON.parse(loginRememberInfo) : null;
        await this.props.load(loginRememberInfo);
        this.setState({
            loginRememberInfo: loginRememberInfo,
            remember_me: loginRememberInfo && loginRememberInfo.remember_me ? loginRememberInfo.remember_me : false
        })
    }


    submit = async () => {
        this.setState({
            isLoading: true,
            errorMessage: null
        });
        try {
            const accessToken = await this.props.handleSubmit();
            //console.log("accessToken", accessToken)
            const userDetails = await sendLoginRequestForToken(accessToken);
            //console.log("userDetails", userDetails)
            if (userDetails && userDetails._id) {
                // this.getSettingsData(userDetails);
                // save user credentials in storage
                localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                localStorage.setItem('loginCredentialsId', userDetails._id);
                localStorage.setItem('rolePermissions', JSON.stringify(userDetails.rolePermissions));
                if (userDetails && userDetails.roleName && userDetails.roleName === 'Leadership') {
                    this.setState({
                        listView: true
                    })
                }
                await this.setState({
                    isLoginSuccess: true,
                });
            }
        }
        catch (err) {
            console.error(err);
            this.setState({ errorMessage: 'Login failed. Please contact your administrator if the error persists.' });
        }
        finally {
            this.setState({
                isLoading: false
            });
        }
    }

    // getSettingsData = (userDetails) => {
    //     let settingsData;
    //     let filterCriteria = {};
    //     filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    //     fetchRequest('GET', `settings`).then(async (response) => {
    //         if (response && response.respCode) {
    //             settingsData = response.settings[0]
    //             await this.props.load(settingsData)
    //             await this.props.settingsLoad(settingsData)
    //         }
    //     })
    //     return settingsData;
    // }

    render() {
        const { isLoading } = this.state;
        return (
            <>
                <Button
                    className="loginbtn ml-auto btn-block"
                    style={{ width: '100%' }}
                    color="primary"
                    onClick={this.submit}
                    disabled={isLoading}
                >
                    {isLoading ? (<Spinner
                        size="sm"
                    />) :
                        <span>Sign in</span>
                    }
                </Button>
                {
                    this.state.errorMessage && (
                        <div class="alert alert-danger text-center" role="alert">{this.state.errorMessage}</div>)
                }
                {/* change */}
                {/* Navigate to Home */}
                {this.state.isLoginSuccess ?
                    <Redirect to="/scorings" /> : null}
            </>
        );
    }
}

LogInButton = reduxForm({
    form: 'log_in_form', // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(LogInButton);

// You have to connect() to any reducers that you wish to connect to yourself
LogInButton = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    {
        load: loadAccount,// bind account loading action creator
        // settingsLoad: settings //binding settingsDAta
    }
)(LogInButton);

export default LogInButton;
